<button type="button" class="btn btn-icon btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_select_invoice_item" id="itemLookup" [hidden]="buttonIsHidden">
</button>

<div class="modal fade" id="kt_modal_select_invoice_item" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <div class="modal-header" id="kt_modal_select_invoice_item_header">
                <h2 class="fw-bolder">{{'_Select_article' | translate}}</h2>
                <div id="kt_modal_select_invoice_item_close" class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
                    <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="black" />
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                fill="black" />
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body py-10 px-lg-17"  style="padding-left: 30px !important;padding-right: 30px !important;">
                <div>
                    <div class="fv-row mb-7">
                            <input (change)="searchItems()" [(ngModel)]="searchItemCodeName" [ngModelOptions]="{standalone:true}" type="text"
                            data-kt-supplier-table-filter="search" class="form-control form-control-solid w-250px ps-4" id="searchItemInput" 
                            placeholder="{{'_Search_articles' | translate}}" />
                    </div>
                    <div class="fv-row mb-7">
                        <div class="table-window" style="border: dashed #eee 1px;">
                            <table class="table align-middle fs-6 gy-5">
                                <div class="bg-gray-200">
                                <thead class="scroll-items-header">
                                    <tr class="fs-5 fw-bold form-label" style="display: table;
                                    width: 100%;
                                    table-layout: fixed;">
                                        <th style="padding-left:20px;font-weight: bold;width:20%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">{{'_Code'|translate}}</th>
                                        <th style="font-weight: bold;width:20%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">{{'_Article_name'|translate}}</th>
                                        <th style="font-weight: bold;width:15%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">{{'_MPC'|translate}}</th>
                                        <th style="font-weight: bold;width:15%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">{{'_VPC'|translate}}</th>
                                        <th style="font-weight: bold;width:15%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;">{{'_NC'|translate}}</th>
                                      <th style="font-weight: bold;width:15%;font-size:14px;
                                        text-transform: uppercase;padding-top:12px;padding-bottom:12px;" *ngIf="!noStates">{{'_State'|translate}}</th> 
                                    </tr>
                                </thead>
                                </div>
                                <div class="hover-scroll-overlay-y scroll-items" #itemSelectBody style=" margin-top: 0%;
                                padding-top: 0%;
                                max-height: 20em;
                                width: 100%;
                               overflow-y: scroll; ">
                                    <tbody *ngIf="!addingRaw">
                                        <tr *ngFor="let a of itemsToAdd;let r=index" tabindex="0" class="fs-5 clickableRow" (click)="selectArticle(a)" (keydown)="enterOnRow($event)" data-bs-dismiss="modal" style="border-bottom: #eee dashed 1px !important;">
                                            <td style="padding-left:20px;width:20%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.code}}</td>
                                            <td style="width:20%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.name}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.mpc}} {{selectedCurrency}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.vpc}} {{selectedCurrency}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.nc}} {{selectedCurrency}}</td>
                                         <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;" *ngIf="!noStates">{{a.warehouseState}}</td> 
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="addingRaw">
                                        <tr *ngFor="let a of rawsToAdd;let r=index" tabindex="0" class="fs-5 clickableRow" (click)="selectArticle(a)" (keydown)="enterOnRow($event)" data-bs-dismiss="modal" style="border-bottom: #eee dashed 1px !important;">
                                            <td style="padding-left:20px;width:20%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.code}}</td>
                                            <td style="width:20%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.name}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.mpc}} {{selectedCurrency}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.vpc}} {{selectedCurrency}}</td>
                                            <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;">{{a.nc}} {{selectedCurrency}}</td>
                                          <td style="width:15%;font-size:14px;padding-top:8px;padding-bottom:8px;" *ngIf="!noStates">{{a.warehouseState}}</td> 
                                        </tr>
                                    </tbody>
                                </div>
                            </table>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
