import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentTypeModel } from 'src/app/models/paymentType.model';
import { TaxModel } from 'src/app/models/tax.model';
import { DataService } from 'src/app/services/data.service';
import { PaymentTypeRestService } from 'src/app/services/payment-type.service';
import { TranslateService } from 'src/app/translate';
import toastr from 'toastr'

declare var $: any;
declare var KTMenu: any;
declare var Swal: any;

@Component({
  selector: 'app-payment-type',
  templateUrl: './payment-type.component.html',
})
export class PaymentTypeComponent implements OnInit {

  public emptyData: boolean = true;
  public paymentTypees: PaymentTypeModel[];
  public searchForm: UntypedFormGroup;
  public formGroup: UntypedFormGroup;
  public isSubmitted: boolean = false;
  public selectedpaymentType: PaymentTypeModel;
  public searchName:string='';
  public modes:any[]=[{code:'CASH', name:'_Cash'}, {code:'VIR', name:'_Vir'}];
  public selectedCode:string = 'CASH';
  //pagination
  public page: number = 1;
  public perPage: number;
  public totalData: number;
  public maxPage: number;
  public pages: number[] = [];
  public offset: number = 5;
  public firstPage: number = 1;
  public lastPage: number;
  public counter: number = 0;
  public firstLoad: boolean = true;
  public applyZeroPrice:boolean=false;

  get f() { return this.formGroup.controls; }

  @ViewChild('closeAddpaymentTypeModal') closeAddpaymentTypeModal: ElementRef;
  @ViewChild('closeEditpaymentTypeModal') closeEditpaymentTypeModal: ElementRef;
  @ViewChild('selectCode') selectCode: ElementRef;

  constructor(
    private paymentTypeRest: PaymentTypeRestService,
    private formBuilder: UntypedFormBuilder,
    private _changeRef: ChangeDetectorRef,
    public data: DataService,
    private translate: TranslateService) {
    this.page = 1;
    this.perPage = 50;
  }

  ngOnInit() {
      KTMenu.createInstances();
      this.createSearchForm();
      this.fetchpaymentTypees();
      this.createAddEditForm();
  }


  async fetchpaymentTypees() {
      try {
        this.emptyData=false;

          var response = await this.paymentTypeRest.getPaymentTypes(this.data.user.clientId, this.searchName, this.page, this.perPage);
          this.paymentTypees = response.data;

          this.totalData = response.totalCount;
          if (this.totalData == 0)
              this.emptyData = true;
          else
              this.emptyData = false

          if (this.firstLoad) {
              this.maxPage = response.totalPages;
              this.maxPage = Math.floor(this.maxPage);
              this.firstPage = 1;
              if (this.maxPage > 5)
                  this.lastPage = 5;
              else
                  this.lastPage = this.maxPage;

              this.enablePrevious();
              this.firstLoad = false;
          }
          this.initPages();
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  async addpaymentType() {
      this.isSubmitted = false;
      if (this.formGroup.invalid) {
          this.isSubmitted = true;
      }
      if (this.isSubmitted == true) {
          return;
      }

      var obj = this.createAddObject();

      try {
          await this.paymentTypeRest.createPaymentType(obj);
          this.closeAddpaymentTypeModal.nativeElement.click();
          toastr.options = this.data.toastrOptions;
          toastr.success(this.translate.instant('_Added_paymentType_text'), this.translate.instant("_Success"));
          this.fetchpaymentTypees();
      }
      catch (err) {
          toastr.options = this.data.toastrOptions;
          toastr.error(err.error['Message'], this.translate.instant("_Error"));
      }
  }

  async updatepaymentType() {
      this.isSubmitted = false;
      if (this.formGroup.invalid) {
          this.isSubmitted = true;
      }
      if (this.isSubmitted == true) {
          return;
      }

      var obj = this.createEditObject();

      try {
          await this.paymentTypeRest.updatePaymentType(obj);
          this.closeEditpaymentTypeModal.nativeElement.click();
          toastr.options = this.data.toastrOptions;
          toastr.success(this.translate.instant('_Updated_paymentType_text'), this.translate.instant("_Success"));
          this.fetchpaymentTypees();
      }
      catch (err) {
          toastr.options = this.data.toastrOptions;
          toastr.error(err.error['Message'], this.translate.instant("_Error"));
      }
  }

  searchpaymentTypees(paymentTypeName:string) {
      this.searchName=paymentTypeName;
      this.fetchpaymentTypees();
  }

  createAddObject() {
      var obj = new PaymentTypeModel();
      obj.name = this.formGroup.controls.name.value;
      obj.code = this.formGroup.controls.code.value;
      obj.clientId = parseInt(this.data.user.clientId);
      obj.applyZeroProfitPrice=this.applyZeroPrice;

      return obj;
  }

  createEditObject() {
      var obj = new PaymentTypeModel();
      obj.id = this.selectedpaymentType.id;
      obj.name = this.formGroup.controls.name.value;
      obj.code = this.formGroup.controls.code.value;
      obj.clientId = parseInt(this.data.user.clientId);
      obj.applyZeroProfitPrice=this.applyZeroPrice;

      return obj;
  }

  createSearchForm() {
      this.searchForm = this.formBuilder.group({
          name: [''],
      })
  }

  createAddEditForm() {
      this.formGroup = this.formBuilder.group({
          name: ['', Validators.required],
          code: ['CASH'],
          applyZeroPrice: [false]
    })
}

  selectpaymentType(paymentType: PaymentTypeModel) {
      this.selectedpaymentType = paymentType;
      this.formGroup.controls['name'].setValue(this.selectedpaymentType.name);
      this.formGroup.controls['code'].setValue(this.selectedpaymentType.code);

      this.applyZeroPrice=this.selectedpaymentType.applyZeroProfitPrice;
  }

  resetForm() {
      this.formGroup.controls['name'].setValue("");
      this.formGroup.controls['code'].setValue('CASH');

      this.applyZeroPrice=false;
  }

  closeAddModal() {
      var self = this;
      var question_text = this.translate.instant("_Cancel_question_text");
      var yes_text = this.translate.instant("_Yes_cancel_text");
      var no_text = this.translate.instant("_No_return_text");
      Swal.fire({
          html: question_text,
          icon: "warning",
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: yes_text,
          cancelButtonText: no_text,
          customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: 'btn btn-secondary'
          }
      }).then(async (result) => {
          if (result.isConfirmed) {
              self.closeAddpaymentTypeModal.nativeElement.click();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
      });
  }

  closeEditModal() {
    this.closeEditpaymentTypeModal.nativeElement.click();
  }

      //pagination
      initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage=1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchpaymentTypees();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage>this.firstPage+this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage=this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                    if(this.maxPage>this.firstPage+this.offset)
                        this.lastPage = this.firstPage + this.offset;
                    else
                        this.lastPage=this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else 
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchpaymentTypees();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchpaymentTypees();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchpaymentTypees();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchpaymentTypees();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }

}

