import { Injectable } from "@angular/core";

@Injectable()
export class DataService {

    public isUserLogged: boolean = true;
    public user: any;
    public token: string = "";
    public currentMode: string = "";
    public lang: string = "bs";
    public fiscalTypeCode:string;

    public toastrOptions = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toastr-bottom-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    checkIfUserIsLogged() {
        this.user = JSON.parse(sessionStorage.getItem("logged_erp_client") || '{}') as any;
        if (this.user != null && this.user != undefined && Object.entries(this.user).length > 0) {
            this.isUserLogged = true;
            this.token = this.user.token;
        }
        else
            this.isUserLogged = false;
    }

    logout() {
        sessionStorage.removeItem("logged_erp_client");
        sessionStorage.removeItem("dispatchNotePage");
        sessionStorage.removeItem("incomingInvoicePage");
        sessionStorage.removeItem("outgoingInvoicePage");
        sessionStorage.removeItem("productionDocumentPage");
        sessionStorage.removeItem("stocktakingPage");
        sessionStorage.removeItem("warehouseTransferPage");

        this.checkIfUserIsLogged();
    }

    updateCurrentLayoutMode() {
        this.currentMode = sessionStorage.getItem("erp_current_mode");
    }

    saveDocumentState(key:string, document:any){
        sessionStorage.setItem(key, JSON.stringify(document));
        console.log('saved ' + document);
    }

    getDocumentState(key:string){
        var value = sessionStorage.getItem(key);
        if(value=='undefined'){
            return undefined;
        }
        
        var document = JSON.parse(value) as any;
        return document;
    }

    setFiscalType(code:string){
        sessionStorage.setItem('fiscalType', code);
    }

    getFiscalType(){
        this.fiscalTypeCode = sessionStorage.getItem('fiscalType');
        return this.fiscalTypeCode;
    }
}