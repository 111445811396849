import { HalfProductModel } from "./halfProduct.model";
import { NormativeModel } from "./normative.model";

export class ArticleModel{
    id:number;
    mpc:number;
    nc:number;
    vpc:number;
    margin:number;
    ruc:number;
    code:string;
    hh:number;
    happyHourPrice:number;
    fisNumber:number;
    minAmount:number;
    barcodes:string[];
    name:string;
    description:string;
    standardize:boolean;
    halfProduct:boolean;
    priority:number;
    picture:string;
    groupId:number;
    groupTypeId:number;
    groupName:number;
    clientId:number;
    measureId:number;
    isActive:boolean;
    isVisible:boolean;
    isNormative:boolean;
    shouldProduce:boolean;
    normativeItems:NormativeModel[];
    halfProductData:HalfProductModel[];
    pricesHistory:string;
    measureName:string;
    warehouseState:number;
    customCode:boolean;
    presetData:boolean;
}