import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { GroupTypeModel } from "../models/groupType.model";
import { DataService } from "./data.service";

@Injectable()
export class GroupTypeRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }


    async getGroupTypes(name: string, clientId: number, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;
        if(name!='' && name!=undefined && name!=null && name!='null')
            params+="&Name="+name;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/GroupType/Filter"+params).toPromise() as any;
    }

    async updateGroupType(obj: GroupTypeModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/GroupType/Update", obj).toPromise() as any;
    }

    async createGroupType(obj: GroupTypeModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/GroupType/Create", obj).toPromise() as any;
    }
}