<div class="content d-flex flex-column flex-column-fluid" id="kt_content" style="padding-top:0px">
    <div class="toolbar" id="kt_toolbar">
        <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
            <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1" style="font-size:22px!important">Početna</h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <a (click)="selectRetail()"
                    [ngClass]="{'btn-primary text-gray-200': activeButton === 'RETAIL', 'btn-light text-gray-800': activeButton !== 'RETAIL'}"
                    class="btn btn-sm ">Maloprodaja</a>
                <a (click)="selectWholesale()"
                    [ngClass]="{'btn-primary text-gray-200': activeButton === 'WHOLESALE', 'btn-light  text-gray-800': activeButton !== 'WHOLESALE'}"
                    class="btn btn-sm">Veleprodaja</a>
                <a (click)="selectAll()"
                    [ngClass]="{'btn-primary text-gray-200': activeButton === 'ALL', 'btn-light  text-gray-800': activeButton !== 'ALL'}"
                    class="btn btn-sm">Oboje</a>
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-xxl" style="margin-top:25px;padding:0px;margin-bottom:0px;">
            <div class="row g-5  g-xl-12 mb-xl-12" style="margin-bottom:20px!important" [ngStyle]="activeButton === 'ALL' ? {'visibility': 'visible', 'height': 'auto'} : {'visibility': 'hidden', 'height': '0'}">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10"
                    style="height: 90%;margin-bottom:0px!important">
                    <div class="card card-flush mb-5 mb-xl-10" style="height:35%">
                        <div class="row">
                            <div class="col-lg-4">
                                <input class="form-control form-control-solid" placeholder="Pick date rage"
                                    id="kt_daterangepicker_globalSales" style="margin-left:16px;margin-top:8px;" />
                            </div>
                            <div class="col-lg-8" style="padding-top:8px;padding-right:16px;">
                                <button type="button" class="btn btn-sm" style="float: right;margin-right:4px;"
                                    (click)="selectGlobalSalesTodayRange()"
                                    [ngClass]="{'btn-primary': globalSalesToday, 'btn-light': !globalSalesToday}">Danas</button>
                                <button type="button" class="btn btn-sm" style="float: right;margin-right:4px;"
                                    (click)="selectGlobalSalesWeekRange()"
                                    [ngClass]="{'btn-primary': globalSalesWeek, 'btn-light': !globalSalesWeek}">Tjedan</button>
                                <button type="button" class="btn btn-sm" style="float: right;margin-right:4px;"
                                    (click)="selectGlobalSalesMonthRange()"
                                    [ngClass]="{'btn-primary': globalSalesMonth, 'btn-light': !globalSalesMonth}">Mjesec</button>
                                <button type="button" class="btn btn-sm" style="float: right;margin-right:4px;"
                                    (click)="selectGlobalSalesYearRange()"
                                    [ngClass]="{'btn-primary': globalSalesYear, 'btn-light': !globalSalesYear}">2024</button>
                            </div>
                        </div>
                        <div class="card-header pt-5">
                            <div class="card-title d-flex flex-column">
                                <div class="d-flex align-items-center">

                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="fs-2hx fw-bolder text-dark me-2 lh-1">{{globalSales?.totalSales |
                                        number:'1.2-2'}}</span>
                                    <span class="fs-4 fw-bold text-gray-600 me-1 align-self-start">KM</span>
                                    <!--  <span class="badge badge-success fs-6 lh-1 py-1 px-2 d-flex flex-center"
                                        style="height: 22px">
                                        <span class="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.5"
                                                    d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z"
                                                    fill="black" />
                                                <path
                                                    d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z"
                                                    fill="black" />
                                            </svg>
                                        </span>
                                        2.2%</span> -->
                                </div>
                                <span class="text-gray-600 pt-1 fw-bold fs-6" *ngIf="globalSalesToday">Dnevni
                                    promet</span>
                                <span class="text-gray-600 pt-1 fw-bold fs-6" *ngIf="globalSalesWeek">Tjedni
                                    promet</span>
                                <span class="text-gray-600 pt-1 fw-bold fs-6" *ngIf="globalSalesMonth">Mjesečni
                                    promet</span>
                                <span class="text-gray-600 pt-1 fw-bold fs-6" *ngIf="globalSalesYear">Godišnji
                                    promet</span>
                                <span class="text-gray-600 pt-1 fw-bold fs-6" *ngIf="globalSalesCustom">Promet u
                                    odabranom periodu od {{dateFrom|date:'dd.MM.yyyy'}} do
                                    {{dateTo|date:'dd.MM.yyyy'}}</span>
                            </div>
                        </div>
                        <div class="card-body pt-2 pb-4 d-flex align-items-center">
                            <div class="d-flex flex-center me-5 pt-2">
                                <div id="chart-container" style="width: 100%; height: 180px;">
                                    <div id="kt_chart_global_sales" style="min-height: 180px!important;"></div>
                                </div>
                            </div>
                            <div class="d-flex flex-column content-justify-center w-100">
                                <div class="d-flex fs-6 fw-bold align-items-center my-3">
                                    <div class="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                                    <div class="text-gray-500 flex-grow-1 me-4">Nabavna vrijednost</div>
                                    <div class="fw-boldest text-gray-700 text-xxl-end">{{globalSales?.totalPurchaseValue
                                        | number:'1.2-2'}} KM</div>
                                </div>
                                <div class="d-flex fs-6 fw-bold align-items-center my-3">
                                    <div class="bullet w-8px h-6px rounded-2 bg-primary me-3"></div>
                                    <div class="text-gray-500 flex-grow-1 me-4">Promet</div>
                                    <div class="fw-boldest text-gray-700 text-xxl-end">{{globalSales?.totalSales |
                                        number:'1.2-2'}} KM</div>
                                </div>
                                <div class="d-flex fs-6 fw-bold align-items-center my-3">
                                    <div class="bullet w-8px h-6px rounded-2 bg-secondary me-3"></div>
                                    <div class="text-gray-500 flex-grow-1 me-4">Porez</div>
                                    <div class="fw-boldest text-gray-700 text-xxl-end">{{globalSales?.taxAmount |
                                        number:'1.2-2'}} KM</div>
                                </div>
                                <div class="d-flex fs-6 fw-bold align-items-center my-3">
                                    <div class="bullet w-8px h-6px rounded-2 bg-info me-3"></div>
                                    <div class="text-gray-500 flex-grow-1 me-4">Marža</div>
                                    <div class="fw-boldest text-gray-700 text-xxl-end">{{globalSales?.differenceInPrice
                                        | number:'1.2-2'}} KM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card card-flush overflow-hidden " style="height:50%">
                        <div class="card-header py-5">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bolder text-dark">Mjesečni promet</span>
                                <span class="text-gray-600 mt-1 fw-bold fs-6">Usporedba mjesečnog prometa</span>
                            </h3>
                        </div>
                        <div class="card-body d-flex justify-content-between flex-column pb-1 px-0"
                            style="margin-bottom:15px;">
                            <div id="kt_chart_mjesecni_promet" class="min-h-auto ps-4 pe-6"
                                style="height: 350px;min-height:350px"></div>

                        </div>
                    </div>

                </div>
                <div class="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0" style="height: 90%;">
                    <div class="card card-flush overflow-hidden" style="height:90%;;margin-bottom:0px!important">
                        <div class="card-header py-5">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bolder text-dark">Tjedni promet</span>
                                <span class="text-gray-600 mt-1 fw-bold fs-6">Usporedba tjednog prometa</span>
                            </h3>
                        </div>
                        <div class="card-body d-flex justify-content-between flex-column pb-1 px-0"
                            style="padding-top:0px;margin-bottom:40px;">
                            <div id="kt_chart_tjedni_promet" class="min-h-auto ps-4 pe-6"></div>
                            <div style="margin: auto;
                            margin-top: 20px;">
                                <span class="fs-2hx fw-bolder text-dark me-2 lh-1">{{totalCurrentWeeklySales |
                                    number:'1.2-2'}}</span>
                                <span class="fs-4 fw-bold text-gray-600 me-1 align-self-start">KM</span>
                                <span class="badge badge-success fs-6 lh-1 py-1 px-2"
                                    style="height: 22px;margin-left:3px" *ngIf="weeklySalesChange>=0">
                                    <span class="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.5"
                                                d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z"
                                                fill="black" />
                                            <path
                                                d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z"
                                                fill="black" />
                                        </svg>
                                    </span>
                                    {{weeklySalesChange | number:'1.2-2'}}%</span>

                                <span class="badge badge-danger fs-6 lh-1 py-1 px-2"
                                    style="height: 22px;margin-left:3px" *ngIf="weeklySalesChange<0">
                                    <span class="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.5"
                                                d="M11 14.4V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V14.4H11Z"
                                                fill="black" />
                                            <path
                                                d="M18.2929 16.1071C18.9229 15.4771 18.4767 14.4 17.5858 14.4H6.41421C5.52331 14.4 5.07714 15.4771 5.7071 16.1071L11.3 21.7C11.7 22.1 12.3 22.1 12.7 21.7L18.2929 16.1071Z"
                                                fill="black" />
                                        </svg>
                                    </span>
                                    {{weeklySalesChange | number:'1.2-2'}}%</span>
                                <br />
                                <div class="text-gray-600 pt-1 fw-bold fs-6"
                                    style="padding-top: 15px;text-align: center;">Tekući tjedni promet</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row g-5 g-xl-12 mb-xl-12" [ngStyle]="activeButton === 'RETAIL' ? {'visibility': 'visible', 'height': 'auto'} : {'visibility': 'hidden', 'height': '0', 'margin-bottom':'0px!important'}">
                <div class="card card-flush overflow-hidden" style="margin-left:10px;margin-right:10px;">
                    <div class="card-header py-5" style="display: block;">
                        <div class="row">
                            <div class="col-lg-4">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="card-label fw-bolder text-dark">Maloprodaja</span>
                                </h3>
                            </div>
                            <div class="col-lg-8" style="padding-top:8px;padding-right:16px;">

                                <button type="button" class="btn btn-sm" style="float: right;margin-right:4px;"
                                    (click)="selectRetailTodayRange()"
                                    [ngClass]="{'btn-primary': retailToday, 'btn-light': !retailToday}">Danas</button>
                                <button type="button" class="btn btn-sm" style="float: right;margin-right:4px;"
                                    (click)="selectRetailWeekRange()"
                                    [ngClass]="{'btn-primary': retailWeek, 'btn-light': !retailWeek}">Tjedan</button>
                                <button type="button" class="btn btn-sm" style="float: right;margin-right:4px;"
                                    (click)="selectRetailMonthRange()"
                                    [ngClass]="{'btn-primary': retailMonth, 'btn-light': !retailMonth}">Mjesec</button>
                                <button type="button" class="btn btn-sm" style="float: right;margin-right:4px;"
                                    (click)="selectRetailYearRange()"
                                    [ngClass]="{'btn-primary': retailYear, 'btn-light': !retailYear}">2024</button>
                                <input class="form-control form-control-solid" placeholder="Pick date rage"
                                    id="kt_daterangepicker_retail"
                                    style="margin-right:36px;margin-top:0px;max-width:250px;float:right;height:35px;" />
                            </div>
                        </div>
                    </div>
                    <div class="row" style="border-bottom:1px solid #eee">
                        <div class="col-lg-5">
                            <div class="card-header pt-5" style="border-bottom:none;padding-top: 0px !important;">
                                <div class="card-title d-flex flex-column">
                                    <div class="d-flex align-items-center">
                                        <span class="fs-2hx fw-bolder text-dark me-2 lh-1">{{retailDailySales |
                                            number:'1.2-2'}}</span>
                                        <span class="fs-4 fw-bold text-gray-400 me-1 align-self-start">KM</span>
                                        <!--  <span class="badge badge-success fs-6 lh-1 py-1 px-2 d-flex flex-center" style="height: 22px">
                                    <span class="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
                                            <path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
                                        </svg>
                                    </span>
                                   2.2%</span> -->
                                    </div>
                                    <span class="text-gray-400 pt-1 fw-bold fs-6">Dnevni promet</span>
                                </div>
                            </div>
                            <div class="card-body pt-2 pb-4 d-flex align-items-center">
                                <div class="d-flex flex-center me-5 pt-2">
                                    <div id="kt_chart_daily_sales_payment"></div>
                                </div>
                                <div class="d-flex flex-column content-justify-center w-100">
                                    <div class="d-flex fs-6 fw-bold align-items-center my-1"
                                        *ngFor="let i of dailySalesByPayment">
                                        <div class="bullet w-8px h-6px rounded-2 me-3" [ngClass]="i.color"></div>
                                        <div class="text-gray-500 flex-grow-1 me-4">{{i.paymentTypeName}}</div>
                                        <div class="fw-boldest text-gray-700 text-xxl-end">{{i.retailBillTotalAmount |
                                            number:'1.2-2'}} KM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="card-body pt-2 pb-4 d-flex align-items-center">
                                <div class="d-flex flex-center me-5 pt-2">
                                    <div id="kt_chart_promet_po_satu"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="border-bottom:1px solid #eee">
                        <div class="col-lg-5">
                            <div class="card-header py-5" style="border-bottom:none!important">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="card-label fw-bolder text-dark">Promet po operaterima</span>
                                </h3>
                            </div>
                            <div class="card-body d-flex justify-content-between flex-column pb-1 px-0"
                                style="padding-top:0px;margin-bottom:40px;">
                                <div id="kt_chart_operater_promet" class="min-h-auto ps-4 pe-6"></div>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="card card-xl-stretch mb-5 mb-xl-8">
                                <!--begin::Header-->
                                <div class="card-header border-0 pt-5">
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label fw-bolder text-dark">{{'_Most_sold_articles' | translate}}</span>
                                        <span class="text-muted mt-1 fw-bold fs-7">{{'_Most_sold_articles_description' | translate}}</span>
                                    </h3>
                                    <div class="card-toolbar">
                                    </div>
                                </div>
                                <div class="card-body pt-5">
                                    <div class="d-flex align-items-sm-center mb-7" *ngFor="let i of bestSellingItems">
                                        <div class="symbol symbol-60px me-5">
                                            <span class="symbol-label">
                                                <img  [src]="imagesUrl + clientId + '/' + i.picture" class="align-self-center" alt="" style="height:60px;width:60px;border-radius:5px;">
                                            </span>
                                        </div>
                                        <div class="d-flex align-items-center flex-row-fluid flex-wrap" >
                                            <div class="flex-grow-1 me-2">
                                                <a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bolder">{{i.articleName}}</a>
                                            </div>
                                            <span class="badge badge-light fw-bolder my-2">{{i.exitingQuantity}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card-header py-5" style="border-bottom:none!important">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="card-label fw-bolder text-dark">Promet po poslovnicama</span>
                                </h3>
                            </div>
                           <!--  <div class="row">
                                <div class="col-lg-12" style="padding-top:8px;padding-right:16px;">
                                    <div style="float:right;">
                                        <button type="button" class="btn btn-sm btn-light" style="margin-right:4px;"
                                            *ngFor="let i of paymentTypes"
                                            (click)="filterRetailPlaceSalesByPayment(i.id)" [ngClass]="{'btn-primary': selectedPaymentTypeId==i.id, 'btn-light': selectedPaymentTypeId!=i.id}">{{i.name}}</button>
                                        <button type="button" class="btn btn-sm btn-light" style="margin-right:4px;"
                                            (click)="filterRetailPlaceSalesByAllPayments()" [ngClass]="{'btn-primary': selectedPaymentTypeId==null, 'btn-light': selectedPaymentTypeId!=null}">Sve</button>
                                    </div>
                                </div>
                            </div> -->
                            <div class="card-body pt-2 pb-4 d-flex align-items-center"
                                style="padding-top:0px;margin-bottom:40px;">
                                <div class="d-flex flex-center me-5 pt-2">
                                    <div id="kt_chart_retailPlace_promet"></div>
                                </div>
                                <div class="d-flex flex-column content-justify-center w-70">
                                    <div class="d-flex fs-6 fw-bold align-items-center my-1"
                                        *ngFor="let i of retailPlaceSales">
                                        <div class="bullet w-8px h-6px rounded-2 me-3"
                                            [ngStyle]="{'background-color': i.color}"></div>
                                        <div class="text-gray-500 flex-grow-1 me-4">{{i.retailPlaceName}}</div>
                                        <div class="fw-boldest text-gray-700 text-xxl-end">{{i.retailBillTotalAmount |
                                            number:'1.2-2'}} KM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="card-header py-5" style="border-bottom:none!important">
                                        <h3 class="card-title align-items-start flex-column">
                                            <span class="card-label fw-bolder text-dark">Promet po kasama</span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-lg-8" style="padding-top:16px;padding-right:30px;">
                                    <div style="float:right;">
                                        <button type="button" class="btn btn-sm btn-light" style="margin-right:4px;"
                                            *ngFor="let i of paymentTypes"
                                            (click)="filterSalesByPayment(i.id)" [ngClass]="{'btn-primary': selectedPaymentTypeId==i.id, 'btn-light': selectedPaymentTypeId!=i.id}">{{i.name}}</button>
                                        <button type="button" class="btn btn-sm btn-light" style="margin-right:4px;"
                                            (click)="filterSalesByAllPayments()" [ngClass]="{'btn-primary': selectedPaymentTypeId==null, 'btn-light': selectedPaymentTypeId!=null}">Sve</button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body pt-2 pb-4 d-flex align-items-center"
                                style="padding-top:0px;margin-bottom:40px;">
                                <div class="d-flex flex-center me-5 pt-2">
                                    <div id="kt_chart_pointOfSale_promet"></div>
                                </div>
                                <div class="d-flex flex-column content-justify-center w-70">
                                    <div class="d-flex fs-6 fw-bold align-items-center my-1"
                                        *ngFor="let i of pointOfSalesSales">
                                        <div class="bullet w-8px h-6px rounded-2 me-3"
                                            [ngStyle]="{'background-color': i.color}"></div>
                                        <div class="text-gray-500 flex-grow-1 me-4">{{i.pointOfSaleIdName}}</div>
                                        <div class="fw-boldest text-gray-700 text-xxl-end">{{i.retailBillTotalAmount |
                                            number:'1.2-2'}} KM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>