import { ProductionDocumentModel } from "../productionDocument.model";

export class ProductionDocumentSaveModel{
    activeProduction:ProductionDocumentModel;
    activeProductionPreEdit:ProductionDocumentModel;
    addEnabled:boolean;
    editEnabled:boolean;
    startedEditing:boolean;
    editingItems:boolean;
    headerSaved:boolean;
    newProductionActive:boolean;
}