export class WarehouseStateQueryDTO{
    fromDate:string;
    toDate:string;
    articleName:string;
    warehouseIds:number[];
    groupTypeIds:number[];
    groupIds:number[];
    hideZeroes:boolean;
    clientId:number;
    page:number;
    pageSize:number;
}