export class CustomerSuppliersModel{
    id:number;
    name:string;
    code:number;
    firstName:string;
    lastName:string;
    companyName:string;
    address:string;
    countryId:number;
    townId:number;
    clientId:number;
    email:string;
    phone:string;
    idNumber:string;
    pdvNumber:string;
    cardNumber:string;
    isCompany:boolean;
    isSupplier:boolean;
    isCustomer:boolean;
    countryName:string;
    townName:string;
    employerId:number;
    employerName:string;
    inPDV:boolean;

    customCode:boolean;
}