import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { OutgoingInvoiceModel } from "../models/documents/outgoingInvoice.model";
import { DataService } from "./data.service";
import { DeleteDocumentDTO } from "../models/documents/deleteDocumentDTO";
import { UpdateInvoicePaymentDTO } from "../models/updateInvoicePaymentDTO";
import { UpdateInvoiceDiscountDTO } from "../models/documents/updateInvoiceDiscountDTO";
import * as FileSaver from 'file-saver';

@Injectable()
export class OutgoingInvoiceRestService{
    xmlExtension:string = 'application/xml';
    inpExtension:string = 'text/plain';
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async getInvoices(customerName:string, warehouseId:number, retailPlaceId:number, billNumber:string, dateFrom:string, dateTo:string, clientId:number, page:number, pageSize:number){
        var params = "?CustomerName=" + customerName + "&BillNumber=" + billNumber + "&FromDate=" + dateFrom + "&ToDate=" + dateTo + "&ClientId=" + clientId + "&Page=" + page + "&PageSize=" + pageSize;
        
        if(warehouseId!=undefined)
            params+="&WarehouseId="+warehouseId;
        if(retailPlaceId!=undefined)
            params+="&RetailPlaceId="+retailPlaceId;
        
        var config = environment.apiUrl;
        return await this.http.get(config + "api/OutgoingInvoice/FilterOutgoingInvoices" + params).toPromise() as any;
    }

    async createOutgoingInvoice(obj: OutgoingInvoiceModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/OutgoingInvoice/CreateOutgoingInvoice", obj).toPromise() as any;
    }

    async editOutgoingInvoice(obj: OutgoingInvoiceModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/OutgoingInvoice/UpdateOutgoingInvoice", obj).toPromise() as any;
    }

    async updateInvoicePaymentData(obj: UpdateInvoicePaymentDTO) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/OutgoingInvoice/UpdatePaymentInfo", obj).toPromise() as any;
    }

    async updateInvoiceDiscount(obj: UpdateInvoiceDiscountDTO) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/OutgoingInvoice/UpdateInvoiceDiscount", obj).toPromise() as any;
    }

    async getNextNumber(clientId:number){
        var params = "?ClientId=" + clientId + "&TypeCode=" + "OUTINV";
        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentGeneral/GetNextNumber" + params).toPromise() as any;
    }

    async deleteOutgoingInvoice(deleteId: string) {
        var dto = new DeleteDocumentDTO();
        dto.id=deleteId;
        var config = environment.apiUrl;
        return await this.http.post(config + "api/OutgoingInvoice/DeleteOutgoingInvoice", dto).toPromise() as any;
    }

    async fiscalize(obj:any) {
        var config = environment.apiUrl;
        var data:any;
        return await this.http.post(config + "api/FiscalType/FiscalizeOutgoingInvoice", obj, { responseType: 'blob' }).toPromise() as any;
    }

    async annul(obj: any) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/FiscalType/CancelOutgoingInvoice", obj, { responseType: 'blob' }).toPromise() as any;
    }

    saveFile(docData: any, fileName: string, extension:string): void {
        FileSaver.saveAs(docData, fileName + extension);
    }

    async getInvoicesPDF(clientId:number, id:any){
        var obj={
            clientId:clientId,
            id:id
           }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/OutgoingInvoice/GetOutgoingInvoicePDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async getDispatchNoteFromInvoicePDF(clientId:number, id:any){
        var obj={
            clientId:clientId,
            id:id
           }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/OutgoingInvoice/GetDispatchNoteFromOutgoingInvoicePDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }
}