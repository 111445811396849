import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IncomingInvoiceModel } from "../models/documents/incomingInvoice.model";
import { DataService } from "./data.service";
import { DeleteDocumentDTO } from "../models/documents/deleteDocumentDTO";
import { UpdateInvoicePaymentDTO } from "../models/updateInvoicePaymentDTO";
import { UpdateInvoiceDiscountDTO } from "../models/documents/updateInvoiceDiscountDTO";
import { ApplyInvoicePricesDTO } from "../models/documents/applyInvoicePricesDTO";

@Injectable()
export class IncomingInvoiceRestService{
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async getInvoices(customerName:string, warehouseId:number, retailPlaceId:number, billNumber:string, dateFrom:string, dateTo:string, clientId:number, page:number, pageSize:number){
        var params = "?ClientId=" + clientId + "&SupplierName=" + customerName + "&BillNumber=" + billNumber + "&FromDate=" + dateFrom + "&ToDate=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;
        
        if(warehouseId!=undefined)
            params+="&WarehouseId="+warehouseId;
        if(retailPlaceId!=undefined)
            params+="&RetailPlaceId="+retailPlaceId;
        
        var config = environment.apiUrl;
        return await this.http.get(config + "api/IncomingInvoice/FilterIncomingInvoices" + params).toPromise() as any;
    }

    async createIncomingInvoice(obj: IncomingInvoiceModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/IncomingInvoice/CreateIncomingInvoice", obj).toPromise() as any;
    }

    async editIncomingInvoice(obj: IncomingInvoiceModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/IncomingInvoice/UpdateIncomingInvoice", obj).toPromise() as any;
    }

    async updateInvoicePaymentData(obj: UpdateInvoicePaymentDTO) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/IncomingInvoice/UpdatePaymentInfo", obj).toPromise() as any;
    }

    async getNextNumber(clientId:number){
        var params = "?ClientId=" + clientId + "&TypeCode=" + "INCINV";
        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentGeneral/GetNextNumber" + params).toPromise() as any;
    }

    async deleteIncomingInvoice(deleteId: string) {
        var dto = new DeleteDocumentDTO();
        dto.id=deleteId;
        var config = environment.apiUrl;
        return await this.http.post(config + "api/IncomingInvoice/DeleteIncomingInvoice", dto).toPromise() as any;
    }

    async applyPrices(obj: ApplyInvoicePricesDTO) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/IncomingInvoice/ApplyInvoicePrices", obj).toPromise() as any;
    }

    async undoPrices(obj: ApplyInvoicePricesDTO) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/IncomingInvoice/UndoInvoicePrices", obj).toPromise() as any;
    }

    async getInvoicesPDF(clientId:number, id:any){
       var obj={
        clientId:clientId,
        id:id
       }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/IncomingInvoice/GetIncomingInvoicePDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

}