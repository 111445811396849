import { HttpClient } from "@angular/common/http";
import { Injectable, KeyValueDiffers } from "@angular/core";
import { environment } from '../../environments/environment';
import { ArticleModel } from "../models/article.model";
import { IdModel } from "../models/id.model";
import { DataService } from "./data.service";

@Injectable()
export class ArticleRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }


    async getArticles(name: string, groupId: number, clientId: number, measureId: number, isActive:boolean, page: number, pageSize: number, fetchRaws:boolean=false) {
        var params = "?Page=" + page + "&PageSize=" + pageSize + "&FetchRaws=" + fetchRaws;

        if (name != null && name != undefined && name != '')
            params += "&Name=" + name;

        if (groupId != null && groupId != undefined && groupId != 0 && !isNaN(groupId))
            params += "&GroupId=" + groupId;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        if (measureId != null && measureId != undefined && measureId != 0 && !isNaN(measureId))
            params += "&MeasureId=" + measureId;

        if (isActive == true || isActive == false || isActive == "true" || isActive == "false")
            params += "&IsActive=" + isActive;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/Article/Filter" + params,  {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getMeasures() {
        var config = environment.apiUrl;
        return await this.http.get(config + "api/Article/FilterMeasures").toPromise() as any;
    }

    async updateArticle(obj: ArticleModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Article/Update", obj).toPromise() as any;
    }

    async createArticle(obj: ArticleModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Article/Create", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async toggleActive(obj: IdModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Article/ToggleActiveStatus", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async toggleVisibility(obj: IdModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Article/ToggleVisibility", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getArticlesWithStates(clientId:number, warehouseId:number, page:number=1, pageSize:number=10, name:string=undefined, fetchRaws:boolean=false, fetchProducts:boolean=false, articleId:number|null=null, articleIds:any=null, fetchNormatives:boolean=false){
        var params = "?ClientId=" + clientId + "&WarehouseId=" + warehouseId + "&FetchRaws=" + fetchRaws + "&FetchProducts=" + fetchProducts + "&FetchNormatives=" + fetchNormatives + "&Page="+page + "&PageSize="+pageSize;
            
        if(name!=undefined)
            params+="&Name="+name;
        if(articleId!=undefined && articleId!=null)
            params+="&ArticleId="+articleId;
        else if(articleIds!=undefined && articleIds!=null && articleIds.length>0){
            articleIds.forEach(a => {
                params+="&ArticleIds="+a.id;
            });
        }
        
        var config = environment.apiUrl;
        return await this.http.get(config + "api/WarehouseState/GetArticlesWithStates" + params).toPromise() as any;
    }

    async getArticlesWithIds(_clientId:number, _ids:any){
        var obj={clientId:_clientId, ids:_ids};
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Article/GetArticlesWithIds", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getNormativeData(articleId:number){
        var params="?ArticleId="+articleId;
        var config=environment.apiUrl;
        return await this.http.get(config + "api/Article/GetNormativeData" + params).toPromise() as any;
    }

    async getHalfProductData(articleId:number){
        var params="?ArticleId="+articleId;
        var config=environment.apiUrl;
        return await this.http.get(config + "api/Article/getHalfProductData" + params).toPromise() as any;
    }

    async getNextNumber(clientId:number){
        var params = "?ClientId=" + clientId;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/Article/GetNextNumber" + params).toPromise() as any;
    }

    async addArticleImage(clientId:number, articleId:number, file:any){
        var config = environment.apiUrl;
        const formData: FormData = new FormData();
        formData.append('files', file);
    
        return await this.http.post(config + "api/Article/AddArticleImage?clientId="+clientId+"&articleId="+articleId, formData).toPromise() as any;
    }
}