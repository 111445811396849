import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ArticleModel } from 'src/app/models/article.model';
import { ArticleRestService } from 'src/app/services/article.service';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/translate';

declare var $: any;
declare var bootstrap:any;
@Component({
  selector: 'app-item-lookup',
  templateUrl: './item-lookup.component.html',
})
export class ItemLookupComponent implements OnInit {
  public articles: ArticleModel[] = [];
  public itemsToAdd: ArticleModel[];
  public selectedArticle: ArticleModel;
  public selectedArticleCode: string;

  public itemModalOpen: boolean = false;
  public fetchInProgress: boolean = false;
  public firstArticleFetch: boolean = true;
  public preventScrollLoadingitem: boolean = false;

  public articlePage: number = 1;
  public articlePageSize: number = 20;
  public searchItemCodeName: string;
  public articleSearch: string = undefined;
  public previousArticleSearch: string = undefined;
  @Input() public buttonIsHidden: boolean = true;
  @Input() warehouseId: number;
  @Input() selectedCurrency: string;
  @Input() incomingInvoiceSelection: boolean;
  @Input() addingRaw: boolean = false;
  @Input() productsOnly: boolean = false;
  @Input() noStates: boolean = false;
  @Input() includeNormatives: boolean = false;
  @Input() excludeArticleId:any=null;
  @Input() customModalId:string="kt_modal_select_invoice_item";

  @Output() selectArticleEvent = new EventEmitter<ArticleModel>();
  @Output() sendRegistryArticlesEvent = new EventEmitter<Array<ArticleModel>>();
  @ViewChild('itemSelectBody') itemSelectBody: ElementRef;
  @ViewChild('modalTrigger') modalTrigger:ElementRef;


  @HostListener('document:mousewheel', ['$event'])
  onScroll(event: Event): void {
    if (!this.itemModalOpen)
      return;
    const scrollContainer = this.itemSelectBody.nativeElement;
    var diff = (scrollContainer.scrollHeight - scrollContainer.scrollTop) - scrollContainer.clientHeight;
    if ((diff >= -1 && diff <= 1) && !this.fetchInProgress && !this.preventScrollLoadingitem) {
      this.articlePage++;
      this.fetchArticles();
    }
  }

  constructor(
    private articleRestService: ArticleRestService,
    private data: DataService,
    private translate: TranslateService,
    private _changeRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    $('#' + this.customModalId).on('shown.bs.modal', () => {
      this.itemModalOpen = true;
      this._changeRef.detectChanges();
      $("#searchItemInput").focus();
    });

    $('#'+this.customModalId).on('hidden.bs.modal', () => {
      this.itemModalOpen = false;
      this.refreshItemLookup();
      this._changeRef.detectChanges();
      $("#amountInput").focus();
    });
  }

  openItemsModal() {
    console.log('This is a product modal', this.productsOnly);
    if (this.articles.length == 0)
      this.fetchArticles();
    // this.modalTrigger.nativeElement.click();
    const modal = new bootstrap.Modal($('#' + this.customModalId));
    modal.show();
  }

  refreshItemLookup() {
    if (this.searchItemCodeName != '' && this.searchItemCodeName != undefined) {
      this.searchItemCodeName = '';
      this.articleSearch = '';
      this.previousArticleSearch = '';
      this.fetchArticles(true);
    }
  }

  async searchItems() {
    this.articleSearch = this.searchItemCodeName;

    if (this.articleSearch != this.previousArticleSearch) {
      this.articlePage = 1;
      this.preventScrollLoadingitem = false;
      await this.fetchArticles();
      this.previousArticleSearch = this.articleSearch;
      this.selectedArticle = undefined;
    }
  }

  async fetchArticles(refresh: boolean = false) {
    try {
      this.fetchInProgress = true;
      if(refresh)
        this.articlePage=1;

      var response = undefined;
      if (this.noStates)
        response = (await this.articleRestService.getArticles(this.searchItemCodeName, null, this.data.user.clientId, null, null, this.articlePage, this.articlePageSize, false)).data;
      else
        response = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.warehouseId, this.articlePage, this.articlePageSize, this.articleSearch, this.productsOnly, null, null, this.includeNormatives, this.excludeArticleId);

      if (this.articleSearch != this.previousArticleSearch || refresh)
        this.articles = response;
      else if (this.articles == undefined || this.articles.length == 0)
        this.articles = response;
      else {
        var previousLength = this.articles.length;
        this.articles = this.articles.concat(response);
        if (this.articles.length == previousLength)
          this.preventScrollLoadingitem = true;
      }

      this.sendRegistryArticlesEvent.emit(this.articles);
      this.itemsToAdd = this.articles;
      this.fetchInProgress = false;
    }
    catch (err) {
      if (err.status == 401) {
        this.data.logout();
      }
    }
  }


  async selectArticle(a: ArticleModel) {
    this.selectedArticle = a;
    this.selectArticleEvent.emit(this.selectedArticle);
  }
  enterOnRow(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      event.preventDefault();
      var element = event.target as HTMLElement;
      element.click();
    }
  }

  updateWarehouseState(id: number, quantity: number) {
      this.articles.find(x => x.id == id).warehouseState += quantity;
  }
}