import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RetailBillModel } from "../models/documents/retailBill.model";
import { DataService } from "./data.service";
import * as FileSaver from 'file-saver';

@Injectable()
export class ReportRestService {
    xmlExtension: string = 'application/xml';
    inpExtension: string = 'text/plain';
    constructor(private http: HttpClient, private data: DataService) { }

    async getRecapitulationsOutgoing(clientId: any, customerId: any, warehouseIds: any,  dateFrom:any, dateTo:any, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;
        if (customerId != undefined && customerId != null && !isNaN(customerId))
            params += "&CustomersAndSuppliersId=" + customerId;
        if(warehouseIds!=undefined && warehouseIds!=null)
            warehouseIds.forEach(id => {
                params+="&WarehouseIds="+id;
            });

        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentRecapitulation/GetOutgoingInvoiceSource" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getRecapitulationsIncoming(clientId: any, warehouseIds: any, customerId:any,  dateFrom:any, dateTo:any, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;
        if (customerId != undefined && customerId != null && !isNaN(customerId))
            params += "&CustomersAndSuppliersId=" + customerId;
        if(warehouseIds!=undefined && warehouseIds!=null)
            warehouseIds.forEach(id => {
                params+="&WarehouseIds="+id;
            });

        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentRecapitulation/GetIncomingInvoiceSource" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getRecapitulationsDispatch(clientId: any, customerId: any, warehouseIds: any, dateFrom:any, dateTo:any, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;
        if (customerId != undefined && customerId != null && !isNaN(customerId))
            params += "&CustomersAndSuppliersId=" + customerId;
        if(warehouseIds!=undefined && warehouseIds!=null)
            warehouseIds.forEach(id => {
                params+="&WarehouseIds="+id;
            });

        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentRecapitulation/GetDispatchNoteSource" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getRecapitulationsTransfer(clientId: any, warehouseFromId: any, warehouseToId: any,  dateFrom:any, dateTo:any, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;
        if (warehouseFromId != undefined && warehouseFromId != null && !isNaN(warehouseFromId))
            params += "&WarehouseFromId=" + warehouseFromId;
        if (warehouseToId != undefined && warehouseToId != null && !isNaN(warehouseToId))
            params += "&WarehouseToId=" + warehouseToId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentRecapitulation/GetWarehouseTransferSource" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getRecapitulationsProduction(clientId: any, warehouseIds: any,  dateFrom:any, dateTo:any, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;
   
        if(warehouseIds!=undefined && warehouseIds!=null)
            warehouseIds.forEach(id => {
                params+="&WarehouseIds="+id;
            });

        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentRecapitulation/GetProductionDocumentSource" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getArticleCard(clientId: any, articleId: any, dateFrom:any, dateTo:any, warehouseIds: number[], docType: string, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize + "&DateFrom=" + dateFrom + "&DateTo=" + dateTo;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;
        if (articleId != undefined && articleId != null && !isNaN(articleId))
            params += "&ArticleId=" + articleId;
        if (docType != undefined && docType != null && docType != '')
            params += "&DocumentCode=" + docType;

        if(warehouseIds!=undefined && warehouseIds!=null)
            warehouseIds.forEach(id => {
                params+="&WarehouseIds="+id;
            });
       
        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentItem/GetDocumentItemSource" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getSalesByArticle(clientId: any, articleId: any, posIds:any, paymentTypeIds:any, operatorIds:any, groupIds:any, groupTypeIds:any, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;
        if (articleId != undefined && articleId != null && !isNaN(articleId))
            params += "&ArticleId=" + articleId;
        if(operatorIds!=undefined && operatorIds!=null)
            operatorIds.forEach(id => {
                params+="&ClientUserAccountIds="+id;
            });
        if(paymentTypeIds!=undefined && paymentTypeIds!=null)
            paymentTypeIds.forEach(id => {
                params+="&PaymentTypeIds="+id;
            });
        if(posIds!=undefined && posIds!=null)
            posIds.forEach(id => {
                params+="&PointOfSaleIds="+id;
            });
        if(groupIds!=undefined && groupIds!=null)
            groupIds.forEach(id => {
                params+="&GroupIds="+id;
            });
        if(groupTypeIds!=undefined && groupTypeIds!=null)
            groupTypeIds.forEach(id => {
                params+="&GroupTypeIds="+id;
            });

        var config = environment.apiUrl;
        return await this.http.get(config + "api/ArticleSales/GetArticleSalesByGroupReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any[];
    }

    async getSalesByOperator(clientId: any, clientUserAccountId: any, paymentTypeIds:any, pointsOfSaleIds:any, operatorids:any, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        if(paymentTypeIds!=undefined && paymentTypeIds!=null)
            paymentTypeIds.forEach(id => {
                params+="&PaymentTypeIds="+id;
            });
        if(pointsOfSaleIds!=undefined && pointsOfSaleIds!=null)
            pointsOfSaleIds.forEach(id => {
                params+="&PointOfSaleIds="+id;
            });
        if(operatorids!=undefined && operatorids!=null)
            operatorids.forEach(id => {
                params+="&ClientUserAccountIds="+id;
            });


        var config = environment.apiUrl;
        return await this.http.get(config + "api/ArticleSales/GetSalesByOperatorReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getSalesByOperatorAndDate(clientId: any, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/ArticleSales/GetSalesByOperatorByDateReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getSalesByCustomer(clientId: any, customerId: any, paymentTypeIds:any, pointsOfSaleIds:any, operatorIds:any, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        if (customerId != undefined && customerId != null && !isNaN(customerId))
            params += "&CustomersAndSuppliersId=" + customerId;
        if(paymentTypeIds!=undefined && paymentTypeIds!=null)
            paymentTypeIds.forEach(id => {
                params+="&PaymentTypeIds="+id;
            });
        if(pointsOfSaleIds!=undefined && pointsOfSaleIds!=null)
            pointsOfSaleIds.forEach(id => {
                params+="&PointOfSaleIds="+id;
            });
        if(operatorIds!=undefined && operatorIds!=null)
            operatorIds.forEach(id => {
                params+="&ClientUserAccountIds="+id;
            });


        var config = environment.apiUrl;
        return await this.http.get(config + "api/ArticleSales/GetSalesByCustomersAndSuppliersReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getWarehouseDischarge(clientId: any, dateFrom: any, dateTo: any, warehouseId: any, docType:string, paymentTypeIds, posIds, operatorIds, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;
        if (warehouseId != undefined && warehouseId != null && !isNaN(warehouseId))
            params += "&WarehouseId=" + warehouseId;
        if (docType != undefined && docType != null && docType != '')
            params += "&DocumentCode=" + docType;

        if(paymentTypeIds!=undefined && paymentTypeIds!=null)
            paymentTypeIds.forEach(id => {
                params+="&PaymentTypeIds="+id;
            });
        if(posIds!=undefined && posIds!=null)
            posIds.forEach(id => {
                params+="&PointOfSaleIds="+id;
            });
        if(operatorIds!=undefined && operatorIds!=null)
            operatorIds.forEach(id => {
                params+="&ClientUserAccountIds="+id;
            });

        var config = environment.apiUrl;
        return await this.http.get(config + "api/WarehouseDischarge/GetWarehouseDischargeReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getShiftClosings(clientId: any, operatorIds:number[], posIds:number[], dateFrom:any, dateTo:any, page: number, pageSize: number) {
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != undefined && clientId != null && !isNaN(clientId))
            params += "&ClientId=" + clientId;
        if(posIds!=undefined && posIds!=null)
            posIds.forEach(id => {
                params+="&PointOfSaleIds="+id;
            });
        if(operatorIds!=undefined && operatorIds!=null)
            operatorIds.forEach(id => {
                params+="&ClientUserAccountIds="+id;
            });

        var config = environment.apiUrl;
        return await this.http.get(config + "api/ShiftClosingRecord/GetShiftClosingRecord" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    saveFile(docData: any, fileName: string, extension: string): void {
        FileSaver.saveAs(docData, fileName + extension);
    }

    async printFiscalReport(reportCode: string, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var params = "?SelectedReportCode=" + reportCode + "&DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&Page=" + page + "&PageSize=" + pageSize;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/FiscalReports/PrintFiscalReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async moneyDeposit(amount: number, page: number, pageSize: number) {
        var params = "?Amount=" + amount + "&Page=" + page + "&PageSize=" + pageSize;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/FiscalReports/MoneyDeposit" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async moneyWithdrawal(amount: number, page: number, pageSize: number) {
        var params = "?Amount=" + amount + "&Page=" + page + "&PageSize=" + pageSize;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/FiscalReports/MoneyWithdrawal" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async printDuplicate(reportCode: string, documentFrom: string, documentTo: string, page: number, pageSize: number) {
        var params = "?SelectedReportCode=" + reportCode + "&DocumentFrom=" + documentFrom + "&DocumentTo=" + documentTo + "&Page=" + page + "&PageSize=" + pageSize;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/FiscalReports/PrintDuplicateDocument" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async getSalesByOperatorPDF(clientId: any, clientUserAccountId: any, paymentTypeIds:any, posIds:any, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var obj = {
            ClientId: clientId,
            ClientUserAccountIds: clientUserAccountId,
            PaymentTypeIds: paymentTypeIds,
            PointOfSaleIds:posIds,
            DateFrom: dateFrom,
            DateTo: dateTo,
            Page: page,
            PageSize: pageSize
        }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ArticleSales/GetSalesByOperatorReportPDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async getSalesByCustomerPDF(clientId: any, customerId: any, paymentTypeIds:any, operatorIds:any, posIds:any, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var obj = {
            ClientId: clientId,
            CustomersAndSuppliersId: customerId,
            PaymentTypeIds: paymentTypeIds,
            ClientUserAccountIds: operatorIds,
            PointOfSaleIds: posIds,
            DateFrom: dateFrom,
            DateTo: dateTo,
            Page: page,
            PageSize: pageSize
        }

        var config = environment.apiUrl;
        return await this.http.post(config + "api/ArticleSales/GetSalesByCustomersAndSuppliersPDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async getArticleCardPDF(clientId: any, articleId: any, dateFrom:any, dateTo:any, warehouseId: any, docType: string, page: number, pageSize: number) {
        var obj={
            ClientId: clientId,
            ArticleId: articleId,
            DateFrom: dateFrom,
            DateTo:dateTo,
            DocumentCode: docType,
            WarehouseIds: warehouseId,
            Page: page,
            PageSize: pageSize
        }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/DocumentItem/GetDocumentItemPDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async getRecapitulationsIncomingPDF(clientId: any, customerId:any, dateFrom:any, dateTo:any, warehouseId: any, page: number, pageSize: number) {
        var obj={
            ClientId: clientId,
            WarehouseIds: warehouseId,
            CustomersAndSuppliersId: customerId,
            DateFrom:dateFrom,
            DateTo:dateTo,
            Page: page,
            PageSize: pageSize
        }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/DocumentRecapitulation/GetIncomingInvoiceSourcePDF" ,obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async getRecapitulationsOutgoingPDF(clientId: any, customerId: any, warehouseId: any,dateFrom:any, dateTo:any, page: number, pageSize: number) {
        var obj={
            ClientId: clientId,
            WarehouseIds: warehouseId,
            CustomersAndSuppliersId:customerId,
            DateFrom:dateFrom,
            DateTo:dateTo,
            Page: page,
            PageSize: pageSize
        }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/DocumentRecapitulation/GetOutgoingInvoiceSourcePDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async getRecapitulationsDispatchPDF(clientId: any, customerId: any, warehouseId: any, dateFrom:any, dateTo:any, page: number, pageSize: number) {
        var obj={
            ClientId: clientId,
            WarehouseId: warehouseId,
            CustomerId:customerId,
            DateFrom:dateFrom,
            DateTo:dateTo,
            Page: page,
            PageSize: pageSize
        }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/DocumentRecapitulation/GetDispatchNoteSourcePDF",obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async getRecapitulationsTransferPDF(clientId: any, warehouseFromId: any, warehouseToId: any,  dateFrom:any, dateTo:any, page: number, pageSize: number) {
        var obj={
            ClientId: clientId,
            WarehouseFromId: warehouseFromId,
            WarehouseToId:warehouseToId,
            DateFrom:dateFrom,
            DateTo:dateTo,
            Page: page,
            PageSize: pageSize
        }

        var config = environment.apiUrl;
        return await this.http.post(config + "api/DocumentRecapitulation/GetWarehouseTransferSourcePDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }

    async getRecapitulationsProductionPDF(clientId: any, warehouseId: any,  dateFrom:any, dateTo:any, page: number, pageSize: number) {
        var obj={
            ClientId: clientId,
            WarehouseIds: warehouseId,
            DateFrom:dateFrom,
            DateTo:dateTo,
            Page: page,
            PageSize: pageSize
        }

        var config = environment.apiUrl;
        return await this.http.post(config + "api/DocumentRecapitulation/GetProductionDocumentSourcePDF",obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }


}