import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DataService } from "./data.service";
import { WarehouseModel } from "../models/warehouse.model";

@Injectable()
export class WarehouseRestService{
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async filterWarehouses(clientId:number, name:string, page:number, pageSize:number){
        var params = "?ClientId=" + clientId + "&Name=" + name + "&Page=" + page + "&PageSize=" + pageSize; 

        var config = environment.apiUrl;
        return await this.http.get(config + "api/Warehouse/Filter" + params).toPromise() as any;
    }

    async getWarehousesForClient(clientId:number){
        var params = "?ClientId=" + clientId; 

        var config = environment.apiUrl;
        return await this.http.get(config + "api/Warehouse/FilterWarehousesByClientId" + params).toPromise() as any;
    }

    async createWarehouse(obj: WarehouseModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Warehouse/Create", obj).toPromise() as any;
    } 

    async updateWarehouse(obj: WarehouseModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Warehouse/Update", obj).toPromise() as any;
    } 
}