export class HalfProductModel{
    id:number;
    code:string;
    name:string;
    measure:string;
    quantity:number;
    nc:number;
    vpc:number;
    mpc:number;
    marginPercentage:number;
    marginAmount:number;
    groupId:number;
}