import { Component } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/translate';

declare var KTMenu: any;
declare var $: any;

@Component({
  selector: 'erp-page-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  constructor(
    public data: DataService,
    private spinner: SpinnerVisibilityService,
    private translate:TranslateService
  ){}

  ngOnInit() {
    KTMenu.createInstances();
    console.log(this.data?.user?.clientUserRoleIdName);
    if(this.data.currentMode==null || this.data.currentMode==undefined || this.data.currentMode==''){
      this.data.currentMode='light';
    }
    console.log('Current mode is: ' + this.data.currentMode);
  }
  
  changeMode() {
    this.spinner.show();
    var currentMode = sessionStorage.getItem("erp_current_mode");
    if (currentMode == null || currentMode=='')
      currentMode = "light";
    if (currentMode == "light") {
      document.head.querySelectorAll("link").forEach((link: HTMLLinkElement) => {
        if (link.href.includes("assets/plugins/global/plugins.bundle.css")) {
          document.head.removeChild(link);
        }
        if (link.href.includes("assets/css/style.bundle.css")) {
          document.head.removeChild(link);
        }
        if (link.href.includes("assets/css/templateModification.css")) {
          document.head.removeChild(link);
        }
      })
      var head = document.getElementsByTagName('head')[0] as HTMLHeadElement;
      var link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = "assets/plugins/global/plugins.dark.bundle.css";
      head.appendChild(link);
      var link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = "assets/css/style.dark.bundle.css";
      head.appendChild(link);
      var link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = "assets/css/marinModificationDark.css";
      head.appendChild(link);
      sessionStorage.setItem("erp_current_mode", "dark");
    }
    else {
      document.head.querySelectorAll("link").forEach((link: HTMLLinkElement) => {
        if (link.href.includes("assets/plugins/global/plugins.dark.bundle.css")) {
          document.head.removeChild(link);
        }
        if (link.href.includes("assets/css/style.dark.bundle.css")) {
          document.head.removeChild(link);
        }
        if (link.href.includes("assets/css/marinModificationDark.css")) {
          document.head.removeChild(link);
        }
      })
      var head = document.getElementsByTagName('head')[0] as HTMLHeadElement;
      var link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = "assets/plugins/global/plugins.bundle.css";
      head.appendChild(link);
      var link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = "assets/css/style.bundle.css";
      head.appendChild(link);
      var link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = "assets/css/templateModification.css";
      head.appendChild(link);
      sessionStorage.setItem("erp_current_mode", "light");
    }

    this.data.updateCurrentLayoutMode();
    this.spinner.hide();
  }

  changeLanguage(lang: string) {
    sessionStorage.setItem("erp_lang", lang);
    this.data.lang=lang;
    this.translate.use(lang);
  }
}