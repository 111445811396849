<div class="d-flex flex-column flex-root" *ngIf="data?.isUserLogged">
  <div class="page d-flex flex-row flex-column-fluid">
    <erp-page-sidebar></erp-page-sidebar>
    <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <erp-page-header></erp-page-header>
      <div id="kt_content_container" class="d-flex flex-column-fluid align-items-start container-xxl">
        <div class="content flex-row-fluid" id="kt_content" style="padding:0px 0px!important;overflow: hidden;">
          <router-outlet>
          </router-outlet>
        </div>
      </div>
      <erp-page-footer></erp-page-footer>
    </div>
  </div>
</div>

<div class="d-flex flex-column flex-root" *ngIf="!data?.isUserLogged">
  <erp-login></erp-login>
</div>