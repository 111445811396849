import { WarehouseTransferModel } from "../warehouseTransfer.model";

export class WarehouseTransferSaveModel{
    activeTransfer:WarehouseTransferModel;
    activeTransferPreEdit:WarehouseTransferModel;
    
    addEnabled:boolean;
    editEnabled:boolean;
    startedEditing:boolean;
    editingItems:boolean;
    headerSaved:boolean;
    newTransferActive:boolean;
}