import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupModel } from 'src/app/models/group.model';
import { GroupTypeModel } from 'src/app/models/groupType.model';
import { TaxModel } from 'src/app/models/tax.model';
import { ClientRestService } from 'src/app/services/client.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { GroupTypeRestService } from 'src/app/services/groupType.service';
import { ReportRestService } from 'src/app/services/reports.service';
import { TranslateService } from 'src/app/translate';
import toastr from 'toastr'
import { DatePipe } from '@angular/common'

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;

@Component({
  selector: 'app-print-fiscal-report',
  templateUrl: './print-fiscal-report.component.html',
})
export class PrintFiscalReportComponent  {

  public emptyData: boolean = true;
  public report: any;
  public searchForm: UntypedFormGroup;
  public isSubmitted: boolean = false;
  public selectedReportCode:string='PR';
  dateFrom:string;
  dateTo:string;

  //pagination
  public page: number = 1;
  public perPage: number;
  public totalData: number;
  public maxPage: number;
  public pages: number[] = [];
  public offset: number = 5;
  public firstPage: number = 1;
  public lastPage: number;
  public counter: number = 0;
  public firstLoad: boolean = true;

  @ViewChild('selectReportCode') selectReportCode: ElementRef;

  constructor(
      private reportRest: ReportRestService,
      private _changeRef: ChangeDetectorRef,
      public data: DataService,
      private formBuilder:FormBuilder,
      private translate: TranslateService,
      public datepipe: DatePipe
    ) {
      this.page = 1;
      this.perPage = 50;
  }

  ngOnInit() {
      KTMenu.createInstances();
      this.createSearchForm();
  }

  ngAfterViewInit(){
    $("#filter_reportCode").select2().val('PR').trigger('change');
  }

  async fetchReport() {
      if(this.dateFrom==null ||this.dateTo==null){
        toastr.options = this.data.toastrOptions;
        toastr.error(this.translate.instant("_Enter_dates"), this.translate.instant("_Error"));
        return;
      }
      try {
          this.emptyData=false;
          if (this.selectReportCode != null)
              this.selectedReportCode = this.selectReportCode.nativeElement.value;

          var response = await this.reportRest.printFiscalReport(this.selectedReportCode, this.dateFrom, this.dateTo, 1, 99999);
          var extension = this.data.getFiscalType()=='TRI'?'.xml':'.inp';
          var fileName = null;
          if(this.data.getFiscalType()=='TRI'){
              if(this.selectedReportCode=='PR')
                fileName='spi.'+this.dateFrom+'_'+this.dateTo;
              else if(this.selectedReportCode=='CSR')
                fileName='stampatidnevniizvjestaj.'+this.dateFrom+'_'+this.dateTo;
              else if(this.selectedReportCode=='DR')
                fileName='sdi.'+this.dateFrom+'_'+this.dateTo;
          }else {
              if(this.selectedReportCode=='PR')
                fileName='Periodic-'+this.dateFrom+'_'+this.dateTo;
              else if(this.selectedReportCode=='CSR')
                fileName='CurrentState-'+this.dateFrom+'_'+this.dateTo;
              else if(this.selectedReportCode=='DR')
                fileName='Daily-'+this.dateFrom+'_'+this.dateTo;
          }
          this.reportRest.saveFile(response, fileName, extension);

          // this.totalData = response.totalCount;
          // if (this.totalData == 0)
          //     this.emptyData = true;
          // else
          //     this.emptyData = false

          // if (this.firstLoad) {
          //     this.maxPage = response.totalPages;
          //     this.maxPage = Math.floor(this.maxPage);
          //     this.firstPage = 1;
          //     if (this.maxPage > 5)
          //         this.lastPage = 5;
          //     else
          //         this.lastPage = this.maxPage;

          //     this.enablePrevious();
          //     this.firstLoad = false;
          // }
          // this.initPages();
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  resetFilter() {
      this.searchForm.controls['selectedReportCode'].setValue('PR');
      this.selectedReportCode = 'PR';
      $('#selectedReportCode').val('PR').trigger("change")

      $("#kt_daterangepicker_printFiscal").data('daterangepicker').setStartDate(new Date(new Date().getFullYear(), 0 ,1));
      $("#kt_daterangepicker_printFiscal").data('daterangepicker').setEndDate(new Date());
      this.manualInputDate("#kt_daterangepicker_printFiscal");
      this.searchForm.controls['dateFrom'].setValue(new Date(new Date().getFullYear(), 0 ,1));
      this.searchForm.controls['dateTo'].setValue(new Date());

      this.firstLoad = true;
      this.page = 1;
  }

  searchGroups() {
      this.firstLoad = true;
      this.page = 1;
      // //this.fetchGroups();
  }

  createSearchForm() {
      this.searchForm = this.formBuilder.group({
          selectedReportCode: [this.selectedReportCode],
          dateFrom: [this.dateFrom],
          dateTo: [this.dateTo],
      })

      $("#selectReportCode").select2();
      $('#selectReportCode').on("change", function () {
        self.selectReportCode.nativeElement.value = $(this).val();
        self.selectReportCode = $(this).val();
      });

      var start = new Date(new Date().getFullYear(), 0 ,1);
      var end = new Date();

      this.dateFrom = this.datepipe.transform(start, 'yyyy-MM-dd');
      this.dateTo = this.datepipe.transform(end, 'yyyy-MM-dd');

      var self = this;
      $("#kt_daterangepicker_printFiscal").daterangepicker({
          startDate: start,
          endDate: end,
          autoApply:true,
          autoUpdateInput:true,
          locale: {
              format: 'DD/MM/YYYY',
              separator:'-'
          }
      });
      
      $("#kt_daterangepicker_printFiscal").on('apply.daterangepicker', function(ev, picker){
          self.dateFrom = picker.startDate.format('YYYY-MM-DD');
          self.dateTo = picker.endDate.format('YYYY-MM-DD');
      });
      $("#kt_daterangepicker_printFiscal").on('cancel.daterangepicker', function(ev, picker){
          picker.setStartDate(new Date());
          picker.setEndDate(new Date());
          self.dateFrom = picker.startDate.format('YYYY-MM-DD');
          self.dateTo = picker.endDate.format('YYYY-MM-DD');
      });
      var im = new Inputmask("99/99/9999-99/99/9999");
      im.mask($("#kt_daterangepicker_printFiscal"));
  }

  filterByDate(event:KeyboardEvent){
    if(event.key=="Enter" || event.key=="Tab"){
       var pickerElement = $("#kt_daterangepicker_printFiscal");
       $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }
  }

  manualInputDate(id:string){
    var pickerElement = $("#"+id);
    $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
  }

  //pagination
  initPages() {
      this.pages = [];
      for (var i = this.firstPage; i <= this.lastPage; i++) {
          this.pages.push(i);
      }
  }

  nextPage() {
      if ($("#kt_customers_table_next").hasClass("disabled"))
          return;

      if (this.page == 1 && this.firstPage == 1) {
          this.page++;
      }

      else {
          this.page++;
          if (this.counter == 1) {
              if (this.maxPage > (this.lastPage + this.offset)) {
                  this.firstPage++;
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else if (this.maxPage > (this.firstPage + this.offset)) {
                  this.firstPage++;
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else if (this.maxPage) {
                  this.firstPage = this.maxPage - this.offset;
                  this.lastPage = this.maxPage;
              }
              this.counter = 0;
          }
          else {
              if (this.maxPage > (this.lastPage + this.offset)) {
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else if (this.maxPage > (this.firstPage + this.offset)) {
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else {
                  this.firstPage = this.maxPage - this.offset;
                  this.lastPage = this.maxPage;
              }
          }
          this.counter++;
      }
      // //this.fetchGroups();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  prevpage() {
      if ($("#kt_customers_table_previous").hasClass("disabled"))
          return;

      this.page--;
      if (this.counter == 1) {
          if (this.maxPage > this.lastPage + this.offset) {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          else {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          this.counter = 0;
      }
      else {
          if (this.maxPage > this.lastPage + this.offset) {
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          else {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
      }
      this.counter++;
      //this.fetchGroups();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  goToPage(p: number) {
      this.page = p;
      //this.fetchGroups();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  morePages() {
      if ($("#kt_customers_table_morePages").hasClass("disabled"))
          return;
      if (this.maxPage > this.lastPage + this.offset) {
          this.firstPage = this.lastPage;
          this.lastPage = this.firstPage + this.offset;
      }
      else {
          this.firstPage = this.maxPage - this.offset;
          this.lastPage = this.maxPage;
      }
      this.page = this.firstPage;
      //this.fetchGroups();
      this.initPages();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  perPageHandler() {
      this.firstLoad = true;
      this.page = 1;
      //this.fetchGroups();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  enablePrevious() {
      if (this.page > 1) {
          $(document).ready(function () {
              $("#kt_customers_table_previous").removeClass("disabled");
          })
      }
      else if (this.page == 1) {
          $(document).ready(function () {
              $("#kt_customers_table_previous").addClass("disabled");
          })
      }

      if (this.page > 1 && this.page != this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_next").removeClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
          })
      }

      if (this.maxPage == 0) {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
              $("#morePages").addClass("disabled");
              $("#kt_customers_table_previous").addClass("disabled");
          })
      }

      if (this.page == this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_next").removeClass("disabled");
          })
      }

      if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_morePages").addClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_morePages").removeClass("disabled");
          })
      }
  }
}