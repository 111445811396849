import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupTypeModel } from 'src/app/models/groupType.model';
import { ClientRestService } from 'src/app/services/client.service';
import { DataService } from 'src/app/services/data.service';
import { GroupTypeRestService } from 'src/app/services/groupType.service';
import { TranslateService } from 'src/app/translate';
import toastr from 'toastr';

declare var $: any;
declare var KTMenu: any;
declare var Swal: any;

@Component({
    selector: 'erp-groupType',
    templateUrl: './groupType.component.html',
})
export class GroupTypeComponent {

    public groupTypes: GroupTypeModel[];
    public emptyData: boolean = true;
    public searchForm: UntypedFormGroup;
    public formGroup: UntypedFormGroup;
    public selectedGroupType: GroupTypeModel;
    public isSubmitted: boolean = false;

    get f() { return this.formGroup.controls; }

    @ViewChild('closeAddGroupTypeModal') closeAddGroupTypeModal: ElementRef;
    @ViewChild('closeEditGroupTypeModal') closeEditGroupTypeModal: ElementRef;
    @ViewChild('selectedAddEditClient') selectedAddEditClient: ElementRef;
    @ViewChild('selectFilterClientId') selectFilterClientId: ElementRef;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;

    constructor(
        private groupTypeRest: GroupTypeRestService,
        private clientRest: ClientRestService,
        private formBuilder: UntypedFormBuilder,
        public data: DataService,
        private _changeRef: ChangeDetectorRef,
        private translate: TranslateService
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit() {
        KTMenu.createInstances();
        this.createSearchForm();
        this.fetchGroupTypes();
        this.createAddEditForm();
    }

    async fetchGroupTypes() {
        try {
            this.emptyData=false;
            var name = this.searchForm.controls.name.value;
            var response = await this.groupTypeRest.getGroupTypes(name, this.data.user.clientId, this.page, this.perPage);
            this.groupTypes = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    createSearchForm() {
        this.searchForm = this.formBuilder.group({
            name: [''],
        })
    }

    searchGroupTypes() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchGroupTypes();
    }

    resetFilter() {
        this.searchForm.controls['name'].setValue('');
        this.firstLoad = true;
        this.page = 1;
        this.fetchGroupTypes();
    }

    selectGroupType(groupType: GroupTypeModel) {
        this.selectedGroupType = groupType;
        this.formGroup.controls['name'].setValue(this.selectedGroupType.name);
    }

    resetForm() {
        this.formGroup.controls['name'].setValue("");
    }

    createAddEditForm() {
        this.formGroup = this.formBuilder.group({
            name: ['', Validators.required],
        })
    }

    async addGroupType() {
        this.isSubmitted = false;
        if (this.formGroup.invalid) {
            this.isSubmitted = true;
            return;
        }

        var obj = this.createAddObject();

        try {
            await this.groupTypeRest.createGroupType(obj);
            this.closeAddGroupTypeModal.nativeElement.click();
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_group_type_text'), this.translate.instant("_Success"));
            this.fetchGroupTypes();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    async updateGroupType() {
        this.isSubmitted = false;
        if (this.formGroup.invalid) {
            this.isSubmitted = true;
            return;
        }

        var obj = this.createEditObject();

        try {
            await this.groupTypeRest.updateGroupType(obj);
            this.closeEditGroupTypeModal.nativeElement.click();
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Updated_group_type_text'), this.translate.instant("_Success"));
            this.fetchGroupTypes();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    createAddObject() {
        var obj = new GroupTypeModel();
        obj.name = this.formGroup.controls.name.value;
        obj.clientId = parseInt(this.data.user.clientId);

        return obj;
    }

    createEditObject() {
        var obj = new GroupTypeModel();
        obj.id = this.selectedGroupType.id;
        obj.name = this.formGroup.controls.name.value;
        obj.clientId = parseInt(this.data.user.clientId);

        return obj;
    }

    closeAddModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closeAddGroupTypeModal.nativeElement.click();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    closeEditModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closeEditGroupTypeModal.nativeElement.click();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage=1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchGroupTypes();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage>this.firstPage+this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage=this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                    if(this.maxPage>this.firstPage+this.offset)
                        this.lastPage = this.firstPage + this.offset;
                    else
                        this.lastPage=this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else 
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchGroupTypes();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchGroupTypes();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchGroupTypes();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchGroupTypes();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}

