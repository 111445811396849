import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { DataService } from "./data.service";

@Injectable()
export class DashboardRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }


    async getGlobalSalesSummary(clientId: number, reportsCode: string, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var params = "?ReportsCode="+reportsCode+"&Page=" + page + "&PageSize=" + pageSize+"&DateFrom="+dateFrom+"&DateTo="+dateTo;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/GlobalSalesReport/GetGlobalSalesSummary" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getWeeklySalesComparisonReport(clientId: number, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/WeekleySalesReport/GetWeeklySalesComparisonReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getMonthlySalesComparisonReport(clientId: number, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/MonthlySalesReport/GetMonthlySalesComparisonReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getDailySalesByPaymentTypeReport(clientId: number, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/RetailReports/GetDailySalesByPaymentTypeReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getSalesByOperater(clientId: number, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize+"&DateFrom="+dateFrom+"&DateTo="+dateTo;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/RetailReports/GetSalesByOperatorReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getMostSoldArticlesReport(clientId: number, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize+"&DateFrom="+dateFrom+"&DateTo="+dateTo+"&AmountOfArticlesToDisplay=5";

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/RetailReports/GetMostSoldArticlesReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getSalesByRetailPlaceReport(clientId: number, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize+"&DateFrom="+dateFrom+"&DateTo="+dateTo;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/RetailReports/GetSalesByRetailPlaceReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }


    async getSalesByPointOfSaleReport(clientId: number, dateFrom: any, dateTo: any, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize+"&DateFrom="+dateFrom+"&DateTo="+dateTo;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/RetailReports/GetSalesByPointOfSaleReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }


    async getDailySalesByHourSummaryReport(clientId: number, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/RetailReports/GetDailySalesByHourSummaryReport" + params, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

}