import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleRestService } from 'src/app/services/article.service';
import { CustomerSupplierRestService } from 'src/app/services/customerSupplier.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { IncomingInvoiceRestService } from 'src/app/services/incoming-invoice.service';
import { PaymentTypeRestService } from 'src/app/services/payment-type.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { GroupModel } from 'src/app/models/group.model';
import { TranslateService } from 'src/app/translate';
import { ArticleModel } from 'src/app/models/article.model';
import { TaxModel } from 'src/app/models/tax.model';
import { PaymentTypeModel } from 'src/app/models/paymentType.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { CustomerSuppliersModel } from 'src/app/models/customerSupplier.model';
import toastr from 'toastr';
import { IncomingInvoiceModel } from 'src/app/models/documents/incomingInvoice.model';
import { IncomingArticleModel } from 'src/app/models/documents/incomingArticle.model';
import { minValidator } from 'src/app/validators/minValidator';
import { CurrencyModel } from 'src/app/models/currency.model';
import { CurrencyRestService } from 'src/app/services/currency.service';
import { InvoiceCustomerPaymentModel } from 'src/app/models/documents/invoice-customer-payment.model';
import { IncomingInvoiceSaveModel } from 'src/app/models/documents/pageSaveModels/incomingInvoiceSave.model';
import { UpdateInvoicePaymentDTO } from 'src/app/models/updateInvoicePaymentDTO';
import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import { PricesHistoryModel } from 'src/app/models/pricesHistory.model';
import { ApplyInvoicePricesDTO } from 'src/app/models/documents/applyInvoicePricesDTO';
import { ItemLookupComponent } from '../item-lookup/item-lookup.component';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;

@Component({
    selector: 'app-incoming-invoice',
    templateUrl: './incoming-invoice.component.html',
})
export class IncomingInvoiceComponent implements OnInit {
    public selectedRow: string = undefined;
    public incomingInvoices: IncomingInvoiceModel[];
    public groups: GroupModel[];
    public registryArticles: ArticleModel[];
    public suppliers: CustomerSuppliersModel[];
    public taxes: TaxModel[];
    public currencies: CurrencyModel[];
    public paymentTypes: PaymentTypeModel[];
    public warehouses: WarehouseModel[];
    public retailPlaces: RetailPlaceModel[];
    public emptyData: boolean = false;
    public searchForm: UntypedFormGroup;
    public addForm: UntypedFormGroup;
    dateFrom: string;
    dateTo: string;
    supplierName: string = '';
    public isSubmitted: boolean;
    public selectedInvoice: IncomingInvoiceModel;
    public selectedInvoiceTax: TaxModel;
    public selectedInvoicePaymentType: PaymentTypeModel;
    public selectedInvoiceWarehouse: WarehouseModel;
    public addSupplierId: number = 0;
    public addPaymentTypeId: number = 0;
    public addWarehouseId: number = 0;
    public addCurrencyId: number = 0;
    public addBillDate: Date = new Date();
    public addArrivalDate: Date = new Date();
    public addPaidOff: boolean = false;
    public searchSupplierName: string;
    public filterRetailPlaceId: number;
    public filterWarehouseId: number;
    public supplierSubset: CustomerSuppliersModel[];
    public selectedSupplier: CustomerSuppliersModel;
    public headerForm: UntypedFormGroup;
    get hForm() { return this.headerForm.controls; }

    public headerFormEnabled: boolean;
    public itemFormEnabled: boolean;

    public newPaymentAmount: number = 0;
    public newPaymentDate: Date = new Date();
    public newPayments: InvoiceCustomerPaymentModel[] = [];

    public creatingInvoice: IncomingInvoiceModel;
    public selectedArticle: ArticleModel;
    public selectionUnderway: boolean = false;
    public totalAmount: number = 0;
    tableSetUp: boolean = false;
    public addTaxId: number = 0;
    public entryPrice: number;
    public entryPriceDisplay: number;
    public nc: number;
    public vpc: number;
    public mpc: number;
    public vpcDisplay: number;
    public mpcDisplay: number;
    public marginAmount: number;
    public marginAmountDisplay: number;
    public triggerFinal: boolean;
    public finalPrice: number;
    public addItemForm: UntypedFormGroup;
    public selectedCurrency: string;
    get iForm() { return this.addItemForm.controls; }
    selectionModalTimer: any;
    enterCount: number = 0;
    isRawGroup: boolean = false;
    creationInProgress: boolean = false;
    public pdfSource:any;

    public itemsTotalWithoutTax: number;
    public itemsTotalTax: number;

    public paymentsToAdd: InvoiceCustomerPaymentModel[];
    public paymentsForm: UntypedFormGroup;
    get pForm() { return this.paymentsForm.controls; }

    @ViewChild('closeAddInvoiceHeaderModal') closeAddInvoiceHeaderModal: ElementRef;
    @ViewChild('closeAddInvoiceItemsModal') closeAddInvoiceItemsModal: ElementRef;
    @ViewChild('closeEditInvoiceHeaderModal') closeEditInvoiceHeaderModal: ElementRef;
    @ViewChild('closeEditInvoiceItemsModal') closeEditInvoiceItemsModal: ElementRef;
    @ViewChild('selectAddInvoicePaymentTypeId') selectAddInvoicePaymentTypeId: ElementRef;
    @ViewChild('selectAddInvoiceCurrencyId') selectAddInvoiceCurrencyId: ElementRef;
    @ViewChild('selectAddInvoiceWarehouseId') selectAddInvoiceWarehouseId: ElementRef;
    @ViewChild('selectTaxId') selectTaxId: ElementRef;
    @ViewChild('selectEditInvoiceSupplierId') selectEditInvoiceSupplierId: ElementRef;
    @ViewChild('selectEditInvoicePaymentTypeId') selectEditInvoicePaymentTypeId: ElementRef;
    @ViewChild('selectEditInvoiceCurrencyId') selectEditInvoiceCurrencyId: ElementRef;
    @ViewChild('selectEditInvoiceWarehouseId') selectEditInvoiceWarehouseId: ElementRef;
    @ViewChild('selectEditTaxId') selectEditTaxId: ElementRef;
    @ViewChild('closeSelectItemModal') closeSelectItemModal: ElementRef;
    @ViewChild('openFinalPriceModal') openFinalPriceModal: ElementRef;
    @ViewChild('closeFinalPriceModal') closeFinalPriceModal: ElementRef;
    @ViewChild('headerTrigger') headerTrigger: ElementRef;
    @ViewChild('itemsTrigger') itemsTrigger: ElementRef;
    @ViewChild('listTrigger') listTrigger: ElementRef;
    @ViewChild('invoiceNumberOpen') invoiceNumberOpen: ElementRef;
    @ViewChild('invoiceNumberClose') invoiceNumberClose: ElementRef;
    @ViewChild('firstHeaderForm') firstHeaderFormElement: ElementRef;
    @ViewChild('itemCodeInput') itemCodeInput: ElementRef;
    @ViewChild('invoiceNumberInput') invoiceNumberInput: ElementRef;
    @ViewChild('finalPriceInput') finalPriceInput: ElementRef;
    @ViewChild('closePaymentsModal') closePaymentsModal: ElementRef;
    @ViewChild('selectFilterRetailPlaceId') selectFilterRetailPlaceId: ElementRef;
    @ViewChild('selectFilterWarehouseId') selectFilterWarehouseId: ElementRef;
    @ViewChild('addItemsButton') addItemsButton: ElementRef;
    @ViewChild(ItemLookupComponent) itemLookupComponent: ItemLookupComponent;

    public saveState: IncomingInvoiceSaveModel;
    public showItems: boolean = true;
    public setUpComplete: boolean = false;
    public editEnabled: boolean = false;
    public startedEditing: boolean = false;
    public showSupplierError: boolean = false;
    public nextNumber: number;
    public addEnabled: boolean = false;
    public changedArrivalDate: boolean = false;
    public headerSaved: boolean = false;
    public editingItems: boolean = false;
    public newInvoiceActive: boolean = false;
    public totalPaymentsAmount: number = 0;
    public editingItem: IncomingArticleModel;
    public editingItemIndex: number;
    public rememberLast: boolean = true;
    public affectNC: boolean = true;
    public affectVPC: boolean = false;
    public affectMPC: boolean = false;
    public pricesUpToDate: boolean = true;
    public invoiceAmounNotMatching: boolean = false;
    public unappliedItems: boolean;
    public supplierModalOpen: boolean = false;
    public previousWarehouseId: number;
    public documentArticles: ArticleModel[];
    public onHeaderTab: boolean = true;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;
    public resizeObserver: ResizeObserver;

    constructor(
        private incomingInvoiceRest: IncomingInvoiceRestService,
        private articleRestService: ArticleRestService,
        private groupRestService: GroupRestService,
        private supplierRestService: CustomerSupplierRestService,
        private paymentTypeRestService: PaymentTypeRestService,
        private warehouseRestService: WarehouseRestService,
        private currencyRestService: CurrencyRestService,
        private datePipe: DatePipe,
        private _changeRef: ChangeDetectorRef,
        public data: DataService,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private router: Router
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit(): void {
        KTMenu.createInstances();
        this.fetchInvoices();
        this.initializeSaveState();
        this.fetchTaxes();
        this.fetchGroups();
        this.fetchSuppliers();
        this.fetchCurrencies();
        this.fetchPaymentTypes();
        this.fetchWarehouses();
        this.createSearchForm();
        this.createHeaderForm();
        this.createPaymentAndDiscountForm();
        this.createAddItemForm();
        this.viewDocuments();
    }

    ngAfterViewInit() {
        // this.headerTrigger.nativeElement.click();

        $(this.headerTrigger.nativeElement).on('shown.bs.tab', () => {
            this.firstHeaderFormElement.nativeElement.focus();
        });

        $(this.itemsTrigger.nativeElement).on('shown.bs.tab', () => {
            this.itemCodeInput.nativeElement.focus();
        });

        $("#kt_modal_invoice_number").on('shown.bs.modal', () => {
            this.invoiceNumberInput.nativeElement.focus();
        });

        $("#kt_modal_select_invoice_supplier").on('shown.bs.modal', () => {
            $("#searchSupplierInput").focus();
        });

        $("#kt_modal_select_invoice_supplier").on('hidden.bs.modal', () => {
            this.refreshSupplierLookup();
            this._changeRef.detectChanges();
        });

        $("#kt_modal_final_price").on('shown.bs.modal', () => {
            this.finalPriceInput.nativeElement.focus();
        });
    }

    ngAfterViewChecked() {
        if (!this.setUpComplete) {
            this.setUpFields();

            if (this.valuesMatch()) {
                this.setUpComplete = true;

                Object.keys(this.headerForm.controls).forEach(name => {
                    let control = this.headerForm.get(name);

                    control.valueChanges.subscribe(value => this.updateCreatingInvoice(name, value));
                })

                this.changedArrivalDate = this.saveState.changedArrivalDate;

                if (this.addEnabled || (this.startedEditing && !this.editingItems))
                    this.enableHeaderForm();
                else
                    this.disableHeaderForm();
                if (this.editingItems)
                    this.enableItemForm();
                else this.disableItemForm();

                this.updateState();
                this._changeRef.detectChanges();
            }
        }
    }

    valuesMatch() {
        var check = true;

        Object.keys(this.headerForm.controls).forEach(name => {
            let control = this.headerForm.get(name);
            if (control.value != this.creatingInvoice[name]) {
                check = false;
            }
            else {
            }
        });

        return check;
    }

    async createSearchForm() {
        this.searchForm = this.formBuilder.group({
            filterRetailPlaceId: [null],
            filterWarehouseId: [null]
        })

        $("#invoice_filter_warehouseId").select2();
        $('#invoice_filter_warehouseId').on("change", function () {
            self.selectFilterWarehouseId.nativeElement.value = $(this).val();
            self.filterWarehouseId = $(this).val();
        });

        $("#invoice_filter_retailPlaceId").select2();
        $('#invoice_filter_retailPlaceId').on("change", function () {
            self.selectFilterRetailPlaceId.nativeElement.value = $(this).val();
            self.filterRetailPlaceId = $(this).val();
        });

        var start = new Date(new Date().getFullYear(), 0, 1);
        var end = new Date();
        var self = this;
        $("#kt_daterangepicker_filter").daterangepicker({
            startDate: start,
            endDate: end,
            autoApply: true,
            locale: {
                format: 'DD/MM/YYYY',
                separator: '-'
            }
        });
        $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            $("#searchByCustomerInput").focus();
            self.fetchInvoices();
        });
        $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
            picker.setStartDate(new Date());
            picker.setEndDate(new Date());
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            self.fetchInvoices();
        });
        var im = new Inputmask("99/99/9999-99/99/9999");
        im.mask($("#kt_daterangepicker_filter"));
    }

    async resetFilter() {
        this.searchForm.reset();
        $("#invoice_filter_warehouseId").val(null).trigger("change");
        $("#invoice_filter_retailPlaceId").val(null).trigger("change");
    }

    filterByDate(event: KeyboardEvent) {
        if (event.key == "Enter" || event.key == "Tab") {
            var pickerElement = $("#kt_daterangepicker_filter");
            $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        }
    }

    async fetchGroups() {
        try{
        var response = await this.groupRestService.getGroups('', null, null, 1, 100000);
        this.groups = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchSuppliers() {
        try{
        var response = await this.supplierRestService.getCustomerSuppliers(this.data.user.clientId, '', null, null, null, true, 1, 999999);
        this.suppliers = response.data;
        this.supplierSubset = response.data;
        if (this.saveState.supplierCode != undefined || this.saveState.supplierCode != null)
            this.selectedSupplier = this.suppliers.find(x => x.code == this.saveState.supplierCode);
    }
    catch (err) {
        if (err.status == 401) {
            this.data.logout();
        }
    }
    }

    async fetchTaxes() {
        try{
        this.taxes = await this.groupRestService.getTaxesByClientId(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchCurrencies() {
        try{
        this.currencies = await this.currencyRestService.getCurrencies();
        if (this.creatingInvoice != undefined && this.creatingInvoice.currencyId != undefined)
            this.selectedCurrency = this.currencies.find(x => x.id == this.creatingInvoice.currencyId).code;
    }
    catch (err) {
        if (err.status == 401) {
            this.data.logout();
        }
    }
    }

    async fetchPaymentTypes() {
        try{
        var response = await this.paymentTypeRestService.getPaymentTypes(this.data.user.clientId, '', 1, 9999);
        this.paymentTypes = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchWarehouses() {
        try{
        this.warehouses = await this.warehouseRestService.getWarehousesForClient(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchRegistryArticles() {
        try{
        var response = await this.articleRestService.getArticlesWithIds(this.data.user.clientId, this.creatingInvoice.articles.map(obj => obj.id));
        this.registryArticles = response;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }
    attachRegistryArticles(fetchedArticles: Array<ArticleModel>) {
        if (this.registryArticles != undefined)
            this.registryArticles = this.registryArticles.concat(fetchedArticles);
        else
            this.registryArticles = fetchedArticles;
    }

    async fetchInvoices() {
        try {
            this.emptyData = false;
            if (this.dateFrom == undefined)
                this.dateFrom = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
            if (this.dateTo == undefined)
                this.dateTo = this.datePipe.transform((new Date()), 'yyyy-MM-dd');

            var response = await this.incomingInvoiceRest.getInvoices(this.supplierName, this.filterWarehouseId, this.filterRetailPlaceId, '', this.dateFrom, this.dateTo, this.data.user.clientId, this.page, this.perPage);
            this.incomingInvoices = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchInvoicePdf(id: string) {
        var response = await this.incomingInvoiceRest.getInvoicesPDF(this.data.user.clientId, id);
        this.pdfSource = window.URL.createObjectURL(response);
    }

    exportPDF() {
        if (this.pdfSource)
            this.router.navigate(['/incoming-invoice-pdf'], { queryParams: { pdfSrc: this.pdfSource } });
        else {
            toastr.options = this.data.toastrOptions;
            toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
        }
    }

    async getNextNumber() {
        this.nextNumber = await this.incomingInvoiceRest.getNextNumber(this.data.user.clientId);
        if (this.creatingInvoice.documentNumber == undefined) {
            this.creatingInvoice.documentNumber = this.nextNumber;
            this.creatingInvoice.incomingInvoiceNumber = this.nextNumber + '/' + ((new Date()).getFullYear() - 2000);
            this.updateState();
        }
    }

    loadState() {
        return this.data.getDocumentState("incomingInvoicePage");
    }

    updateState() {
        this.saveState.activeInvoice = this.creatingInvoice;
        this.data.saveDocumentState("incomingInvoicePage", this.saveState);
    }

    initializeSaveState() {
        this.saveState = this.loadState();
        if (this.saveState == undefined || this.saveState == null || !this.saveState.newInvoiceActive) {
            this.resetActiveInvoice();
        } else if (this.saveState.newInvoiceActive) {
            this.creatingInvoice = this.saveState.activeInvoice;
            this.addEnabled = this.saveState.addEnabled;
            this.headerSaved = this.saveState.headerSaved;
            this.editEnabled = this.saveState.editEnabled;
            this.startedEditing = this.saveState.startedEditing;
            this.editingItems = this.saveState.editingItems;
            this.newInvoiceActive = true;
            this.paymentsToAdd = [];
            this.creatingInvoice.payments.forEach(x => { this.totalPaymentsAmount += x.amount; this.paymentsToAdd.push(x) });
            this.fetchRegistryArticles();
        }
    }

    resetActiveInvoice() {
        this.saveState = new IncomingInvoiceSaveModel();
        this.saveState.activeInvoice = new IncomingInvoiceModel();
        this.saveState.activeInvoice.clientId = this.data.user.clientId;
        this.saveState.activeInvoice.articles = [];
        this.saveState.activeInvoice.amountWithoutTax = 0;
        this.saveState.activeInvoice.taxAmount = 0;
        this.saveState.activeInvoice.paidOff = false;
        this.saveState.activeInvoice.payments = [];
        this.creatingInvoice = this.saveState.activeInvoice;
        this.saveState.activeInvoicePreEdit = this.creatingInvoice;
        this.updateState();
    }

    setUpFields() {
        if (this.creatingInvoice.paymentTypeId == undefined && this.paymentTypes != undefined && !this.editEnabled) {
            $("#add_invoice_paymentTypeId").val(this.paymentTypes[0].id).trigger("change");;
            this.creatingInvoice.paymentTypeId = this.paymentTypes[0].id;
        }
        else if (this.paymentTypes != undefined) {
            $("#add_invoice_paymentTypeId").val(this.creatingInvoice.paymentTypeId).trigger("change");
        }

        if (this.creatingInvoice.currencyId == undefined && this.currencies != undefined && !this.editEnabled) {
            $("#add_invoice_currencyId").val(this.currencies[0].id).trigger("change");;
            this.creatingInvoice.currencyId = this.currencies[0].id;
        }
        else if (this.creatingInvoice.currencyId != undefined) {
            $("#add_invoice_currencyId").val(this.creatingInvoice.currencyId).trigger("change");
        }

        if (this.creatingInvoice.warehouseId == undefined && this.warehouses != undefined && !this.editEnabled) {
            $("#add_invoice_warehouseId").val(this.warehouses[0].id).trigger("change");
            this.creatingInvoice.warehouseId = this.warehouses[0].id;
        }
        else if (this.creatingInvoice.warehouseId != undefined) {
            if (this.addWarehouseId == undefined || this.addWarehouseId == null || this.addWarehouseId == 0 || isNaN(this.addWarehouseId))
                $("#add_invoice_warehouseId").val(this.creatingInvoice.warehouseId).trigger("change");
        }
        if (this.creatingInvoice.billDate != undefined) {
            $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingInvoice.billDate));
            this.headerForm.controls.billDate.setValue(this.creatingInvoice.billDate);
        } else {
            this.addBillDate = new Date();
            this.headerForm.controls.billDate.setValue(this.addBillDate);
            $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(this.addBillDate);
            this.addArrivalDate = new Date(this.addBillDate.getFullYear(), this.addBillDate.getMonth() + 1, this.addBillDate.getDate());
            $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(this.addArrivalDate);
            this.headerForm.controls.arrivalDate.setValue(this.addArrivalDate);
            this.creatingInvoice.billDate = this.addBillDate;
            this.creatingInvoice.arrivalDate = this.addArrivalDate;
        }

        if (this.creatingInvoice.arrivalDate != undefined) {
            $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingInvoice.arrivalDate));
            this.headerForm.controls.arrivalDate.setValue(this.creatingInvoice.arrivalDate);
        }
        this.headerForm.controls.billNumber.setValue(this.creatingInvoice.billNumber);
        this.headerForm.controls.evidentialNumber.setValue(this.creatingInvoice.evidentialNumber);
        this.headerForm.controls.amountWithoutTax.setValue(this.creatingInvoice.amountWithoutTax);
        this.headerForm.controls.taxAmount.setValue(this.creatingInvoice.taxAmount);
        this.headerForm.controls.note.setValue(this.creatingInvoice.note);
        this.paymentsForm.controls.paidOff.setValue(this.creatingInvoice.paidOff);

        this._changeRef.detectChanges();
    }

    updateCreatingInvoice(name: string, value: any) {
        this.creatingInvoice[name] = value;

        this.updateState();
    }

    async searchInvoices(supplierName: string) {
        this.supplierName = supplierName;
        this.firstLoad = true;
        this.page = 1;
        this.fetchInvoices();
    }

    async searchSuppliers() {
        if (this.searchSupplierName == '')
            this.supplierSubset = this.suppliers;
        else
            this.supplierSubset = this.suppliers.filter(x => x.name.toLowerCase().startsWith(this.searchSupplierName.toLowerCase()));
    }

    selectSupplier(c: CustomerSuppliersModel) {
        this.selectedSupplier = c;
        this.saveState.supplierCode = c.code;
        this.creatingInvoice.supplierId = c.id;
        this.creatingInvoice.supplierName = c.name;
        this.creatingInvoice.supplierIDNumber = c.idNumber;
        this.creatingInvoice.supplierPDVNumber = c.pdvNumber;
        this.creatingInvoice.supplierAddress = c.address;
        this.showSupplierError = false;
        this.updateState();
    }

    refreshSupplierLookup() {
        this.searchSupplierName = '';
        this.supplierSubset = this.suppliers;
    }

    saveSupplier(c: number) {
        this.saveState.supplierCode = c;
        this.updateState();
    }

    openItemsOnEmpty($event: KeyboardEvent = null) {
        var fieldValue = $("#itemCodeInput").val();
        if (!this.itemFormEnabled || (fieldValue != '' && fieldValue != undefined))
            return;

        if ($event != null) {
            if ($event.key == "Enter") {
                this.enterCount++;
                clearTimeout(this.selectionModalTimer);
                this.selectionModalTimer = setTimeout(() => {
                    this.enterCount = 0;
                }, 500);

                if (this.enterCount != 2)
                    return;

                this.itemLookupComponent.openItemsModal();
            }
        } else {
            this.itemLookupComponent.openItemsModal();
        }
    }

    toggleItems(toggle: boolean) {
        this.showItems = toggle;
    }
    toggleDocNav(toggle: boolean) {
        this.onHeaderTab = toggle;
    }

    viewDocuments() {
        this.toggleItems(false);
        this.toggleDocNav(false);
        this.resetSave();
        this.resetForm();
        this.resetItemForm();
        this.disableForm();
        this.headerSaved = false;
        this.startedEditing = false;
        this.editEnabled = false;
        this.addEnabled = false;
        this.editingItems = false;
    }

    async selectInvoice(invoice: IncomingInvoiceModel, switchToHeaderTab: boolean = true) {
        if (switchToHeaderTab)
            this.headerTrigger.nativeElement.click();

        if (this.saveState.newInvoiceActive && this.creatingInvoice.id != invoice.id) {
            this.saveState.newInvoiceActive = false;
        }

        this.creatingInvoice = invoice;
        this.fetchInvoicePdf(this.creatingInvoice.id);
        this.selectedInvoicePaymentType = this.paymentTypes.find(x => x.id == invoice.paymentTypeId);
        this.selectedCurrency = this.currencies.find(x => x.id == invoice.currencyId).code;
        await this.fetchRegistryArticles();

        $('#add_invoice_paymentTypeId').val(this.creatingInvoice.paymentTypeId).trigger("change");
        $('#add_invoice_warehouseId').val(this.creatingInvoice.warehouseId).trigger("change");
        $('#add_invoice_currencyId').val(this.creatingInvoice.currencyId).trigger("change");
        $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingInvoice.billDate));
        $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingInvoice.arrivalDate));
        this.hForm.billNumber.setValue(this.creatingInvoice.billNumber);
        this.hForm.evidentialNumber.setValue(this.creatingInvoice.evidentialNumber);
        this.hForm.billDate.setValue(this.creatingInvoice.billDate);
        this.hForm.arrivalDate.setValue(this.creatingInvoice.arrivalDate);
        this.hForm.amountWithoutTax.setValue(parseFloat(this.creatingInvoice.amountWithoutTax.toFixed(2)));
        this.hForm.taxAmount.setValue(parseFloat(this.creatingInvoice.taxAmount.toFixed(2)));
        this.addEnabled = false;
        this.editEnabled = true;
        this.startedEditing = false;

        this.saveState.activeInvoicePreEdit = this.makePreEditObject();
        this.saveState.supplierCode = this.suppliers.find(x => x.id == this.creatingInvoice.supplierId).code;

        $("#supplierCodeInput").val(this.saveState.supplierCode);
        this.selectedSupplier = this.suppliers.find(x => x.id == this.creatingInvoice.supplierId);
        this.hForm.note.setValue(this.creatingInvoice.note);
        this.iForm.discount1.setValue(0);

        this.selectedArticle = null;
        this.pForm.paidOff.setValue(this.creatingInvoice.paidOff);
        this.paymentsToAdd = [];
        this.creatingInvoice.payments.forEach(x => this.paymentsToAdd.push(x));
        this.totalPaymentsAmount = 0;
        this.paymentsToAdd.forEach(x => this.totalPaymentsAmount += x.amount);

        for (let index = 0; index < this.creatingInvoice.articles.length; index++) {
            let i = this.creatingInvoice.articles[index];
            i.pricesUpToDate = this.pricesMatchCheck(i);
            if (!i.pricesUpToDate)
                this.unappliedItems = true;
        }

        if (this.creatingInvoice.warehouseId != this.previousWarehouseId) {
            this._changeRef.detectChanges();
            this.previousWarehouseId = this.creatingInvoice.warehouseId;
            this.itemLookupComponent.fetchArticles(true);
        }

        this.checkInvoiceAmountMatch();
        this.headerSaved = true;
        this.disableForm();
    }

    async selectNext() {
        var index = this.incomingInvoices.findIndex(x => x.id == this.creatingInvoice.id);

        if (index == 0) {
            if (this.page == 1)
                return;

            await this.prevpage();
            await this.fetchInvoices();

            if (this.incomingInvoices.length > 0) {
                this.selectInvoice(this.incomingInvoices[this.incomingInvoices.length - 1]);
            }
        }
        else {
            this.selectInvoice(this.incomingInvoices[index - 1]);
        }
    }

    async selectPrevious() {
        var index = this.incomingInvoices.findIndex(x => x.id == this.creatingInvoice.id);
        if (index == (this.incomingInvoices.length - 1)) {
            if (this.page == this.maxPage)
                return;

            await this.nextPage();
            await this.fetchInvoices();

            if (this.incomingInvoices.length > 0) {
                this.selectInvoice(this.incomingInvoices[0]);
            }
        }
        else {
            this.selectInvoice(this.incomingInvoices[index + 1]);
        }
    }

    startEditing(header: boolean) {
        if (header) {
            this.headerSaved = false;
        }
        this.enableHeaderForm(false);
        this.disableItemForm();
        this.resetItemForm();
        this.startedEditing = true;
        if (this.editingItems)
            this.editingItems = false;
    }
    startAddingItems() {
        if (this.itemLookupComponent.articles.length == 0)
            this.itemLookupComponent.fetchArticles();
        this.enableItemForm();
        this.startedEditing = true;
        this.editingItems = true;
    }
    switchToArticles() {
        this.itemsTrigger.nativeElement.click();
        this.startAddingItems();
    }

    cancelEditing(header: boolean = true) {
        this.selectInvoice(this.saveState.activeInvoicePreEdit, false);
        this.disableForm();
        if (!header) {
            this.resetItemForm();
        }
        this.startedEditing = false;
        this.editEnabled = true;
        this.addEnabled = false;
        this.editingItems = false;
        if (this.creatingInvoice.id != undefined)
            this.headerSaved = true;
    }

    exitDetails(redirectToHeader: boolean = false) {
        if (redirectToHeader)
            this.headerTrigger.nativeElement.click();

        this.resetSave();
        this.resetForm()
        this.headerSaved = false;
    }

    cancelAdding() {
        this.disableForm();
        this.resetSave();
        this.resetForm();
        this.updateState();
    }

    manualInputDate(id: string) {
        var pickerElement = $("#" + id);
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }

    async createHeaderForm() {
        this.headerForm = this.formBuilder.group({
            billNumber: [null, Validators.required],
            evidentialNumber: [null, Validators.required],
            paymentTypeId: [0, Validators.required],
            warehouseId: [0, Validators.required],
            currencyId: [0, Validators.required],
            billDate: [],
            arrivalDate: [],
            amountWithoutTax: [0, Validators.required],
            taxAmount: [0, Validators.required],
            note: [''],
        });

        var self = this;

        $("#add_invoice_paymentTypeId").select2();
        $('#add_invoice_paymentTypeId').on("change", function () {
            self.selectAddInvoicePaymentTypeId.nativeElement.value = $(this).val();
            self.addPaymentTypeId = $(this).val();
        });

        $("#add_invoice_warehouseId").select2();
        $('#add_invoice_warehouseId').on("change", function () {
            self.selectAddInvoiceWarehouseId.nativeElement.value = $(this).val();
            self.addWarehouseId = $(this).val();
        });

        $("#add_invoice_currencyId").select2();
        $('#add_invoice_currencyId').on("change", function () {
            self.selectAddInvoiceCurrencyId.nativeElement.value = $(this).val();
            self.addCurrencyId = $(this).val();
        });

        var start = (new Date()).setDate((new Date()).getDate() - 29);
        var end = new Date();
        $("#kt_bill_date_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            autoUpdateInput: true,
            startDate: start,
            endDate: end,
            locale: {
                format: 'DD/MM/YYYY',
            }
        });
        $("#kt_bill_date_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.addBillDate = picker.startDate._d;
            self.headerForm.controls.billDate.setValue(self.addBillDate);
            if (!self.changedArrivalDate) {
                self.addArrivalDate = new Date(self.addBillDate.getFullYear(), self.addBillDate.getMonth() + 1, self.addBillDate.getDate());
                $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(self.addArrivalDate);
                self.headerForm.controls.arrivalDate.setValue(self.addArrivalDate);
            }
        });
        var im = new Inputmask("99/99/9999");
        im.mask($("#kt_bill_date_add_picker"));

        var arrivalDefault = new Date(new Date().setMonth(new Date().getMonth() + 1));
        var startArrival = (arrivalDefault).setDate(arrivalDefault.getDate());
        $("#kt_arrival_date_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: startArrival,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });
        $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(arrivalDefault);
        $("#kt_arrival_date_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.changedArrivalDate = true;
            self.saveState.changedArrivalDate = true;
            self.addArrivalDate = picker.startDate._d;
            self.headerForm.controls.arrivalDate.setValue(self.addArrivalDate);
        });
        var im2 = new Inputmask("99/99/9999");
        im2.mask($("#kt_arrival_date_add_picker"));

        if (this.editEnabled && !this.startedEditing)
            this.disableForm();
    }

    createAddItemForm() {
        this.addItemForm = this.formBuilder.group({
            quantity: [1, Validators.required],
            entryPrice: [0, [Validators.required, minValidator(0.01)]],
            discount1: [0, Validators.required],
            discount2: [0, Validators.required],
            margin: [0, Validators.required],
            vpc: [0, Validators.required],
            mpc: [0, Validators.required],
            addTaxId: [0, Validators.required],
        });
        var self = this;
        $("#add_item_taxId").select2();
        $('#add_item_taxId').on("change", function () {
            self.selectTaxId.nativeElement.value = $(this).val();
            self.addTaxId = $(this).val();
            self.selectedInvoiceTax = self.taxes.find(x => x.id == self.addTaxId);
            self.changedTax();
        });
    }

    async createPaymentAndDiscountForm() {
        this.paymentsForm = this.formBuilder.group({
            newPaymentAmount: [0, Validators.required],
            paymentDate: [],
            paidOff: [false]
        });
        var self = this;
        var start = new Date();
        $("#kt_new_payment_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: start,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });
        $("#kt_new_payment_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.newPaymentDate = picker.startDate._d;
            $("#newPaymentAmount").focus();
        });
        var im = new Inputmask("99/99/9999");
        im.mask($("#kt_new_payment_add_picker"));
    }

    disableForm() {
        this.disableHeaderForm(false);
        this.disableItemForm();
    }
    disableHeaderForm(emit: boolean = true) {
        if (emit == false) {
            this.headerForm.disable({ emitEvent: false });
        } else {
            this.headerForm.disable();
        }

        this.headerFormEnabled = false;
        $("#supplierCodeInput").prop("disabled", true);
        $("#supplierLookup").addClass("disabled");
        $("#kt_bill_date_add_picker").prop("disabled", true);
        $("#kt_arrival_date_add_picker").prop("disabled", true);
    }
    disableItemForm() {
        this.addItemForm.disable();
        if (this.itemFormEnabled) {
            this.itemFormEnabled = false;
            setTimeout(() => {
                this.addItemsButton.nativeElement.focus();
            }, 0);
        }
        $("#itemCodeInput").prop("disabled", true);
        $("#itemLookup").addClass("disabled");
    }

    enableForm() {
        this.enableHeaderForm();
        this.enableItemForm();
    }
    enableHeaderForm(emit: boolean = true) {
        if (emit == false)
            this.headerForm.enable({ emitEvent: false });
        else
            this.headerForm.enable();
        this.headerFormEnabled = true;
        $("#supplierCodeInput").prop("disabled", false);
        $("#supplierLookup").removeClass("disabled");
        $("#kt_bill_date_add_picker").prop("disabled", false);
        $("#kt_arrival_date_add_picker").prop("disabled", false);
    }
    enableItemForm() {
        this.addItemForm.enable();
        this.itemFormEnabled = true;
        $("#itemCodeInput").prop("disabled", false);
        $("#itemLookup").removeClass("disabled");
        $("#add_item_taxId").val(this.taxes[0].id).trigger("change");
    }

    async resetForm() {
        this.selectedSupplier = null;
        $('#add_invoice_paymentTypeId').val(this.paymentTypes[0].id).trigger("change");
        $('#add_invoice_currencyId').val(this.currencies[0].id).trigger("change");
        $('#add_invoice_warehouseId').val(this.warehouses[0].id).trigger("change");
        $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date());
        this.addBillDate = new Date();
        this.hForm.billDate.setValue(this.addBillDate);
        this.addArrivalDate = new Date(this.addBillDate.getFullYear(), this.addBillDate.getMonth() + 1, this.addBillDate.getDate());
        $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(this.addArrivalDate);
        this.hForm.arrivalDate.setValue(this.addArrivalDate);

        this.headerForm.controls.evidentialNumber.setValue(null);
        this.headerForm.controls.billNumber.setValue(null);
        this.headerForm.controls.amountWithoutTax.setValue(0);
        this.headerForm.controls.taxAmount.setValue(0);
        this.headerForm.controls.note.setValue(null);

        $('#itemCodeInput').val(null);
        this.selectedArticle = undefined;
        this.addItemForm.controls.quantity.setValue(1);
        this.addItemForm.controls.entryPrice.setValue(null);
        this.addItemForm.controls.discount1.setValue(0);
        this.addItemForm.controls.discount2.setValue(0);
        this.addItemForm.controls.margin.setValue(0);
        this.addItemForm.controls.vpc.setValue(0);
        this.addItemForm.controls.mpc.setValue(0);
        this.marginAmount = 0;
        this.marginAmountDisplay = 0;
        $('#add_item_taxId').val(this.taxes[0].id).trigger("change");
        this.isRawGroup = false;

        this.affectNC = true;
        this.affectVPC = false;
        this.affectMPC = false;
        this.rememberLast = true;
        this.addEnabled = false;
        this.editEnabled = false;
        this.startedEditing = false;

        this.entryPrice = 0;
        this.finalPrice = 0;
        this.totalAmount = 0;

        this.paymentsForm.controls.paidOff.setValue(false);
        this.addPaidOff = false;
        this.newPaymentAmount = 0;

        this.headerFormEnabled = false;
        this.itemFormEnabled = false;

        this.changedArrivalDate = false;
    }

    resetSave() {
        this.saveState = new IncomingInvoiceSaveModel();
        this.saveState.activeInvoice = new IncomingInvoiceModel();
        this.saveState.activeInvoice.clientId = this.data.user.clientId;
        this.saveState.activeInvoice.warehouseId = this.warehouses[0].id;
        this.saveState.activeInvoice.paymentTypeId = this.paymentTypes[0].id;
        this.saveState.activeInvoice.currencyId = this.currencies[0].id;
        this.saveState.activeInvoice.articles = [];
        this.saveState.activeInvoice.payments = [];

        this.creatingInvoice = this.saveState.activeInvoice;

        this.editEnabled = false;
        this.addEnabled = false;
        this.startedEditing = false;
        this.saveState.addEnabled = this.addEnabled;
        this.saveState.changedArrivalDate = false;
    }

    async addIncomingInvoice() {
        if (this.creationInProgress)
            return;
        this.creationInProgress = true;

        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            this.creationInProgress = false;
            return;
        }

        try {
            await this.incomingInvoiceRest.createIncomingInvoice(this.creatingInvoice);
            this.creatingInvoice.id = await this.getNewlyCreatedId();
            this.saveState.activeInvoice.id = this.creatingInvoice.id;
            this.saveState.activeInvoicePreEdit = this.makePreEditObject();
            this.headerSaved = true;
            this.editingItems = true;
            this.saveState.editingItems = true;
            this.saveState.headerSaved = true;
            this.updateState();
            this.setPostAddingLayout();

            if (this.creatingInvoice.warehouseId != this.previousWarehouseId) {
                this.previousWarehouseId = this.creatingInvoice.warehouseId;
                this.itemLookupComponent.fetchArticles(true);
            }

            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_incoming_invoice_text'), this.translate.instant("_Success"));
            this.fetchInvoices();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }

        this.creationInProgress = false;
    }
    makePreEditObject() {
        var obj = new IncomingInvoiceModel();
        Object.keys(this.creatingInvoice).forEach(key => {
            if (key != 'articles') {
                let value = this.creatingInvoice[key];
                obj[key] = value;
            }
        })
        obj.articles = [];
        this.creatingInvoice.articles.forEach(x => {
            var itemCopy = new IncomingArticleModel();
            Object.keys(x).forEach(key => {
                let value = x[key];
                itemCopy[key] = value;
            })
            obj.articles.push(itemCopy);
        });
        return obj;
    }
    async getNewlyCreatedId() {
        var response = await this.incomingInvoiceRest.getInvoices('', this.filterWarehouseId, this.filterRetailPlaceId, this.creatingInvoice.billNumber, this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.data.user.clientId, 1, 1)
        var id = response.data[0].id;
        return id;
    }

    showList() {
        this.listTrigger.nativeElement.click();
    }

    async editIncomingInvoice(instantReverse: boolean = false) {
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        try {
            await this.incomingInvoiceRest.editIncomingInvoice(this.creatingInvoice);
            this.saveState.activeInvoicePreEdit = this.makePreEditObject();
            this.startedEditing = false;
            this.editingItems = false;
            this.saveState.startedEditing = false;
            this.saveState.editingItems = false;
            this.headerSaved = true;
            this.saveState.headerSaved = true;
            this.resetItemForm();
            this.disableForm();
            this.updateState();
            this.checkInvoiceAmountMatch();

            if (this.creatingInvoice.warehouseId != this.previousWarehouseId) {
                this.previousWarehouseId = this.creatingInvoice.warehouseId;
                this.itemLookupComponent.fetchArticles(true);
            }

            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Edited_incoming_invoice_text'), this.translate.instant("_Success"));
            this.fetchInvoices();
        }
        catch (err) {
            if (instantReverse)
                this.creatingInvoice = this.saveState.activeInvoicePreEdit;

            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    async deleteInvoice(id: string) {
        var self = this;
        var question_text = this.translate.instant("_Delete_invoice_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.incomingInvoiceRest.deleteIncomingInvoice(id);
                if (this.creatingInvoice.id == id) {
                    this.resetActiveInvoice();
                    this.disableForm();
                    this.startedEditing = false;
                    this.editEnabled = true;
                    this.addEnabled = false;
                    this.editingItems = false;
                    this.resetForm();
                }
                await this.fetchInvoices();
                toastr.options = this.data.toastrOptions;
                toastr.success(this.translate.instant('_Deleted_incoming_invoice_text'), this.translate.instant("_Success"));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    checkInvoiceAmountMatch() {
        this.itemsTotalWithoutTax = 0;
        this.itemsTotalTax = 0;
        this.creatingInvoice.articles.forEach(x => {
            var discount1Amount = x.entryPrice * (x.discount1Percentage / 100);
            var postDiscount1Price = x.entryPrice - discount1Amount;
            var discount2Amount = postDiscount1Price * (x.discount2Percentage / 100);
            var combinedDiscountAmount = discount1Amount + discount2Amount;
            var preTaxPrice = x.entryPrice - combinedDiscountAmount;
            var tax = parseFloat((preTaxPrice * (x.taxPercentage / 100)).toFixed(6));
            this.itemsTotalTax += tax * x.quantity;
            this.itemsTotalWithoutTax += preTaxPrice * x.quantity;
        });
        if (this.creatingInvoice.amountWithoutTax != this.itemsTotalWithoutTax || this.creatingInvoice.taxAmount != this.itemsTotalTax)
            this.invoiceAmounNotMatching = true;
        else this.invoiceAmounNotMatching = false;

    }

    shouldFetchArticleState(id: number) {
        if (this.itemLookupComponent.articles.find(x => x.id == id) != undefined)
            return false;
        if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == id) != undefined)
            return false;

        return true;
    }
    async selectItem(item: IncomingArticleModel) {
        if (this.shouldFetchArticleState(item.id)) {
            try {
                var articlesToFetch = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.creatingInvoice.warehouseId, 1, 99999, '', false, false, item.id);
                if (this.documentArticles == undefined)
                    this.documentArticles = [];
                this.documentArticles.push(articlesToFetch[0]);
                this.selectedArticle = articlesToFetch[0];
            } catch (err: any) {
                toastr.options = this.data.toastrOptions;
                toastr.error(err.error['Message'], this.translate.instant("_Error"));
            }
        } else {
            if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == item.id))
                this.selectedArticle = this.documentArticles.find(x => x.id == item.id);
            else
                this.selectedArticle = this.itemLookupComponent.articles.find(x => x.id == item.id);
        }
        this.enableItemForm();
        $("#quantity").val(item.quantity);
        this.iForm.quantity.setValue(item.quantity);
        $("#discount1").val(item.discount1Percentage);
        $("#discount2").val(item.discount2Percentage);
        $("#add_item_taxId").val(item.taxId).trigger("change");
        this.iForm.discount1.setValue(item.discount1Percentage);
        this.iForm.discount2.setValue(item.discount2Percentage);
        this.entryPrice = item.entryPrice;
        this.entryPriceDisplay = parseFloat(item.entryPrice.toFixed(2));
        this.iForm.entryPrice.setValue(this.entryPriceDisplay);
        this.iForm.vpc.setValue(item.vpc),
            this.iForm.mpc.setValue(item.mpc),
            this.nc = item.nc
        this.vpc = item.vpc;
        this.mpc = item.mpc;
        this.iForm.margin.setValue(item.marginPercentage),
            this.marginAmount = item.marginAmount;
        this.marginAmountDisplay = parseFloat(this.marginAmount.toFixed(2));
        this.iForm.margin.setValue(item.marginPercentage);
        this.rememberLast = item.rememberLast;
        this.affectNC = item.affectNC;
        this.affectVPC = item.affectVPC;
        this.affectMPC = item.affectMPC;
        var articleGroup = this.groups.find(x => x.id == this.selectedArticle.groupId);
        this.isRawGroup = articleGroup.isRawGroup;

        this.startedEditing = true;
        this.editingItems = true;
        this.editingItemIndex = this.creatingInvoice.articles.indexOf(item);
        this.editingItem = item;

        this.itemsTrigger.nativeElement.click();
    }
    editItem() {
        this.isSubmitted = false;
        if (this.addItemForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateItemForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted) {
            return;
        }

        var itemId = 0; var itemQuantity = 0;
        var existingPriceArticle = this.checkForSamePriceItem(null, true);
        if (existingPriceArticle != null) {
            existingPriceArticle.quantity += this.iForm.quantity.value;
            existingPriceArticle.totalEntryPrice += existingPriceArticle.entryPrice * this.iForm.quantity.value;
            existingPriceArticle.totalCombinedDiscountAmount = existingPriceArticle.combinedDiscountAmount * existingPriceArticle.quantity;
            existingPriceArticle.totalDiscount1Amount = existingPriceArticle.discount1Amount * existingPriceArticle.quantity;
            existingPriceArticle.totalDiscount2Amount = existingPriceArticle.discount2Amount * existingPriceArticle.quantity;
            existingPriceArticle.totalMPC = existingPriceArticle.mpc * existingPriceArticle.quantity;
            existingPriceArticle.totalNC = existingPriceArticle.nc * existingPriceArticle.quantity;
            existingPriceArticle.totalVPC = existingPriceArticle.vpc * existingPriceArticle.quantity;
            existingPriceArticle.totalTaxAmount = existingPriceArticle.taxAmount * existingPriceArticle.quantity;
            existingPriceArticle.totalPostDiscount1Price = existingPriceArticle.postDiscount1Price + existingPriceArticle.quantity;

            existingPriceArticle.rememberLast = this.rememberLast;
            existingPriceArticle.affectNC = this.affectNC;
            existingPriceArticle.affectVPC = this.affectVPC;
            existingPriceArticle.affectMPC = this.affectMPC;
            existingPriceArticle.pricesUpToDate = this.pricesMatchCheck(existingPriceArticle);

            itemId = existingPriceArticle.id;
            itemQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));

            this.creatingInvoice.articles.forEach((element, index) => {
                if (element == this.editingItem) this.creatingInvoice.articles.splice(index, 1);
            });
        } else {
            var newQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
            var quantityDiff = newQuantity - this.editingItem.quantity;
            this.editingItem.quantity = newQuantity;
            this.editingItem.entryPrice = this.entryPrice;
            this.editingItem.discount1Percentage = this.iForm.discount1.value;
            this.editingItem.discount1Amount = parseFloat((this.editingItem.entryPrice * (this.editingItem.discount1Percentage / 100)).toFixed(6));
            this.editingItem.postDiscount1Price = this.editingItem.entryPrice - this.editingItem.discount1Amount;
            this.editingItem.discount2Percentage = this.iForm.discount2.value;
            this.editingItem.discount2Amount = parseFloat((this.editingItem.postDiscount1Price * (this.editingItem.discount2Percentage / 100)).toFixed(6));
            this.editingItem.combinedDiscountPercentage = parseFloat((this.editingItem.combinedDiscountAmount / this.editingItem.entryPrice * 100).toFixed(6));

            this.editingItem.nc = this.editingItem.entryPrice - this.editingItem.combinedDiscountAmount;
            this.editingItem.vpc = this.vpc;
            this.editingItem.mpc = this.mpc;
            this.editingItem.marginAmount = this.marginAmount;
            this.editingItem.marginPercentage = parseFloat((this.marginAmount / this.nc * 100).toFixed(6));

            this.editingItem.taxId = this.addTaxId;
            this.editingItem.taxName = this.taxes.find(x => x.id == this.addTaxId).name;
            this.editingItem.taxPercentage = this.taxes.find(x => x.id == this.addTaxId).amount;
            if (this.isRawGroup)
                this.editingItem.taxAmount = parseFloat((this.editingItem.nc * (this.editingItem.taxPercentage / 100)).toFixed(6));
            else
                this.editingItem.taxAmount = parseFloat((this.editingItem.vpc * (this.editingItem.taxPercentage / 100)).toFixed(6));

            this.editingItem.totalDiscount1Amount = this.editingItem.discount1Amount * this.editingItem.quantity;
            this.editingItem.totalPostDiscount1Price = this.editingItem.postDiscount1Price * this.editingItem.quantity;
            this.editingItem.totalDiscount2Amount = this.editingItem.discount2Amount * this.editingItem.quantity;
            this.editingItem.totalEntryPrice = this.editingItem.entryPrice * this.editingItem.quantity;
            this.editingItem.combinedDiscountAmount = this.editingItem.discount1Amount + this.editingItem.discount2Amount;
            this.editingItem.combinedDiscountPercentage = parseFloat((this.editingItem.combinedDiscountAmount / this.editingItem.entryPrice * 100).toFixed(6));
            this.editingItem.totalCombinedDiscountAmount = this.editingItem.combinedDiscountAmount * this.editingItem.quantity;
            this.editingItem.totalTaxAmount = this.editingItem.taxAmount * this.editingItem.quantity;
            this.editingItem.totalNC = this.editingItem.nc * this.editingItem.quantity;
            this.editingItem.totalVPC = this.editingItem.vpc * this.editingItem.quantity;
            this.editingItem.totalMPC = this.editingItem.mpc * this.editingItem.quantity;

            if (this.editingItem.rememberLast != this.rememberLast)
                this.editingItem.changedLastAverage = true;
            else this.editingItem.changedLastAverage = false;
            this.editingItem.rememberLast = this.rememberLast;
            this.editingItem.affectNC = this.affectNC;
            this.editingItem.affectVPC = this.affectVPC;
            this.editingItem.affectMPC = this.affectMPC;
            this.setMostRecentArticleEffect(this.editingItem);
            this.editingItem.pricesUpToDate = this.pricesMatchCheck(this.editingItem);
            if (!this.editingItem.pricesUpToDate)
                this.unappliedItems = true;
            else if (this.unappliedItems) {
                for (let index = 0; index < this.creatingInvoice.articles.length; index++) {
                    let i = this.creatingInvoice.articles[index];
                    if (!i.pricesUpToDate)
                        this.unappliedItems = true;
                }
            }

            var item = this.creatingInvoice.articles.find(x => x.id == this.editingItem.id);
            item = this.editingItem;
            itemQuantity = quantityDiff;
        }

        this.editIncomingInvoice();
        this.itemLookupComponent.updateWarehouseState(item.id, itemQuantity);
        this.editingItem = undefined;
        this.resetItemForm();
        if (this.saveState.newInvoiceActive)
            this.updateState();
    }

    validateAddForm() {
        var check = true;

        if (this.selectedSupplier == undefined) {
            check = false;
            this.showSupplierError = true;
        }
        else {
            this.showSupplierError = false;
        }

        if (this.addPaymentTypeId == 0) {
            $("#add_invoice_paymentTypeId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_paymentTypeId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.addWarehouseId == 0) {
            $("#add_invoice_warehouseId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_warehouseId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.addCurrencyId == 0) {
            $("#add_invoice_currencyId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_currencyId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    proceedToAddItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addInvoiceItemsTrigger").click();
    }

    proceedToEditItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addInvoiceItemsTrigger").click();
    }

    backToHeader() {
        $("#headerModalTrigger").click();
    }

    calculateMarginAmount() {
        if (this.iForm.margin.value == undefined) {
            this.marginAmount = undefined;
            this.marginAmountDisplay = undefined;
            return;
        }
        if (this.nc == undefined)
            return;
        this.marginAmount = parseFloat((this.nc * (this.iForm.margin.value / 100)).toFixed(6));
        this.marginAmountDisplay = parseFloat(this.marginAmount.toFixed(2));
    }
    calculateMarginPercentage() {
        if (this.mpc != undefined) {
            var amount = this.mpc - this.nc;
            if (this.nc != 0 && this.nc != undefined) {
                this.iForm.margin.setValue(parseFloat(((amount / this.nc) * 100).toFixed(2)));
            }
        } else {
            this.iForm.margin.setValue(undefined);
        }

        this.calculateMarginAmount();
    }
    changedEntryPrice() {
        if (this.iForm.entryPrice.value != undefined && this.iForm.entryPrice.value != null) {
            this.entryPrice = parseFloat(this.iForm.entryPrice.value.toFixed(6))
            this.entryPriceDisplay = parseFloat(this.iForm.entryPrice.value.toFixed(2));
            this.nc = undefined;
        } else {
            this.entryPrice = undefined;
            this.entryPriceDisplay = undefined;
            return;
        }

        var discount1 = this.iForm.discount1.value;
        var discount2 = this.iForm.discount2.value;
        if (discount1 == undefined || isNaN(discount1)) {
            discount1 = 0;
            this.iForm.discount1.setValue(discount1);
        }
        if (discount2 == undefined || isNaN(discount2)) {
            discount2 = 0;
            this.iForm.discount2.setValue(discount2);
        }
        var discount1Amount = this.entryPrice * (discount1 / 100);
        var postDiscount1Price = this.entryPrice - discount1Amount;
        var discount2Amount = postDiscount1Price * (discount2 / 100);
        var combinedDiscountAmount = discount1Amount + discount2Amount;
        this.nc = parseFloat((this.entryPrice - combinedDiscountAmount).toFixed(6));

        var taxToApply = this.taxes.find(x => x.id == this.addTaxId).amount;

        if (this.iForm.vpc.value == undefined) {
            if (this.isRawGroup) {
                this.vpc = this.nc;
                this.vpcDisplay = parseFloat(this.vpc.toFixed(2));
                this.iForm.vpc.setValue(this.vpcDisplay);
            }
        }
        if (this.iForm.mpc.value == undefined) {
            if (this.isRawGroup) {
                this.mpc = parseFloat((this.nc * (1 + taxToApply / 100)).toFixed(6));
                this.mpcDisplay = parseFloat(this.mpc.toFixed(2));
                this.iForm.mpc.setValue(this.mpcDisplay);
            }
            else if (this.iForm.vpc.value != undefined) {
                this.mpc = parseFloat((this.vpc * (1 + taxToApply / 100)).toFixed(6));
                this.mpcDisplay = parseFloat(this.mpc.toFixed(2));
                this.iForm.mpc.setValue(this.mpcDisplay);
            }
            else return;
        }

        this.calculateMarginPercentage();
    }
    changedVPC() {
        if (this.iForm.vpc.value != undefined && this.iForm.vpc.value != null) {
            this.vpc = parseFloat(this.iForm.vpc.value.toFixed(6));
            this.vpcDisplay = parseFloat(this.iForm.vpc.value.toFixed(2));
        } else {
            this.vpc = undefined;
            this.vpcDisplay = undefined;
            return;
        }

        if (this.iForm.mpc.value == null || this.iForm.mpc == undefined) {
            var taxToApply = this.taxes.find(x => x.id == this.addTaxId).amount;
            this.mpc = parseFloat((this.vpc + (this.vpc * taxToApply / 100)).toFixed(6));
            this.mpcDisplay = parseFloat(this.mpc.toFixed(2));
            this.iForm.mpc.setValue(this.mpcDisplay);
            this.calculateMarginPercentage();
        }
    }
    changedMPC() {
        if (this.iForm.mpc.value != undefined && this.iForm.mpc.value != null) {
            this.mpc = parseFloat(this.iForm.mpc.value.toFixed(6));
            this.mpcDisplay = parseFloat(this.iForm.mpc.value.toFixed(2));
        } else {
            this.mpc = undefined;
            this.mpcDisplay = undefined;
        }

        this.calculateMarginPercentage();
    }
    changedMargin() {
        this.calculateMarginAmount();

        if (this.iForm.margin.value == undefined)
            return;
        if (this.iForm.mpc.value == null || this.iForm.mpc == undefined) {
            this.mpc = parseFloat((this.nc + this.marginAmount).toFixed(6));
            this.mpcDisplay = parseFloat(this.mpc.toFixed(2));
            this.iForm.mpc.setValue(this.mpcDisplay);
        }
    }
    changedDiscount() {
        this.changedEntryPrice();
    }
    changedTax() {
        if (this.iForm.vpc.value != null && this.iForm.vpc.value != undefined)
            if (this.iForm.mpc.value == null || this.iForm.mpc.value == undefined) {
                var taxToApply = this.taxes.find(x => x.id == this.addTaxId).amount;

                if (this.isRawGroup) {
                    this.mpc = parseFloat((this.nc / (1 + taxToApply / 100)).toFixed(6));
                } else {
                    this.mpc = parseFloat((this.vpc / (1 + taxToApply / 100)).toFixed(6));
                }

                this.mpcDisplay = parseFloat(this.mpc.toFixed(2));
                this.iForm.mpc.setValue(this.mpcDisplay);
            }
    }

    negateEnter(event: KeyboardEvent) {
        if (event.key == "Enter")
            event.preventDefault();
    }

    selectOnFocus(id: string) {
        $("#" + id).select();
    }

    editNumber() {
        if (!this.canEditNumber())
            return;

        this.invoiceNumberOpen.nativeElement.click();
        var input = $("#invoiceNumberInput");
        input.removeClass("is-invalid");

        if (this.creatingInvoice.documentNumber != null)
            input.val(this.creatingInvoice.documentNumber);
        else
            input.val(this.nextNumber);
    }
    canEditNumber() {
        if (this.editEnabled && !this.startedEditing)
            return false;

        return true;
    }
    saveNumber() {
        var input = $("#invoiceNumberInput");
        if (input.val() == '' || input.val().includes('.')) {
            input.addClass("is-invalid");
            return;
        } else {
            input.removeClass("is-invalid");
            this.creatingInvoice.documentNumber = input.val();
            this.creatingInvoice.customDocumentNumber = this.creatingInvoice.documentNumber;
            this.creatingInvoice.incomingInvoiceNumber = this.creatingInvoice.documentNumber + "/" + ((new Date()).getFullYear() % 100);
        }

        this.updateState();
        this.invoiceNumberClose.nativeElement.click();
    }

    switchToHeaderAndEnableAdding() {
        this.headerTrigger.nativeElement.click();
        this.enableAdding();
    }

    switchToHeader() {
        this.headerTrigger.nativeElement.click();
    }

    enableAdding() {
        this.getNextNumber();
        this.resetSave();
        this.resetForm();
        this.addEnabled = true;
        this.headerSaved = false;
        this.headerSaved = false;
        this.saveState.addEnabled = true;
        this.saveState.newInvoiceActive = true;
        this.updateState();
        this.enableHeaderForm();
        this.firstHeaderFormElement.nativeElement.focus();
    }
    setPostAddingLayout() {
        this.addEnabled = false;
        this.saveState.addEnabled = false;
        this.editEnabled = true;
        this.saveState.editEnabled = true;
        this.startedEditing = true;
        this.saveState.startedEditing = true;
        this.updateState();
        this.disableHeaderForm(false);
        this.enableItemForm();
        this.itemsTrigger.nativeElement.click();
    }

    async addItem(save: boolean = false) {
        this.isSubmitted = false;
        if (this.addItemForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateItemForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted) {
            return;
        }

        var itemId = 0; var itemQuantity = 0;
        var existingPriceArticle = this.checkForSamePriceItem(this.selectedArticle);
        if (existingPriceArticle != null) {
            existingPriceArticle.quantity += this.iForm.quantity.value;
            existingPriceArticle.totalEntryPrice += existingPriceArticle.entryPrice * this.iForm.quantity.value;
            existingPriceArticle.totalCombinedDiscountAmount = existingPriceArticle.combinedDiscountAmount * existingPriceArticle.quantity;
            existingPriceArticle.totalDiscount1Amount = existingPriceArticle.discount1Amount * existingPriceArticle.quantity;
            existingPriceArticle.totalDiscount2Amount = existingPriceArticle.discount2Amount * existingPriceArticle.quantity;
            existingPriceArticle.totalMPC = existingPriceArticle.mpc * existingPriceArticle.quantity;
            existingPriceArticle.totalNC = existingPriceArticle.nc * existingPriceArticle.quantity;
            existingPriceArticle.totalVPC = existingPriceArticle.vpc * existingPriceArticle.quantity;
            existingPriceArticle.totalTaxAmount = existingPriceArticle.taxAmount * existingPriceArticle.quantity;
            existingPriceArticle.totalPostDiscount1Price = existingPriceArticle.postDiscount1Price + existingPriceArticle.quantity;

            existingPriceArticle.rememberLast = this.rememberLast;
            existingPriceArticle.affectNC = this.affectNC;
            existingPriceArticle.affectVPC = this.affectVPC;
            existingPriceArticle.affectMPC = this.affectMPC;
            existingPriceArticle.pricesUpToDate = this.pricesMatchCheck(existingPriceArticle);

            itemId = existingPriceArticle.id;
            itemQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
        }
        else {
            var item = new IncomingArticleModel();
            item.id = this.selectedArticle.id;
            item.code = this.selectedArticle.code;
            item.name = this.selectedArticle.name;
            item.entryPrice = this.entryPrice;
            item.quantity = this.iForm.quantity.value;
            item.totalEntryPrice = item.entryPrice * item.quantity;
            item.discount1Percentage = this.iForm.discount1.value;
            item.discount1Amount = parseFloat((item.entryPrice * (item.discount1Percentage / 100)).toFixed(6));
            item.postDiscount1Price = item.entryPrice - item.discount1Amount;
            item.discount2Percentage = this.iForm.discount2.value;
            item.discount2Amount = parseFloat((item.postDiscount1Price * (item.discount2Percentage / 100)).toFixed(6));
            item.combinedDiscountAmount = item.discount1Amount + item.discount2Amount;
            item.combinedDiscountPercentage = parseFloat((item.combinedDiscountAmount / item.entryPrice * 100).toFixed(6));

            item.nc = item.entryPrice - item.combinedDiscountAmount;
            item.vpc = this.vpc;
            item.mpc = this.mpc;
            item.totalNC = item.nc * item.quantity;
            item.totalMPC = item.mpc * item.quantity;
            item.totalVPC = item.vpc * item.quantity;

            item.margin = this.iForm.margin.value;
            item.marginPercentage = this.iForm.margin.value;
            item.marginAmount = this.marginAmount;
            item.measureShort = this.selectedArticle.measureName;
            item.isNormative = this.selectedArticle.isNormative;
            item.g = this.selectedArticle.groupId;
            item.gt = this.selectedArticle.groupTypeId;

            item.taxId = this.addTaxId;
            item.taxName = this.taxes.find(x => x.id == this.addTaxId).name;
            item.taxPercentage = this.selectedInvoiceTax.amount;
            if (this.isRawGroup)
                item.taxAmount = parseFloat((item.nc * (item.taxPercentage / 100)).toFixed(6));
            else
                item.taxAmount = parseFloat((item.vpc * (item.taxPercentage / 100)).toFixed(6));

            item.totalDiscount1Amount = item.discount1Amount * item.quantity;
            item.totalDiscount2Amount = item.discount2Amount * item.quantity;
            item.totalPostDiscount1Price = item.postDiscount1Price * item.quantity;
            item.totalPostDiscountPrice = item.postDiscountPrice * item.quantity;
            item.combinedDiscountAmount = item.discount1Amount + item.discount2Amount;
            item.totalCombinedDiscountAmount = item.combinedDiscountAmount * item.quantity;
            item.totalTaxAmount = item.taxAmount * item.quantity;

            item.rememberLast = this.rememberLast;
            item.affectNC = this.affectNC;
            item.affectVPC = this.affectVPC;
            item.affectMPC = this.affectMPC;
            this.creatingInvoice.articles.push(item);
            this.setArticleAddedOrderIndex(item);
            this.setMostRecentArticleEffect(item);
            item.pricesUpToDate = this.pricesMatchCheck(item);
            if (!item.pricesUpToDate)
                this.unappliedItems = true;

            itemId = item.id;
            itemQuantity = item.quantity;

            item.uniqueItemId = uuidv4();
        }

        // this.searchItemCodeName='';
        // this.searchItems();
        this.selectedArticle = undefined;
        this.resetItemForm();

        this.updateState();
        if (save) {
            await this.editIncomingInvoice(true);
            this.itemLookupComponent.updateWarehouseState(itemId, itemQuantity);
        }
    }

    setMostRecentArticleEffect(item: IncomingArticleModel) {
        var duplicates = this.creatingInvoice.articles.filter(x => x.id == item.id && x != item);
        if (duplicates.length > 0) {
            var indexes = duplicates.map(x => x.changeOrderIndex).sort();
            item.changeOrderIndex = indexes[indexes.length - 1] + 1;
        }
        else item.changeOrderIndex = 0;
    }
    setArticleAddedOrderIndex(item: IncomingArticleModel) {
        var duplicates = this.creatingInvoice.articles.filter(x => x.id == item.id && x != item);
        item.addedOrderIndex = duplicates.length;
    }

    pricesMatchCheck(item: IncomingArticleModel) {
        //maybe add check if it has the greatest change index later on

        var registryArticle = this.registryArticles.find(x => x.id == item.id);
        this.pricesUpToDate = true;
        var history: PricesHistoryModel;
        history = JSON.parse(registryArticle.pricesHistory);

        if (item.changedLastAverage) {
            this.pricesUpToDate = false;
            return this.pricesUpToDate;
        }
        if (this.affectNC) {
            var existing = history.NC_History.find(x => x.DocumentId == this.creatingInvoice.id && x.Price == item.nc && x.RememberLast == item.rememberLast);
            if (existing == undefined && history.NC_History[history.NC_History.length - 1].Price != item.nc) {
                this.pricesUpToDate = false;
                return this.pricesUpToDate;
            }
        }
        if (this.affectVPC) {
            var existing = history.VPC_History.find(x => x.DocumentId == this.creatingInvoice.id && x.Price == item.vpc && x.RememberLast == item.rememberLast);
            if (existing == undefined && history.VPC_History[history.VPC_History.length - 1].Price != item.vpc) {
                this.pricesUpToDate = false;
                return this.pricesUpToDate;
            }
        }
        if (this.affectMPC) {
            var existing = history.MPC_History.find(x => x.DocumentId == this.creatingInvoice.id && x.Price == item.mpc && x.RememberLast == item.rememberLast);
            if (existing == undefined && history.MPC_History[history.MPC_History.length - 1].Price != item.mpc) {
                this.pricesUpToDate = false;
                return this.pricesUpToDate;
            }
        }

        return this.pricesUpToDate;
    }

    async applyInvoicePrices() {
        var dto = new ApplyInvoicePricesDTO();
        dto.documentId = this.creatingInvoice.id;
        dto.articles = this.creatingInvoice.articles.filter(x => x.pricesUpToDate == false);

        try {
            await this.incomingInvoiceRest.applyPrices(dto);
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Prices_updated_text'), this.translate.instant("_Success"));
            await this.fetchRegistryArticles();
            await this.itemLookupComponent.fetchArticles(true);
            for (let index = 0; index < this.creatingInvoice.articles.length; index++) {
                let element = this.creatingInvoice.articles[index];

                if (!element.pricesUpToDate) {
                    element.changedLastAverage = false;
                    element.pricesUpToDate = this.pricesMatchCheck(element);
                }
            }
            this.unappliedItems = false;
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    async undoInvoicePrices() {
        var dto = new ApplyInvoicePricesDTO();
        dto.documentId = this.creatingInvoice.id;
        dto.articles = this.creatingInvoice.articles.filter(x => x.affectMPC || x.affectNC || x.affectVPC);

        try {
            await this.incomingInvoiceRest.undoPrices(dto);
            toastr.options = this.data.toastrOptions;
            await this.itemLookupComponent.fetchArticles(true);
            for (let index = 0; index < this.creatingInvoice.articles.length; index++) {
                let element = this.creatingInvoice.articles[index];
                element.pricesUpToDate = this.pricesMatchCheck(element);
            }
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    resetItemForm() {
        this.finalPrice = undefined;
        this.entryPrice = 0;
        $("#itemCodeInput").val(undefined);
        this.iForm.quantity.setValue(1);
        this.iForm.entryPrice.setValue(null);
        this.iForm.margin.setValue(0);
        this.marginAmount = 0;
        this.marginAmountDisplay = 0;
        this.iForm.discount1.setValue(0);
        this.iForm.discount2.setValue(0);
        this.nc = 0;
        this.vpc = 0;
        this.mpc = 0;
        this.iForm.vpc.setValue(0);
        this.iForm.mpc.setValue(0);
        $("#add_item_taxId").val(this.taxes[0].id).trigger("change");
        this.selectedArticle = undefined;
        this.editingItem = undefined;
        this.affectNC = true;
        this.affectVPC = false;
        this.affectMPC = false;
        this.rememberLast = true;
        $("#itemCodeInput").removeClass('is-invalid');
        $("#add_item_taxId").nextAll('span').first().removeClass('is-invalid');
        this.isRawGroup = false;
    }

    checkForSamePriceItem(activeItem: ArticleModel = null, updating: boolean = false) {
        var discount1Percentage = this.iForm.discount1.value;
        var discount1Amount = parseFloat((this.entryPrice * (discount1Percentage / 100)).toFixed(6));
        var postDiscount1Price = this.entryPrice - discount1Amount;
        var discount2Percentage = this.iForm.discount2.value;
        var discount2Amount = parseFloat((postDiscount1Price * (discount2Percentage / 100)).toFixed(6));
        var combinedDiscountAmount = discount1Amount + discount2Amount;
        var nc = this.entryPrice - combinedDiscountAmount;

        if (updating == false)
            return this.creatingInvoice.articles.find(x => x.id == activeItem.id && x.nc == nc && x.vpc == this.vpc && x.mpc == this.mpc);
        else {
            return this.creatingInvoice.articles.find(x => x.id==this.editingItem.id && x.nc == nc && x.vpc == this.vpc && x.mpc == this.mpc && this.creatingInvoice.articles.indexOf(x) != this.editingItemIndex);
        }
    }

    async removeItem(item: IncomingArticleModel) {
        this.creatingInvoice.articles.forEach((element, index) => {
            if (element == item) this.creatingInvoice.articles.splice(index, 1);
        });

        await this.editIncomingInvoice();
        this.itemLookupComponent.updateWarehouseState(item.id, -item.quantity);

        if (this.saveState.newInvoiceActive)
            this.updateState();
    }


    //Payments
    addPayment() {
        if (this.paymentsToAdd == undefined) {
            this.paymentsToAdd = [];
            this.creatingInvoice.payments.forEach(x => this.paymentsToAdd.push(x));
        }

        if (this.newPaymentAmount == 0 || this.newPaymentAmount == undefined) {
            $("#newPaymentAmount").addClass('is-invalid');
            return;
        } else {
            $("#newPaymentAmount").removeClass('is-invalid');
        }
        var newPayment = new InvoiceCustomerPaymentModel();
        newPayment.amount = this.newPaymentAmount;
        newPayment.date = this.newPaymentDate;
        newPayment.displayDate = this.datePipe.transform(this.newPaymentDate, 'yyyy-MM-dd');
        this.paymentsToAdd.push(newPayment);
        this.totalPaymentsAmount += newPayment.amount;
    }
    removePayment(payment: InvoiceCustomerPaymentModel) {
        if (this.paymentsToAdd == undefined) {
            this.paymentsToAdd = [];
            this.creatingInvoice.payments.forEach(x => this.paymentsToAdd.push(x));
        }

        this.paymentsToAdd.forEach((element, index) => {
            if (element == payment) this.paymentsToAdd.splice(index, 1);
        });
        this.totalPaymentsAmount -= payment.amount;
    }
    async updatePaymentInfo() {
        var dto = new UpdateInvoicePaymentDTO();
        dto.invoiceId = this.creatingInvoice.id;
        dto.paidOff = this.addPaidOff;
        dto.payments = this.paymentsToAdd;

        try {
            await this.incomingInvoiceRest.updateInvoicePaymentData(dto);
            this.creatingInvoice.payments = dto.payments;
            this.creatingInvoice.paidOff = this.addPaidOff;
            this.saveState.activeInvoicePreEdit = this.makePreEditObject();
            this.updateState();
            await this.fetchInvoices();
            this.closePaymentsModal.nativeElement.click();
            this.resetPaymentForm();
            toastr.options = this.data.toastrOptions;
            toastr.success("Payment info updated!");
        } catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }
    cancelPaymentChanges() {
        var paymentsToRemove = this.paymentsToAdd.filter(x => !this.creatingInvoice.payments.includes(x));
        paymentsToRemove.forEach(x => this.totalPaymentsAmount -= x.amount);
        this.paymentsToAdd = [];
        this.creatingInvoice.payments.forEach(x => this.paymentsToAdd.push(x));
    }
    resetPaymentForm() {
        this.addPaidOff = this.saveState.activeInvoicePreEdit.paidOff;
        this.pForm.newPaymentAmount.setValue(0);
        $("#kt_new_payment_add_picker").data('daterangepicker').setStartDate(new Date());
    }
    closePaymentModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closePaymentsModal.nativeElement.click();
                self.cancelPaymentChanges();
                self.resetPaymentForm();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    validateItemForm() {
        var check = true;

        if (this.selectedArticle == undefined) {
            check = false;
            $("#itemCodeInput").addClass('is-invalid');
        }
        else {
            $("#itemCodeInput").removeClass('is-invalid');
        }

        if (this.addTaxId == 0) {
            $("#add_item_taxId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_item_taxId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    async selectArticle(a: ArticleModel) {
        $("#itemCodeInput").val(a.code);
        this.selectedArticle = a;
        this.selectionUnderway = true;

        if (this.selectedArticle != undefined) {
            $("#itemCodeInput").removeClass('is-invalid');
            this.entryPrice = a.nc;
            this.entryPriceDisplay = parseFloat(this.entryPrice.toFixed(2));
            this.iForm.entryPrice.setValue(this.entryPriceDisplay);
            this.nc = a.nc;
            this.vpc = a.vpc;
            this.vpcDisplay = parseFloat(this.vpc.toFixed(2));
            this.iForm.vpc.setValue(this.vpcDisplay);
            this.mpc = a.mpc;
            this.mpcDisplay = parseFloat(this.mpc.toFixed(2));
            this.iForm.mpc.setValue(this.mpcDisplay);

            var articleGroup = this.groups.find(x => x.id == this.selectedArticle.groupId);
            this.isRawGroup = articleGroup.isRawGroup;

            if (a.mpc != undefined && a.nc != undefined) {
                this.marginAmount = a.mpc - a.nc;
                this.marginAmountDisplay = parseFloat(this.marginAmount.toFixed(2));

                if (a.nc != 0)
                    this.iForm.margin.setValue(parseFloat(((this.marginAmount / a.nc) * 100).toFixed(2)));
            } else {
                this.iForm.margin.setValue(0);
                this.marginAmount = 0;
            }
            this.marginAmountDisplay = this.marginAmount;

            var groupTaxId = articleGroup.taxId;
            $("#add_item_taxId").val(groupTaxId).trigger("change");
        }
        this.selectionUnderway = false;
    }
    selectArticleByCode(event: KeyboardEvent, c: string) {
        if (event.key != 'Enter')
            return;

        event.preventDefault();
        this.selectedArticle = this.itemLookupComponent.articles.find(x => x.code == c);

        if (this.selectedArticle != undefined) {
            this.selectArticle(this.selectedArticle);
        }
    }
    enterOnRow(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            var element = event.target as HTMLElement;
            element.click();
        }
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchInvoices();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchInvoices();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchInvoices();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchInvoices();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchInvoices();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}