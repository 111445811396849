import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleRestService } from 'src/app/services/article.service';
import { CustomerSupplierRestService } from 'src/app/services/customerSupplier.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { ProductionDocumentRestService } from 'src/app/services/production-document.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { GroupModel } from 'src/app/models/group.model';
import { TranslateService } from 'src/app/translate';
import { ArticleModel } from 'src/app/models/article.model';
import { TaxModel } from 'src/app/models/tax.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { CustomerSuppliersModel } from 'src/app/models/customerSupplier.model';
import toastr from 'toastr';
import { ProductionDocumentModel } from 'src/app/models/documents/productionDocument.model';
import { ProductionArticleModel } from 'src/app/models/documents/productionArticle.model';
import { minValidator } from 'src/app/validators/minValidator';
import { CurrencyModel } from 'src/app/models/currency.model';
import { CurrencyRestService } from 'src/app/services/currency.service';
import { InvoiceCustomerPaymentModel } from 'src/app/models/documents/invoice-customer-payment.model';
import { ProductionDocumentSaveModel } from 'src/app/models/documents/pageSaveModels/productionDocumentSave.model';
import { UpdateInvoicePaymentDTO } from 'src/app/models/updateInvoicePaymentDTO';
import { UpdateInvoiceDiscountDTO } from 'src/app/models/documents/updateInvoiceDiscountDTO';
import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import { ItemLookupComponent } from '../item-lookup/item-lookup.component';
import { Router } from '@angular/router';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;

@Component({
    selector: 'app-production-document',
    templateUrl: './production-document.component.html',
})
export class ProductionDocumentComponent implements OnInit {
    public selectedRow: string = undefined;
    public productionDocuments: ProductionDocumentModel[];
    public groups: GroupModel[];
    public rawGroups: GroupModel[];
    public customers: CustomerSuppliersModel[];
    public taxes: TaxModel[];
    public currencies: CurrencyModel[];
    public warehouses: WarehouseModel[];
    public retailPlaces: RetailPlaceModel[];
    public emptyData: boolean = false;
    public searchForm: UntypedFormGroup;
    public addForm: UntypedFormGroup;
    dateFrom: string;
    dateTo: string;
    customerName: string = '';
    public isSubmitted: boolean;
    public selectedInvoice: ProductionDocumentModel;
    public selectedInvoiceTax: TaxModel;
    public selectedInvoiceWarehouse: WarehouseModel;
    public addCustomerId: number = 0;
    public addWarehouseId: number = 0;
    public addCurrencyId: number = 0;
    public addDate: Date = new Date();
    public addArrivalDate: Date = new Date();
    public addPaidOff: boolean = false;
    public searchCustomerName: string;
    public filterRetailPlaceId: number;
    public filterWarehouseId: number;
    public customerSubset: CustomerSuppliersModel[];
    public selectedCustomer: CustomerSuppliersModel;
    public headerForm: UntypedFormGroup;
    public addingItems: boolean = false;
    get hForm() { return this.headerForm.controls; }

    public headerFormEnabled: boolean;
    public itemFormEnabled: boolean;

    public newPaymentAmount: number = 0;
    public newPaymentDate: Date = new Date();
    public newPayments: InvoiceCustomerPaymentModel[] = [];

    public activeProduction: ProductionDocumentModel;
    public selectedArticle: ArticleModel;
    public totalAmount: number = 0;
    tableSetUp: boolean = false;
    public addTaxId: number = 0;
    public basePrice: number;
    public basePriceDisplay: number;
    public triggerFinal: boolean;
    public finalPrice: number;
    public postDiscountPrice: number;
    public invoiceDiscount: number;
    public nc: number;
    public ncDisplay: number;
    public vpc: number;
    public mpc: number;
    public vpcDisplay: number;
    public mpcDisplay: number;
    public marginAmount: number;
    public marginAmountDisplay: number;
    public marginPercentage: number;
    public marginPercentageDisplay: number;
    public addItemForm: UntypedFormGroup;
    public addRawForm: UntypedFormGroup;
    public selectedCurrency: string;
    get iForm() { return this.addItemForm.controls; }
    get rForm() { return this.addRawForm.controls; }
    selectionModalTimer: any;
    enterCount: number = 0;
    creationInProgress: boolean = false;
    addingRaw: boolean = false;
    addingRawForId: number;
    addingRawForIndex: number;
    addingRawUntilIndex: number;
    canAddRaw:boolean=false;
    public previousWarehouseId: number;
    public pdfSource: any;
    
    public paymentsToAdd: InvoiceCustomerPaymentModel[];
    public paymentsForm: UntypedFormGroup;
    get pForm() { return this.paymentsForm.controls; }

    @ViewChild('closeAddInvoiceHeaderModal') closeAddInvoiceHeaderModal: ElementRef;
    @ViewChild('closeAddInvoiceItemsModal') closeAddInvoiceItemsModal: ElementRef;
    @ViewChild('closeEditInvoiceHeaderModal') closeEditInvoiceHeaderModal: ElementRef;
    @ViewChild('closeEditInvoiceItemsModal') closeEditInvoiceItemsModal: ElementRef;
    @ViewChild('selectAddInvoiceCurrencyId') selectAddInvoiceCurrencyId: ElementRef;
    @ViewChild('selectAddInvoiceWarehouseId') selectAddInvoiceWarehouseId: ElementRef;
    @ViewChild('selectTaxId') selectTaxId: ElementRef;
    @ViewChild('selectEditInvoiceCustomerId') selectEditInvoiceCustomerId: ElementRef;
    @ViewChild('selectEditInvoiceCurrencyId') selectEditInvoiceCurrencyId: ElementRef;
    @ViewChild('selectEditInvoiceWarehouseId') selectEditInvoiceWarehouseId: ElementRef;
    @ViewChild('selectEditTaxId') selectEditTaxId: ElementRef;
    @ViewChild('closeSelectItemModal') closeSelectItemModal: ElementRef;
    @ViewChild('openFinalPriceModal') openFinalPriceModal: ElementRef;
    @ViewChild('closeFinalPriceModal') closeFinalPriceModal: ElementRef;
    @ViewChild('headerTrigger') headerTrigger: ElementRef;
    @ViewChild('itemsTrigger') itemsTrigger: ElementRef;
    @ViewChild('listTrigger') listTrigger: ElementRef;
    @ViewChild('closeInvoiceDiscountModal') closeInvoiceDiscountModal: ElementRef;
    @ViewChild('invoiceNumberOpen') invoiceNumberOpen: ElementRef;
    @ViewChild('invoiceNumberClose') invoiceNumberClose: ElementRef;
    @ViewChild('firstHeaderForm') firstHeaderFormElement: ElementRef;
    @ViewChild('itemCodeInput') itemCodeInput: ElementRef;
    @ViewChild('invoiceDiscountInput') invoiceDiscountInput: ElementRef;
    @ViewChild('invoiceNumberInput') invoiceNumberInput: ElementRef;
    @ViewChild('finalPriceInput') finalPriceInput: ElementRef;
    @ViewChild('closePaymentsModal') closePaymentsModal: ElementRef;
    @ViewChild('selectFilterRetailPlaceId') selectFilterRetailPlaceId: ElementRef;
    @ViewChild('selectFilterWarehouseId') selectFilterWarehouseId: ElementRef;
    @ViewChild('addItemsButton') addItemsButton: ElementRef;
    @ViewChild('addRawButton') addRawButton: ElementRef;
    @ViewChild(ItemLookupComponent) itemLookupComponent: ItemLookupComponent;

    public saveState: ProductionDocumentSaveModel;
    public showItems: boolean = true;
    public setUpComplete: boolean = false;
    public editEnabled: boolean = false;
    public startedEditing: boolean = false;
    public showCustomerError: boolean = false;
    public nextNumber: number;
    public addEnabled: boolean = false;
    public headerSaved: boolean = false;
    public editingItems: boolean = false;
    public newProductionActive: boolean = false;
    public totalPaymentsAmount: number = 0;
    public editingItem: ProductionArticleModel;
    public isRawGroup: boolean = false;
    public documentArticles: ArticleModel[];
    public lastArticleForProductId: any;
    public onHeaderTab: boolean = true;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;
    public resizeObserver: ResizeObserver;

    public articlePage: number = 1;
    public articlePageSize: number = 99999;
    public halfProductData: any[] = [];

    constructor(
        private productionDocumentRest: ProductionDocumentRestService,
        private articleRestService: ArticleRestService,
        private groupRestService: GroupRestService,
        private customerRestService: CustomerSupplierRestService,
        private warehouseRestService: WarehouseRestService,
        private currencyRestService: CurrencyRestService,
        private datePipe: DatePipe,
        private _changeRef: ChangeDetectorRef,
        public data: DataService,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private router: Router
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit(): void {
        KTMenu.createInstances();
        this.fetchProductionDocuments();
        this.initializeSaveState();
        this.fetchTaxes();
        this.fetchGroups();
        this.fetchCurrencies();
        this.fetchWarehouses();
        this.createSearchForm();
        this.createHeaderForm();
        this.createAddItemForm();
        this.viewDocuments();
    }

    ngAfterViewInit() {
        $(this.headerTrigger.nativeElement).on('shown.bs.tab', () => {
            this.firstHeaderFormElement.nativeElement.focus();
        });

        $(this.itemsTrigger.nativeElement).on('shown.bs.tab', () => {
            this.itemCodeInput.nativeElement.focus();
        });

        $("#kt_modal_invoice_number").on('shown.bs.modal', () => {
            this.invoiceNumberInput.nativeElement.focus();
        });

        $("#kt_modal_select_invoice_customer").on('shown.bs.modal', () => {
            $("#searchCustomerInput").focus();
        });

        $("#kt_modal_invoice_discount").on('shown.bs.modal', () => {
            this.invoiceDiscountInput.nativeElement.focus();
        });

        $("#kt_modal_final_price").on('shown.bs.modal', () => {
            this.finalPrice = undefined;
            this.finalPriceInput.nativeElement.focus();
        });
        $("#kt_modal_final_price").on('hidden.bs.modal', () => {
            if (this.finalPrice == undefined)
                this.finalPrice = 0;
            $("#discount1").focus();
        });
    }

    ngAfterViewChecked() {
        if (!this.setUpComplete) {
            this.setUpFields();

            if (this.valuesMatch()) {
                this.setUpComplete = true;

                Object.keys(this.headerForm.controls).forEach(name => {
                    let control = this.headerForm.get(name);

                    control.valueChanges.subscribe(value => this.updateactiveProduction(name, value));
                })

                if (this.addEnabled || (this.startedEditing && !this.editingItems))
                    this.enableHeaderForm();
                else
                    this.disableHeaderForm();
                if (this.editingItems)
                    this.enableItemForm();
                else this.disableItemForm();

                this.updateState();
                this._changeRef.detectChanges();
            }

            if (this.activeProduction != undefined && this.itemFormEnabled)
                this.itemLookupComponent.fetchArticles();
        }
    }

    valuesMatch() {
        var check = true;

        Object.keys(this.headerForm.controls).forEach(name => {
            let control = this.headerForm.get(name);
            if (control.value != this.activeProduction[name]) {
                check = false;
            }
            else {
            }
        });

        return check;
    }

    async createSearchForm() {
        this.searchForm = this.formBuilder.group({
            filterRetailPlaceId: [null],
            filterWarehouseId: [null]
        })

        $("#invoice_filter_warehouseId").select2();
        $('#invoice_filter_warehouseId').on("change", function () {
            self.selectFilterWarehouseId.nativeElement.value = $(this).val();
            self.filterWarehouseId = $(this).val();
        });

        $("#invoice_filter_retailPlaceId").select2();
        $('#invoice_filter_retailPlaceId').on("change", function () {
            self.selectFilterRetailPlaceId.nativeElement.value = $(this).val();
            self.filterRetailPlaceId = $(this).val();
        });

        var start = new Date(new Date().getFullYear(), 0, 1);
        var end = new Date();
        var self = this;
        $("#kt_daterangepicker_filter").daterangepicker({
            startDate: start,
            endDate: end,
            autoApply: true,
            autoUpdateInput: true,
            locale: {
                format: 'DD/MM/YYYY',
                separator: '-'
            }
        });
        $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            $("#searchByCustomerInput").focus();
            self.fetchProductionDocuments();
        });
        $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
            picker.setStartDate(new Date());
            picker.setEndDate(new Date());
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            self.fetchProductionDocuments();
        });
        var im = new Inputmask("99/99/9999-99/99/9999");
        im.mask($("#kt_daterangepicker_filter"));
    }

    async resetFilter() {
        this.searchForm.reset();
        $("#invoice_filter_warehouseId").val(null).trigger("change");
        $("#invoice_filter_retailPlaceId").val(null).trigger("change");
    }

    filterByDate(event: KeyboardEvent) {
        if (event.key == "Enter" || event.key == "Tab") {
            var pickerElement = $("#kt_daterangepicker_filter");
            $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        }
    }

    async fetchGroups() {
        try {
            var response = await this.groupRestService.getGroups('', null, null, 1, 100000);
            this.groups = response.data;
            this.rawGroups = this.groups.filter(x => x.isRawGroup == true);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchTaxes() {
        try {
            this.taxes = await this.groupRestService.getTaxesByClientId(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchCurrencies() {
        try {
            this.currencies = await this.currencyRestService.getCurrencies();
            if (this.activeProduction != undefined && this.activeProduction.currencyId != undefined)
                this.selectedCurrency = this.currencies.find(x => x.id == this.activeProduction.currencyId).code;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchWarehouses() {
        try {
            this.warehouses = await this.warehouseRestService.getWarehousesForClient(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchProductionDocuments() {
        try {
            this.emptyData = false;
            if (this.dateFrom == undefined)
                this.dateFrom = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
            if (this.dateTo == undefined)
                this.dateTo = this.datePipe.transform((new Date()), 'yyyy-MM-dd');
            var response = await this.productionDocumentRest.getProductionDocuments(this.filterWarehouseId, this.dateFrom, this.dateTo, this.data.user.clientId, this.page, this.perPage);
            this.productionDocuments = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchInvoicePdf(id: string) {
        try {
            var response = await this.productionDocumentRest.getProductionDocumentsPDF(this.data.user.clientId, id);
            this.pdfSource = window.URL.createObjectURL(response);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
            else {
                toastr.options = this.data.toastrOptions;
                toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
            }
        }
    }

    async exportPDF() {
        await this.fetchInvoicePdf(this.activeProduction.id);
        if (this.pdfSource)
            this.router.navigate(['/production-document-pdf'], { queryParams: { pdfSrc: this.pdfSource } });
    }

    async getNextNumber() {
        this.nextNumber = await this.productionDocumentRest.getNextNumber(this.data.user.clientId);
        if (this.activeProduction.documentNumber == undefined) {
            this.activeProduction.documentNumber = this.nextNumber;
            this.activeProduction.productionDocumentNumber = this.nextNumber + '/' + ((new Date()).getFullYear() - 2000);
            this.updateState();
        }
    }

    loadState() {
        return this.data.getDocumentState("productionDocumentPage");
    }

    updateState() {
        this.saveState.activeProduction = this.activeProduction;
        this.data.saveDocumentState("productionDocumentPage", this.saveState);
    }

    initializeSaveState() {
        this.saveState = this.loadState();
        if (this.saveState == undefined || this.saveState == null || !this.saveState.newProductionActive) {
            this.resetactiveProduction();
        } else if (this.saveState.newProductionActive) {
            this.activeProduction = this.saveState.activeProduction;
            this.addEnabled = this.saveState.addEnabled;
            this.headerSaved = this.saveState.headerSaved;
            this.editEnabled = this.saveState.editEnabled;
            this.startedEditing = this.saveState.startedEditing;
            this.editingItems = this.saveState.editingItems;
            this.newProductionActive = true;
        }
    }

    resetactiveProduction() {
        this.saveState = new ProductionDocumentSaveModel();
        this.saveState.activeProduction = new ProductionDocumentModel();
        this.saveState.activeProduction.clientId = this.data.user.clientId;
        this.saveState.activeProduction.articles = [];
        // this.saveState.activeProduction.totalAmount=0;
        // this.saveState.activeProduction.totalBase=0;
        // this.saveState.activeProduction.totalDiscount=0;
        // this.saveState.activeProduction.totalTax=0;
        this.activeProduction = this.saveState.activeProduction;
        this.saveState.activeProductionPreEdit = this.activeProduction;
        this.updateState();
    }

    setUpFields() {
        if (this.activeProduction.currencyId == undefined && this.currencies != undefined && !this.editEnabled) {
            $("#add_invoice_currencyId").val(this.currencies[0].id).trigger("change");;
            this.activeProduction.currencyId = this.currencies[0].id;
        }
        else if (this.activeProduction.currencyId != undefined) {
            $("#add_invoice_currencyId").val(this.activeProduction.currencyId).trigger("change");
        }

        if (this.activeProduction.warehouseId == undefined && this.warehouses != undefined && !this.editEnabled) {
            $("#add_invoice_warehouseId").val(this.warehouses[0].id).trigger("change");
            this.activeProduction.warehouseId = this.warehouses[0].id;
        }
        else if (this.activeProduction.warehouseId != undefined) {
            if (this.addWarehouseId == undefined || this.addWarehouseId == null || this.addWarehouseId == 0 || isNaN(this.addWarehouseId))
                $("#add_invoice_warehouseId").val(this.activeProduction.warehouseId).trigger("change");
        }
        if (this.activeProduction.date != undefined) {
            $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date(this.activeProduction.date));
            this.headerForm.controls.date.setValue(this.activeProduction.date);
        } else {
            this.addDate = new Date();
            this.headerForm.controls.date.setValue(this.addDate);
            $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(this.addDate);
            this.activeProduction.date = this.addDate;
        }

        this.headerForm.controls.note.setValue(this.activeProduction.note);
        this._changeRef.detectChanges();
    }

    updateactiveProduction(name: string, value: any) {
        this.activeProduction[name] = value;

        this.updateState();
    }

    async searchProductionDocuments() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchProductionDocuments();
    }

    openItemsOnEmpty($event: KeyboardEvent = null) {
        var fieldValue = $("#itemCodeInput").val();
        if (!this.itemFormEnabled || (fieldValue != '' && fieldValue != undefined))
            return;

        if ($event != null) {
            if ($event.key == "Enter") {
                this.enterCount++;
                clearTimeout(this.selectionModalTimer);
                this.selectionModalTimer = setTimeout(() => {
                    this.enterCount = 0;
                }, 500);

                if (this.enterCount != 2)
                    return;

                this.itemLookupComponent.openItemsModal();
            }
        } else {
            this.itemLookupComponent.openItemsModal();
        }
    }

    addItems() {
        this.addingItems = true;
        this.headerSaved = false;
        this.startedEditing = false;
        this.editEnabled = false;
        this.addEnabled = false;
        this.editingItems = false;
        this.onHeaderTab = false;
        this.headerTrigger.nativeElement.click();
    }

    toggleItems(toggle: boolean) {
        this.showItems = toggle;
    }
    toggleDocNav(toggle: boolean) {
        this.onHeaderTab = toggle;
    }

    viewDocuments() {
        this.toggleItems(false);
        this.toggleDocNav(false);
        this.resetSave();
        this.resetItemForm();
        this.disableForm();
        this.headerSaved = false;
        this.startedEditing = false;
        this.editEnabled = false;
        this.addEnabled = false;
        this.editingItems = false;
        this.addingItems = false;
    }

    selectProductionDocument(productionDocument: ProductionDocumentModel, switchToHeaderTab: boolean = true) {
        if (switchToHeaderTab)
            this.headerTrigger.nativeElement.click();

        if (this.saveState.newProductionActive && this.activeProduction.id != productionDocument.id)
            this.saveState.newProductionActive = false;

        this.activeProduction = productionDocument;
        this.selectedCurrency = this.currencies.find(x => x.id == productionDocument.currencyId).code;

        $('#add_invoice_warehouseId').val(this.activeProduction.warehouseId).trigger("change");
        $('#add_invoice_currencyId').val(this.activeProduction.currencyId).trigger("change");
        $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date(this.activeProduction.date));
        this.hForm.date.setValue(this.activeProduction.date);
        this.addEnabled = false;
        this.editEnabled = true;
        this.startedEditing = false;
        this.saveState.activeProductionPreEdit = this.makePreEditObject();

        this.hForm.note.setValue(this.activeProduction.note);
        this.selectedArticle = null;
        this.headerSaved = true;

        if (this.addWarehouseId != this.previousWarehouseId) {
            this._changeRef.detectChanges();
            this.previousWarehouseId = this.addWarehouseId;
            this.itemLookupComponent.fetchArticles(true);
        }

        this.canAddRaw=false;
        if (this.activeProduction.articles.length != 0) {
            var products = this.activeProduction.articles.filter(x => x.rawForProductId == null);
            var lastProduct = products[products.length - 1];
            this.addingRawForId = lastProduct.id;
            this.lastArticleForProductId = this.activeProduction.articles[this.activeProduction.articles.length - 1].rawForProductId;
            
            if(!this.activeProduction.articles[this.activeProduction.articles.length-1].presetData)
                this.canAddRaw = true;
        }

        this.disableForm();
    }

    async selectNext() {
        var index = this.productionDocuments.findIndex(x => x.id == this.activeProduction.id);

        if (index == 0) {
            if (this.page == 1)
                return;

            await this.prevpage();
            await this.fetchProductionDocuments();

            if (this.productionDocuments.length > 0) {
                this.selectProductionDocument(this.productionDocuments[this.productionDocuments.length - 1]);
            }
        }
        else {
            this.selectProductionDocument(this.productionDocuments[index - 1]);
        }
    }

    async selectPrevious() {
        var index = this.productionDocuments.findIndex(x => x.id == this.activeProduction.id);
        if (index == (this.productionDocuments.length - 1)) {
            if (this.page == this.maxPage)
                return;

            await this.nextPage();
            await this.fetchProductionDocuments();

            if (this.productionDocuments.length > 0) {
                this.selectProductionDocument(this.productionDocuments[0]);
            }
        }
        else {
            this.selectProductionDocument(this.productionDocuments[index + 1]);
        }
    }

    startEditing(header: boolean) {
        if (header) {
            this.headerSaved = false;
        }
        this.enableHeaderForm(false);
        this.disableItemForm();
        this.resetItemForm();
        this.startedEditing = true;
        if (this.editingItems)
            this.editingItems = false;
    }
    startAddingItems() {
        if (this.itemLookupComponent.articles.length == 0)
            this.itemLookupComponent.fetchArticles();
        this.addingRaw = false;
        this._changeRef.detectChanges();
        this.enableItemForm();
        this.startedEditing = true;
        this.editingItems = true;
    }
    startAddingRaw() {
        this.addingRaw = true;
        this._changeRef.detectChanges();
        this.enableItemForm();
        this.startedEditing = true;
        this.editingItems = true;
    }
    addRawToProduct(item: ProductionArticleModel) {
        this.addingRawForId = item.id;
        this.addingRawForIndex = this.activeProduction.articles.indexOf(item);

        var products = this.activeProduction.articles.filter(x => x.rawForProductId == undefined);
        var productsForIndex = products.indexOf(item);
        if (productsForIndex == products.length - 1)
            this.addingRawUntilIndex = 999999;
        else {
            var productsUntilItem = products[productsForIndex + 1];
            this.addingRawUntilIndex = this.activeProduction.articles.indexOf(productsUntilItem);
        }

        this.itemsTrigger.nativeElement.click();
        this.startAddingRaw();
    }
    addRawToLast() {
        var products = this.activeProduction.articles.filter(x => x.rawForProductId == null || x.rawForProductId == undefined);
        var last = products[products.length - 1];

        this.addingRawForId = last.id;
        this.addingRawForIndex = this.activeProduction.articles.indexOf(last);
        this.addingRawUntilIndex = 999999;

        this.startAddingRaw();
    }

    cancelEditing(header: boolean = true) {
        this.selectProductionDocument(this.saveState.activeProductionPreEdit, false);
        this.disableForm();
        if (!header) {
            this.resetItemForm();
        }
        this.startedEditing = false;
        this.editEnabled = true;
        this.addEnabled = false;
        this.editingItems = false;
        if (this.activeProduction.id != undefined)
            this.headerSaved = true;
    }

    exitDetails(redirectToHeader: boolean = false) {
        if (redirectToHeader)
            this.headerTrigger.nativeElement.click();

        this.resetSave();
        this.resetForm()
        this.headerSaved = false;
    }

    cancelAdding() {
        this.disableForm();
        this.resetSave();

        this.resetForm();
        this.updateState();
    }

    manualInputDate(id: string) {
        var pickerElement = $("#" + id);
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }

    selectOnFocus(id: string) {
        $("#" + id).select();
    }

    async createHeaderForm() {
        this.headerForm = this.formBuilder.group({
            warehouseId: [0, Validators.required],
            currencyId: [0, Validators.required],
            date: Date,
            note: [''],
        });

        var self = this;

        $("#add_invoice_warehouseId").select2();
        $('#add_invoice_warehouseId').on("change", function () {
            self.selectAddInvoiceWarehouseId.nativeElement.value = $(this).val();
            self.addWarehouseId = $(this).val();
        });

        $("#add_invoice_currencyId").select2();
        $('#add_invoice_currencyId').on("change", function () {
            self.selectAddInvoiceCurrencyId.nativeElement.value = $(this).val();
            self.addCurrencyId = $(this).val();
        });

        var start = (new Date()).setDate((new Date()).getDate() - 29);
        var end = new Date();
        $("#kt_bill_date_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: start,
            endDate: end,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });
        $("#kt_bill_date_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.addDate = picker.startDate._d;
            self.headerForm.controls.date.setValue(self.addDate);
        });
        var im = new Inputmask("99/99/9999");
        im.mask($("#kt_bill_date_add_picker"));

        if (this.editEnabled && !this.startedEditing)
            this.disableForm();
    }

    createAddItemForm() {
        this.addItemForm = this.formBuilder.group({
            quantity: [1, Validators.required],
            nc: [0, Validators.required],
            vpc: [0, Validators.required],
            mpc: [0, Validators.required],
            margin: [0, Validators.required],
            addTaxId: [0, Validators.required],
        });

        var self = this;
        $("#add_item_taxId").select2();
        $('#add_item_taxId').on("change", function () {
            self.selectTaxId.nativeElement.value = $(this).val();
            self.addTaxId = $(this).val();
            self.selectedInvoiceTax = self.taxes.find(x => x.id == self.addTaxId);
            self.changedTax();
        });
    }

    disableForm() {
        this.disableHeaderForm(false);
        this.disableItemForm();
    }
    disableHeaderForm(emit: boolean = true) {
        if (emit == false) {
            this.headerForm.disable({ emitEvent: false });
        } else {
            this.headerForm.disable();
        }

        this.headerFormEnabled = false;
        $("#customerCodeInput").prop("disabled", true);
        $("#customerLookup").addClass("disabled");
        $("#kt_bill_date_add_picker").prop("disabled", true);
        $("#kt_arrival_date_add_picker").prop("disabled", true);
    }
    disableItemForm() {
        this.addItemForm.disable();
        if (this.itemFormEnabled) {
            this.itemFormEnabled = false;
            setTimeout(() => {
                this.addItemsButton.nativeElement.focus();
            }, 0);
        }
        $("#itemCodeInput").prop("disabled", true);
        $("#itemLookup").addClass("disabled");
    }

    enableForm() {
        this.enableHeaderForm();
        this.enableItemForm();
    }
    enableHeaderForm(emit: boolean = true) {
        if (emit == false)
            this.headerForm.enable({ emitEvent: false });
        else
            this.headerForm.enable();
        this.headerFormEnabled = true;
        $("#customerCodeInput").prop("disabled", false);
        $("#customerLookup").removeClass("disabled");
        $("#kt_bill_date_add_picker").prop("disabled", false);
        $("#kt_arrival_date_add_picker").prop("disabled", false);
    }
    enableItemForm() {
        if (this.basePrice == undefined)
            this.basePrice = 0;
        this.addItemForm.enable();
        this.itemFormEnabled = true;
        $("#itemCodeInput").prop("disabled", false);
        $("#itemLookup").removeClass("disabled");
        $("#add_item_taxId").val(this.taxes[0].id).trigger("change");
    }

    async resetForm() {
        this.selectedCustomer = null;
        $('#add_invoice_currencyId').val(this.currencies[0].id).trigger("change");
        $('#add_invoice_warehouseId').val(this.warehouses[0].id).trigger("change");
        $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date());
        $('#add_item_taxId').val(this.taxes[0].id).trigger("change");

        this.addDate = new Date();
        this.hForm.date.setValue(this.addDate);
        this.headerForm.controls.note.setValue(null);

        $('#itemCodeInput').val(null);
        this.selectedArticle = undefined;
        this.addItemForm.controls.quantity.setValue(1);
        this.addItemForm.controls.nc.setValue(0);
        this.addItemForm.controls.mpc.setValue(0);

        this.addEnabled = false;
        this.editEnabled = false;
        this.startedEditing = false;

        this.nc = 0;
        this.mpc = 0;
        this.ncDisplay = 0;
        this.mpcDisplay = 0;
        this.totalAmount = 0;

        this.headerFormEnabled = false;
        this.itemFormEnabled = false;
    }

    resetSave() {
        this.saveState = new ProductionDocumentSaveModel();
        this.saveState.activeProduction = new ProductionDocumentModel();
        this.saveState.activeProduction.clientId = this.data.user.clientId;
        if (this.warehouses)
            this.saveState.activeProduction.warehouseId = this.warehouses[0].id;
        if (this.currencies)
            this.saveState.activeProduction.currencyId = this.currencies[0].id;
        this.saveState.activeProduction.articles = [];
        this.saveState.activeProduction.totalMarginAmount = 0;
        this.saveState.activeProduction.totalMarginPercentage = 0;
        this.saveState.activeProduction.totalProducedNC = 0;
        this.saveState.activeProduction.totalProducedVPC = 0;
        this.saveState.activeProduction.totalProducedMPC = 0;
        this.saveState.activeProduction.totalUsedNC = 0;
        this.saveState.activeProduction.totalUsedVPC = 0;
        this.saveState.activeProduction.totalUsedMPC = 0;
        this.activeProduction = this.saveState.activeProduction;

        this.editEnabled = false;
        this.addEnabled = false;
        this.startedEditing = false;
        this.saveState.addEnabled = this.addEnabled;
    }

    async addProductionDocument() {
        if (this.creationInProgress)
            return;
        this.creationInProgress = true;
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        try {
            await this.productionDocumentRest.createProductionDocument(this.activeProduction);
            this.activeProduction.id = await this.getNewlyCreatedId();
            this.saveState.activeProduction.id = this.activeProduction.id;
            this.saveState.activeProductionPreEdit = this.makePreEditObject();
            this.headerSaved = true;
            this.editingItems = true;
            this.saveState.editingItems = true;
            this.saveState.headerSaved = true;
            this.updateState();
            this.setPostAddingLayout();

            if (this.addWarehouseId != this.previousWarehouseId) {
                this.previousWarehouseId = this.addWarehouseId;
                this.itemLookupComponent.fetchArticles(true);
            }

            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_outgoing_invoice_text'), this.translate.instant("_Success"));
            this.fetchProductionDocuments();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
        this.creationInProgress = false;
    }
    makePreEditObject() {
        var obj = new ProductionDocumentModel();
        Object.keys(this.activeProduction).forEach(key => {
            if (key != 'articles') {
                let value = this.activeProduction[key];
                obj[key] = value;
            }
        })
        obj.articles = [];
        this.activeProduction.articles.forEach(x => {
            var itemCopy = new ProductionArticleModel();
            Object.keys(x).forEach(key => {
                let value = x[key];
                itemCopy[key] = value;
            })
            obj.articles.push(itemCopy);
        });
        return obj;
    }
    async getNewlyCreatedId() {
        var response = await this.productionDocumentRest.getProductionDocuments(this.filterWarehouseId, this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.data.user.clientId, 1, 1)
        var id = response.data[0].id;
        return id;
    }

    showList() {
        this.listTrigger.nativeElement.click();
    }

    async editProductionDocument(instantReverse: boolean = false) {
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        try {
            await this.productionDocumentRest.editProductionDocument(this.activeProduction);
            this.saveState.activeProductionPreEdit = this.makePreEditObject();
            this.startedEditing = false;
            this.editingItems = false;
            this.saveState.startedEditing = false;
            this.saveState.editingItems = false;
            this.headerSaved = true;
            this.saveState.headerSaved = true;
            this.resetItemForm();
            this.disableForm();
            this.updateState();

            if (this.addWarehouseId != this.previousWarehouseId) {
                this.previousWarehouseId = this.addWarehouseId;
                this.itemLookupComponent.fetchArticles(true);
                this.documentArticles = undefined;
            }

            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Edited_outgoing_invoice_text'), this.translate.instant("_Success"));
            this.fetchProductionDocuments();
        }
        catch (err) {
            if (instantReverse)
                this.activeProduction = this.saveState.activeProductionPreEdit;

            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    async deleteProductionDocument(id: string) {
        var self = this;
        var question_text = this.translate.instant("_Delete_invoice_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.productionDocumentRest.deleteProductionDocument(id);
                if (this.activeProduction.id == id) {
                    this.resetactiveProduction();
                    this.disableForm();
                    this.startedEditing = false;
                    this.editEnabled = true;
                    this.addEnabled = false;
                    this.editingItems = false;
                    this.resetForm();
                }
                await this.fetchProductionDocuments();
                this.itemLookupComponent.fetchArticles(true);
                toastr.options = this.data.toastrOptions;
                toastr.success(this.translate.instant('_Deleted_production_document_text'), this.translate.instant("_Success"));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    shouldFetchArticleState(id: number) {
        if (this.itemLookupComponent.articles.find(x => x.id == id) != undefined || this.itemLookupComponent.raws.find(x => x.id == id) != undefined)
            return false
        if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == id) != undefined)
            return false

        return true;
    }
    async selectItem(item: ProductionArticleModel) {
        if (this.shouldFetchArticleState(item.id)) {
            try {
                if (this.documentArticles == undefined)
                    this.documentArticles = [];

                var articlesToFetch = null;

                if (item.rawForProductId == undefined) {
                    var rawsIdsForProduct = this.activeProduction.articles.filter(x => x.rawForProductId == item.id).filter(this.uniqueFilter);
                    articlesToFetch = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.activeProduction.warehouseId, 1, 99999, '', false, true, item.id);
                    this.documentArticles.push(articlesToFetch[0]);

                    articlesToFetch = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.activeProduction.warehouseId, 1, 99999, '', true, false, null, rawsIdsForProduct);
                    articlesToFetch.forEach(element => {
                        this.documentArticles.push(element);
                    });
                } else {
                    articlesToFetch = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.activeProduction.warehouseId, 1, 99999, '', true, false, item.id);
                    this.documentArticles.push(articlesToFetch[0]);
                }

                this.selectedArticle = this.documentArticles.find(x => x.id == item.id);
            } catch (err: any) {
                toastr.options = this.data.toastrOptions;
                toastr.error(err.error['Message'], this.translate.instant("_Error"));
            }
        } else {
            if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == item.id))
                this.selectedArticle = this.documentArticles.find(x => x.id == item.id);
            else {
                if (item.rawForProductId != null)
                    this.selectedArticle = this.itemLookupComponent.raws.find(x => x.id == item.id);
                else
                    this.selectedArticle = this.itemLookupComponent.articles.find(x => x.id == item.id);
            }
        }

        this.enableItemForm();
        $("#quantity").val(item.quantity);
        this.iForm.quantity.setValue(item.quantity);
        $("#add_item_taxId").val(item.taxId).trigger("change");
        this.startedEditing = true;
        this.editingItems = true;
        this.editingItem = item;
        this.iForm.nc.setValue(item.nc);
        this.iForm.vpc.setValue(item.vpc);
        this.iForm.mpc.setValue(item.mpc);
        this.nc = item.nc;
        this.vpc = item.vpc;
        this.mpc = item.mpc;
        this.iForm.margin.setValue(item.marginPercentage);
        this.marginAmount = item.marginAmount;
        this.marginAmountDisplay = parseFloat(this.marginAmount.toFixed(2));
        this.itemsTrigger.nativeElement.click();
    }
    uniqueFilter(value, index, self) {
        return self.indexOf(value) === index;
    }

    editItem() {
        this.isSubmitted = false;
        if (this.addItemForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateItemForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted) {
            return;
        }

        var newQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
        var quantityDiff = newQuantity - this.editingItem.quantity;
        this.editingItem.quantity = newQuantity;
        this.editingItem.nc = this.nc;
        this.editingItem.vpc = this.vpc;
        this.editingItem.mpc = this.mpc;
        this.editingItem.marginAmount = this.marginAmount;
        this.editingItem.marginPercentage = this.iForm.margin.value;

        this.editingItem.taxId = this.addTaxId;
        this.editingItem.taxName = this.taxes.find(x => x.id == this.addTaxId).name;
        this.editingItem.taxPercentage = this.taxes.find(x => x.id == this.addTaxId).amount;
        if (this.isRawGroup)
            this.editingItem.taxAmount = parseFloat((this.editingItem.nc * (this.editingItem.taxPercentage / 100)).toFixed(6));
        else
            this.editingItem.taxAmount = parseFloat((this.editingItem.vpc * (this.editingItem.taxPercentage / 100)).toFixed(6));

        this.editingItem.totalTaxAmount = this.editingItem.taxAmount * this.editingItem.quantity;
        this.editingItem.totalNC = this.editingItem.nc * this.editingItem.quantity;
        this.editingItem.totalVPC = this.editingItem.vpc * this.editingItem.quantity;
        this.editingItem.totalMPC = this.editingItem.mpc * this.editingItem.quantity;

        var item = this.activeProduction.articles.find(x => x.id == this.editingItem.id);

        if (quantityDiff != 0) {
            if (this.editingItem.rawForProductId != null) {
                if (this.itemLookupComponent.raws.find(x => x.id == this.editingItem.id) != undefined)
                    this.itemLookupComponent.updateWarehouseState(item.id, -quantityDiff, true);
                else
                    this.updateInDocumentArticles(this.editingItem.id, -quantityDiff);
            }
            else {
                if (this.itemLookupComponent.articles.find(x => x.id == this.editingItem.id) != undefined)
                    this.itemLookupComponent.updateWarehouseState(item.id, quantityDiff);
                else
                    this.updateInDocumentArticles(this.editingItem.id, quantityDiff);

                this.updateRawQuantities(this.editingItem, quantityDiff);
            }
        }

        this.editProductionDocument();
        this.editingItem = undefined;
        this.resetItemForm();
        if (this.saveState.newProductionActive)
            this.updateState();
    }
    updateRawQuantities(product: ProductionArticleModel, quantityDiff: number) {
        var rawsToUpdate: ProductionArticleModel[] = [];
        var productIndex = this.activeProduction.articles.indexOf(product);
        var updateLimit = this.activeProduction.articles.find(x => x.rawForProductId == undefined && this.activeProduction.articles.indexOf(x) > productIndex);
        if (updateLimit == undefined)
            rawsToUpdate = this.activeProduction.articles.filter(x => x.rawForProductId == product.id && this.activeProduction.articles.indexOf(x) > productIndex);
        else
            rawsToUpdate = this.activeProduction.articles.filter(x => x.rawForProductId == product.id && this.indexMatch(x, productIndex, this.activeProduction.articles.indexOf(updateLimit)));

        rawsToUpdate.forEach(raw => {
            var oldRawQuantity = raw.quantity;
            var oldProductQuantity = product.quantity - quantityDiff;
            var oldProporitons = oldRawQuantity / oldProductQuantity;

            var newRawQuantity = parseFloat((oldProporitons * product.quantity).toFixed(6));
            var rawQuantityDiff = newRawQuantity - raw.quantity;
            raw.quantity = newRawQuantity;
            raw.totalMPC = parseFloat((raw.mpc * newRawQuantity).toFixed(6));
            raw.totalNC = parseFloat((raw.nc * newRawQuantity).toFixed(6));
            raw.totalVPC = parseFloat((raw.vpc * newRawQuantity).toFixed(6));
            raw.totalTaxAmount = parseFloat((raw.taxAmount * newRawQuantity).toFixed(6));

            if (this.itemLookupComponent.raws.find(x => x.id == raw.id) != undefined)
                this.itemLookupComponent.updateWarehouseState(raw.id, rawQuantityDiff, true);
            else
                this.updateInDocumentArticles(raw.id, rawQuantityDiff);
        });
    }
    updateInDocumentArticles(id: number, quantityDiff: number) {
        var a = this.documentArticles.find(x => x.id == id);
        a.warehouseState += quantityDiff;
    }
    validateAddForm() {
        var check = true;

        if (this.addWarehouseId == 0) {
            $("#add_invoice_warehouseId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_warehouseId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.addCurrencyId == 0) {
            $("#add_invoice_currencyId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_currencyId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    proceedToAddItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addInvoiceItemsTrigger").click();
    }

    proceedToEditItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addInvoiceItemsTrigger").click();
    }

    backToHeader() {
        $("#headerModalTrigger").click();
    }

    enteredPrice(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            if (this.iForm.basePrice.value != undefined)
                this.basePrice = parseFloat(this.iForm.basePrice.value.toFixed(6));
            else this.basePrice = undefined;

            if (this.basePrice == 0) {
                this.openFinalPriceModal.nativeElement.click();
                this.finalPrice = undefined;
            }
            else {
                this.recalculatePrices();
            }
        }
    }
    updateBasePrice() {
        if (this.iForm.basePrice.value != undefined)
            this.basePrice = parseFloat(this.iForm.basePrice.value.toFixed(6));
        else this.basePrice = undefined;
    }
    backToBase() {
        this.closeFinalPriceModal.nativeElement.click();
    }
    enteredFinal(event: KeyboardEvent) {
        if (event.key == 'Tab' || event.key == 'Enter') {
            event.preventDefault();
            this.recalculatePrices(false);
            this.backToBase();
            this._changeRef.detectChanges();
        }
    }
    recalculatePrices(fromBaseToFinal: boolean = true) {
        if (this.iForm.discount1.value == undefined)
            this.iForm.discount1.setValue(0);
        if (this.iForm.discount2.value == undefined)
            this.iForm.discount1.setValue(0);
        var taxToApply = this.taxes.find(x => x.id == this.addTaxId).amount;

        if (fromBaseToFinal) {
            if (this.basePrice == undefined)
                return;
            this.basePriceDisplay = parseFloat(this.basePrice.toFixed(2));
            var postDiscount1Price = this.basePrice * (1 - (this.iForm.discount1.value / 100))
            this.postDiscountPrice = parseFloat((postDiscount1Price * (1 - (this.iForm.discount2.value / 100))).toFixed(6));
            this.finalPrice = parseFloat((this.postDiscountPrice + (this.postDiscountPrice * taxToApply / 100)).toFixed(6));
        }
        else {
            this.postDiscountPrice = parseFloat((this.finalPrice / (1 + taxToApply / 100)).toFixed(6));
            var postDiscount1 = this.postDiscountPrice * (1 + (this.iForm.discount2.value / 100));
            this.basePrice = parseFloat((postDiscount1 * (1 + (this.iForm.discount1.value / 100))).toFixed(6));
            this.basePriceDisplay = parseFloat(this.basePrice.toFixed(2));
            this.iForm.basePrice.setValue(this.basePriceDisplay);
        }
    }

    calculateMarginAmount() {
        if (this.iForm.margin.value == undefined) {
            this.marginAmount = undefined;
            this.marginAmountDisplay = undefined;
            return;
        }
        if (this.nc == undefined)
            return;
        this.marginAmount = parseFloat((this.nc * (this.iForm.margin.value / 100)).toFixed(6));
        this.marginAmountDisplay = parseFloat(this.marginAmount.toFixed(2));
    }
    calculateMarginPercentage() {
        if (this.mpc != undefined && this.nc != undefined) {
            if (this.nc == 0 && this.mpc == 0)
                this.marginPercentage = 0;
            else if (this.nc != 0) {
                var amount = this.mpc - this.nc;
                this.marginPercentage = parseFloat(((amount / this.nc) * 100).toFixed(2));
                this.marginPercentageDisplay = parseFloat(this.marginPercentage.toFixed(2));
            }
        } else {
            this.marginPercentage = undefined;
            this.marginPercentageDisplay = undefined;
        }

        this.iForm.margin.setValue(this.marginPercentageDisplay);
        this.calculateMarginAmount();
    }
    changedMargin() {
        this.marginPercentage = parseFloat(this.iForm.margin.value().toFixed(6));
        this.marginPercentageDisplay = this.iForm.margin.value();
        this.calculateMarginAmount();

        if (this.iForm.margin.value == undefined)
            return;
        if (this.iForm.mpc.value == null || this.iForm.mpc == undefined) {
            this.mpc = parseFloat((this.nc + this.marginAmount).toFixed(6));
            this.mpcDisplay = parseFloat(this.mpc.toFixed(2));
            this.iForm.mpc.setValue(this.mpcDisplay);
        }
    }

    negateEnter(event: KeyboardEvent) {
        if (event.key == "Enter")
            event.preventDefault();
    }

    editNumber() {
        if (!this.canEditNumber())
            return;

        this.invoiceNumberOpen.nativeElement.click();
        var input = $("#invoiceNumberInput");
        input.removeClass("is-invalid");

        if (this.activeProduction.documentNumber != null)
            input.val(this.activeProduction.documentNumber);
        else
            input.val(this.nextNumber);
    }
    canEditNumber() {
        if (this.editEnabled && !this.startedEditing)
            return false;

        return true;
    }
    saveNumber() {
        var input = $("#invoiceNumberInput");
        if (input.val() == '' || input.val().includes('.')) {
            input.addClass("is-invalid");
            return;
        } else {
            input.removeClass("is-invalid");
            this.activeProduction.documentNumber = input.val();
            this.activeProduction.customDocumentNumber = this.activeProduction.documentNumber;
            this.activeProduction.productionDocumentNumber = this.activeProduction.documentNumber + "/" + ((new Date()).getFullYear() % 100);
        }

        this.updateState();
        this.invoiceNumberClose.nativeElement.click();
    }

    switchToHeaderAndEnableAdding() {
        this.headerTrigger.nativeElement.click();
        this.onHeaderTab = true;
        this.enableAdding();
    }

    enableAdding() {
        this.getNextNumber();
        this.resetSave();
        this.resetForm();
        this.addEnabled = true;
        this.saveState.addEnabled = true;
        this.saveState.newProductionActive = true;
        this.updateState();
        this.enableHeaderForm();
        this.firstHeaderFormElement.nativeElement.focus();
    }
    setPostAddingLayout() {
        this.addEnabled = false;
        this.addingRaw = false;
        this.saveState.addEnabled = false;
        this.editEnabled = true;
        this.saveState.editEnabled = true;
        this.startedEditing = true;
        this.saveState.startedEditing = true;
        this.updateState();
        this.disableHeaderForm(false);
        this.enableItemForm();
        this.itemsTrigger.nativeElement.click();
    }

    async addItem(save: boolean = false) {
        this.isSubmitted = false;
        if (this.addItemForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateItemForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted) {
            return;
        }

        var itemId = 0; var itemQuantity = 0;
        var existingPriceArticle = this.existingPrice(this.activeProduction.articles.filter(x => x.id == this.selectedArticle.id));
        if (existingPriceArticle != null) {
            existingPriceArticle.quantity += this.iForm.quantity.value;
            if (!this.selectedArticle.halfProduct) {
                this.activeProduction.articles.forEach(a => {
                    if (a.rawForProductId == existingPriceArticle.id) {
                        a.quantity++;
                        a.totalNC = a.nc * a.quantity;
                        a.totalVPC = a.vpc * a.quantity;
                        a.totalMPC = a.mpc * a.quantity;
                        a.totalTaxAmount = a.taxAmount * a.quantity;
                    }
                });
            }

            existingPriceArticle.totalTaxAmount = existingPriceArticle.taxAmount * existingPriceArticle.quantity;
            existingPriceArticle.totalNC = existingPriceArticle.nc * existingPriceArticle.quantity;
            existingPriceArticle.totalVPC = existingPriceArticle.vpc * existingPriceArticle.quantity;
            existingPriceArticle.totalMPC = existingPriceArticle.mpc * existingPriceArticle.quantity;

            itemId = existingPriceArticle.id;
            itemQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
        }
        else {
            var item = new ProductionArticleModel();
            item.id = this.selectedArticle.id;
            item.code = this.selectedArticle.code;
            item.name = this.selectedArticle.name;
            item.quantity = this.iForm.quantity.value;
            item.nc = this.nc;
            item.vpc = this.vpc;
            item.mpc = this.mpc;
            item.g = this.selectedArticle.groupId;
            item.gt = this.selectedArticle.groupTypeId;
            item.marginAmount = this.marginAmount;
            item.marginPercentage = this.marginPercentage;
            item.taxId = this.addTaxId;
            item.taxName = this.taxes.find(x => x.id == this.addTaxId).name;
            item.taxPercentage = this.taxes.find(x => x.id == this.addTaxId).amount;
            if (this.selectedArticle.halfProduct)
                item.taxAmount = parseFloat((item.nc * (item.taxPercentage / 100)).toFixed(6));
            else
                item.taxAmount = parseFloat((item.vpc * (item.taxPercentage / 100)).toFixed(6));

            item.totalTaxAmount = item.taxAmount * item.quantity;
            item.totalNC = item.nc * item.quantity;
            item.totalVPC = item.vpc * item.quantity;
            item.totalMPC = item.mpc * item.quantity;

            itemId = item.id;
            itemQuantity = item.quantity;
            if (!this.selectedArticle.halfProduct) {
                this.addingRawForId = item.id;
                this.activeProduction.articles.push(item);
                var halfProductData = await this.articleRestService.getHalfProductData(this.selectedArticle.id);
                this.canAddRaw=true;
                this.lastArticleForProductId = null;
                if (halfProductData != undefined && halfProductData.length > 0) {
                    this.canAddRaw=false;
                    item.presetData=true;
                    halfProductData.forEach(r => {
                        var raw = new ProductionArticleModel();
                        raw.id = r.id;
                        raw.code = r.code;
                        raw.name = r.name;
                        raw.quantity = parseFloat((r.quantity * item.quantity).toFixed(6));
                        raw.nc = r.nc;
                        raw.vpc = r.vpc;
                        raw.mpc = r.mpc;
                        raw.marginAmount = r.marginAmount;
                        raw.marginPercentage = r.marginPercentage;
                        raw.rawForProductId = this.selectedArticle.id;
                        raw.taxId = this.groups.find(x => x.id == r.groupId).taxId;
                        var tax = this.taxes.find(x => x.id == raw.taxId);
                        raw.taxName = tax.name;
                        raw.taxPercentage = tax.amount;
                        raw.taxAmount = parseFloat((raw.vpc * raw.taxPercentage / 100).toFixed(6));
                        raw.totalTaxAmount = raw.taxAmount * raw.quantity;
                        raw.totalNC = raw.nc * raw.quantity;
                        raw.totalVPC = raw.vpc * raw.quantity;
                        raw.totalMPC = raw.mpc * raw.quantity;
                        raw.g = r.groupId;
                        raw.gt = r.groupTypeId;
                        raw.presetData=true;

                        this.activeProduction.articles.push(raw);
                        this.lastArticleForProductId = item.id;
                    });
                }
            }
            else {
                this.canAddRaw = true;
                item.rawForProductId = this.addingRawForId;
                var rawsFor = this.activeProduction.articles.filter(x => x.rawForProductId == this.addingRawForId && this.indexMatch(x));

                var indexToPlace = 0;
                if (rawsFor.length == 0) {
                    indexToPlace = this.addingRawForIndex;
                } else {
                    var lastRawFor = rawsFor[rawsFor.length - 1];
                    indexToPlace = this.activeProduction.articles.indexOf(lastRawFor);
                }

                this.activeProduction.articles.splice(indexToPlace + 1, 0, item);
                this.lastArticleForProductId = item.rawForProductId;
            }
        }

        var itemRaw = this.rawGroups.find(x => x.id == this.selectedArticle.groupId) != undefined;
        this.selectedArticle = undefined;
        this.resetItemForm();
        this.updateState();

        if (save) {
            await this.editProductionDocument(true);
            if (itemRaw && this.itemLookupComponent.raws.find(x=>x.id==itemId)!=undefined) {
                this.itemLookupComponent.updateWarehouseState(itemId, -itemQuantity, true);
            }
            else if(this.itemLookupComponent.articles.find(x=>x.id==itemId)!=undefined)
                this.itemLookupComponent.updateWarehouseState(itemId, itemQuantity);
        }
    }
    indexMatch(item: ProductionArticleModel, customStartIndex: number = undefined, customLimitIndex: number = undefined) {
        if (customStartIndex == undefined)
            customStartIndex = this.addingRawForIndex;
        if (customLimitIndex == undefined)
            customLimitIndex = this.addingRawUntilIndex;

        var index = this.activeProduction.articles.indexOf(item);
        return index > customStartIndex && index < customLimitIndex;
    }

    resetItemForm() {
        $("#itemCodeInput").val(undefined);
        this.iForm.quantity.setValue(1);
        this.iForm.nc.setValue(0);
        this.iForm.mpc.setValue(0);
        this.iForm.margin.setValue(0);
        this.nc = 0;
        this.mpc = 0;
        this.marginPercentage = 0;
        this.marginAmount = 0;
        this.mpcDisplay = 0;
        this.ncDisplay = 0;
        this.marginPercentageDisplay = 0;
        this.marginAmountDisplay = 0;
        if (this.taxes)
            $("#add_item_taxId").val(this.taxes[0].id).trigger("change");
        $("#add_item_taxId").nextAll('span').first().removeClass('is-invalid');

        this.selectedArticle = undefined;
        this.editingItem = undefined;

        $("#itemCodeInput").removeClass('is-invalid');
    }

    existingPrice(existingArticles: ProductionArticleModel[]) {
        if (this.mpc != undefined && this.nc != undefined) {
            if (this.selectedArticle.halfProduct) {
                var existingArticle = existingArticles.find(x => x.mpc == this.mpc && x.nc == this.nc && x.rawForProductId == this.addingRawForId);
                if (existingArticle != undefined && this.indexMatch(existingArticle))
                    return existingArticle;
                else return undefined;
            }
            else return existingArticles.find(x => x.mpc == this.mpc && x.nc == this.nc);
        }

        return null;
    }

    async removeItem(item: ProductionArticleModel) {
        var deletingLast = item.id == this.activeProduction.articles[this.activeProduction.articles.length - 1].id
                            || this.lastArticleForProductId == item.id;

        var itemsToRemove: ProductionArticleModel[] = [];
        if (item.rawForProductId == undefined) {
            var indexToDelete = this.activeProduction.articles.indexOf(item);
            var deleteLimit = this.activeProduction.articles.find(x => x.rawForProductId == undefined && this.activeProduction.articles.indexOf(x) > indexToDelete);
            if (deleteLimit == undefined)
                itemsToRemove = this.activeProduction.articles.filter(x => x.rawForProductId == item.id && this.activeProduction.articles.indexOf(x) > indexToDelete);
            else
                itemsToRemove = this.activeProduction.articles.filter(x => x.rawForProductId == item.id && this.indexMatch(x, indexToDelete, this.activeProduction.articles.indexOf(deleteLimit)));
        }
        itemsToRemove.push(item);
        itemsToRemove.forEach(i => {
            this.activeProduction.articles.forEach((element, index) => {
                if (element == i) this.activeProduction.articles.splice(index, 1);
            });
        });

        await this.editProductionDocument();
        if (item.rawForProductId != null && this.itemLookupComponent.raws.find(x=>x.id==item.id)!=undefined)
            this.itemLookupComponent.updateWarehouseState(item.id, item.quantity, true);
        else if(this.itemLookupComponent.articles.find(x=>x.id==item.id)!=undefined)
            this.itemLookupComponent.updateWarehouseState(item.id, -item.quantity);

        if (deletingLast && this.activeProduction.articles.length > 0) {
            this.lastArticleForProductId = this.activeProduction.articles[this.activeProduction.articles.length - 1].rawForProductId;
        }

        if(this.lastArticleForProductId != null){
            if(this.activeProduction.articles.find(x=>x.id==this.lastArticleForProductId).presetData)
                this.canAddRaw=false;
            else this.canAddRaw=true;
        }
        else
            this.canAddRaw = false;

        if (this.saveState.newProductionActive)
            this.updateState();
    }

    validateItemForm() {
        var check = true;

        if (this.selectedArticle == undefined) {
            check = false;
            $("#itemCodeInput").addClass('is-invalid');
        }
        else {
            $("#itemCodeInput").removeClass('is-invalid');
        }

        if (this.addTaxId == 0) {
            $("#add_item_taxId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_item_taxId").nextAll('span').first().removeClass("is-invalid");
        }


        return check;
    }

    async selectArticle(a: ArticleModel) {
        $("#itemCodeInput").val(a.code);
        this.selectedArticle = a;
        if (this.selectedArticle != undefined) {
            $("#itemCodeInput").removeClass('is-invalid');
            this.nc = a.nc;
            this.ncDisplay = parseFloat(a.nc.toFixed(2));
            this.iForm.nc.setValue(this.ncDisplay);
            this.vpc = a.vpc;
            this.vpcDisplay = parseFloat(a.vpc.toFixed(2));
            this.iForm.vpc.setValue(this.vpcDisplay);
            this.mpc = a.mpc;
            this.mpcDisplay = parseFloat(a.mpc.toFixed(2));
            this.iForm.mpc.setValue(this.mpcDisplay);
            this.calculateMarginPercentage();

            var articleGroup = this.groups.find(x => x.id == this.selectedArticle.groupId);
            this.isRawGroup = articleGroup.isRawGroup;
            $("#add_item_taxId").val(articleGroup.taxId).trigger("change");

            if (!this.selectedArticle.halfProduct)
                this.halfProductData = await this.articleRestService.getHalfProductData(this.selectedArticle.id);
        }
    }
    selectArticleByCode(event: KeyboardEvent, c: string) {
        if (event.key != 'Enter')
            return;

        event.preventDefault();
        this.selectedArticle = this.itemLookupComponent.articles.find(x => x.code == c);

        if (this.selectedArticle != undefined) {
            $("#itemCodeInput").removeClass('is-invalid');
            this.nc = this.selectedArticle.nc;
            this.ncDisplay = parseFloat(this.selectedArticle.nc.toFixed(2));
            this.iForm.nc.setValue(this.ncDisplay);
            this.mpc = this.selectedArticle.mpc;
            this.mpcDisplay = parseFloat(this.selectedArticle.mpc.toFixed(2));
            this.iForm.mpc.setValue(this.mpcDisplay);
            this.calculateMarginPercentage();
            var groupTaxId = this.groups.find(x => x.id == this.selectedArticle.groupId).taxId;
            $("#add_item_taxId").val(groupTaxId).trigger("change");
        }
    }
    enterOnRow(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            var element = event.target as HTMLElement;
            element.click();
        }
    }
    changedNC() {
        if (this.iForm.nc.value != undefined && this.iForm.nc.value != null) {
            this.nc = parseFloat(this.iForm.nc.value.toFixed(6));
            this.ncDisplay = parseFloat(this.iForm.nc.value.toFixed(2));
        } else {
            this.nc = undefined;
            this.ncDisplay = undefined;
        }

        this.calculateMarginPercentage();
    }
    changedVPC() {
        if (this.iForm.vpc.value != undefined && this.iForm.vpc.value != null) {
            this.vpc = parseFloat(this.iForm.vpc.value.toFixed(6));
            this.vpcDisplay = parseFloat(this.iForm.vpc.value.toFixed(2));
        } else {
            this.vpc = undefined;
            this.vpcDisplay = undefined;
            return;
        }

        if (this.iForm.mpc.value == null || this.iForm.mpc == undefined) {
            var taxToApply = this.taxes.find(x => x.id == this.addTaxId).amount;
            this.mpc = parseFloat((this.vpc + (this.vpc * taxToApply / 100)).toFixed(6));
            this.mpcDisplay = parseFloat(this.mpc.toFixed(2));
            this.iForm.mpc.setValue(this.mpcDisplay);
            this.calculateMarginPercentage();
        }
    }
    changedMPC() {
        if (this.iForm.mpc.value != undefined && this.iForm.mpc.value != null) {
            this.mpc = parseFloat(this.iForm.mpc.value.toFixed(6));
            this.mpcDisplay = parseFloat(this.iForm.mpc.value.toFixed(2));
        } else {
            this.mpc = undefined;
            this.mpcDisplay = undefined;
        }

        this.calculateMarginPercentage();
    }
    changedTax() {
        if (this.iForm.vpc.value != null && this.iForm.vpc.value != undefined)
            if (this.iForm.mpc.value == null || this.iForm.mpc.value == undefined) {
                var taxToApply = this.taxes.find(x => x.id == this.addTaxId).amount;

                if (this.isRawGroup) {
                    this.mpc = parseFloat((this.nc / (1 + taxToApply / 100)).toFixed(6));
                } else {
                    this.mpc = parseFloat((this.vpc / (1 + taxToApply / 100)).toFixed(6));
                }

                this.mpcDisplay = parseFloat(this.mpc.toFixed(2));
                this.iForm.mpc.setValue(this.mpcDisplay);
            }
    }

    switchToArticlesAndAddProduct() {
        this.itemsTrigger.nativeElement.click();
        this.startAddingItems();
    }

    switchToArticlesAndAddRaw() {
        this.itemsTrigger.nativeElement.click();
        this.addRawToLast();
    }


    switchToHeader() {
        this.headerTrigger.nativeElement.click();
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchProductionDocuments();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchProductionDocuments();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchProductionDocuments();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchProductionDocuments();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchProductionDocuments();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}