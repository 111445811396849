export class FiscalizationConfigModel{
    id:number;
    commandPath:string;
    answerFilePath:string;
    fiscalTypeId:number;
    fiscalTypeCode:string;
    fiscalTypeName:string;
    fiscalDeviceId:number;
    operatorName:string;
    operatorCode:string;
    clientId:number;

    skipAfterMPC:boolean;
}