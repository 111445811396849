import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { RetailPlaceModel } from "../models/retailPlace.model";
import { DataService } from "./data.service";

@Injectable()
export class RetailPlaceRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }


    async getRetailPlaces(name: string, clientId: number, page: number, pageSize: number) {
        var params = "?Name=" + name  + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/RetailPlace/Filter" + params).toPromise() as any;
    }

    async updateRetailPlace(obj: RetailPlaceModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/RetailPlace/Update", obj).toPromise() as any;
    }

    async createRetailPlace(obj: RetailPlaceModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/RetailPlace/Create", obj).toPromise() as any;
    }
}