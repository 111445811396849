import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupModel } from 'src/app/models/group.model';
import { GroupTypeModel } from 'src/app/models/groupType.model';
import { TaxModel } from 'src/app/models/tax.model';
import { ClientRestService } from 'src/app/services/client.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { GroupTypeRestService } from 'src/app/services/groupType.service';
import { ReportRestService } from 'src/app/services/reports.service';
import { TranslateService } from 'src/app/translate';
import toastr from 'toastr'
import { DatePipe } from '@angular/common'

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;
@Component({
  selector: 'app-money-withdrawal',
  templateUrl: './money-withdrawal.component.html',
})
export class MoneyWithdrawalComponent {

  public emptyData: boolean = true;
  public report: any;
  public searchForm: UntypedFormGroup;
  public isSubmitted: boolean = false;
  public selectedReportCode:string='PR';
  dateFrom:string;
  dateTo:string;
  get f() { return this.searchForm.controls; }
  
  //pagination
  public page: number = 1;
  public perPage: number;
  public totalData: number;
  public maxPage: number;
  public pages: number[] = [];
  public offset: number = 5;
  public firstPage: number = 1;
  public lastPage: number;
  public counter: number = 0;
  public firstLoad: boolean = true;

  constructor(
      private reportRest: ReportRestService,
      private _changeRef: ChangeDetectorRef,
      public data: DataService,
      private formBuilder:FormBuilder,
      private translate: TranslateService,
      public datepipe: DatePipe
    ) {
      this.page = 1;
      this.perPage = 50;
  }

  ngOnInit() {
      KTMenu.createInstances();
      this.createSearchForm();
  }

  async fetchReport() {
      this.isSubmitted=false;
      if(this.searchForm.invalid){
        this.isSubmitted=true;
        return;
      }

      try {
          this.emptyData=false;
          var response = await this.reportRest.moneyWithdrawal(this.searchForm.controls.amount.value, 1, 99999);
          var extension = this.data.getFiscalType()=='TRI'?'.xml':'.inp';
          var fileName = null;
          if(this.data.getFiscalType()=='TRI')
                fileName='povratnovca.'+this.datepipe.transform(new Date(),'dd-MM-yyyy');
          else
                fileName='withdrawal-'+this.datepipe.transform(new Date(),'dd-MM-yyyy');
          this.reportRest.saveFile(response, fileName, extension);

          // this.totalData = response.totalCount;
          // if (this.totalData == 0)
          //     this.emptyData = true;
          // else
          //     this.emptyData = false

          // if (this.firstLoad) {
          //     this.maxPage = response.totalPages;
          //     this.maxPage = Math.floor(this.maxPage);
          //     this.firstPage = 1;
          //     if (this.maxPage > 5)
          //         this.lastPage = 5;
          //     else
          //         this.lastPage = this.maxPage;

          //     this.enablePrevious();
          //     this.firstLoad = false;
          // }
          // this.initPages();
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  resetFilter() {
      this.searchForm.controls['amount'].setValue(null);
     
      this.firstLoad = true;
      this.page = 1;
  }

  createSearchForm() {
      this.searchForm = this.formBuilder.group({
          amount: [null, Validators.required],
      })
  }

  //pagination
  initPages() {
      this.pages = [];
      for (var i = this.firstPage; i <= this.lastPage; i++) {
          this.pages.push(i);
      }
  }

  nextPage() {
      if ($("#kt_customers_table_next").hasClass("disabled"))
          return;

      if (this.page == 1 && this.firstPage == 1) {
          this.page++;
      }

      else {
          this.page++;
          if (this.counter == 1) {
              if (this.maxPage > (this.lastPage + this.offset)) {
                  this.firstPage++;
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else if (this.maxPage > (this.firstPage + this.offset)) {
                  this.firstPage++;
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else if (this.maxPage) {
                  this.firstPage = this.maxPage - this.offset;
                  this.lastPage = this.maxPage;
              }
              this.counter = 0;
          }
          else {
              if (this.maxPage > (this.lastPage + this.offset)) {
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else if (this.maxPage > (this.firstPage + this.offset)) {
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else {
                  this.firstPage = this.maxPage - this.offset;
                  this.lastPage = this.maxPage;
              }
          }
          this.counter++;
      }
      // //this.fetchGroups();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  prevpage() {
      if ($("#kt_customers_table_previous").hasClass("disabled"))
          return;

      this.page--;
      if (this.counter == 1) {
          if (this.maxPage > this.lastPage + this.offset) {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          else {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          this.counter = 0;
      }
      else {
          if (this.maxPage > this.lastPage + this.offset) {
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          else {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
      }
      this.counter++;
      //this.fetchGroups();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  goToPage(p: number) {
      this.page = p;
      //this.fetchGroups();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  morePages() {
      if ($("#kt_customers_table_morePages").hasClass("disabled"))
          return;
      if (this.maxPage > this.lastPage + this.offset) {
          this.firstPage = this.lastPage;
          this.lastPage = this.firstPage + this.offset;
      }
      else {
          this.firstPage = this.maxPage - this.offset;
          this.lastPage = this.maxPage;
      }
      this.page = this.firstPage;
      //this.fetchGroups();
      this.initPages();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  perPageHandler() {
      this.firstLoad = true;
      this.page = 1;
      //this.fetchGroups();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  enablePrevious() {
      if (this.page > 1) {
          $(document).ready(function () {
              $("#kt_customers_table_previous").removeClass("disabled");
          })
      }
      else if (this.page == 1) {
          $(document).ready(function () {
              $("#kt_customers_table_previous").addClass("disabled");
          })
      }

      if (this.page > 1 && this.page != this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_next").removeClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
          })
      }

      if (this.maxPage == 0) {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
              $("#morePages").addClass("disabled");
              $("#kt_customers_table_previous").addClass("disabled");
          })
      }

      if (this.page == this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_next").removeClass("disabled");
          })
      }

      if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_morePages").addClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_morePages").removeClass("disabled");
          })
      }
  }
}