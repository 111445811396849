import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-recapitulation-production-pdf',
    templateUrl: './recapitulation-production-pdf.component.html',
})
export class RecapitulationProductionPdfViewerComponent implements OnInit {
    pdfSrc: any;
    sanitizedPdfSrc: SafeResourceUrl;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.pdfSrc = params['pdfSrc'];
        });

        this.sanitizedPdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc);
    }

    back() {
        this.router.navigate(['/recapitulation-production']);
    }

    saveFile() {
        var a = document.createElement('a');
        a.href = this.pdfSrc;
        a.download = "Rekapitulacija dokumenta proizvodnje.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(this.pdfSrc);
      }

      printPDF() {
        const iframe = document.getElementById('pdfIframeRP') as HTMLIFrameElement;
        setTimeout(() => {
            const iframeWindow = iframe.contentWindow;
            if (iframeWindow) {
                iframeWindow.focus();
                iframeWindow.print();
            }
        }, 1000);
    }
}