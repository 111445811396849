import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { DataService } from 'src/app/services/data.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { TranslateService } from 'src/app/translate';
import toastr from 'toastr'

declare var $: any;
declare var KTMenu: any;
declare var Swal: any;

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
})
export class WarehouseComponent {

  public emptyData: boolean = true;
  public warehouses: WarehouseModel[];
  public searchForm: UntypedFormGroup;
  public formGroup: UntypedFormGroup;
  public isSubmitted: boolean = false;
  public selectedWarehouse: WarehouseModel;
  public searchName:string='';
  //pagination
  public page: number = 1;
  public perPage: number;
  public totalData: number;
  public maxPage: number;
  public pages: number[] = [];
  public offset: number = 5;
  public firstPage: number = 1;
  public lastPage: number;
  public counter: number = 0;
  public firstLoad: boolean = true;

  get f() { return this.formGroup.controls; }

  @ViewChild('closeAddWarehouseModal') closeAddWarehouseModal: ElementRef;
  @ViewChild('closeEditWarehouseModal') closeEditWarehouseModal: ElementRef;

  constructor(
    private warehouseRest: WarehouseRestService,
    private formBuilder: UntypedFormBuilder,
    private _changeRef: ChangeDetectorRef,
    public data: DataService,
    private translate: TranslateService) {
    this.page = 1;
    this.perPage = 50;
  }

  ngOnInit() {
      KTMenu.createInstances();
      this.createSearchForm();
      this.fetchWarehouses();
      this.createAddEditForm();
  }
  
  async fetchWarehouses() {
      try {
          this.emptyData=false;

          var response = await this.warehouseRest.filterWarehouses(this.data.user.clientId, this.searchName, this.page, this.perPage);
          this.warehouses = response.data;

          this.totalData = response.totalCount;
          if (this.totalData == 0)
              this.emptyData = true;
          else
              this.emptyData = false

          if (this.firstLoad) {
              this.maxPage = response.totalPages;
              this.maxPage = Math.floor(this.maxPage);
              this.firstPage = 1;
              if (this.maxPage > 5)
                  this.lastPage = 5;
              else
                  this.lastPage = this.maxPage;

              this.enablePrevious();
              this.firstLoad = false;
          }
          this.initPages();
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  async addWarehouse() {
      this.isSubmitted = false;
      if (this.formGroup.invalid) {
          this.isSubmitted = true;
      }
      if (this.isSubmitted == true) {
          return;
      }

      var obj = this.createAddObject();

      try {
          await this.warehouseRest.createWarehouse(obj);
          this.closeAddWarehouseModal.nativeElement.click();
          toastr.options = this.data.toastrOptions;
          toastr.success(this.translate.instant('_Added_warehouse_text'), this.translate.instant("_Success"));
          this.fetchWarehouses();
      }
      catch (err) {
          toastr.options = this.data.toastrOptions;
          toastr.error(err.error['Message'], this.translate.instant("_Error"));
      }
  }

  async updateWarehouse() {
      this.isSubmitted = false;
      if (this.formGroup.invalid) {
          this.isSubmitted = true;
      }
      if (this.isSubmitted == true) {
          return;
      }

      var obj = this.createEditObject();

      try {
          await this.warehouseRest.updateWarehouse(obj);
          this.closeEditWarehouseModal.nativeElement.click();
          toastr.options = this.data.toastrOptions;
          toastr.success(this.translate.instant('_Updated_warehouse_text'), this.translate.instant("_Success"));
          this.fetchWarehouses();
      }
      catch (err) {
          toastr.options = this.data.toastrOptions;
          toastr.error(err.error['Message'], this.translate.instant("_Error"));
      }
  }

  searchWarehouses(warehouseName:string) {
      this.searchName=warehouseName;
      this.fetchWarehouses();
  }

  createAddObject() {
      var obj = new WarehouseModel();
      obj.name = this.formGroup.controls.name.value;
      obj.address = this.formGroup.controls.address.value;
      obj.clientId = parseInt(this.data.user.clientId);

      return obj;
  }

  createEditObject() {
      var obj = new WarehouseModel();
      obj.id = this.selectedWarehouse.id;
      obj.name = this.formGroup.controls.name.value;
      obj.address = this.formGroup.controls.address.value;
      obj.clientId = parseInt(this.data.user.clientId);

      return obj;
  }

  createSearchForm() {
      this.searchForm = this.formBuilder.group({
          name: [''],
      })
  }

  createAddEditForm() {
      this.formGroup = this.formBuilder.group({
          name: ['', Validators.required],
          address: ['', Validators.required],
      })
  }

  selectWarehouse(warehouse: WarehouseModel) {
      this.selectedWarehouse = warehouse;
      this.formGroup.controls['name'].setValue(this.selectedWarehouse.name);
      this.formGroup.controls['address'].setValue(this.selectedWarehouse.address);
  }

  resetForm() {
      this.formGroup.controls['name'].setValue("");
      this.formGroup.controls['address'].setValue("");
  }

  closeAddModal() {
      var self = this;
      var question_text = this.translate.instant("_Cancel_question_text");
      var yes_text = this.translate.instant("_Yes_cancel_text");
      var no_text = this.translate.instant("_No_return_text");
      Swal.fire({
          html: question_text,
          icon: "warning",
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: yes_text,
          cancelButtonText: no_text,
          customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: 'btn btn-secondary'
          }
      }).then(async (result) => {
          if (result.isConfirmed) {
              self.closeAddWarehouseModal.nativeElement.click();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
      });
  }

  closeEditModal() {
      var self = this;
      var question_text = this.translate.instant("_Cancel_question_text");
      var yes_text = this.translate.instant("_Yes_cancel_text");
      var no_text = this.translate.instant("_No_return_text");
      Swal.fire({
          html: question_text,
          icon: "warning",
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: yes_text,
          cancelButtonText: no_text,
          customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: 'btn btn-secondary'
          }
      }).then(async (result) => {
          if (result.isConfirmed) {
              self.closeEditWarehouseModal.nativeElement.click();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
      });
  }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage=1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchWarehouses();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage>this.firstPage+this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage=this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                    if(this.maxPage>this.firstPage+this.offset)
                        this.lastPage = this.firstPage + this.offset;
                    else
                        this.lastPage=this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else 
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchWarehouses();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchWarehouses();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchWarehouses();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchWarehouses();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}
