export class UserAccountModel{
    firstName:string;
    lastName:string;
    username:string;
    clientUserRoleId:number;
    clientUserRoleName:string;
    clientId:number;
    clientName:string;
    retailPlaceName:string;
    retailPlaceId:number;
    password:string;
    pin:string;
    id:number;
    isActive:boolean;
}