<div class="toolbar" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1" style="font-size:22px!important;">{{'_Account_settings' | translate}}</h1>
            <span class="h-20px border-gray-300 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-gray-600">
                    <a [routerLink]="['/dashboard']" class="text-muted text-hover-primary" style="font-size:16px!important">{{'_Dashboard' |
                        translate}}</a>
                </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-600 w-5px h-2px" style="font-size:16px!important"></span>
                </li>
                <li class="breadcrumb-item text-gray-600"   style="font-size:16px!important">{{'_Account_settings' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl" style="margin-top:25px;padding:0px;">
        <div class="card mb-5 mb-xl-10">
            <div class="card-body pt-9 pb-0">
                <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                    <div class="me-7 mb-4">
                        <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <ngx-avatars name="{{data?.user?.firstName + ' '+data?.user?.lastName}}" [round]="false"
                                [cornerRadius]="5" [size]="100" [bgColor]="'#3699FF'"></ngx-avatars>
                            <div
                                class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px">
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div class="d-flex flex-column">
                                <div class="d-flex align-items-center mb-2">
                                    <a href="#"
                                        class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{{data?.user?.firstName
                                        + ' '+data?.user?.lastName}}</a>
                                </div>
                                <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                    <span class="text-gray-900 me-1">
                                        {{data?.user?.clientUserRoleName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-5 mb-xl-10">
            <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details" aria-expanded="true"
                aria-controls="kt_account_profile_details">
                <div class="card-title m-0">
                    <h3 class="fw-bolder m-0">{{'_Profile_details' | translate}}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" class="collapse show">
                <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework"
                    novalidate="novalidate" [formGroup]="formGroup">
                    <div class="card-body border-top p-9">
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label required fw-bold fs-6">{{'_First_Last_name' |
                                translate}}</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input type="text" name="fname"
                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" formControlName="firstName" required
                                            [ngClass]="{ 'is-invalid': isSubmitted && f.firstName.errors }">
                                        <div class="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input type="text" name="lname"
                                            class="form-control form-control-lg form-control-solid" formControlName="lastName" required
                                            [ngClass]="{ 'is-invalid': isSubmitted && f.lastName.errors }">
                                        <div class="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="button" class="btn btn-light btn-active-light-primary me-2" (click)="discardChanges()">{{'_Discard' | translate}}</button>
                        <button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit" (click)="updateClient()">{{'_Submit' | translate}}</button>
                    </div>
                    <input type="hidden">
                    <div></div>
                </form>
            </div>
        </div>
        <div class="card mb-5 mb-xl-10">
            <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
                data-bs-target="#kt_account_signin_method">
                <div class="card-title m-0">
                    <h3 class="fw-bolder m-0">{{'_Sign_in_method' | translate}}</h3>
                </div>
            </div>
            <div id="kt_account_settings_signin_method" class="collapse show">
                <div class="card-body border-top p-9">
                    <div class="d-flex flex-wrap align-items-center mb-10">
                        <div id="kt_signin_pin" *ngIf="!changePin">
                            <div class="fs-6 fw-bolder mb-1">{{'PIN' | translate}}</div>
                            <div class="fw-bold text-gray-600">************</div>
                        </div>
                        <div *ngIf="changePin" id="kt_signin_pin_edit" class="flex-row-fluid">
                            <form id="kt_signin_change_pin" class="form fv-plugins-bootstrap5 fv-plugins-framework"
                                [formGroup]="resetformGroupPin">
                                <div class="row mb-1">
                                    <div class="col-lg-4">
                                        <div class="fv-row mb-0 fv-plugins-icon-container">
                                            <label for="currentPin" class="form-label fs-6 fw-bolder mb-3">Current
                                                PIN</label>
                                            <input type="password"
                                                class="form-control form-control-lg form-control-solid" formControlName="currentPin"
                                                name="currentPin" id="currentPin" [required]="data?.user?.pin!=undefined"
                                                [ngClass]="{ 'is-invalid': isSubmittedPin && f3.currentPin.errors }">
                                            <div class="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="fv-row mb-0 fv-plugins-icon-container">
                                            <label for="newPin" class="form-label fs-6 fw-bolder mb-3">New
                                                PIN</label>
                                            <input type="password"
                                                class="form-control form-control-lg form-control-solid" formControlName="newPin"
                                                name="newPin" id="newPin" required
                                                [ngClass]="{ 'is-invalid': isSubmittedPin && f3.newPin.errors }">
                                            <div class="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="fv-row mb-0 fv-plugins-icon-container">
                                            <label for="confirmPin" class="form-label fs-6 fw-bolder mb-3">Confirm
                                                New PIN</label>
                                            <input type="password"
                                                class="form-control form-control-lg form-control-solid" formControlName="confirmPin"
                                                name="confirmPin" id="confirmPin" required
                                                [ngClass]="{ 'is-invalid': isSubmittedPin && f3.confirmPin.errors }">
                                            <div class="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <button id="kt_pin_submit" type="submit"
                                        class="btn btn-primary me-2 px-6" (click)="resetPin()">{{'_Change_PIN'|translate}}</button>
                                    <button (click)="cancelPinReset()" id="kt_pin_cancel" type="button"
                                        class="btn btn-color-gray-400 btn-active-light-primary px-6">{{"_Discard"|translate}}</button>
                                </div>
                                <div></div>
                            </form>
                        </div>
                        <div id="kt_signin_pin_button" class="ms-auto" *ngIf="!changePin">
                            <button (click)="toggleResetPinForm(true)" class="btn btn-light btn-active-light-primary">Reset PIN</button>
                        </div>
                    </div>
                    <div class="separator separator-dashed my-6"></div>
                    <div class="d-flex flex-wrap align-items-center mb-10">
                        <div id="kt_signin_password" *ngIf="!changePassword">
                            <div class="fs-6 fw-bolder mb-1">{{'_Password' | translate}}</div>
                            <div class="fw-bold text-gray-600">************</div>
                        </div>
                        <div *ngIf="changePassword" id="kt_signin_password_edit" class="flex-row-fluid">
                            <form id="kt_signin_change_password" action="#" class="form fv-plugins-bootstrap5 fv-plugins-framework"
                                [formGroup]="resetformGroup">
                                <div class="row mb-1">
                                    <div class="col-lg-4">
                                        <div class="fv-row mb-0 fv-plugins-icon-container">
                                            <label for="currentpassword" class="form-label fs-6 fw-bolder mb-3">Current
                                                Password</label>
                                            <input type="password"
                                                class="form-control form-control-lg form-control-solid" formControlName="currentpassword"
                                                name="currentpassword" id="currentpassword" required
                                                [ngClass]="{ 'is-invalid': isSubmittedPass && f2.currentpassword.errors }">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="fv-row mb-0 fv-plugins-icon-container">
                                            <label for="newpassword" class="form-label fs-6 fw-bolder mb-3">New
                                                Password</label>
                                            <input type="password"
                                                class="form-control form-control-lg form-control-solid" formControlName="newpassword"
                                                name="newpassword" id="newpassword" required 
                                                [ngClass]="{ 'is-invalid': isSubmittedPass && f2.newpassword.errors }">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="fv-row mb-0 fv-plugins-icon-container">
                                            <label for="confirmpassword" class="form-label fs-6 fw-bolder mb-3">Confirm
                                                New Password</label>
                                            <input type="password"
                                                class="form-control form-control-lg form-control-solid" formControlName="confirmpassword"
                                                name="confirmpassword" id="confirmpassword" required
                                                [ngClass]="{ 'is-invalid': isSubmittedPass && f2.confirmpassword.errors }">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-text mb-5">Password must be at least 8 character and contain symbols
                                </div>
                                <div class="d-flex">
                                    <button id="kt_password_submit" type="submit"
                                        class="btn btn-primary me-2 px-6" (click)="resetPassword()">{{'_Change_password'|translate}}</button>
                                    <button (click)="cancelPasswordReset()" id="kt_password_cancel" type="button"
                                        class="btn btn-color-gray-400 btn-active-light-primary px-6">{{"_Discard"|translate}}</button>
                                </div>
                                <div></div>
                            </form>
                        </div>
                        <div id="kt_signin_password_button" class="ms-auto" *ngIf="!changePassword">
                            <button (click)="toggleResetPasswordForm(true)" class="btn btn-light btn-active-light-primary">Reset Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-http-loader [spinner]="'sk-wave'"></ng-http-loader>