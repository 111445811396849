import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ArticleModel } from 'src/app/models/article.model';
import { ArticleRestService } from 'src/app/services/article.service';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/translate';

declare var $: any;

@Component({
  selector: 'app-item-lookup',
  templateUrl: './item-lookup.component.html',
})
export class ItemLookupComponent implements OnInit {
  public articles: ArticleModel[] = [];
  public itemsToAdd: ArticleModel[];
  public raws: ArticleModel[] = [];
  public rawsToAdd: ArticleModel[];
  public selectedArticle: ArticleModel;
  public selectedArticleCode: string;


  public itemModalOpen: boolean = false;
  public fetchInProgress: boolean = false;
  public firstArticleFetch: boolean = true;
  public preventScrollLoadingitem: boolean = false;

  public articlePage: number = 1;
  public articlePageSize: number = 20;
  public searchItemCodeName: string;
  public articleSearch: string = undefined;
  public previousArticleSearch: string = undefined;
  @Input() public buttonIsHidden: boolean = true;
  @Input() warehouseId: number;
  @Input() selectedCurrency: string;
  @Input() incomingInvoiceSelection: boolean;
  @Input() productionSelection: boolean = false;
  @Input() addingRaw: boolean = false;
  @Input() noStates: boolean = false;
  @Input() includeNormatives: boolean = false;

  @Output() selectArticleEvent = new EventEmitter<ArticleModel>();
  @Output() sendRegistryArticlesEvent = new EventEmitter<Array<ArticleModel>>();
  @ViewChild('itemSelectBody') itemSelectBody: ElementRef;

  @HostListener('document:mousewheel', ['$event'])
  onScroll(event: Event): void {
    if (!this.itemModalOpen)
      return;
    const scrollContainer = this.itemSelectBody.nativeElement;
    var diff = (scrollContainer.scrollHeight - scrollContainer.scrollTop) - scrollContainer.clientHeight;
    if ((diff >= -1 && diff <= 1) && !this.fetchInProgress && !this.preventScrollLoadingitem) {
      this.articlePage++;
      this.fetchArticles();
    }
  }

  constructor(
    private articleRestService: ArticleRestService,
    private data: DataService,
    private translate: TranslateService,
    private _changeRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    $("#kt_modal_select_invoice_item").on('shown.bs.modal', () => {
      this.itemModalOpen = true;
      this._changeRef.detectChanges();
      $("#searchItemInput").focus();
    });

    $("#kt_modal_select_invoice_item").on('hidden.bs.modal', () => {
      this.itemModalOpen = false;
      this.refreshItemLookup();
      this._changeRef.detectChanges();
      $("#amountInput").focus();
    });
  }

  openItemsModal() {
    if (this.articles.length == 0)
      this.fetchArticles();
    $("#itemLookup").click();
  }

  refreshItemLookup() {
    if (this.searchItemCodeName != '' && this.searchItemCodeName != undefined) {
      this.searchItemCodeName = '';
      this.articleSearch = '';
      this.previousArticleSearch = '';
      this.fetchArticles(true);
    }
  }

  async searchItems() {
    this.articleSearch = this.searchItemCodeName;

    if (this.articleSearch != this.previousArticleSearch) {
      this.articlePage = 1;
      this.preventScrollLoadingitem = false;
      await this.fetchArticles();
      this.previousArticleSearch = this.articleSearch;
      this.selectedArticle = undefined;
    }
  }

  async fetchArticles(refresh: boolean = false) {
    try {
      this.fetchInProgress = true;

      if (this.productionSelection) {
        await this.fetchForProduction(refresh);
        return;
      }

      var response = undefined;
      if (this.noStates)
        response = (await this.articleRestService.getArticles(this.searchItemCodeName, null, this.data.user.clientId, null, null, this.articlePage, this.articlePageSize, false)).data;
      else
        response = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.warehouseId, this.articlePage, this.articlePageSize, this.articleSearch, false, false, null, null, this.includeNormatives);

      if (this.articleSearch != this.previousArticleSearch || refresh)
        this.articles = response;
      else if (this.articles == undefined || this.articles.length == 0)
        this.articles = response;
      else {
        var previousLength = this.articles.length;
        this.articles = this.articles.concat(response);
        if (this.articles.length == previousLength)
          this.preventScrollLoadingitem = true;
      }

      this.sendRegistryArticlesEvent.emit(this.articles);
      this.itemsToAdd = this.articles;
      this.fetchInProgress = false;
    }
    catch (err) {
      if (err.status == 401) {
        this.data.logout();
      }
    }
  }

  async fetchForProduction(changedWarehouse: boolean = false) {
    try {
      var response = null;

      if (this.firstArticleFetch) {
        response = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.warehouseId, this.articlePage, this.articlePageSize, this.articleSearch, false, true, null, null, this.includeNormatives);
        this.articles = response;
        var responseRaw = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.warehouseId, this.articlePage, this.articlePageSize, this.articleSearch, true, false, null, null, this.includeNormatives);
        this.raws = responseRaw;
        this.itemsToAdd = this.articles;
        this.rawsToAdd = this.raws;

        this.firstArticleFetch = false;
        this.fetchInProgress = false;
        return;
      } else {
        response = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.warehouseId, this.articlePage, this.articlePageSize, this.articleSearch, this.addingRaw, !this.addingRaw, null, null, this.includeNormatives);
      }

      if (this.addingRaw == false) {
        if (this.articleSearch != this.previousArticleSearch || changedWarehouse)
          this.articles = response;
        else if (this.articles == undefined || this.articles.length == 0)
          this.articles = response;
        else {
          var previousLength = this.articles.length;
          this.articles = this.articles.concat(response);
          if (this.articles.length == previousLength)
            this.preventScrollLoadingitem = true;
        }

        if (this.incomingInvoiceSelection)
          this.sendRegistryArticlesEvent.emit(this.articles);

        this.itemsToAdd = this.articles;
      } else {
        if (this.articleSearch != this.previousArticleSearch || changedWarehouse)
          this.raws = response;
        else if (this.raws == undefined || this.raws.length == 0)
          this.raws = response;
        else {
          var previousLength = this.raws.length;
          this.raws = this.raws.concat(response);
          if (this.raws.length == previousLength)
            this.preventScrollLoadingitem = true;
        }

        this.rawsToAdd = this.raws;
      }
    }
    catch (err) {
      if (err.status == 401) {
        this.data.logout();
      }
    }
  }

  async selectArticle(a: ArticleModel) {
    this.selectedArticle = a;
    this.selectArticleEvent.emit(this.selectedArticle);
  }
  enterOnRow(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      event.preventDefault();
      var element = event.target as HTMLElement;
      element.click();
    }
  }

  updateWarehouseState(id: number, quantity: number, raw: boolean = false) {
    if (raw) {
      this.raws.find(x => x.id == id).warehouseState += quantity;
    } else {
      this.articles.find(x => x.id == id).warehouseState += quantity;
    }
  }
}