import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientUserRoles } from 'src/app/models/clientUserRoles.model';
import { IdModel } from 'src/app/models/id.model';
import { UserAccountModel } from 'src/app/models/userAccount.model';
import { DataService } from 'src/app/services/data.service';
import { UserAccountRestService } from 'src/app/services/userAccount.service';
import { TranslateService } from 'src/app/translate';
import { RetailPlaceRestService } from 'src/app/services/retailPlace.service';
import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import toastr from 'toastr';
import { CountryModel } from 'src/app/models/country.model';
import { CustomerSupplierRestService } from 'src/app/services/customerSupplier.service';
import { TownModel } from 'src/app/models/town.model';
import { CustomerSuppliersModel } from 'src/app/models/customerSupplier.model';

declare var $: any;
declare var Swal: any;
declare var KTMenu: any;

@Component({
  selector: 'app-customer-suppliers',
  templateUrl: './customer-suppliers.component.html',
})
export class CustomerSuppliersComponent {

  public searchForm: UntypedFormGroup;
  public isActive: any = "null";
  public clientUserRoleId: any = 'null';
  public retailPlaceId: number = null;
  public emptyData: boolean = true;
  public users: UserAccountModel[];
  public userRoles: ClientUserRoles[];
  public retailPlaces: RetailPlaceModel[];
  public editFormPhysical: UntypedFormGroup;
  public editFormLegal: UntypedFormGroup;
  public userRoleAddEditId: number = null;
  public isSubmitted: boolean = false;
  public selectedUser: UserAccountModel;
  public addFormPhysical: UntypedFormGroup;
  public addFormLegal: UntypedFormGroup;
  public filterCountryId:number = 0;
  public filterTownId: number = 0;
  public countries: CountryModel[];
  public addEditCountryId: number = null;
  public towns: TownModel[];
  public addEditTownId: number = null;
  public customers:CustomerSuppliersModel[];
  public selectedCustomer: CustomerSuppliersModel;
  public isCustomer:boolean=false;
  public isSupplier:boolean=false;
  public addEditEmployerId:number = null;
  public employers:CustomerSuppliersModel[];
  public nextNumber:number;
  public setCode:string;
  public customCode:boolean;

  //pagination
  public page: number = 1;
  public perPage: number;
  public totalData: number;
  public maxPage: number;
  public pages: number[] = [];
  public offset: number = 5;
  public firstPage: number = 1;
  public lastPage: number;
  public counter: number = 0;
  public firstLoad: boolean = true;

  get f1() { return this.editFormPhysical.controls; }
  get f2() { return this.addFormPhysical.controls; }
  get f3() { return this.addFormLegal.controls; }
  get f4() { return this.editFormLegal.controls; }

  @ViewChild('selectFilterCountryId') selectFilterCountryId: ElementRef;
  @ViewChild('selectFilterTownId') selectFilterTownId: ElementRef;
  @ViewChild('closeEditPhysicalModal') closeEditPhysicalModal: ElementRef;
  @ViewChild('closeEditLegalModal') closeEditLegalModal: ElementRef;
  @ViewChild('closeAddPhysicalModal') closeAddPhysicalModal: ElementRef;
  @ViewChild('closeAddLegalModal') closeAddLegalModal: ElementRef;
  @ViewChild('searchCustomerUsername') searchCustomerUsername: ElementRef;
  @ViewChild('selectAddCountryId') selectAddCountryId: ElementRef;
  @ViewChild('selectAddTownId') selectAddTownId: ElementRef;
  @ViewChild('selectEditCountryId') selectEditCountryId: ElementRef;
  @ViewChild('selectEditTownId') selectEditTownId: ElementRef;
  @ViewChild('selectAddEmployerId') selectAddEmployerId: ElementRef;
  @ViewChild('selectEditEmployerId') selectEditEmployerId: ElementRef;
  @ViewChild('codeAddPhysical') codeAddPhysical: ElementRef;
  @ViewChild('codeAddLegal') codeAddLegal: ElementRef;
  @ViewChild('codeEditPhysical') codeEditPhysical: ElementRef;
  @ViewChild('codeEditLegal') codeEditLegal: ElementRef;

  constructor(
      private formBuilder: UntypedFormBuilder,
      public data: DataService,
      private _changeRef: ChangeDetectorRef,
      private translate: TranslateService,
      private customersRest: CustomerSupplierRestService
  ) {
      this.page = 1;
      this.perPage = 50;
  }

  ngOnInit() {
      KTMenu.createInstances();
      this.createSearchForm();
      this.createAddeditForm();
      this.fetchCountries();
      this.fetchTownsByCountry();
      this.fetchCustomers();
      this.fetchEmployers();
    }

  async fetchCountries() {
      var response = await this.customersRest.getCountries();
      this.countries = response.data;
  }

  async fetchEmployers(){
    var response = await this.customersRest.getCustomerSuppliers(this.data.user.clientId, '', null, null, null, true, this.page, 9999999);
    this.employers = response.data;
  }

  async fetchCustomers(){
      try {
          this.emptyData=false;
          var username = this.searchForm.controls.username.value;
          if(username==null)
              username='';

          var response = await this.customersRest.getCustomerSuppliers(this.data.user.clientId, username, this.filterCountryId, this.filterTownId, null, null, this.page, this.perPage);
          this.customers = response.data;
          this.totalData = response.totalCount;

          if (this.totalData == 0)
              this.emptyData = true;
          else
              this.emptyData = false

          if (this.firstLoad) {
              this.maxPage = response.totalPages;
              this.maxPage = Math.floor(this.maxPage);
              this.firstPage = 1;
              if (this.maxPage > 5)
                  this.lastPage = 5;
              else
                  this.lastPage = this.maxPage;
              this.enablePrevious();

              this.firstLoad = false;
          }
          this.initPages();
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  createSearchForm() {
      this.searchForm = this.formBuilder.group({
          username: [''],
          countryId: [0, Validators.required],
          townId: [0, Validators.required]
      })

      $("#customer_filter_countryId").select2();
      $("#customer_filter_townId").select2();
      var self = this;
      $('#customer_filter_countryId').on("change", function () {
          self.filterCountryId = $(this).val();
          // self.selectFilterCountryId.nativeElement.value = $(this).val();
          self.fetchTownsByCountry(self.filterCountryId);
      });
      $('#customer_filter_townId').on("change", function () {
          self.filterTownId = $(this).val();
          // self.selectFilterCountryId.nativeElement.value = $(this).val();
      });
  }

  async getNextNumber(){
    this.nextNumber = await this.customersRest.getNextNumber(this.data.user.clientId);
    this.setCode=this.nextNumber.toString();
    this.addFormPhysical.controls.code.setValue(this.setCode);
    this.addFormLegal.controls.code.setValue(this.setCode);
    $(this.codeAddPhysical.nativeElement).val(this.setCode);
    $(this.codeAddLegal.nativeElement).val(this.setCode);
  }

  checkIfInvalid(event:KeyboardEvent){
    this.customCode=this.nextNumber!=parseInt(this.setCode);

    var regex = /[^0-9]/g;
    if(regex.test(event.key)){
        if(event.key!='Enter' && event.key!='Tab' && event.key!='Backspace'){
            event.preventDefault();
            return;
        }
    }
  }
  updateCustomCode(newValue:number){
    this.setCode=newValue.toString();
  }

  createAddeditForm() {
      this.addFormPhysical = this.formBuilder.group({
          code: [, Validators.required],
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          idNumber: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13)]],
          phone: [''],
          email: [''],
          addEditCountryId: [null],
          addEditTownId: [null],
          addEditEmployerId: [null],
      })

      $("#physical_add_countryId").select2();
      var self = this;
      $('#physical_add_countryId').on("change", function () {
          self.addEditCountryId = $(this).val();
          self.selectAddCountryId.nativeElement.value = $(this).val();
          self.fetchTownsByCountry(self.addEditCountryId);
      });

      $("#physical_add_townId").select2();
      $('#physical_add_townId').on("change", function () {
          self.addEditTownId = $(this).val();
          self.selectAddTownId.nativeElement.value = $(this).val();
      });

      $("#physical_add_employerId").select2();
      $('#physical_add_employerId').on("change", function () {
          self.addEditEmployerId = $(this).val();
          self.selectAddEmployerId.nativeElement.value = $(this).val();
      });

      this.addFormLegal = this.formBuilder.group({
        code: ['', Validators.required],
        name: ['', Validators.required],
        idNumber: ['', Validators.required],
        address: ['', Validators.required],
        pdvNumber: [''],
        addEditCountryId: [null],
        addEditTownId: [null],
        isSupplier: [null, Validators.required],
        isCustomer: [null, Validators.required],
      });

      $("#legal_add_countryId").select2();
      var self = this;
      $('#legal_add_countryId').on("change", function () {
          self.addEditCountryId = $(this).val();
          self.selectAddCountryId.nativeElement.value = $(this).val();
          self.fetchTownsByCountry(self.addEditCountryId);
      });

      $("#legal_add_townId").select2();
      $('#legal_add_townId').on("change", function () {
          self.addEditTownId = $(this).val();
          self.selectAddTownId.nativeElement.value = $(this).val();
      });

      this.editFormPhysical = this.formBuilder.group({
        code: [, Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        idNumber: ['', Validators.required],
        phone: [''],
        email: [''],
        addEditCountryId: [null],
        addEditTownId: [null],
        addEditEmployerId: [null]
      })

      $("#physical_edit_countryId").select2();
      $("#physical_edit_townId").select2();
      $("#physical_edit_employerId").select2();

      var self = this;
      $('#physical_edit_countryId').on("change", function () {
          self.addEditCountryId = $(this).val();
          self.selectEditCountryId.nativeElement.value = $(this).val();
          self.fetchTownsByCountry(self.addEditCountryId);
      });

      $('#physical_edit_townId').on("change", function () {
          self.addEditTownId = $(this).val();
          self.selectEditTownId.nativeElement.value = $(this).val();
      });
      $('#physical_edit_employerId').on("change", function () {
        self.addEditEmployerId = $(this).val();
        self.selectEditEmployerId.nativeElement.value = $(this).val();
      });

      this.editFormLegal = this.formBuilder.group({
        code: [, Validators.required],
        name: ['', Validators.required],
        address: ['', Validators.required],
        idNumber: ['', Validators.required],
        pdvNumber: [''],
        addEditCountryId: [null],
        addEditTownId: [null],
        isSupplier: [null, Validators.required],
        isCustomer: [null, Validators.required],
      });

      $("#legal_edit_countryId").select2();
      var self = this;
      $('#legal_edit_countryId').on("change", function () {
          self.addEditCountryId = $(this).val();
          self.selectEditCountryId.nativeElement.value = $(this).val();
          self.fetchTownsByCountry(self.addEditCountryId);
      });

      $("#legal_edit_townId").select2();
      $('#legal_edit_townId').on("change", function () {
          self.addEditTownId = $(this).val();
          self.selectAddTownId.nativeElement.value = $(this).val();
      });
  }

  async fetchTownsByCountry(searchCountryId:number|null=null){
      if (searchCountryId != null && searchCountryId != undefined && searchCountryId != 0 && !isNaN(searchCountryId))
          this.towns = await this.customersRest.fetchTownsByCountry(searchCountryId);
      else{
          var response = await this.customersRest.getTowns();
          this.towns  = response.data;
      }
  }

  resetForm() {
      this.isSubmitted = false;
      this.addFormPhysical.controls['code'].setValue("");
      this.addFormPhysical.controls['firstName'].setValue("");
      this.addFormPhysical.controls['lastName'].setValue("");
      this.addFormPhysical.controls['idNumber'].setValue("");
      this.addFormPhysical.controls['email'].setValue("");
      this.addFormPhysical.controls['phone'].setValue("");
      this.addFormPhysical.controls['addEditCountryId'].setValue(null);
      this.addFormPhysical.controls['addEditTownId'].setValue(null);
      this.addFormPhysical.controls['addEditEmployerId'].setValue(null);
      this.addEditCountryId = null; 
      this.addEditTownId = null;
      this.addEditEmployerId = null;
      $('#physical_add_countryId').select2().val(null).trigger("change");
      $('#physical_add_townId').select2().val(null).trigger("change");
      $('#physical_add_employerId').select2().val(null).trigger("change");

      this.addFormLegal.controls['code'].setValue("");
      this.addFormLegal.controls['name'].setValue("");
      this.addFormLegal.controls['address'].setValue("");
      this.addFormLegal.controls['idNumber'].setValue("");
      this.addFormLegal.controls['pdvNumber'].setValue("");
      this.addFormLegal.controls['isSupplier'].setValue(true);
      this.addFormLegal.controls['isCustomer'].setValue(false);
      this.addFormPhysical.controls['addEditCountryId'].setValue(null);
      this.addFormPhysical.controls['addEditTownId'].setValue(null);
      this.addEditCountryId = null;
      this.addEditTownId = null;

      $('#legal_add_countryId').select2().val(null).trigger("change");
      $('#legal_add_townId').select2().val(null).trigger("change");
      $('#addPdvNumber').removeClass("is-invalid");

      $(this.codeAddLegal.nativeElement).removeClass('is-invalid');
      $(this.codeEditLegal.nativeElement).removeClass('is-invalid');
      $(this.codeAddPhysical.nativeElement).removeClass('is-invalid');
      $(this.codeEditPhysical.nativeElement).removeClass('is-invalid');
    }

  async selectCustomer(customer: CustomerSuppliersModel, legal:boolean) {
      this.selectedCustomer = customer;
      try {
        if(legal){
            this.editFormLegal.controls['name'].setValue(this.selectedCustomer.name);
            this.editFormLegal.controls['idNumber'].setValue(this.selectedCustomer.idNumber);
            this.editFormLegal.controls['address'].setValue(this.selectedCustomer.address);
            this.editFormLegal.controls['pdvNumber'].setValue(this.selectedCustomer.pdvNumber);
            this.editFormLegal.controls['isSupplier'].setValue(this.selectedCustomer.isSupplier);
            this.editFormLegal.controls['isCustomer'].setValue(this.selectedCustomer.isCustomer);
            this.editFormLegal.controls['addEditCountryId'].setValue(this.selectedCustomer.countryId);
            $('#legal_edit_countryId').select2().val(this.selectedCustomer.countryId).trigger("change");
            setTimeout(() => {
                $("#legal_edit_townId").val(this.selectedCustomer.townId).trigger("change");
            }, 300);
            $('#editPdvNumber').removeClass("is-invalid");
            this.addEditCountryId = null;
            this.addEditTownId = null;
            this.setCode=this.selectedCustomer.code.toString();
            $(this.codeEditLegal.nativeElement).val(this.setCode);  
            $(this.codeEditLegal.nativeElement).removeClass("is-invalid");  
        }else{
            this.editFormPhysical.controls['firstName'].setValue(this.selectedCustomer.firstName);
            this.editFormPhysical.controls['lastName'].setValue(this.selectedCustomer.lastName);
            this.editFormPhysical.controls['idNumber'].setValue(this.selectedCustomer.idNumber);
            this.editFormPhysical.controls['phone'].setValue(this.selectedCustomer.phone);
            this.editFormPhysical.controls['email'].setValue(this.selectedCustomer.email);
            this.editFormPhysical.controls['addEditCountryId'].setValue(this.selectedCustomer.countryId);
            this.editFormPhysical.controls['addEditEmployerId'].setValue(this.selectedCustomer.employerId);
            $('#physical_edit_countryId').select2().val(this.selectedCustomer.countryId).trigger("change");
            setTimeout(() => {
                $("#physical_edit_townId").val(this.selectedCustomer.townId).trigger("change");
            }, 300);
            $('#physical_edit_employerId').select2().val(this.selectedCustomer.employerId).trigger("change");
            this.addEditCountryId=this.selectedCustomer.countryId;
            this.addEditTownId=this.selectedCustomer.townId;
            this.addEditEmployerId=this.selectedCustomer.employerId;
            this.setCode=this.selectedCustomer.code.toString();
            $(this.codeEditPhysical.nativeElement).val(this.setCode);    
            $(this.codeEditPhysical.nativeElement).removeClass("is-invalid");    
        }
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  searchCustomers(customerName: string) {
      this.searchForm.controls["username"].setValue(customerName);
      this.firstLoad = true;
      this.page = 1;
      this.fetchCustomers();
  }

  resetFilter() {
      this.searchForm.controls['username'].setValue('');

      this.searchForm.controls['countryId'].setValue('0');
      this.searchForm.controls['townId'].setValue('0');
      this.filterCountryId = 0;
      this.filterTownId = 0;
      this.firstLoad = true;
      this.page = 1;
      $('#customer_filter_countryId').val(0).trigger("change")
      $('#customer_filter_townId').val(0).trigger("change")
      this.searchCustomerUsername.nativeElement.value = "";
      this.fetchCustomers();
  }

  async updateCustomer(legal:boolean) {
      this.isSubmitted = false;
      if(legal){
        if (this.editFormLegal.invalid) {
            this.isSubmitted = true;
        }
        if(!this.validateEditForm(true)){
            this.isSubmitted=true;
        }
      }else{
        if (this.editFormPhysical.invalid) {
            this.isSubmitted = true;
        }
        if(!this.validateEditForm(false)){
            this.isSubmitted=true;
        }
      }
      if (this.isSubmitted == true) {
          return;
      }

      var obj = this.createEditObject(legal);

      try {
          await this.customersRest.updateCustomerSupplier(obj);
          this.customCode=false;
          this.setCode=null;

          if(legal)
            this.closeEditLegalModal.nativeElement.click();
          else
            this.closeEditPhysicalModal.nativeElement.click();
          toastr.options = this.data.toastrOptions;
          toastr.success(this.translate.instant('_Updated_customer_text'), this.translate.instant("_Success"));
          this.fetchCustomers();
      }
      catch (err) {
          toastr.options = this.data.toastrOptions;
          toastr.error(err.error['Message'], this.translate.instant("_Error"));
      }
  }

  createEditObject(legal:boolean) {
      var obj = new CustomerSuppliersModel();
      obj.id = this.selectedCustomer.id;

      if(legal){
        obj.idNumber = this.editFormLegal.controls.idNumber.value;
        obj.name = this.editFormLegal.controls.name.value;
        obj.address = this.editFormLegal.controls.address.value;
        obj.pdvNumber = this.editFormLegal.controls.pdvNumber.value;
        obj.isCustomer = this.isCustomer;
        obj.isSupplier = this.isSupplier;
        obj.isCompany=true;
      }
      else{
        obj.firstName = this.editFormPhysical.controls.firstName.value;
        obj.lastName = this.editFormPhysical.controls.lastName.value;
        obj.name = this.editFormPhysical.controls.firstName.value + " " + this.editFormPhysical.controls.lastName.value;
        obj.phone = this.editFormPhysical.controls.phone.value;
        obj.email = this.editFormPhysical.controls.email.value;
        obj.idNumber = this.editFormPhysical.controls.idNumber.value;
        obj.isCustomer = true;
        obj.isCompany=false;

        if(this.addEditEmployerId != 0 && this.addEditEmployerId != undefined && this.addEditEmployerId != 0 && !isNaN(this.addEditEmployerId))
            obj.employerId = this.addEditEmployerId;
      }
      
      if(this.addEditCountryId != 0 && this.addEditCountryId != undefined && this.addEditCountryId != 0 && !isNaN(this.addEditCountryId))
        obj.countryId = this.addEditCountryId;
      if(this.addEditTownId != 0 && this.addEditTownId != undefined && this.addEditTownId != 0 && !isNaN(this.addEditTownId))
        obj.townId = this.addEditTownId;
      obj.clientId = this.data.user.clientId;

      if(this.selectedCustomer.code!=parseInt(this.setCode)){
        obj.code=parseInt(this.setCode);
        obj.customCode=true;
      }else{
        obj.code=this.selectedCustomer.code;
      }

      return obj;
  }

  async addCustomer(legal:boolean) {
      this.isSubmitted = false;
      if(legal){
        if (this.addFormLegal.invalid) {
            this.isSubmitted = true;
        }
        if(!this.validateAddForm(true)){
            this.isSubmitted=true;
        }
      }else{
        if (this.addFormPhysical.invalid) {
            this.isSubmitted = true;
        }
        if(!this.validateAddForm(false)){
            this.isSubmitted=true;
        }
      }
      if (this.isSubmitted == true) {
          return;
      }

      var obj = this.createAddObject(legal);

      try {
          await this.customersRest.createCustomerSupplier(obj);
          if(legal)
            this.closeAddLegalModal.nativeElement.click();
          else
            this.closeAddPhysicalModal.nativeElement.click();

          this.customCode=false;
          this.setCode=null;
          toastr.options = this.data.toastrOptions;
          toastr.success(this.translate.instant('_Added_user_text'), this.translate.instant("_Success"));
          this.fetchCustomers();
      }
      catch (err) {
          toastr.options = this.data.toastrOptions;
          toastr.error(err.error['Message'], this.translate.instant("_Error"));
      }
  }
  validateAddForm(legal:boolean){
    var input = legal?this.codeAddLegal:this.codeAddPhysical;

    if(this.setCode==null || this.setCode==undefined || this.setCode.length==0){
        $(input.nativeElement).addClass("is-invalid");
        return false;
    }
    else{
        $(input.nativeElement).removeClass("is-invalid");
        return true;  
    }
  }
  validateEditForm(legal:boolean){
    var input = legal?this.codeEditLegal:this.codeEditPhysical;

    if(this.setCode==null || this.setCode==undefined || this.setCode.length==0){
        $(input.nativeElement).addClass("is-invalid");
        return false;
    }
    else{
        $(input.nativeElement).removeClass("is-invalid");
        return true;  
    } 
  }

  createAddObject(legal:boolean) {
      var obj = new CustomerSuppliersModel();

      if(legal){
        obj.address = this.addFormLegal.controls.address.value;
        obj.name = this.addFormLegal.controls.name.value;
        obj.idNumber = this.addFormLegal.controls.idNumber.value;
        obj.pdvNumber = this.addFormLegal.controls.pdvNumber.value;
        obj.isCustomer = this.isCustomer;
        obj.isSupplier = this.isSupplier;
        obj.isCompany=true;
      }else{
        obj.firstName = this.addFormPhysical.controls.firstName.value;
        obj.lastName = this.addFormPhysical.controls.lastName.value;
        obj.name = obj.firstName + " " + obj.lastName;
        obj.email = this.addFormPhysical.controls.email.value;
        obj.phone = this.addFormPhysical.controls.phone.value;
        obj.idNumber=this.addFormPhysical.controls.idNumber.value;
        obj.isCustomer=true;
        obj.isCompany=false;

        if(this.addEditEmployerId != 0 && this.addEditEmployerId != undefined && this.addEditEmployerId != 0 && !isNaN(this.addEditEmployerId))
            obj.employerId = this.addEditEmployerId;
      }

      if(this.addEditCountryId != 0 && this.addEditCountryId != undefined && this.addEditCountryId != 0 && !isNaN(this.addEditCountryId))
        obj.countryId = this.addEditCountryId;
      if(this.addEditTownId != 0 && this.addEditTownId != undefined && this.addEditTownId != 0 && !isNaN(this.addEditTownId))
        obj.townId = this.addEditTownId;
      obj.clientId = this.data.user.clientId;

      if(parseInt(this.setCode)!=this.nextNumber){
        obj.code=parseInt(this.setCode);
        obj.customCode=true;
      }
      else{
        obj.code=this.nextNumber;
        obj.customCode=false;
      } 

      return obj;
  }

  closeAddModal(legal:boolean) {
      var self = this;
      var question_text = this.translate.instant("_Cancel_question_text");
      var yes_text = this.translate.instant("_Yes_cancel_text");
      var no_text = this.translate.instant("_No_return_text");
      Swal.fire({
          html: question_text,
          icon: "warning",
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: yes_text,
          cancelButtonText: no_text,
          customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: 'btn btn-secondary'
          }
      }).then(async (result) => {
          if (result.isConfirmed) {
              if(legal){
                self.closeAddLegalModal.nativeElement.click();
              }
              else self.closeAddPhysicalModal.nativeElement.click();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
              self.resetFilter();
          }
      });
  }

  closeEditModal(legal:boolean) {
      var self = this;
      var question_text = this.translate.instant("_Cancel_question_text");
      var yes_text = this.translate.instant("_Yes_cancel_text");
      var no_text = this.translate.instant("_No_return_text");
      Swal.fire({
          html: question_text,
          icon: "warning",
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: yes_text,
          cancelButtonText: no_text,
          customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: 'btn btn-secondary'
          }
      }).then(async (result) => {
          if (result.isConfirmed) {
              if(legal)
                self.closeEditLegalModal.nativeElement.click();
              else
                self.closeEditPhysicalModal.nativeElement.click();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
              self.resetFilter();
          }
      });
  }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage=1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchCustomers();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage>this.firstPage+this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage=this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                    if(this.maxPage>this.firstPage+this.offset)
                        this.lastPage = this.firstPage + this.offset;
                    else
                        this.lastPage=this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else 
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchCustomers();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchCustomers();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchCustomers();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchCustomers();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}

