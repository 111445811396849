<div class="toolbar" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1" style="font-size:22px!important;">{{'_Warehouses' | translate}}</h1>
            <span class="h-20px border-gray-600 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/home']" class="text-gray-600 text-hover-primary" style="font-size:16px!important">{{'_Home' | translate}}</a>
                </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-600 w-5px h-2px" style="font-size:16px!important"></span>
                </li>
                <li class="breadcrumb-item text-gray-600" style="font-size:16px!important">{{'_Warehouses' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl" style="padding:0px;margin-top:25px;margin-bottom:25px;">
        <div class="card" >
            <div class="card-header border-0 pt-6">
                <div class="card-title">
                    <!--    <div class="d-flex align-items-center position-relative my-1">
                        <span class="svg-icon svg-icon-1 position-absolute ms-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="black" />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="black" />
                            </svg>
                        </span>
                       <input (input)="searchGroups(searchGroupName.value)" #searchGroupName type="text"
                            data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15"
                            placeholder="{{'_Search_groups' | translate}}" />
                    </div> -->
                </div>
                <div class="card-toolbar">
                    <div class="card-title">
                        <div class="d-flex align-items-center position-relative my-1">
                            <span class="svg-icon svg-icon-1 position-absolute ms-6" *ngIf="data.currentMode=='light'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                        transform="rotate(45 17.0365 15.1223)" fill="black" />
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="black" />
                                </svg>
                            </span>
                            <span class="svg-icon svg-icon-1 position-absolute ms-6" *ngIf="data.currentMode=='dark'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                        transform="rotate(45 17.0365 15.1223)" fill="gray" />
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="gray" />
                                </svg>
                            </span>
                            <input (change)="searchWarehouses(searchWarehouseName.value)" #searchWarehouseName type="text"
                                data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15"
                                placeholder="{{'_Search_by_name' | translate}}" />
                        </div>
                    </div>
                    <div class="d-flex justify-content-end" data-kt-group-type-table-toolbar="base">                        
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#kt_modal_add_warehouse" (click)="resetForm()"><i class="fas fa-plus"></i>{{'_Add_warehouse' |
                            translate}}</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_group-types_table"
                    *ngIf="!emptyData">
                    <thead>
                        <tr class="text-start text-gray-900 fw-bolder fs-6 text-uppercase gs-0">
                            <th style="width:40%;font-size:14px;padding-left:10px;">{{'_Name' | translate}}</th>
                            <th style="width:40%;font-size:14px;">{{'_Address' | translate}}</th>
                            <th class="text-center" style="width:20%;font-size:14px;">{{'_Actions' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-1000">
                        <tr *ngFor="let a of warehouses;let r=index" [ngClass]="{ 'evenTableRowColor': r%2==0, 'oddTableRowColor':r%2!=0 }">
                            <td style="width:40%;padding-left:10px;padding-top:8px;padding-bottom:8px;">{{a.name}}</td>
                            <td style="width:40%;padding-top:8px;padding-bottom:8px;">{{a.address}}</td>
                            <td class="text-center" style="width:20%;padding-top:8px;padding-bottom:8px;">
                                <a (click)="selectWarehouse(a)" class="btn btn-icon btn-warning fs-8"
                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_edit_warehouse">                                    
                                    <i class="fa fa-pencil-alt fs-4" aria-hidden="true"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row" *ngIf="!emptyData">
                    <div
                        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="dataTables_length" id="kt_customers_table_length"><label><select
                                    name="kt_customers_table_length" aria-controls="kt_customers_table"
                                    class="form-select form-select-sm form-select-solid" [(ngModel)]="perPage"
                                    (change)="perPageHandler()">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select></label></div>
                    </div>
                    <div
                        class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div class="dataTables_paginate paging_simple_numbers" id="kt_customers_table_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous disabled"
                                    id="kt_customers_table_previous"><a (click)="prevpage()"
                                        aria-controls="kt_customers_table" data-dt-idx="0" tabindex="0"
                                        class="page-link" style="cursor:pointer"><i class="previous"></i></a></li>

                                <div *ngFor="let p of pages">
                                    <li class="paginate_button page-item active" *ngIf="page==p">
                                        <a aria-controls="kt_customers_table" data-dt-idx="2" tabindex="0"
                                            class="page-link" (click)="goToPage(p)" style="cursor:pointer">{{p}}</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="page!=p">
                                        <a aria-controls="kt_customers_table" data-dt-idx="2" tabindex="0"
                                            class="page-link" (click)="goToPage(p)" style="cursor:pointer">{{p}}</a>
                                    </li>
                                </div>
                                <li class="paginate_button page-item disabled" id="kt_customers_table_morePages"
                                    *ngIf="maxPage>offset"><a (click)="morePages()" aria-controls="kt_customers_table"
                                        data-dt-idx="0" tabindex="0" class="page-link" style="cursor:pointer">...</a>
                                </li>
                                <li class="paginate_button page-item next" id="kt_customers_table_next"><a
                                        (click)="nextPage()" aria-controls="kt_customers_table" data-dt-idx="5"
                                        tabindex="0" class="page-link" style="cursor:pointer"><i class="next"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="emptyData">
                    <div class="text-center">
                        <h1 class="fw-bolder mb-5">{{'_No_results' | translate}}</h1>
                        <div class="separator separator-dashed border-danger opacity-25 mb-5"></div>
                        <div class="mb-9 text-dark">
                            {{'_No_results_text' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_add_warehouse" tabindex="-1" aria-hidden="true" #closeAddWarehouseModal>
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <form class="form" action="#" id="kt_modal_add_warehouse_form" [formGroup]="formGroup">
                <div class="modal-header" id="kt_modal_add_warehouse_header">
                    <h2 class="fw-bolder">{{'_Add_warehouse' | translate}}</h2>
                    <div id="kt_modal_add_warehouse_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                        (click)="closeAddModal()">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_add_warehouse_scroll" data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_add_warehouse_header"
                        data-kt-scroll-wrappers="#kt_modal_add_warehouse_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Name' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="name"
                                value="" formControlName="name" required
                                [ngClass]="{ 'is-invalid': isSubmitted && f.name.errors }" />
                        </div>
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Address' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="address"
                                value="" formControlName="address" required
                                [ngClass]="{ 'is-invalid': isSubmitted && f.address.errors }" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer flex-center">
                    <button type="reset" id="kt_modal_add_warehouse_cancel" (click)="closeAddModal()"
                        class="btn btn-light me-3">{{'_Discard' | translate}}</button>
                    <button type="submit" id="kt_modal_add_warehouse_submit" class="btn btn-primary" (click)="addWarehouse()">
                        <span class="indicator-label">{{'_Submit' | translate}}</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_edit_warehouse" tabindex="-1" aria-hidden="true" #closeEditWarehouseModal>
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <form class="form" action="#" id="kt_modal_edit_warehouse_form" [formGroup]="formGroup">
                <div class="modal-header" id="kt_modal_edit_warehouse_header">
                    <h2 class="fw-bolder">{{'_Edit_warehouse' | translate}}</h2>
                    <div id="kt_modal_edit_warehouse_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                        (click)="closeEditModal()">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_edit_warehouse_scroll" data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_edit_warehouse_header"
                        data-kt-scroll-wrappers="#kt_modal_edit_warehouse_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Name' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="name"
                                value="" formControlName="name" required
                                [ngClass]="{ 'is-invalid': isSubmitted && f.name.errors }" />
                        </div>
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Address' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="address"
                                value="" formControlName="address" required
                                [ngClass]="{ 'is-invalid': isSubmitted && f.address.errors }" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer flex-center">
                    <button type="reset" id="kt_modal_edit_warehouse_cancel" (click)="closeEditModal()"
                        class="btn btn-light me-3">{{'_Discard' | translate}}</button>
                    <button type="submit" id="kt_modal_edit_warehouse_submit" class="btn btn-primary" (click)="updateWarehouse()">
                        <span class="indicator-label">{{'_Submit' | translate}}</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<ng-http-loader [spinner]="'sk-wave'"></ng-http-loader>