export class SoldArticleModel{
    id:number;
    code:string;
    name:string;
    quantity:number;
    basePrice:number;
    totalBasePrice:number;

    discount1Percentage:number;
    discount1Amount:number;
    totalDiscount1Amount:number;
    postDiscount1Price:number;
    totalPostDiscount1Price:number;
    discount2Percentage:number;
    discount2Amount:number;
    totalDiscount2Amount:number;
    postDiscountPrice:number;
    totalPostDiscountPrice:number;
    combinedDiscountAmount:number;
    combinedDiscountPercentage:number;
    totalCombinedDiscountAmount:number;    
    
    taxId:number;
    taxName:string;
    taxPercentage:number;
    taxAmount:number;
    totalTaxAmount:number;
    finalPrice:number;
    totalPrice:number;

    isNormative:boolean;
    measureShort:string;
    displayBase:number;
    displayFinal:number;
    displayTotal:number;
    nc:number;
    mpc:number;
    g:number;
    gt:number;

    uniqueItemId:string;
}