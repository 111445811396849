<div class="toolbar" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1" style="font-size:22px!important;">{{'_CustomersAndSuppliers' | translate}}</h1>
            <span class="h-20px border-gray-600 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-gray-600">
                    <a [routerLink]="['/home']" class="text-gray-600 text-hover-primary" style="font-size:16px!important">{{'_Home' | translate}}</a>
                </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-600 w-5px h-2px" style="font-size:16px!important"></span>
                </li>
                <li class="breadcrumb-item text-gray-600" style="font-size:16px!important">{{'_CustomersAndSuppliers' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl" style="padding:0px;margin-top:25px;margin-bottom:25px;">
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title">
                    <div class="d-flex align-items-center position-relative my-1">
                        <span class="svg-icon svg-icon-1 position-absolute ms-6" *ngIf="data.currentMode=='light'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="black" />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="black" />
                            </svg>
                        </span>
                        <span class="svg-icon svg-icon-1 position-absolute ms-6" *ngIf="data.currentMode=='dark'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="gray" />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="gray" />
                            </svg>
                        </span>
                        <input (input)="searchCustomers(searchCustomerUsername.value)" #searchCustomerUsername type="text"
                            data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15"
                            placeholder="{{'_Search_customers' | translate}}" />
                    </div>
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary me-3" data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end">
                            <span class="svg-icon svg-icon-2"  *ngIf="data.currentMode=='light'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                        fill="black"></path>
                                </svg>
                            </span>
                            <span class="svg-icon svg-icon-2"  *ngIf="data.currentMode=='dark'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                        fill="white"></path>
                                </svg>
                            </span>
                            Filter
                        </button>
                        <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true"
                            id="kt-filter-user">
                            <div class="px-7 py-5">
                                <div class="fs-4 text-dark fw-bolder">{{'_Filter_options' | translate}}</div>
                            </div>
                            <div class="separator border-gray-200"></div>
                            <div class="px-7 py-5">
                                <form [formGroup]="searchForm">
                                    <div class="mb-10">
                                        <label class="form-label fs-5 fw-bold mb-3">{{'_Country' |
                                            translate}}:</label>
                                        <select #selectFilterCountryId class="form-select form-select-solid fw-bolder"
                                            data-placeholder="Odaberite državu"
                                            data-allow-clear="true" data-kt-group-type-table-filter="month"
                                            id="customer_filter_countryId" data-dropdown-parent="#kt-filter-user"
                                            formControlName="countryId" [(ngModel)]="filterCountryId" name="countryId">
                                            <option></option>
                                            <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
                                        </select>
                                    </div>  
                                    <div class="mb-10">
                                        <label class="form-label fs-5 fw-bold mb-3">{{'_Town' |
                                            translate}}:</label>
                                        <select #selectFilterTownId class="form-select form-select-solid fw-bolder"
                                            data-placeholder="Odaberite grad"
                                            data-allow-clear="true" data-kt-group-type-table-filter="month"
                                            id="customer_filter_townId" data-dropdown-parent="#kt-filter-user"
                                            formControlName="townId" [(ngModel)]="filterTownId" name="townId">
                                            <option></option>
                                            <option *ngFor="let t of towns" [value]="t.id">{{t.name}}</option>
                                        </select>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button type="reset" class="btn btn-light btn-active-light-primary me-2"
                                            data-kt-menu-dismiss="true" data-kt-group-type-table-filter="reset"
                                            (click)="resetFilter()">{{'_Reset' | translate}}</button>
                                        <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true"
                                            data-kt-group-type-table-filter="filter"
                                            (click)="searchCustomers(searchCustomerUsername.value)">{{'_Apply' |
                                            translate}}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                            <button type="button" class="btn btn-primary  me-3" data-bs-toggle="modal"
                            data-bs-target="#kt_modal_add_physical" (click)="resetForm()" (click)="getNextNumber()" id="addPhysicalButton"><i class="fas fa-plus"></i>{{'_Add_physical' |
                            translate}}</button>
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#kt_modal_add_legal" (click)="resetForm()" (click)="getNextNumber()" id="addCustomerButton"><i class="fas fa-plus"></i>{{'_Add_legal' |
                            translate}}</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_customers_table" *ngIf="!emptyData">
                    <thead>
                        <tr class="text-start text-gray-900 fw-bolder fs-6 text-uppercase gs-0">
                            <th style="width:5%;font-size:14px;padding-left:10px;">{{'_Code' | translate}}</th>
                            <th style="width:15%;font-size:14px;">{{'_Name' | translate}}</th>
                            <th style="width:15%;font-size:14px;">{{'_ID_Number' | translate}}</th>
                            <th style="width:13%;font-size:14px;">{{'_Country' | translate}}</th>
                            <th style="width:11%;font-size:14px;">{{'_Town' | translate}}</th>
                            <th style="width:10%;font-size:14px;">{{'_Is_Customer' | translate}}</th>
                            <th style="width:10%;font-size:14px;">{{'_Is_Supplier' | translate}}</th>
                            <th style="width:10%;font-size:14px;">{{'_Type' | translate}}</th>
                            <th class="text-center" style="font-size:14px;">{{'_Actions' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-1000">
                        <tr *ngFor="let a of customers;let r=index" [ngClass]="{ 'evenTableRowColor': r%2==0, 'oddTableRowColor':r%2!=0 }">
                            <td style="width:5%;padding-left:10px;padding-top:8px;padding-bottom:8px;">{{a.code}}</td>
                            <td style="width:15%;padding-top:8px;padding-bottom:8px;">{{a.name}}</td>
                            <td style="width:15%;padding-top:8px;padding-bottom:8px;">{{a.idNumber}}</td>
                            <td style="width:13%;padding-top:8px;padding-bottom:8px;">{{a.countryName}}</td>
                            <td style="width:11%;padding-top:8px;padding-bottom:8px;">{{a.townName}}</td>
                            <td *ngIf="a.isCustomer" style="width:10%;padding-top:8px;padding-bottom:8px;">{{'_Yes_text' |translate}}</td>
                            <td *ngIf="!a.isCustomer" style="width:10%;padding-top:8px;padding-bottom:8px;">{{'_No_text' |translate}}</td>
                            <td *ngIf="a.isSupplier" style="width:10%;padding-top:8px;padding-bottom:8px;">{{'_Yes_text' |translate}}</td>
                            <td *ngIf="!a.isSupplier" style="width:10%;padding-top:8px;padding-bottom:8px;">{{'_No_text' |translate}}</td>
                            <td *ngIf="!a.isCompany" style="width:10%;padding-top:8px;padding-bottom:8px;">{{'_Physical' |translate}}</td>
                            <td *ngIf="a.isCompany" style="width:10%;padding-top:8px;padding-bottom:8px;">{{'_Legal' |translate}}</td>
                            <td class="text-center" style="padding-top:8px;padding-bottom:8px;">
                                <a *ngIf="!a.isCompany" (click)="selectCustomer(a, false)" class="btn btn-icon btn-warning fs-8"
                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_edit_physical">
                                    <i class="fa fa-pencil-alt fs-4" aria-hidden="true"></i>
                                </a>
                                <a *ngIf="a.isCompany" (click)="selectCustomer(a, true)" class="btn btn-icon btn-warning fs-8"
                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_edit_legal">
                                    <i class="fa fa-pencil-alt fs-4" aria-hidden="true"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row" *ngIf="!emptyData">
                    <div
                        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="dataTables_length" id="kt_customers_table_length"><label><select
                                    name="kt_customers_table_length" aria-controls="kt_customers_table"
                                    class="form-select form-select-sm form-select-solid" [(ngModel)]="perPage"
                                    (change)="perPageHandler()">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select></label></div>
                    </div>
                    <div
                        class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div class="dataTables_paginate paging_simple_numbers" id="kt_customers_table_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous disabled"
                                    id="kt_customers_table_previous"><a (click)="prevpage()"
                                        aria-controls="kt_customers_table" data-dt-idx="0" tabindex="0"
                                        class="page-link" style="cursor:pointer"><i class="previous"></i></a></li>

                                <div *ngFor="let p of pages">
                                    <li class="paginate_button page-item active" *ngIf="page==p">
                                        <a aria-controls="kt_customers_table" data-dt-idx="2" tabindex="0"
                                            class="page-link" (click)="goToPage(p)" style="cursor:pointer">{{p}}</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="page!=p">
                                        <a aria-controls="kt_customers_table" data-dt-idx="2" tabindex="0"
                                            class="page-link" (click)="goToPage(p)" style="cursor:pointer">{{p}}</a>
                                    </li>
                                </div>
                                <li class="paginate_button page-item disabled" id="kt_customers_table_morePages"
                                    *ngIf="maxPage>offset"><a (click)="morePages()" aria-controls="kt_customers_table"
                                        data-dt-idx="0" tabindex="0" class="page-link" style="cursor:pointer">...</a>
                                </li>
                                <li class="paginate_button page-item next" id="kt_customers_table_next"><a
                                        (click)="nextPage()" aria-controls="kt_customers_table" data-dt-idx="5"
                                        tabindex="0" class="page-link" style="cursor:pointer"><i class="next"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="emptyData">
                    <div class="text-center">
                        <h1 class="fw-bolder mb-5">{{'_No_results' | translate}}</h1>
                        <div class="separator separator-dashed border-danger opacity-25 mb-5"></div>
                        <div class="mb-9 text-dark">
                            {{'_No_results_text' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_add_physical" tabindex="-1" aria-hidden="true" #closeAddPhysicalModal>
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <form class="form" action="#" id="kt_modal_add_physical_form" [formGroup]="addFormPhysical">
                <div class="modal-header" id="kt_modal_add_physical_header">
                    <h2 class="fw-bolder">{{'_Add_physical' | translate}}</h2>
                    <div id="kt_modal_add_physical_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                         (click)="closeAddModal(false)">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_add_physical_scroll" data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_add_physical_header"
                        data-kt-scroll-wrappers="#kt_modal_add_physical_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Code' | translate}}</label>
                            <input type="number" min="0" class="form-control form-control-solid" placeholder=""
                                name="code" formControlName="code" [ngModel]="setCode" (keydown)="checkIfInvalid($event)" 
                                (input)="updateCustomCode(codeAddPhysical.value)" required #codeAddPhysical/>
                        </div>
                        <div class="row g-9 mb-7">
                            <div class="col-md-6 fv-row">
                                <label class="required fs-6 fw-bold mb-2">{{'_First_name' | translate}}</label>
                                <input type="text" class="form-control form-control-solid" placeholder=""
                                    name="firstName" value="" formControlName="firstName" required
                                    [ngClass]="{ 'is-invalid': isSubmitted && f2.firstName.errors }" />
                            </div>
                            <div class="col-md-6 fv-row">
                                <label class="required fs-6 fw-bold mb-2">{{'_Last_name' | translate}}</label>
                                <input type="text" class="form-control form-control-solid" placeholder=""
                                    name="lastName" value="" formControlName="lastName" required
                                    [ngClass]="{ 'is-invalid': isSubmitted && f2.lastName.errors }" />
                            </div>
                        </div>
                        <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold mb-2 required">{{'_JMBG' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="idNumber"
                                value="" formControlName="idNumber"
                                [ngClass]="{ 'is-invalid': isSubmitted && f2.idNumber.errors }" />
                        </div>
                        <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold mb-2">{{'_Email' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="email"
                                value="" formControlName="email"
                                [ngClass]="{ 'is-invalid': isSubmitted && f2.email.errors }" />
                        </div>
                        <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold mb-2">{{'_Phone' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="phone"
                                value="" formControlName="phone"
                                [ngClass]="{ 'is-invalid': isSubmitted && f2.phone.errors }" />
                        </div>
                        <div class="row g-9 mb-7">
                            <div class="col-md-6 fv-row">
                                <label class="form-label fs-6 fw-bold mb-2">{{'_Country' |
                                    translate}}:</label>
                                <select #selectAddCountryId class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite državu"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="physical_add_countryId" data-dropdown-parent="#kt_modal_add_physical_form"
                                    formControlName="addEditCountryId" [(ngModel)]="addEditCountryId" name="addEditCountryId">
                                    <option></option>
                                    <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-6 fv-row">
                                <label class="form-label fs-6 fw-bold mb-2">{{'_Town' |
                                    translate}}:</label>
                                <select #selectAddTownId class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite grad"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="physical_add_townId" data-dropdown-parent="#kt_modal_add_physical_form"
                                    formControlName="addEditTownId" [(ngModel)]="addEditTownId" name="addEditTownId">
                                    <option></option>
                                    <option *ngFor="let t of towns" [value]="t.id">{{t.name}}</option>
                                </select>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="form-label fs-6 fw-bold mb-2">{{'_Employer' |
                                    translate}}:</label>
                                <select #selectAddEmployerId class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite poslodavca"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="physical_add_employerId" data-dropdown-parent="#kt_modal_add_physical_form"
                                    formControlName="addEditEmployerId" [(ngModel)]="addEditEmployerId" name="addEditEmployerId">
                                    <option></option>
                                    <option *ngFor="let e of employers" [value]="e.id">{{e.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer flex-center">
                    <button type="button" id="kt_modal_add_user_cancel" (click)="closeAddModal(false)"
                        class="btn btn-light me-3">{{'_Discard' | translate}}</button>
                    <button type="submit" id="kt_modal_add_user_submit" class="btn btn-primary" (click)="addCustomer(false)">
                        <span class="indicator-label">{{'_Submit' | translate}}</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_add_legal" tabindex="-1" aria-hidden="true" #closeAddLegalModal>
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <form class="form" action="#" id="kt_modal_add_legal_form" [formGroup]="addFormLegal">
                <div class="modal-header" id="kt_modal_add_legal_header">
                    <h2 class="fw-bolder">{{'_Add_legal' | translate}}</h2>
                    <div id="kt_modal_add_legal_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                         (click)="closeAddModal(true)">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_add_legal_scroll" data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_add_legal_header"
                        data-kt-scroll-wrappers="#kt_modal_add_legal_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Code' | translate}}</label>
                            <input type="number" min="0" class="form-control form-control-solid" placeholder=""
                                name="code" formControlName="code" [ngModel]="setCode" (keydown)="checkIfInvalid($event)" 
                                (input)="updateCustomCode(codeAddLegal.value)" required #codeAddLegal/>
                        </div>
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Name' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder=""
                                name="name" value="" formControlName="name" required
                                [ngClass]="{ 'is-invalid': isSubmitted && f3.name.errors }" />
                        </div>
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_ID_Number' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="idNumber"
                                value="" formControlName="idNumber" minlength="13" maxlength="13"
                                [ngClass]="{ 'is-invalid': isSubmitted && f3.idNumber.errors }" />
                            <small *ngIf="isSubmitted && f3.idNumber.value.length!=13" style="color:red">ID number must be 13 characters</small>
                        </div>
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Address' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder=""
                                name="address" value="" formControlName="address" required
                                [ngClass]="{ 'is-invalid': isSubmitted && f3.address.errors }" />
                        </div>
                        <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold mb-2">{{'_PDV_number' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="pdvNumber"
                                value="" formControlName="pdvNumber" id="addPdvNumber"/>
                        </div>
                        <div class="row g-9 mb-7">
                            <div class="col-md-6 fv-row">
                                <label class="form-label fs-6 fw-bold mb-2">{{'_Country' |
                                    translate}}:</label>
                                <select #selectAddCountryIdLegal class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite državu"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="legal_add_countryId" data-dropdown-parent="#kt_modal_add_legal_form"
                                    formControlName="addEditCountryId" [(ngModel)]="addEditCountryId" name="addEditCountryId">
                                    <option></option>
                                    <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-6 fv-row">
                                <label class="form-label fs-6 fw-bold mb-2">{{'_Town' |
                                    translate}}:</label>
                                <select #selectAddTownIdLegal class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite grad"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="legal_add_townId" data-dropdown-parent="#kt_modal_add_legal_form"
                                    formControlName="addEditTownId" [(ngModel)]="addEditTownId" name="addEditTownId">
                                    <option></option>
                                    <option *ngFor="let t of towns" [value]="t.id">{{t.name}}</option>
                                </select>
                            </div>
                            <div class="row g-9 mb-7">
                                <div class="col-md-6 fv-row">
                                    <label class="fs-5 fw-bold form-label mb-5">{{'_Is_Customer' | translate}}:</label>
                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                            <input class="form-check-input" type="radio" [value]="true"
                                                formControlName="isCustomer" [(ngModel)]="isCustomer" name="isCustomer"/>
                                            <span class="form-check-label text-gray-600 fw-bold">{{'_Yes_text' |
                                                translate}}</span>
                                        </label>
                                        <label
                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                            <input class="form-check-input" type="radio" [value]="false"
                                                formControlName="isCustomer" [(ngModel)]="isCustomer" name="isCustomer"/>
                                            <span class="form-check-label text-gray-600 fw-bold">{{'_No_text' |
                                                translate}}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 fv-row">
                                    <label class="fs-5 fw-bold form-label mb-5">{{'_Is_Supplier' | translate}}:</label>
                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                            <input class="form-check-input" type="radio" [value]="true"
                                                formControlName="isSupplier" [(ngModel)]="isSupplier" name="isSupplier"/>
                                            <span class="form-check-label text-gray-600 fw-bold">{{'_Yes_text' |
                                                translate}}</span>
                                        </label>
                                        <label
                                            class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                            <input class="form-check-input" type="radio" [value]="false"
                                                formControlName="isSupplier" [(ngModel)]="isSupplier" name="isSupplier"/>
                                            <span class="form-check-label text-gray-600 fw-bold">{{'_No_text' |
                                                translate}}</span>
                                        </label>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer flex-center">
                    <button type="button" id="kt_modal_add_user_cancel" (click)="closeAddModal(true)"
                        class="btn btn-light me-3">{{'_Discard' | translate}}</button>
                    <button type="submit" id="kt_modal_add_user_submit" class="btn btn-primary" (click)="addCustomer(true)">
                        <span class="indicator-label">{{'_Submit' | translate}}</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_edit_physical" tabindex="-1" aria-hidden="true" #closeEditPhysicalModal>
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <form class="form" action="#" id="kt_modal_edit_physical_form" [formGroup]="editFormPhysical">
                <div class="modal-header" id="kt_modal_edit_physical_header">
                    <h2 class="fw-bolder">{{'_Edit' | translate}}</h2>
                    <div id="kt_modal_edit_physical_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                        (click)="closeEditModal(false)">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_edit_physical_scroll" data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_edit_physical_header"
                        data-kt-scroll-wrappers="#kt_modal_edit_physical_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Code' | translate}}</label>
                            <input type="number" min="0" class="form-control form-control-solid" placeholder=""
                                name="code" formControlName="code" [ngModel]="setCode" (keydown)="checkIfInvalid($event)" 
                                (input)="updateCustomCode(codeEditPhysical.value)" required #codeEditPhysical/>
                        </div>
                        <div class="row g-9 mb-7">
                            <div class="col-md-6 fv-row">
                                <label class="required fs-6 fw-bold mb-2">{{'_First_name' | translate}}</label>
                                <input type="text" class="form-control form-control-solid" placeholder=""
                                    name="firstName" value="" formControlName="firstName" required
                                    [ngClass]="{ 'is-invalid': isSubmitted && f1.firstName.errors }" />
                            </div>
                            <div class="col-md-6 fv-row">
                                <label class="required fs-6 fw-bold mb-2">{{'_Last_name' | translate}}</label>
                                <input type="text" class="form-control form-control-solid" placeholder=""
                                    name="lastName" value="" formControlName="lastName" required
                                    [ngClass]="{ 'is-invalid': isSubmitted && f1.lastName.errors }" />
                            </div>
                        </div>
                        <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold mb-2">{{'_JMBG' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="idNumber"
                                value="" minlength="13" maxlength="13" formControlName="idNumber"
                                [ngClass]="{ 'is-invalid': isSubmitted && f1.idNumber.errors }" />
                            <small style="color:red" *ngIf="isSubmitted && f1.idNumber.errors">{{'JMBG_13_chars'|translate}}</small>
                        </div>
                        <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold mb-2">{{'_Email' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="email"
                                value="" formControlName="email"
                                [ngClass]="{ 'is-invalid': isSubmitted && f1.email.errors }" />
                        </div>
                        <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold mb-2">{{'_Phone' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="phone"
                                value="" formControlName="phone"
                                [ngClass]="{ 'is-invalid': isSubmitted && f1.phone.errors }" />
                        </div>
                        <div class="row g-9 mb-7">
                            <div class="col-md-6 fv-row">
                                <label class="form-label fs-6 fw-bold mb-2">{{'_Country' |
                                    translate}}:</label>
                                <select #selectEditCountryId class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite državu"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="physical_edit_countryId" data-dropdown-parent="#kt_modal_edit_physical_form"
                                    formControlName="addEditCountryId" [(ngModel)]="addEditCountryId" name="addEditCountryId">
                                    <option></option>
                                    <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-6 fv-row">
                                <label class="form-label fs-6 fw-bold mb-2">{{'_Town' |
                                    translate}}:</label>
                                <select #selectEditTownId class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite grad"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="physical_edit_townId" data-dropdown-parent="#kt_modal_edit_physical_form"
                                    formControlName="addEditTownId" [(ngModel)]="addEditTownId" name="addEditTownId">
                                    <option></option>
                                    <option *ngFor="let t of towns" [value]="t.id">{{t.name}}</option>
                                </select>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="form-label fs-6 fw-bold mb-2">{{'_Employer' |
                                    translate}}:</label>
                                <select #selectEditEmployerId class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite poslodavca"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="physical_edit_employerId" data-dropdown-parent="#kt_modal_edit_physical_form"
                                    formControlName="addEditEmployerId" [(ngModel)]="addEditEmployerId" name="addEditEmployerId">
                                    <option></option>
                                    <option *ngFor="let e of employers" [value]="e.id">{{e.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer flex-center">
                    <button type="button" id="kt_modal_edit_user_cancel"(click)="closeEditModal(false)"
                        class="btn btn-light me-3">{{'_Discard' | translate}}</button>
                    <button type="submit" id="kt_modal_edit_user_submit" class="btn btn-primary" (click)="updateCustomer(false)">
                        <span class="indicator-label">{{'_Submit' | translate}}</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_edit_legal" tabindex="-1" aria-hidden="true" #closeEditLegalModal>
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
            <form class="form" action="#" id="kt_modal_edit_legal_form" [formGroup]="editFormLegal">
                <div class="modal-header" id="kt_modal_edit_legal_header">
                    <h2 class="fw-bolder">{{'_Edit' | translate}}</h2>
                    <div id="kt_modal_edit_legal_close" class="btn btn-icon btn-sm btn-active-icon-primary"
                        (click)="closeEditModal(true)">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_edit_legal_scroll" data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_edit_legal_header"
                        data-kt-scroll-wrappers="#kt_modal_edit_legal_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Code' | translate}}</label>
                            <input type="number" min="0" class="form-control form-control-solid" placeholder=""
                                name="code" formControlName="code" [ngModel]="setCode" (keydown)="checkIfInvalid($event)" 
                                (input)="updateCustomCode(codeEditLegal.value)" required #codeEditLegal/>
                        </div>
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Name' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder=""
                                name="name" value="" formControlName="name" required
                                [ngClass]="{ 'is-invalid': isSubmitted && f4.name.errors }" />
                        </div>
                        <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold mb-2">{{'_ID_Number' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="idNumber"
                                value="" formControlName="idNumber"
                                [ngClass]="{ 'is-invalid': isSubmitted && f4.idNumber.errors }" />
                            <small style="color:red" *ngIf="isSubmitted && f1.idNumber.errors">{{'ID_13_chars'|translate}}</small>
                        </div>
                        <div class="fv-row mb-7">
                            <label class="required fs-6 fw-bold mb-2">{{'_Address' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder=""
                                name="address" value="" formControlName="address" required
                                [ngClass]="{ 'is-invalid': isSubmitted && f4.address.errors }" />
                        </div>
                        <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold mb-2">{{'_PDV_number' | translate}}</label>
                            <input type="text" class="form-control form-control-solid" placeholder="" name="pdvNumber"
                                value="" formControlName="pdvNumber" id="editPdvNumber"/>
                        </div>
                        <div class="row g-9 mb-7">
                            <div class="col-md-6 fv-row">
                                <label class="form-label fs-6 fw-bold mb-2">{{'_Country' |
                                    translate}}:</label>
                                <select #selectAddCountryIdLegal class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite državu"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="legal_edit_countryId" data-dropdown-parent="#kt_modal_edit_legal_form"
                                    formControlName="addEditCountryId" [(ngModel)]="addEditCountryId" name="addEditCountryId">
                                    <option></option>
                                    <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-6 fv-row">
                                <label class="form-label fs-6 fw-bold mb-2">{{'_Town' |
                                    translate}}:</label>
                                <select #selectAddTownIdLegal class="form-select form-select-solid fw-bolder"
                                    data-placeholder="Odaberite grad"
                                    data-allow-clear="true" data-kt-group-type-table-filter="month"
                                    id="legal_edit_townId" data-dropdown-parent="#kt_modal_edit_legal_form"
                                    formControlName="addEditTownId" [(ngModel)]="addEditTownId" name="addEditTownId">
                                    <option></option>
                                    <option *ngFor="let t of towns" [value]="t.id">{{t.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row g-9 mb-7">
                            <div class="col-md-6 fv-row">
                                <label class="fs-5 fw-bold form-label mb-5">{{'_Is_Customer' | translate}}:</label>
                                <div class="d-flex flex-column">
                                    <label
                                        class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                        <input class="form-check-input" type="radio" [value]="true"
                                            formControlName="isCustomer" [(ngModel)]="isCustomer" name="isCustomer"/>
                                        <span class="form-check-label text-gray-600 fw-bold">{{'_Yes_text' |
                                            translate}}</span>
                                    </label>
                                    <label
                                        class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                        <input class="form-check-input" type="radio" [value]="false"
                                            formControlName="isCustomer" [(ngModel)]="isCustomer" name="isCustomer"/>
                                        <span class="form-check-label text-gray-600 fw-bold">{{'_No_text' |
                                            translate}}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6 fv-row">
                                <label class="fs-5 fw-bold form-label mb-5">{{'_Is_Supplier' | translate}}:</label>
                                <div class="d-flex flex-column">
                                    <label
                                        class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                        <input class="form-check-input" type="radio" [value]="true"
                                            formControlName="isSupplier" [(ngModel)]="isSupplier" name="isSupplier"/>
                                        <span class="form-check-label text-gray-600 fw-bold">{{'_Yes_text' |
                                            translate}}</span>
                                    </label>
                                    <label
                                        class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                        <input class="form-check-input" type="radio" [value]="false"
                                            formControlName="isSupplier" [(ngModel)]="isSupplier" name="isSupplier"/>
                                        <span class="form-check-label text-gray-600 fw-bold">{{'_No_text' |
                                            translate}}</span>
                                    </label>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="modal-footer flex-center">
                    <button type="button" id="kt_modal_edit_user_cancel"(click)="closeEditModal(true)"
                        class="btn btn-light me-3">{{'_Discard' | translate}}</button>
                    <button type="submit" id="kt_modal_edit_user_submit" class="btn btn-primary" (click)="updateCustomer(true)">
                        <span class="indicator-label">{{'_Submit' | translate}}</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>


<ng-http-loader [spinner]="'sk-wave'"></ng-http-loader>