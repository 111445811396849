export class GroupModel{
    id:number;
    name:string;
    groupTypeId:number;
    groupTypeName:string;
    clientId:number;
    clientName:string;
    tax:number;
    taxId:number;
    isRawGroup:boolean;
}