import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { ClientModel } from "../models/client.model";
import { LoginModel } from "../models/login.model";
import { DataService } from "./data.service";

@Injectable()
export class FiscalRestService {
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async getFiscalTypes(){
        var config = environment.apiUrl;
        return await this.http.get(config + "api/FiscalType/GetAllFiscalTypes").toPromise() as any;
    }

    async getConfigForClient(clientId:number){
        var config = environment.apiUrl;
        return await this.http.get(config + "api/FiscalType/GetFiscalConfigurationForClient?ClientId="+clientId).toPromise() as any;
    }

    async setConfig(obj: any) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/FiscalType/CreateOrUpdateFiscalConfiguration", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }
}