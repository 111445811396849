import { ProductionArticleModel } from "./productionArticle.model";

export class ProductionDocumentModel{
    id:string;
    clientId:number;
    productionDocumentNumber:string;
    documentNumber:number;
    customDocumentNumber:number;
    date:Date;
    warehouseId:number;
    warehouseName:string;
    currencyId:number;
    currencyName:string;
    note:string;
    articles:ProductionArticleModel[];

    totalUsedNC:number;
    totalUsedVPC:number;
    totalUsedMPC:number;
    totalProducedNC:number;
    totalProducedVPC:number;
    totalProducedMPC:number;

    totalMarginAmount:number;
    totalMarginPercentage:number;
}