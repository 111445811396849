import { CustomerSuppliersModel } from "../../customerSupplier.model";
import { OutgoingInvoiceModel } from "../outgoingInvoice.model";

export class OutgoingInvoiceSaveModel{
    activeInvoice:OutgoingInvoiceModel;
    activeInvoicePreEdit:OutgoingInvoiceModel;
    
    customerCode:number;
    addEnabled:boolean;
    editEnabled:boolean;
    startedEditing:boolean;
    editingItems:boolean;
    changedArrivalDate:boolean;
    headerSaved:boolean;
    newInvoiceActive:boolean;
}