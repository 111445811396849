import { CustomerSuppliersModel } from "../../customerSupplier.model";
import { DispatchNoteModel } from "../dispatchNote.model";

export class DispatchNoteSaveModel{
    activeDispatchNote:DispatchNoteModel;
    activeDispatchNotePreEdit:DispatchNoteModel;
    
    customerCode:number;
    addEnabled:boolean;
    editEnabled:boolean;
    startedEditing:boolean;
    editingItems:boolean;
    changedArrivalDate:boolean;
    headerSaved:boolean;
    newDispatchNoteActive:boolean;
}