import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { DataService } from "./data.service";

@Injectable()
export class PointOfSaleRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async getPointsOfSale(clientId: number, page: number, pageSize: number) {
        var params = "?ClientId=" + this.data.user.clientId  + "&Page=" + page + "&PageSize=" + pageSize;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/PointOfSale/Filter" + params).toPromise() as any;
    }
}