import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleRestService } from 'src/app/services/article.service';
import { CustomerSupplierRestService } from 'src/app/services/customerSupplier.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { DispatchNoteRestService } from 'src/app/services/dispatch-note.service';
import { PaymentTypeRestService } from 'src/app/services/payment-type.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { GroupModel } from 'src/app/models/group.model';
import { TranslateService } from 'src/app/translate';
import { ArticleModel } from 'src/app/models/article.model';
import { TaxModel } from 'src/app/models/tax.model';
import { PaymentTypeModel } from 'src/app/models/paymentType.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { CustomerSuppliersModel } from 'src/app/models/customerSupplier.model';
import toastr from 'toastr';
import { DispatchNoteModel } from 'src/app/models/documents/dispatchNote.model';
import { SoldArticleModel } from 'src/app/models/documents/soldArticle.model';
import { minValidator } from 'src/app/validators/minValidator';
import { CurrencyModel } from 'src/app/models/currency.model';
import { CurrencyRestService } from 'src/app/services/currency.service';
import { InvoiceCustomerPaymentModel } from 'src/app/models/documents/invoice-customer-payment.model';
import { DispatchNoteSaveModel } from 'src/app/models/documents/pageSaveModels/dispatchNoteSave.model';
import { UpdateInvoicePaymentDTO } from 'src/app/models/updateInvoicePaymentDTO';
import { UpdateInvoiceDiscountDTO } from 'src/app/models/documents/updateInvoiceDiscountDTO';
import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import { ItemLookupComponent } from '../item-lookup/item-lookup.component';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;

@Component({
    selector: 'app-dispatch-note',
    templateUrl: './dispatch-note.component.html',
})
export class DispatchNoteComponent implements OnInit {
    public selectedRow: string = undefined;
    public dispatchNotes: DispatchNoteModel[];
    public groups: GroupModel[];
    public customers: CustomerSuppliersModel[];
    public taxes: TaxModel[];
    public currencies: CurrencyModel[];
    public paymentTypes: PaymentTypeModel[];
    public warehouses: WarehouseModel[];
    public retailPlaces: RetailPlaceModel[];
    public emptyData: boolean = false;
    public searchForm: UntypedFormGroup;
    public addForm: UntypedFormGroup;
    dateFrom: string;
    dateTo: string;
    customerName: string = '';
    public isSubmitted: boolean;
    public selectedDispatchNote: DispatchNoteModel;
    public selectedDispatchNoteTax: TaxModel;
    public selectedDispatchNotePaymentType: PaymentTypeModel;
    public selectedDispatchNoteWarehouse: WarehouseModel;
    public addCustomerId: number = 0;
    public addPaymentTypeId: number = 0;
    public addWarehouseId: number = 0;
    public addCurrencyId: number = 0;
    public addBillDate: Date = new Date();
    public addArrivalDate: Date = new Date();
    public addPaidOff: boolean = false;
    public searchCustomerName: string;
    public filterRetailPlaceId: number;
    public filterWarehouseId: number;
    public customerSubset: CustomerSuppliersModel[];
    public selectedCustomer: CustomerSuppliersModel;
    public headerForm: UntypedFormGroup;
    get hForm() { return this.headerForm.controls; }

    public headerFormEnabled: boolean;
    public itemFormEnabled: boolean;

    public newPaymentAmount: number = 0;
    public newPaymentDate: Date = new Date();
    public newPayments: InvoiceCustomerPaymentModel[] = [];

    public creatingDispatchNote: DispatchNoteModel;
    public selectedArticle: ArticleModel;
    public totalAmount: number = 0;
    tableSetUp: boolean = false;
    public addTaxId: number = 0;
    public basePrice: number;
    public basePriceDisplay: number;
    public triggerFinal: boolean;
    public finalPrice: number;
    public postDiscountPrice: number;
    public invoiceDiscount: number;
    public addItemForm: UntypedFormGroup;
    public selectedCurrency: string;
    get iForm() { return this.addItemForm.controls; }
    selectionModalTimer: any;
    enterCount: number = 0;
    creationInProgress: boolean = false;
    public previousWarehouseId: number;
    public pdfSource: any;
    public pdfSource2: any;

    public paymentsToAdd: InvoiceCustomerPaymentModel[];
    public paymentsForm: UntypedFormGroup;
    get pForm() { return this.paymentsForm.controls; }

    @ViewChild('closeAddDispatchNoteHeaderModal') closeAddDispatchNoteHeaderModal: ElementRef;
    @ViewChild('closeAddDispatchNoteItemsModal') closeAddDispatchNoteItemsModal: ElementRef;
    @ViewChild('closeEditDispatchNoteHeaderModal') closeEditDispatchNoteHeaderModal: ElementRef;
    @ViewChild('closeEditDispatchNoteItemsModal') closeEditDispatchNoteItemsModal: ElementRef;
    @ViewChild('selectAddDispatchNotePaymentTypeId') selectAddDispatchNotePaymentTypeId: ElementRef;
    @ViewChild('selectAddDispatchNoteCurrencyId') selectAddDispatchNoteCurrencyId: ElementRef;
    @ViewChild('selectAddDispatchNoteWarehouseId') selectAddDispatchNoteWarehouseId: ElementRef;
    @ViewChild('selectTaxId') selectTaxId: ElementRef;
    @ViewChild('selectEditDispatchNoteCustomerId') selectEditDispatchNoteCustomerId: ElementRef;
    @ViewChild('selectEditDispatchNotePaymentTypeId') selectEditDispatchNotePaymentTypeId: ElementRef;
    @ViewChild('selectEditDispatchNoteCurrencyId') selectEditDispatchNoteCurrencyId: ElementRef;
    @ViewChild('selectEditDispatchNoteWarehouseId') selectEditDispatchNoteWarehouseId: ElementRef;
    @ViewChild('selectEditTaxId') selectEditTaxId: ElementRef;
    @ViewChild('closeSelectItemModal') closeSelectItemModal: ElementRef;
    @ViewChild('openFinalPriceModal') openFinalPriceModal: ElementRef;
    @ViewChild('closeFinalPriceModal') closeFinalPriceModal: ElementRef;
    @ViewChild('headerTrigger') headerTrigger: ElementRef;
    @ViewChild('itemsTrigger') itemsTrigger: ElementRef;
    @ViewChild('listTrigger') listTrigger: ElementRef;
    @ViewChild('closeDispatchNoteDiscountModal') closeDispatchNoteDiscountModal: ElementRef;
    @ViewChild('invoiceNumberOpen') invoiceNumberOpen: ElementRef;
    @ViewChild('invoiceNumberClose') invoiceNumberClose: ElementRef;
    @ViewChild('firstHeaderForm') firstHeaderFormElement: ElementRef;
    @ViewChild('itemCodeInput') itemCodeInput: ElementRef;
    @ViewChild('invoiceDiscountInput') invoiceDiscountInput: ElementRef;
    @ViewChild('invoiceNumberInput') invoiceNumberInput: ElementRef;
    @ViewChild('finalPriceInput') finalPriceInput: ElementRef;
    @ViewChild('closePaymentsModal') closePaymentsModal: ElementRef;
    @ViewChild('selectFilterRetailPlaceId') selectFilterRetailPlaceId: ElementRef;
    @ViewChild('selectFilterWarehouseId') selectFilterWarehouseId: ElementRef;
    @ViewChild('addItemsButton') addItemsButton: ElementRef;
    @ViewChild(ItemLookupComponent) itemLookupComponent: ItemLookupComponent;

    public saveState: DispatchNoteSaveModel;
    public showItems: boolean = true;
    public setUpComplete: boolean = false;
    public editEnabled: boolean = false;
    public startedEditing: boolean = false;
    public showCustomerError: boolean = false;
    public nextNumber: number;
    public addEnabled: boolean = false;
    public changedArrivalDate: boolean = false;
    public headerSaved: boolean = false;
    public editingItems: boolean = false;
    public newDispatchNoteActive: boolean = false;
    public totalPaymentsAmount: number = 0;
    public editingItem: SoldArticleModel;
    public editingItemIndex: number;
    public documentArticles: ArticleModel[];
    public onHeaderTab: boolean = true;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;

    private resizeObserver: ResizeObserver;

    constructor(
        private dispatchNoteRest: DispatchNoteRestService,
        private articleRestService: ArticleRestService,
        private groupRestService: GroupRestService,
        private customerRestService: CustomerSupplierRestService,
        private paymentTypeRestService: PaymentTypeRestService,
        private warehouseRestService: WarehouseRestService,
        private currencyRestService: CurrencyRestService,
        private datePipe: DatePipe,
        private _changeRef: ChangeDetectorRef,
        public data: DataService,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private router: Router
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit(): void {
        KTMenu.createInstances();
        this.fetchDispatchNotes();
        this.initializeSaveState();
        this.fetchTaxes();
        this.fetchGroups();
        this.fetchCustomers();
        this.fetchCurrencies();
        this.fetchPaymentTypes();
        this.fetchWarehouses();
        this.createSearchForm();
        this.createHeaderForm();
        this.createPaymentAndDiscountForm();
        this.createAddItemForm();
        this.viewDocuments();
    }

    ngAfterViewInit() {
        $(this.headerTrigger.nativeElement).on('shown.bs.tab', () => {
            this.firstHeaderFormElement.nativeElement.focus();
        });

        $(this.itemsTrigger.nativeElement).on('shown.bs.tab', () => {
            this.itemCodeInput.nativeElement.focus();
        });

        $("#kt_modal_invoice_number").on('shown.bs.modal', () => {
            this.invoiceNumberInput.nativeElement.focus();
        });

        $("#kt_modal_select_invoice_customer").on('shown.bs.modal', () => {
            $("#searchCustomerInput").focus();
        });

        $("#kt_modal_select_invoice_customer").on('hidden.bs.modal', () => {
            this.refreshCustomerLookup();
            this._changeRef.detectChanges();
        });

        $("#kt_modal_invoice_discount").on('shown.bs.modal', () => {
            this.invoiceDiscountInput.nativeElement.focus();
        });

        $("#kt_modal_final_price").on('shown.bs.modal', () => {
            this.finalPrice = undefined;
            this.finalPriceInput.nativeElement.focus();
        });
        $("#kt_modal_final_price").on('hidden.bs.modal', () => {
            if (this.finalPrice == undefined)
                this.finalPrice = 0;
            $("#discount1").focus();
        });
    }

    ngAfterViewChecked() {
        if (!this.setUpComplete) {
            this.setUpFields();

            if (this.valuesMatch()) {
                this.setUpComplete = true;

                Object.keys(this.headerForm.controls).forEach(name => {
                    let control = this.headerForm.get(name);

                    control.valueChanges.subscribe(value => this.updateCreatingDispatchNote(name, value));
                })

                this.changedArrivalDate = this.saveState.changedArrivalDate;

                if (this.addEnabled || (this.startedEditing && !this.editingItems))
                    this.enableHeaderForm();
                else
                    this.disableHeaderForm();
                if (this.editingItems)
                    this.enableItemForm();
                else this.disableItemForm();

                this.updateState();
                this._changeRef.detectChanges();
            }
        }
    }

    valuesMatch() {
        var check = true;

        Object.keys(this.headerForm.controls).forEach(name => {
            let control = this.headerForm.get(name);
            if (control.value != this.creatingDispatchNote[name]) {
                check = false;
            }
            else {
            }
        });

        return check;
    }

    async createSearchForm() {
        this.searchForm = this.formBuilder.group({
            filterRetailPlaceId: [null],
            filterWarehouseId: [null]
        })

        $("#invoice_filter_warehouseId").select2();
        $('#invoice_filter_warehouseId').on("change", function () {
            self.selectFilterWarehouseId.nativeElement.value = $(this).val();
            self.filterWarehouseId = $(this).val();
        });

        $("#invoice_filter_retailPlaceId").select2();
        $('#invoice_filter_retailPlaceId').on("change", function () {
            self.selectFilterRetailPlaceId.nativeElement.value = $(this).val();
            self.filterRetailPlaceId = $(this).val();
        });

        var start = new Date(new Date().getFullYear(), 0, 1);
        var end = new Date();
        var self = this;
        $("#kt_daterangepicker_filter").daterangepicker({
            startDate: start,
            endDate: end,
            autoApply: true,
            autoUpdateInput: true,
            locale: {
                format: 'DD/MM/YYYY',
                separator: '-'
            }
        });
        $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            $("#searchByCustomerInput").focus();
            self.fetchDispatchNotes();
        });
        $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
            picker.setStartDate(new Date());
            picker.setEndDate(new Date());
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            self.fetchDispatchNotes();
        });
        var im = new Inputmask("99/99/9999-99/99/9999");
        im.mask($("#kt_daterangepicker_filter"));
    }

    async resetFilter() {
        this.searchForm.reset();
        $("#invoice_filter_warehouseId").val(null).trigger("change");
        $("#invoice_filter_retailPlaceId").val(null).trigger("change");
    }

    filterByDate(event: KeyboardEvent) {
        if (event.key == "Enter" || event.key == "Tab") {
            var pickerElement = $("#kt_daterangepicker_filter");
            $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        }
    }

    async fetchGroups() {
        try {
            var response = await this.groupRestService.getGroups('', null, null, 1, 100000);
            this.groups = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchCustomers() {
        try {
            var response = await this.customerRestService.getCustomerSuppliers(this.data.user.clientId, '', null, null, true, null, 1, 999999);
            this.customers = response.data;
            this.customerSubset = response.data;
            if (this.saveState.customerCode != undefined || this.saveState.customerCode != null)
                this.selectedCustomer = this.customers.find(x => x.code == this.saveState.customerCode);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchTaxes() {
        try {
            this.taxes = await this.groupRestService.getTaxesByClientId(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchCurrencies() {
        try {
            this.currencies = await this.currencyRestService.getCurrencies();
            if (this.creatingDispatchNote != undefined && this.creatingDispatchNote.currencyId != undefined)
                this.selectedCurrency = this.currencies.find(x => x.id == this.creatingDispatchNote.currencyId).code;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchPaymentTypes() {
        try {
            var response = await this.paymentTypeRestService.getPaymentTypes(this.data.user.clientId, '', 1, 9999);
            this.paymentTypes = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchWarehouses() {
        try {
            this.warehouses = await this.warehouseRestService.getWarehousesForClient(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchDispatchNotes() {
        try {
            this.emptyData = false;
            if (this.dateFrom == undefined)
                this.dateFrom = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
            if (this.dateTo == undefined)
                this.dateTo = this.datePipe.transform((new Date()), 'yyyy-MM-dd');
            var response = await this.dispatchNoteRest.getDispatchNotes(this.customerName, this.filterWarehouseId, this.filterRetailPlaceId, '', this.dateFrom, this.dateTo, this.data.user.clientId, this.page, this.perPage);
            this.dispatchNotes = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchDispatchNotesPdf(id: string) {
        try{
            var response = await this.dispatchNoteRest.getDispatchNotesPDF(this.data.user.clientId, id);
        this.pdfSource = window.URL.createObjectURL(response);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
            else {
                toastr.options = this.data.toastrOptions;
                toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
            }
        }
    }

    async exportPDF() {
        await this.fetchDispatchNotesPdf(this.creatingDispatchNote.id);
        if (this.pdfSource)
            this.router.navigate(['/dispatch-notes-pdf'], { queryParams: { pdfSrc: this.pdfSource } });
    }

    async fetchDispatchNotesWithoutPricesPdf(id: string) {
        try{
        var response = await this.dispatchNoteRest.getDispatchNotesWithoutPricesPDF(this.data.user.clientId, id);
        this.pdfSource2 = window.URL.createObjectURL(response);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
            else {
                toastr.options = this.data.toastrOptions;
                toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
            }
        }
    }

    async exportWithoutPricesPDF() {
        await this.fetchDispatchNotesWithoutPricesPdf(this.creatingDispatchNote.id);
        if (this.pdfSource2)
            this.router.navigate(['/dispatch-notes-without-prices-pdf'], { queryParams: { pdfSrc: this.pdfSource2 } });
        
    }

    async getNextNumber() {
        this.nextNumber = await this.dispatchNoteRest.getNextNumber(this.data.user.clientId);
        if (this.creatingDispatchNote.documentNumber == undefined) {
            this.creatingDispatchNote.documentNumber = this.nextNumber;
            this.creatingDispatchNote.dispatchNoteNumber = this.nextNumber + '/' + ((new Date()).getFullYear() - 2000);
            this.updateState();
        }
    }

    loadState() {
        return this.data.getDocumentState("dispatchNotePage");
    }

    updateState() {
        this.saveState.activeDispatchNote = this.creatingDispatchNote;
        this.data.saveDocumentState("dispatchNotePage", this.saveState);
    }

    initializeSaveState() {
        this.saveState = this.loadState();
        if (this.saveState == undefined || this.saveState == null || !this.saveState.newDispatchNoteActive) {
            this.resetActiveDispatchNote();
        } else if (this.saveState.newDispatchNoteActive) {
            this.creatingDispatchNote = this.saveState.activeDispatchNote;
            this.addEnabled = this.saveState.addEnabled;
            this.headerSaved = this.saveState.headerSaved;
            this.editEnabled = this.saveState.editEnabled;
            this.startedEditing = this.saveState.startedEditing;
            this.editingItems = this.saveState.editingItems;
            this.newDispatchNoteActive = true;
            this.paymentsToAdd = [];
            this.creatingDispatchNote.payments.forEach(x => { this.totalPaymentsAmount += x.amount; this.paymentsToAdd.push(x) });
            this.invoiceDiscount = this.creatingDispatchNote.invoiceDiscount;
        }
    }

    resetActiveDispatchNote() {
        this.saveState = new DispatchNoteSaveModel();
        this.saveState.activeDispatchNote = new DispatchNoteModel();
        this.saveState.activeDispatchNote.clientId = this.data.user.clientId;
        this.saveState.activeDispatchNote.articles = [];
        this.saveState.activeDispatchNote.totalAmount = 0;
        this.saveState.activeDispatchNote.totalBase = 0;
        this.saveState.activeDispatchNote.totalDiscount = 0;
        this.saveState.activeDispatchNote.totalTax = 0;
        this.saveState.activeDispatchNote.paidOff = false;
        this.saveState.activeDispatchNote.payments = [];
        this.saveState.activeDispatchNote.invoiceDiscount = 0;
        this.creatingDispatchNote = this.saveState.activeDispatchNote;
        this.saveState.activeDispatchNotePreEdit = this.creatingDispatchNote;
        this.updateState();
    }

    setUpFields() {
        this.headerForm.controls.customerId.setValue(this.creatingDispatchNote.customerId);

        if (this.creatingDispatchNote.paymentTypeId == undefined && this.paymentTypes != undefined && !this.editEnabled) {
            $("#add_invoice_paymentTypeId").val(this.paymentTypes[0].id).trigger("change");;
            this.creatingDispatchNote.paymentTypeId = this.paymentTypes[0].id;
        }
        else if (this.paymentTypes != undefined) {
            $("#add_invoice_paymentTypeId").val(this.creatingDispatchNote.paymentTypeId).trigger("change");
        }

        if (this.creatingDispatchNote.currencyId == undefined && this.currencies != undefined && !this.editEnabled) {
            $("#add_invoice_currencyId").val(this.currencies[0].id).trigger("change");;
            this.creatingDispatchNote.currencyId = this.currencies[0].id;
        }
        else if (this.creatingDispatchNote.currencyId != undefined) {
            $("#add_invoice_currencyId").val(this.creatingDispatchNote.currencyId).trigger("change");
        }

        if (this.creatingDispatchNote.warehouseId == undefined && this.warehouses != undefined && !this.editEnabled) {
            $("#add_invoice_warehouseId").val(this.warehouses[0].id).trigger("change");
            this.creatingDispatchNote.warehouseId = this.warehouses[0].id;
        }
        else if (this.creatingDispatchNote.warehouseId != undefined) {
            if (this.addWarehouseId == undefined || this.addWarehouseId == null || this.addWarehouseId == 0 || isNaN(this.addWarehouseId))
                $("#add_invoice_warehouseId").val(this.creatingDispatchNote.warehouseId).trigger("change");
        }
        if (this.creatingDispatchNote.billDate != undefined) {
            $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingDispatchNote.billDate));
            this.headerForm.controls.billDate.setValue(this.creatingDispatchNote.billDate);
        } else {
            this.addBillDate = new Date();
            this.headerForm.controls.billDate.setValue(this.addBillDate);
            $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(this.addBillDate);
            this.addArrivalDate = new Date(this.addBillDate.getFullYear(), this.addBillDate.getMonth() + 1, this.addBillDate.getDate());
            $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(this.addArrivalDate);
            this.headerForm.controls.arrivalDate.setValue(this.addArrivalDate);
            this.creatingDispatchNote.billDate = this.addBillDate;
            this.creatingDispatchNote.arrivalDate = this.addArrivalDate;
        }

        if (this.creatingDispatchNote.arrivalDate != undefined) {
            $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingDispatchNote.arrivalDate));
            this.headerForm.controls.arrivalDate.setValue(this.creatingDispatchNote.arrivalDate);
        }
        this.invoiceDiscount = this.creatingDispatchNote.invoiceDiscount;
        this.headerForm.controls.note.setValue(this.creatingDispatchNote.note);
        this.paymentsForm.controls.paidOff.setValue(this.creatingDispatchNote.paidOff);

        this._changeRef.detectChanges();
    }

    updateCreatingDispatchNote(name: string, value: any) {
        this.creatingDispatchNote[name] = value;

        this.updateState();
    }

    async searchDispatchNotes(customerName: string) {
        this.customerName = customerName;
        this.firstLoad = true;
        this.page = 1;
        this.fetchDispatchNotes();
    }

    async searchCustomers() {
        if (this.searchCustomerName == '')
            this.customerSubset = this.customers;
        else
            this.customerSubset = this.customers.filter(x => x.name.toLowerCase().startsWith(this.searchCustomerName.toLowerCase()));
    }

    selectCustomer(c: CustomerSuppliersModel) {
        this.selectedCustomer = c;
        this.saveState.customerCode = c.code;
        this.creatingDispatchNote.customerId = c.id;
        this.creatingDispatchNote.customerName = c.name;
        this.showCustomerError = false;
        this.updateState();
    }

    refreshCustomerLookup() {
        this.searchCustomerName = '';
        this.customerSubset = this.customers;
    }

    saveCustomer(c: number) {
        this.saveState.customerCode = c;
        this.updateState();
    }

    openItemsOnEmpty($event: KeyboardEvent = null) {
        var fieldValue = $("#itemCodeInput").val();
        if (!this.itemFormEnabled || (fieldValue != '' && fieldValue != undefined))
            return;

        if ($event != null) {
            if ($event.key == "Enter") {
                this.enterCount++;
                clearTimeout(this.selectionModalTimer);
                this.selectionModalTimer = setTimeout(() => {
                    this.enterCount = 0;
                }, 500);

                if (this.enterCount != 2)
                    return;

                this.itemLookupComponent.openItemsModal();
            }
        } else {
            this.itemLookupComponent.openItemsModal();
        }
    }

    toggleItems(toggle: boolean) {
        this.showItems = toggle;
    }
    toggleDocNav(toggle: boolean) {
        this.onHeaderTab = toggle;
    }

    viewDocuments() {
        this.toggleItems(false);
        this.toggleDocNav(false);
        this.resetSave();
        this.resetForm();
        this.resetItemForm();
        this.disableForm();
        this.headerSaved = false;
        this.startedEditing = false;
        this.editEnabled = false;
        this.addEnabled = false;
        this.editingItems = false;
    }

    selectDispatchNote(invoice: DispatchNoteModel, switchToHeaderTab: boolean = true) {
        if (switchToHeaderTab)
            this.headerTrigger.nativeElement.click();

        if (this.saveState.newDispatchNoteActive && this.creatingDispatchNote.id != invoice.id)
            this.saveState.newDispatchNoteActive = false;

        this.creatingDispatchNote = invoice;
        this.selectedDispatchNotePaymentType = this.paymentTypes.find(x => x.id == invoice.paymentTypeId);
        this.selectedCurrency = this.currencies.find(x => x.id == invoice.currencyId).code;
        this.selectedDispatchNoteTax = this.taxes.find(x => x.id == invoice.taxId);

        $('#add_invoice_paymentTypeId').val(this.creatingDispatchNote.paymentTypeId).trigger("change");
        $('#add_invoice_warehouseId').val(this.creatingDispatchNote.warehouseId).trigger("change");
        $('#add_invoice_currencyId').val(this.creatingDispatchNote.currencyId).trigger("change");
        $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingDispatchNote.billDate));
        $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingDispatchNote.arrivalDate));
        this.hForm.billDate.setValue(this.creatingDispatchNote.billDate);
        this.hForm.arrivalDate.setValue(this.creatingDispatchNote.arrivalDate);
        this.addEnabled = false;
        this.editEnabled = true;
        this.startedEditing = false;

        this.saveState.activeDispatchNotePreEdit = this.makePreEditObject();
        this.hForm.customerId.setValue(this.creatingDispatchNote.customerId);
        this.saveState.customerCode = this.customers.find(x => x.id == this.creatingDispatchNote.customerId).code;

        $("#customerCodeInput").val(this.saveState.customerCode);
        this.selectedCustomer = this.customers.find(x => x.id == this.creatingDispatchNote.customerId);
        this.hForm.customerId.setValue(this.creatingDispatchNote.customerId);
        this.hForm.note.setValue(this.creatingDispatchNote.note);

        this.hForm.affectWarehouseState.setValue(this.creatingDispatchNote.affectWarehouseState);
        this.hForm.noPrices.setValue(this.creatingDispatchNote.noPrices);

        if (this.creatingDispatchNote.warehouseId != this.previousWarehouseId) {
            this._changeRef.detectChanges();
            this.previousWarehouseId = this.creatingDispatchNote.warehouseId;
            this.itemLookupComponent.fetchArticles(true);
        }

        this.selectedArticle = null;
        this.invoiceDiscount = this.creatingDispatchNote.invoiceDiscount;
        this.iForm.discount1.setValue(this.invoiceDiscount);

        this.pForm.paidOff.setValue(this.creatingDispatchNote.paidOff);
        this.paymentsToAdd = [];
        this.creatingDispatchNote.payments.forEach(x => this.paymentsToAdd.push(x));
        this.totalPaymentsAmount = 0;
        this.paymentsToAdd.forEach(x => this.totalPaymentsAmount += x.amount);

        this.headerSaved = true;

        this.disableForm();
    }

    async selectNext() {
        var index = this.dispatchNotes.findIndex(x => x.id == this.creatingDispatchNote.id);

        if (index == 0) {
            if (this.page == 1)
                return;

            await this.prevpage();
            await this.fetchDispatchNotes();

            if (this.dispatchNotes.length > 0) {
                this.selectDispatchNote(this.dispatchNotes[this.dispatchNotes.length - 1]);
            }
        }
        else {
            this.selectDispatchNote(this.dispatchNotes[index - 1]);
        }
    }

    async selectPrevious() {
        var index = this.dispatchNotes.findIndex(x => x.id == this.creatingDispatchNote.id);
        if (index == (this.dispatchNotes.length - 1)) {
            if (this.page == this.maxPage)
                return;

            await this.nextPage();
            await this.fetchDispatchNotes();

            if (this.dispatchNotes.length > 0) {
                this.selectDispatchNote(this.dispatchNotes[0]);
            }
        }
        else {
            this.selectDispatchNote(this.dispatchNotes[index + 1]);
        }
    }

    startEditing(header: boolean) {
        if (header) {
            this.headerSaved = false;
        }
        this.enableHeaderForm(false);
        this.disableItemForm();
        this.resetItemForm();
        this.startedEditing = true;
        if (this.editingItems)
            this.editingItems = false;
    }
    startAddingItems() {
        if (this.itemLookupComponent.articles.length == 0)
            this.itemLookupComponent.fetchArticles();
        this.enableItemForm();
        this.startedEditing = true;
        this.editingItems = true;
    }

    cancelEditing(header: boolean = true) {
        this.selectDispatchNote(this.saveState.activeDispatchNotePreEdit, false);
        this.disableForm();
        if (!header) {
            this.resetItemForm();
        }
        this.startedEditing = false;
        this.editEnabled = true;
        this.addEnabled = false;
        this.editingItems = false;
        if (this.creatingDispatchNote.id != undefined)
            this.headerSaved = true;
    }

    exitDetails(redirectToHeader: boolean = false) {
        if (redirectToHeader)
            this.headerTrigger.nativeElement.click();

        this.resetSave();
        this.resetForm()
        this.headerSaved = false;
    }

    cancelAdding() {
        this.disableForm();
        this.resetSave();

        this.resetForm();
        this.updateState();
    }

    manualInputDate(id: string) {
        var pickerElement = $("#" + id);
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }

    selectOnFocus(id: string) {
        $("#" + id).select();
    }

    async createHeaderForm() {
        this.headerForm = this.formBuilder.group({
            customerId: [0, Validators.required],
            paymentTypeId: [0, Validators.required],
            warehouseId: [0, Validators.required],
            currencyId: [0, Validators.required],
            billDate: [],
            arrivalDate: [],
            note: [''],
            affectWarehouseState: [true],
            noPrices: [false]
        });

        var self = this;

        $("#add_invoice_paymentTypeId").select2();
        $('#add_invoice_paymentTypeId').on("change", function () {
            self.selectAddDispatchNotePaymentTypeId.nativeElement.value = $(this).val();
            self.addPaymentTypeId = $(this).val();
        });

        $("#add_invoice_warehouseId").select2();
        $('#add_invoice_warehouseId').on("change", function () {
            self.selectAddDispatchNoteWarehouseId.nativeElement.value = $(this).val();
            self.addWarehouseId = $(this).val();
        });

        $("#add_invoice_currencyId").select2();
        $('#add_invoice_currencyId').on("change", function () {
            self.selectAddDispatchNoteCurrencyId.nativeElement.value = $(this).val();
            self.addCurrencyId = $(this).val();
        });

        var start = (new Date()).setDate((new Date()).getDate() - 29);
        var end = new Date();
        $("#kt_bill_date_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: start,
            endDate: end,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });
        $("#kt_bill_date_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.addBillDate = picker.startDate._d;
            self.headerForm.controls.billDate.setValue(self.addBillDate);
            if (!self.changedArrivalDate) {
                self.addArrivalDate = new Date(self.addBillDate.getFullYear(), self.addBillDate.getMonth() + 1, self.addBillDate.getDate());
                $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(self.addArrivalDate);
                self.headerForm.controls.arrivalDate.setValue(self.addArrivalDate);
            }
        });
        var im2 = new Inputmask("99/99/9999");
        im2.mask($("#kt_bill_date_add_picker"));

        var arrivalDefault = new Date(new Date().setMonth(new Date().getMonth() + 1));
        var startArrival = (arrivalDefault).setDate(arrivalDefault.getDate());
        $("#kt_arrival_date_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: startArrival,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });
        $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(arrivalDefault);
        $("#kt_arrival_date_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.changedArrivalDate = true;
            self.saveState.changedArrivalDate = true;
            self.addArrivalDate = picker.startDate._d;
            self.headerForm.controls.arrivalDate.setValue(self.addArrivalDate);
        });
        var im = new Inputmask("99/99/9999");
        im.mask($("#kt_arrival_date_add_picker"));

        if (this.editEnabled && !this.startedEditing)
            this.disableForm();
    }

    createAddItemForm() {
        this.addItemForm = this.formBuilder.group({
            quantity: [1, Validators.required],
            basePrice: [0, [Validators.required, minValidator(0.01)]],
            discount1: [this.creatingDispatchNote.invoiceDiscount, Validators.required],
            discount2: [0, Validators.required],
            addTaxId: [0, Validators.required],
        });
        var self = this;
        $("#add_item_taxId").select2();
        $('#add_item_taxId').on("change", function () {
            self.selectTaxId.nativeElement.value = $(this).val();
            self.addTaxId = $(this).val();
            self.selectedDispatchNoteTax = self.taxes.find(x => x.id == self.addTaxId);
            self.recalculatePrices();
        });
    }

    async createPaymentAndDiscountForm() {
        this.paymentsForm = this.formBuilder.group({
            newPaymentAmount: [0, Validators.required],
            paymentDate: [],
            paidOff: [false]
        });
        var self = this;
        var start = new Date();
        $("#kt_new_payment_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            startDate: start,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });
        $("#kt_new_payment_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.newPaymentDate = picker.startDate._d;
            $("#newPaymentAmount").focus();
        });
        var im = new Inputmask("99/99/9999");
        im.mask($("#kt_new_payment_add_picker"));
    }

    disableForm() {
        this.disableHeaderForm(false);
        this.disableItemForm();
    }
    disableHeaderForm(emit: boolean = true) {
        if (emit == false) {
            this.headerForm.disable({ emitEvent: false });
        } else {
            this.headerForm.disable();
        }

        this.headerFormEnabled = false;
        $("#customerCodeInput").prop("disabled", true);
        $("#customerLookup").addClass("disabled");
        $("#kt_bill_date_add_picker").prop("disabled", true);
        $("#kt_arrival_date_add_picker").prop("disabled", true);
    }
    disableItemForm() {
        this.addItemForm.disable();
        if (this.itemFormEnabled) {
            this.itemFormEnabled = false;
            setTimeout(() => {
                this.addItemsButton.nativeElement.focus();
            }, 0);
        }
        $("#itemCodeInput").prop("disabled", true);
        $("#itemLookup").addClass("disabled");
    }

    enableForm() {
        this.enableHeaderForm();
        this.enableItemForm();
    }
    enableHeaderForm(emit: boolean = true) {
        if (emit == false)
            this.headerForm.enable({ emitEvent: false });
        else
            this.headerForm.enable();
        this.headerFormEnabled = true;
        $("#customerCodeInput").prop("disabled", false);
        $("#customerLookup").removeClass("disabled");
        $("#kt_bill_date_add_picker").prop("disabled", false);
        $("#kt_arrival_date_add_picker").prop("disabled", false);
    }
    enableItemForm() {
        if (this.basePrice == undefined)
            this.basePrice = 0;
        this.addItemForm.enable();
        this.itemFormEnabled = true;
        $("#itemCodeInput").prop("disabled", false);
        $("#itemLookup").removeClass("disabled");
        $("#add_item_taxId").val(this.taxes[0].id).trigger("change");
    }

    async resetForm() {
        this.selectedCustomer = null;
        $('#add_invoice_paymentTypeId').val(this.paymentTypes[0].id).trigger("change");
        $('#add_invoice_currencyId').val(this.currencies[0].id).trigger("change");
        $('#add_invoice_warehouseId').val(this.warehouses[0].id).trigger("change");
        $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date());
        this.addBillDate = new Date();
        this.hForm.billDate.setValue(this.addBillDate);
        this.addArrivalDate = new Date(this.addBillDate.getFullYear(), this.addBillDate.getMonth() + 1, this.addBillDate.getDate());
        $("#kt_arrival_date_add_picker").data('daterangepicker').setStartDate(this.addArrivalDate);
        this.hForm.arrivalDate.setValue(this.addArrivalDate);

        this.headerForm.controls.note.setValue(null);

        this.hForm.affectWarehouseState.setValue(true);
        this.hForm.noPrices.setValue(false);

        $('#itemCodeInput').val(null);
        this.selectedArticle = undefined;
        this.addItemForm.controls.quantity.setValue(1);
        this.addItemForm.controls.basePrice.setValue(undefined);
        this.addItemForm.controls.discount1.setValue(0);
        this.addItemForm.controls.discount2.setValue(0);
        $('#add_item_taxId').val(this.taxes[0].id).trigger("change");

        this.addEnabled = false;
        this.editEnabled = false;
        this.startedEditing = false;

        this.basePrice = 0;
        this.finalPrice = 0;
        this.totalAmount = 0;

        this.paymentsForm.controls.paidOff.setValue(false);
        this.addPaidOff = false;
        this.newPaymentAmount = 0;
        this.invoiceDiscount = 0;

        this.headerFormEnabled = false;
        this.itemFormEnabled = false;

        this.changedArrivalDate = false;
    }

    resetSave() {
        this.saveState = new DispatchNoteSaveModel();
        this.saveState.activeDispatchNote = new DispatchNoteModel();
        this.saveState.activeDispatchNote.clientId = this.data.user.clientId;
        this.saveState.activeDispatchNote.warehouseId = this.warehouses[0].id;
        this.saveState.activeDispatchNote.paymentTypeId = this.paymentTypes[0].id;
        this.saveState.activeDispatchNote.currencyId = this.currencies[0].id;
        this.saveState.activeDispatchNote.articles = [];
        this.saveState.activeDispatchNote.payments = [];
        this.saveState.activeDispatchNote.totalAmount = 0;
        this.saveState.activeDispatchNote.totalBase = 0;
        this.saveState.activeDispatchNote.totalDiscount = 0;
        this.saveState.activeDispatchNote.totalTax = 0;
        this.saveState.activeDispatchNote.invoiceDiscount = 0;
        this.creatingDispatchNote = this.saveState.activeDispatchNote;
        this.invoiceDiscount = this.creatingDispatchNote.invoiceDiscount;

        this.editEnabled = false;
        this.addEnabled = false;
        this.startedEditing = false;
        this.saveState.addEnabled = this.addEnabled;
        this.saveState.changedArrivalDate = false;
    }

    async addDispatchNote() {
        if (this.creationInProgress)
            return;
        this.creationInProgress = true;
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            this.creationInProgress = false;
            return;
        }

        try {
            await this.dispatchNoteRest.createDispatchNote(this.creatingDispatchNote);
            this.creatingDispatchNote.id = await this.getNewlyCreatedId();
            this.saveState.activeDispatchNote.id = this.creatingDispatchNote.id;
            this.saveState.activeDispatchNotePreEdit = this.makePreEditObject();
            this.headerSaved = true;
            this.editingItems = true;
            this.saveState.editingItems = true;
            this.saveState.headerSaved = true;
            this.updateState();
            this.setPostAddingLayout();

            if (this.creatingDispatchNote.warehouseId != this.previousWarehouseId) {
                this.previousWarehouseId = this.creatingDispatchNote.warehouseId;
                this.itemLookupComponent.fetchArticles(true);
            }

            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_dispatch_note_text'), this.translate.instant("_Success"));
            this.fetchDispatchNotes();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }

        this.creationInProgress = false;
    }
    makePreEditObject() {
        var obj = new DispatchNoteModel();
        Object.keys(this.creatingDispatchNote).forEach(key => {
            if (key != 'articles') {
                let value = this.creatingDispatchNote[key];
                obj[key] = value;
            }
        })
        obj.articles = [];
        this.creatingDispatchNote.articles.forEach(x => {
            var itemCopy = new SoldArticleModel();
            Object.keys(x).forEach(key => {
                let value = x[key];
                itemCopy[key] = value;
            })
            obj.articles.push(itemCopy);
        });
        return obj;
    }
    async getNewlyCreatedId() {
        var response = await this.dispatchNoteRest.getDispatchNotes('', this.filterWarehouseId, this.filterRetailPlaceId, this.creatingDispatchNote.dispatchNoteNumber, this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.data.user.clientId, 1, 1)
        var id = response.data[0].id;
        return id;
    }

    showList() {
        this.listTrigger.nativeElement.click();
    }

    async editDispatchNote(instantReverse: boolean = false) {
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        try {
            await this.dispatchNoteRest.editDispatchNote(this.creatingDispatchNote);
            this.saveState.activeDispatchNotePreEdit = this.makePreEditObject();
            this.startedEditing = false;
            this.editingItems = false;
            this.saveState.startedEditing = false;
            this.saveState.editingItems = false;
            this.headerSaved = true;
            this.saveState.headerSaved = true;
            this.resetItemForm();
            this.disableForm();
            this.updateState();

            if (this.creatingDispatchNote.warehouseId != this.previousWarehouseId) {
                this.previousWarehouseId = this.creatingDispatchNote.warehouseId;
                this.itemLookupComponent.fetchArticles(true);
            }

            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Edited_dispatch_note_text'), this.translate.instant("_Success"));
            this.fetchDispatchNotes();
        }
        catch (err) {
            if (instantReverse)
                this.creatingDispatchNote = this.saveState.activeDispatchNotePreEdit;

            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    async deleteDispatchNote(id: string) {
        var self = this;
        var question_text = this.translate.instant("_Delete_dispatch_note_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.dispatchNoteRest.deleteDispatchNote(id);
                if (this.creatingDispatchNote.id == id) {
                    this.resetActiveDispatchNote();
                    this.disableForm();
                    this.startedEditing = false;
                    this.editEnabled = true;
                    this.addEnabled = false;
                    this.editingItems = false;
                    this.resetForm();
                }
                await this.fetchDispatchNotes();
                toastr.options = this.data.toastrOptions;
                toastr.success(this.translate.instant('_Deleted_dispatch_note_text'), this.translate.instant("_Success"));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    shouldFetchArticleState(id: number) {
        if (this.itemLookupComponent.articles.find(x => x.id == id) != undefined)
            return false;
        if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == id) != undefined)
            return false;

        return true;
    }
    async selectItem(item: SoldArticleModel) {
        if (this.shouldFetchArticleState(item.id)) {
            try {
                var articlesToFetch = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.creatingDispatchNote.warehouseId, 1, 99999, '', false, false, item.id);
                if (this.documentArticles == undefined)
                    this.documentArticles = [];
                this.documentArticles.push(articlesToFetch[0]);
                this.selectedArticle = articlesToFetch[0];
            } catch (err: any) {
                toastr.options = this.data.toastrOptions;
                toastr.error(err.error['Message'], this.translate.instant("_Error"));
            }
        } else {
            if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == item.id))
                this.selectedArticle = this.documentArticles.find(x => x.id == item.id);
            else
                this.selectedArticle = this.itemLookupComponent.articles.find(x => x.id == item.id);
        }

        this.enableItemForm();
        $("#quantity").val(item.quantity);
        this.iForm.quantity.setValue(item.quantity);
        $("#discount1").val(item.discount1Percentage);
        $("#discount2").val(item.discount2Percentage);
        $("#add_item_taxId").val(item.taxId).trigger("change");
        this.iForm.discount1.setValue(item.discount1Percentage);
        this.iForm.discount2.setValue(item.discount2Percentage);
        this.basePrice = item.basePrice;
        this.basePriceDisplay = parseFloat(item.basePrice.toFixed(2));
        this.iForm.basePrice.setValue(this.basePriceDisplay);
        this.finalPrice = item.finalPrice;
        this.postDiscountPrice = item.postDiscountPrice;

        this.startedEditing = true;
        this.editingItems = true;
        this.editingItem = item;
        this.editingItemIndex = this.creatingDispatchNote.articles.indexOf(item);

        this.itemsTrigger.nativeElement.click();
    }
    editItem() {
        this.isSubmitted = false;
        if (this.addItemForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateItemForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted) {
            return;
        }

        var itemId = 0; var itemQuantity = 0;
        var existingPriceArticle = this.checkForSamePriceItem(null, true);
        if (existingPriceArticle != null) {
            existingPriceArticle.quantity += this.iForm.quantity.value;
            existingPriceArticle.totalPrice += existingPriceArticle.finalPrice * this.iForm.quantity.value;
            existingPriceArticle.totalDiscount1Amount = existingPriceArticle.discount1Amount * existingPriceArticle.quantity;
            existingPriceArticle.totalDiscount2Amount = existingPriceArticle.discount2Amount * existingPriceArticle.quantity;
            existingPriceArticle.totalPostDiscount1Price = existingPriceArticle.postDiscount1Price * existingPriceArticle.quantity;
            existingPriceArticle.totalPostDiscountPrice = existingPriceArticle.postDiscountPrice * existingPriceArticle.quantity;
            existingPriceArticle.totalTaxAmount = existingPriceArticle.taxAmount * existingPriceArticle.quantity;

            this.creatingDispatchNote.totalAmount += parseFloat((existingPriceArticle.finalPrice * this.iForm.quantity.value).toFixed(6));
            this.creatingDispatchNote.totalBase += parseFloat((existingPriceArticle.basePrice).toFixed(6));
            this.creatingDispatchNote.totalTax += existingPriceArticle.taxPercentage;
            this.creatingDispatchNote.totalDiscount += (existingPriceArticle.basePrice - existingPriceArticle.postDiscountPrice);

            itemId = existingPriceArticle.id;
            itemQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));


            this.creatingDispatchNote.articles.forEach((element, index) => {
                if (element == this.editingItem) this.creatingDispatchNote.articles.splice(index, 1);
            });
        }
        else {
            var newQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
            var quantityDiff = this.editingItem.quantity - newQuantity;
            this.editingItem.quantity = newQuantity;
            this.editingItem.basePrice = this.basePrice;
            this.recalculatePrices();

            this.editingItem.discount1Percentage = this.iForm.discount1.value;
            this.editingItem.discount1Amount = parseFloat((this.editingItem.basePrice * (this.editingItem.discount1Percentage / 100)).toFixed(6));
            this.editingItem.postDiscount1Price = parseFloat((this.editingItem.basePrice * (1 - (this.editingItem.discount1Percentage / 100))).toFixed(6));
            this.editingItem.discount2Percentage = this.iForm.discount2.value;
            this.editingItem.discount2Amount = parseFloat((this.editingItem.postDiscount1Price * (this.editingItem.discount2Percentage / 100)).toFixed(6));
            this.editingItem.postDiscountPrice = this.postDiscountPrice;
            this.editingItem.combinedDiscountAmount = this.editingItem.discount1Amount + this.editingItem.discount2Amount;

            this.editingItem.taxId = this.addTaxId;
            this.editingItem.taxName = this.taxes.find(x => x.id == this.addTaxId).name;
            this.editingItem.taxPercentage = this.taxes.find(x => x.id == this.addTaxId).amount;
            this.editingItem.taxAmount = parseFloat((this.editingItem.postDiscount1Price * (this.editingItem.taxPercentage / 100)).toFixed(6));
            this.editingItem.totalTaxAmount = this.editingItem.taxAmount * this.editingItem.quantity;
            this.editingItem.finalPrice = this.finalPrice;

            this.editingItem.totalDiscount1Amount = this.editingItem.discount1Amount * this.editingItem.quantity;
            this.editingItem.totalDiscount2Amount = this.editingItem.discount2Amount * this.editingItem.quantity;
            this.editingItem.totalPostDiscount1Price = this.editingItem.postDiscount1Price * this.editingItem.quantity;
            this.editingItem.totalPostDiscountPrice = this.editingItem.postDiscountPrice * this.editingItem.quantity;
            this.editingItem.totalPrice = this.editingItem.finalPrice * this.editingItem.quantity;
            this.editingItem.totalCombinedDiscountAmount = this.editingItem.combinedDiscountAmount * this.editingItem.quantity;

            var item = this.creatingDispatchNote.articles.find(x => x.id == this.editingItem.id);
            item = this.editingItem;

            this.creatingDispatchNote.totalAmount = 0;
            this.creatingDispatchNote.articles.forEach(x => this.creatingDispatchNote.totalAmount += x.totalPrice);
            this.creatingDispatchNote.totalBase = 0;
            this.creatingDispatchNote.articles.forEach(x => this.creatingDispatchNote.totalBase += (x.basePrice * x.quantity));
            this.creatingDispatchNote.totalTax = 0;
            this.creatingDispatchNote.articles.forEach(x => this.creatingDispatchNote.totalTax += x.taxPercentage);
            this.creatingDispatchNote.totalDiscount = 0;
            this.creatingDispatchNote.articles.forEach(x => this.creatingDispatchNote.totalDiscount += (x.basePrice - x.postDiscountPrice));
        }

        this.editDispatchNote();
        if (this.creatingDispatchNote.affectWarehouseState && this.itemLookupComponent.articles.find(x=>x.id==item.id)!=undefined)
            this.itemLookupComponent.updateWarehouseState(item.id, quantityDiff);
        this.editingItem = undefined;
        this.resetItemForm();
        if (this.saveState.newDispatchNoteActive)
            this.updateState();
    }

    validateAddForm() {
        var check = true;

        if (this.selectedCustomer == undefined) {
            check = false;
            this.showCustomerError = true;
        }
        else {
            this.showCustomerError = false;
        }

        if (this.addPaymentTypeId == 0) {
            $("#add_invoice_paymentTypeId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_paymentTypeId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.addWarehouseId == 0) {
            $("#add_invoice_warehouseId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_warehouseId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.addCurrencyId == 0) {
            $("#add_invoice_currencyId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_currencyId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    proceedToAddItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addDispatchNoteItemsTrigger").click();
    }

    proceedToEditItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addDispatchNoteItemsTrigger").click();
    }

    backToHeader() {
        $("#headerModalTrigger").click();
    }

    enteredPrice(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            if (this.iForm.basePrice.value != undefined)
                this.basePrice = parseFloat(this.iForm.basePrice.value.toFixed(6));
            else this.basePrice = undefined;

            if (this.basePrice == 0) {
                this.openFinalPriceModal.nativeElement.click();
                this.finalPrice = undefined;
            }
            else {
                this.recalculatePrices();
            }
        }
    }
    updateBasePrice() {
        if (this.iForm.basePrice.value != undefined)
            this.basePrice = parseFloat(this.iForm.basePrice.value.toFixed(6));
        else this.basePrice = undefined;
    }
    backToBase() {
        this.closeFinalPriceModal.nativeElement.click();
    }
    enteredFinal(event: KeyboardEvent) {
        if (event.key == 'Tab' || event.key == 'Enter') {
            event.preventDefault();
            this.recalculatePrices(false);
            this.backToBase();
            this._changeRef.detectChanges();
        }
    }
    recalculatePrices(fromBaseToFinal: boolean = true) {
        if (this.iForm.discount1.value == undefined)
            this.iForm.discount1.setValue(0);
        if (this.iForm.discount2.value == undefined)
            this.iForm.discount1.setValue(0);
        var taxToApply = this.taxes.find(x => x.id == this.addTaxId).amount;

        if (fromBaseToFinal) {
            if (this.basePrice == undefined)
                return;
            this.basePriceDisplay = parseFloat(this.basePrice.toFixed(2));
            var postDiscount1Price = this.basePrice * (1 - (this.iForm.discount1.value / 100))
            this.postDiscountPrice = parseFloat((postDiscount1Price * (1 - (this.iForm.discount2.value / 100))).toFixed(6));
            this.finalPrice = parseFloat((this.postDiscountPrice + (this.postDiscountPrice * taxToApply / 100)).toFixed(6));
        }
        else {
            this.postDiscountPrice = parseFloat((this.finalPrice / (1 + taxToApply / 100)).toFixed(6));
            var postDiscount1 = this.postDiscountPrice * (1 + (this.iForm.discount2.value / 100));
            this.basePrice = parseFloat((postDiscount1 * (1 + (this.iForm.discount1.value / 100))).toFixed(6));
            this.basePriceDisplay = parseFloat(this.basePrice.toFixed(2));
            this.iForm.basePrice.setValue(this.basePriceDisplay);
        }
    }

    async enteredDiscount(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            try {
                var dto = new UpdateInvoiceDiscountDTO();
                dto.discount = this.invoiceDiscount;
                dto.id = this.creatingDispatchNote.id;
                dto.typeCode = "OUTINV";
                await this.dispatchNoteRest.updateDiscount(dto);

                this.creatingDispatchNote.invoiceDiscount = this.invoiceDiscount;
                this.saveState.activeDispatchNotePreEdit.invoiceDiscount = this.invoiceDiscount;
                this.updateState();
                $("#discount1").val(this.invoiceDiscount);
                this.iForm.discount1.setValue(this.invoiceDiscount);
                this.updateState();
                this.closeDispatchNoteDiscountModal.nativeElement.click();
                toastr.options = this.data.toastrOptions;
                toastr.success("DispatchNote discount updated!");
            } catch (err) {
                toastr.options = this.data.toastrOptions;
                toastr.error(err.error['Message'], this.translate.instant("_Error"));
            }
        }
    }
    negateEnter(event: KeyboardEvent) {
        if (event.key == "Enter")
            event.preventDefault();
    }

    editNumber() {
        if (!this.canEditNumber())
            return;

        this.invoiceNumberOpen.nativeElement.click();
        var input = $("#invoiceNumberInput");
        input.removeClass("is-invalid");

        if (this.creatingDispatchNote.documentNumber != null)
            input.val(this.creatingDispatchNote.documentNumber);
        else
            input.val(this.nextNumber);
    }
    canEditNumber() {
        if (this.editEnabled && !this.startedEditing)
            return false;

        return true;
    }
    saveNumber() {
        var input = $("#invoiceNumberInput");
        if (input.val() == '' || input.val().includes('.')) {
            input.addClass("is-invalid");
            return;
        } else {
            input.removeClass("is-invalid");
            this.creatingDispatchNote.documentNumber = input.val();
            this.creatingDispatchNote.customDocumentNumber = this.creatingDispatchNote.documentNumber;
            this.creatingDispatchNote.dispatchNoteNumber = this.creatingDispatchNote.documentNumber + "/" + ((new Date()).getFullYear() % 100);
        }

        this.updateState();
        this.invoiceNumberClose.nativeElement.click();
    }

    switchToHeaderAndEnableAdding() {
        this.headerTrigger.nativeElement.click();
        this.enableAdding();
    }

    enableAdding() {
        this.getNextNumber();
        this.resetSave();
        this.resetForm();
        this.addEnabled = true;
        this.saveState.addEnabled = true;
        this.saveState.newDispatchNoteActive = true;
        this.updateState();
        this.enableHeaderForm();
        this.firstHeaderFormElement.nativeElement.focus();
    }
    setPostAddingLayout() {
        this.addEnabled = false;
        this.saveState.addEnabled = false;
        this.editEnabled = true;
        this.saveState.editEnabled = true;
        this.startedEditing = true;
        this.saveState.startedEditing = true;
        this.updateState();
        this.disableHeaderForm(false);
        this.enableItemForm();
        this.itemsTrigger.nativeElement.click();
    }

    async addItem(save: boolean = false) {
        this.isSubmitted = false;
        if (this.addItemForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateItemForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted) {
            return;
        }

        var itemId = 0; var itemQuantity = 0;
        var existingPriceArticle = this.checkForSamePriceItem(this.selectedArticle);
        if (existingPriceArticle != null) {
            existingPriceArticle.quantity += this.iForm.quantity.value;
            existingPriceArticle.totalPrice += existingPriceArticle.finalPrice * this.iForm.quantity.value;
            existingPriceArticle.totalDiscount1Amount = existingPriceArticle.discount1Amount * existingPriceArticle.quantity;
            existingPriceArticle.totalDiscount2Amount = existingPriceArticle.discount2Amount * existingPriceArticle.quantity;
            existingPriceArticle.totalPostDiscount1Price = existingPriceArticle.postDiscount1Price * existingPriceArticle.quantity;
            existingPriceArticle.totalPostDiscountPrice = existingPriceArticle.postDiscountPrice * existingPriceArticle.quantity;
            existingPriceArticle.totalTaxAmount = existingPriceArticle.taxAmount * existingPriceArticle.quantity;

            this.creatingDispatchNote.totalAmount += parseFloat((existingPriceArticle.finalPrice * this.iForm.quantity.value).toFixed(6));
            this.creatingDispatchNote.totalBase += parseFloat((existingPriceArticle.basePrice).toFixed(6));
            this.creatingDispatchNote.totalTax += existingPriceArticle.taxPercentage;
            this.creatingDispatchNote.totalDiscount += (existingPriceArticle.basePrice - existingPriceArticle.postDiscountPrice);

            itemId = existingPriceArticle.id;
            itemQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
        }
        else {
            var item = new SoldArticleModel();
            item.id = this.selectedArticle.id;
            item.code = this.selectedArticle.code;
            item.name = this.selectedArticle.name;
            item.quantity = this.iForm.quantity.value;
            item.basePrice = this.basePrice;
            item.totalBasePrice = item.basePrice * item.quantity;
            item.measureShort = this.selectedArticle.measureName;

            item.discount1Percentage = this.iForm.discount1.value;
            item.discount1Amount = parseFloat((item.basePrice * (item.discount1Percentage / 100)).toFixed(6));
            item.postDiscount1Price = parseFloat((item.basePrice * (1 - (item.discount1Percentage / 100))).toFixed(6));
            item.discount2Percentage = this.iForm.discount2.value;
            item.discount2Amount = parseFloat((item.postDiscount1Price * (item.discount2Percentage / 100)).toFixed(6));
            item.postDiscountPrice = this.postDiscountPrice;
            item.combinedDiscountAmount = item.discount1Amount + item.discount2Amount;
            item.isNormative = this.selectedArticle.isNormative;
            item.g = this.selectedArticle.groupId;
            item.gt = this.selectedArticle.groupTypeId;

            item.taxId = this.addTaxId;
            item.taxName = this.taxes.find(x => x.id == this.addTaxId).name;
            item.taxPercentage = this.selectedDispatchNoteTax.amount;
            item.taxAmount = parseFloat((item.postDiscountPrice * (item.taxPercentage / 100)).toFixed(6));

            item.totalDiscount1Amount = item.discount1Amount * item.quantity;
            item.totalDiscount2Amount = item.discount2Amount * item.quantity;
            item.totalCombinedDiscountAmount = item.combinedDiscountAmount * item.quantity;
            item.totalPostDiscount1Price = item.postDiscount1Price * item.quantity;
            item.totalPostDiscountPrice = item.postDiscountPrice * item.quantity;
            item.totalTaxAmount = item.taxAmount * item.quantity;
            item.finalPrice = this.finalPrice;
            item.totalPrice = item.finalPrice * item.quantity;
            item.nc = this.selectedArticle.nc;
            item.mpc = this.selectedArticle.mpc;
            this.creatingDispatchNote.articles.push(item);
            this.creatingDispatchNote.totalAmount += parseFloat((item.totalPrice).toFixed(6));
            this.creatingDispatchNote.totalBase += parseFloat((item.basePrice * item.quantity).toFixed(6));
            this.creatingDispatchNote.totalTax += item.taxPercentage;
            this.creatingDispatchNote.totalDiscount += (item.basePrice - item.postDiscountPrice);

            itemId = item.id;
            itemQuantity = item.quantity;
            item.uniqueItemId = uuidv4();
        }

        this.selectedArticle = undefined;
        this.resetItemForm();

        this.updateState();

        if (save) {
            await this.editDispatchNote(true);
            if (this.creatingDispatchNote.affectWarehouseState && this.itemLookupComponent.articles.find(x=>x.id==item.id)!=undefined)
                this.itemLookupComponent.updateWarehouseState(itemId, -itemQuantity);
        }
    }

    resetItemForm() {
        this.finalPrice = undefined;
        this.basePrice = 0;
        $(this.finalPriceInput.nativeElement).val(undefined);
        $("#itemCodeInput").val(undefined);
        this.iForm.quantity.setValue(1);
        this.iForm.basePrice.setValue(null);
        this.iForm.discount1.setValue(this.creatingDispatchNote.invoiceDiscount);
        this.iForm.discount2.setValue(0);
        $("#add_item_taxId").val(this.taxes[0].id).trigger("change");
        this.selectedArticle = undefined;
        this.editingItem = undefined;

        $("#itemCodeInput").removeClass('is-invalid');
        $("#add_item_taxId").nextAll('span').first().removeClass('is-invalid');
    }

    checkForSamePriceItem(activeItem: ArticleModel = null, updating: boolean = false) {
        if (updating == false)
            return this.creatingDispatchNote.articles.find(x => x.id == activeItem.id && x.finalPrice == this.finalPrice);
        else
            return this.creatingDispatchNote.articles.find(x => x.id == this.editingItem.id && x.finalPrice == this.finalPrice && this.creatingDispatchNote.articles.indexOf(x) != this.editingItemIndex);
    }

    async removeItem(item: SoldArticleModel) {
        this.creatingDispatchNote.totalAmount -= item.totalPrice;
        this.creatingDispatchNote.totalBase -= item.basePrice;
        this.creatingDispatchNote.totalTax -= item.taxPercentage;
        this.creatingDispatchNote.totalDiscount -= (item.basePrice - item.postDiscountPrice);

        this.creatingDispatchNote.articles.forEach((element, index) => {
            if (element == item) this.creatingDispatchNote.articles.splice(index, 1);
        });

        await this.editDispatchNote();
        if (this.creatingDispatchNote.affectWarehouseState && this.itemLookupComponent.articles.find(x=>x.id==item.id)!=undefined)
            this.itemLookupComponent.updateWarehouseState(item.id, item.quantity);

        if (this.saveState.newDispatchNoteActive)
            this.updateState();
    }


    //Payments
    addPayment() {
        if (this.paymentsToAdd == undefined) {
            this.paymentsToAdd = [];
            this.creatingDispatchNote.payments.forEach(x => this.paymentsToAdd.push(x));
        }

        if (this.newPaymentAmount == 0 || this.newPaymentAmount == undefined) {
            $("#newPaymentAmount").addClass('is-invalid');
            return;
        } else {
            $("#newPaymentAmount").removeClass('is-invalid');
        }
        var newPayment = new InvoiceCustomerPaymentModel();
        newPayment.amount = this.newPaymentAmount;
        newPayment.date = this.newPaymentDate;
        newPayment.displayDate = this.datePipe.transform(this.newPaymentDate, 'yyyy-MM-dd');
        this.paymentsToAdd.push(newPayment);
        this.totalPaymentsAmount += newPayment.amount;
    }
    removePayment(payment: InvoiceCustomerPaymentModel) {
        if (this.paymentsToAdd == undefined) {
            this.paymentsToAdd = [];
            this.creatingDispatchNote.payments.forEach(x => this.paymentsToAdd.push(x));
        }

        this.paymentsToAdd.forEach((element, index) => {
            if (element == payment) this.paymentsToAdd.splice(index, 1);
        });
        this.totalPaymentsAmount -= payment.amount;
    }
    async updatePaymentInfo() {
        var dto = new UpdateInvoicePaymentDTO();
        dto.invoiceId = this.creatingDispatchNote.id;
        dto.paidOff = this.addPaidOff;
        dto.payments = this.paymentsToAdd;

        try {
            await this.dispatchNoteRest.updatePaymentData(dto);
            this.creatingDispatchNote.payments = dto.payments;
            this.creatingDispatchNote.paidOff = this.addPaidOff;
            this.saveState.activeDispatchNotePreEdit = this.makePreEditObject();
            this.updateState();
            await this.fetchDispatchNotes();
            this.closePaymentsModal.nativeElement.click();
            this.resetPaymentForm();
            toastr.options = this.data.toastrOptions;
            toastr.success("Payment info updated!");
        } catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }
    cancelPaymentChanges() {
        var paymentsToRemove = this.paymentsToAdd.filter(x => !this.creatingDispatchNote.payments.includes(x));
        paymentsToRemove.forEach(x => this.totalPaymentsAmount -= x.amount);
        this.paymentsToAdd = [];
        this.creatingDispatchNote.payments.forEach(x => this.paymentsToAdd.push(x));
    }
    resetPaymentForm() {
        this.addPaidOff = this.saveState.activeDispatchNotePreEdit.paidOff;
        this.pForm.newPaymentAmount.setValue(0);
        this.newPaymentDate = new Date(),
            $("#kt_new_payment_add_picker").data('daterangepicker').setStartDate(new Date());
    }
    closePaymentModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closePaymentsModal.nativeElement.click();
                self.cancelPaymentChanges();
                self.resetPaymentForm();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    validateItemForm() {
        var check = true;

        if (this.selectedArticle == undefined) {
            check = false;
            $("#itemCodeInput").addClass('is-invalid');
        }
        else {
            $("#itemCodeInput").removeClass('is-invalid');
        }

        if (this.addTaxId == 0) {
            $("#add_item_taxId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_item_taxId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    async selectArticle(a: ArticleModel) {
        $("#itemCodeInput").val(a.code);
        this.selectedArticle = a;

        if (this.selectedArticle != undefined) {
            $("#itemCodeInput").removeClass('is-invalid');
            this.basePrice = 0;
            this.iForm.basePrice.setValue(undefined);

            var groupTaxId = this.groups.find(x => x.id == this.selectedArticle.groupId).taxId;
            $("#add_item_taxId").val(groupTaxId).trigger("change");
        }
    }
    selectArticleByCode(event: KeyboardEvent, c: string) {
        if (event.key != 'Enter')
            return;

        event.preventDefault();
        this.selectedArticle = this.itemLookupComponent.articles.find(x => x.code == c);

        if (this.selectedArticle != undefined) {
            $("#itemCodeInput").removeClass('is-invalid');
            this.iForm.basePrice.setValue(0);
            this.iForm.basePrice.setValue(undefined);
            var groupTaxId = this.groups.find(x => x.id == this.selectedArticle.groupId).taxId;
            $("#add_item_taxId").val(groupTaxId).trigger("change");
        }
    }
    enterOnRow(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            var element = event.target as HTMLElement;
            element.click();
        }
    }

    switchToArticles() {
        this.itemsTrigger.nativeElement.click();
        this.startAddingItems();
    }

    switchToHeader() {
        this.headerTrigger.nativeElement.click();
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchDispatchNotes();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchDispatchNotes();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchDispatchNotes();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchDispatchNotes();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchDispatchNotes();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}