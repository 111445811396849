import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DataService } from "./data.service";
import { DeleteDocumentDTO } from "../models/documents/deleteDocumentDTO";
import { StocktakingFilter, StocktakingSource, StocktakingModel } from "../models/documents/stockTaking.model";

@Injectable()
export class StocktakingRestService{
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async getStocktaking(warehouseId:number, dateFrom:string, dateTo:string, clientId:number, page:number, pageSize:number){
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&ClientId=" + clientId + "&Page=" + page + "&PageSize=" + pageSize;
        
        if(warehouseId!=undefined)
            params+="&WarehouseId="+warehouseId;
        
        var config = environment.apiUrl;
        return await this.http.get(config + "api/Stocktaking/FilterStocktakings" + params).toPromise() as any;
    }

    async createStocktaking(obj: StocktakingModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Stocktaking/CreateStocktakingDocument", obj).toPromise() as any;
    }

    async getStocktakingArticles(id:string, lastSource:number, focusLast:boolean, page:number, pageSize:number){
        var params = "?StocktakingDocumentId=" + id + "&StartingGroup=" + lastSource + "&JumpToLastChanged=" + focusLast + "&Page=" + page + "&PageSize=" + pageSize;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/Stocktaking/GetStocktakingArticles" + params).toPromise() as any;
    }

    async getStocktakingArticleByBarcode(id:string, barcode:string, clientId:number){
        var params = "?StocktakingDocumentId=" + id + "&Barcode=" + barcode + "&ClientId=" + clientId;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/Stocktaking/GetStocktakingArticleByBarcode" + params).toPromise() as any;
    }

    async addSourcesToStocktaking(id:string, addedSources:StocktakingSource[]){
        var obj:any={
            stocktakingDocumentId:id,
            sources:addedSources
        };
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Stocktaking/AddSourcesToStocktakingDocument", obj).toPromise() as any;
    }

    async updateFiltersOnStocktakingDocument(id:string, updatedFilters:StocktakingFilter[], hideEntered:boolean, sortByGroup:boolean){
        var obj:any={
            stocktakingDocumentId:id,
            updatedFilters:updatedFilters,
            hideEntered:hideEntered,
            sortByGroup:sortByGroup
        };
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Stocktaking/UpdateFiltersOnStocktakingDocument", obj).toPromise() as any;
    }

    async updateArticleState(stocktakingId:string, articleId:number, realState:number){
        var obj:any={
            stocktakingDocumentId:stocktakingId,
            articleId:articleId,
            updatedRealState:realState
        };
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Stocktaking/UpdateArticleRealState", obj).toPromise() as any;
    }

    async editHeader(obj: any) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Stocktaking/UpdateStocktakingDocumentHeader", obj).toPromise() as any;
    }

    async updateControl(id:string, change:boolean){
        var obj = {
            id:id,
            updatedControl:change
        };
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Stocktaking/UpdateUIControl", obj).toPromise() as any;
    }

    async getNextNumber(clientId:number){
        var params = "?ClientId=" + clientId + "&TypeCode=" + "STOCK";
        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentGeneral/GetNextNumber" + params).toPromise() as any;
    }

    async deleteStocktaking(deleteId: string) {
        var dto = new DeleteDocumentDTO();
        dto.id=deleteId;
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Stocktaking/DeleteStocktakingDocument", dto).toPromise() as any;
    }

    async getNextDifferenceNumber(clientId:number){
        var params = "?ClientId=" + clientId + "&TypeCode=" + "STOCKDIFF";
        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentGeneral/GetNextNumber" + params).toPromise() as any;
    }

    async createStocktakingDifference(id:string, diffNumber:number, allStates:boolean){
        var obj:any={
            stocktakingDocumentId:id,
            documentNumber:diffNumber,
            allStatesEntered:allStates
        };
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Stocktaking/CreateStocktakingDifference", obj).toPromise() as any;
    }

    async getStocktakingDifferences(warehouseId:number, dateFrom:string, dateTo:string, clientId:number, page:number, pageSize:number){
        var params = "?FromDate=" + dateFrom + "&ToDate=" + dateTo + "&ClientId=" + clientId + "&Page=" + page + "&PageSize=" + pageSize;
        
        if(warehouseId!=undefined)
            params+="&WarehouseId="+warehouseId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/Stocktaking/GetStocktakingDifferences" + params).toPromise() as any;
    }

    async getStocktakingDifferenceArticles(id:string, sourceFilters:number[], sortByGroup:boolean, lastSource:number, page:number, pageSize:number){
        var params = "?StocktakingDifferenceId=" + id + "&StartingGroup=" + lastSource + "&SortByGroup=" + sortByGroup + "&Page=" + page + "&PageSize=" + pageSize;
        var config = environment.apiUrl;

        if(sourceFilters!=undefined && sourceFilters!=null && sourceFilters.length>0){
            params+="&Sources="+sourceFilters;
        }

        var dto={
            StocktakingDifferenceId:id,
            StartingGroup:lastSource,
            SortByGroup:sortByGroup,
            Page:page,
            PageSize:pageSize,
            Sources:sourceFilters
        }

        return await this.http.post(config + "api/Stocktaking/GetStocktakingDifferenceArticles",dto).toPromise() as any;
    }

    async getStocktakingDifferencesPDF(warehouseId:number, dateFrom:string, dateTo:string, clientId:number, stocktakingDifferenceNumber:string, page:number, pageSize:number){
        var obj={
            page: page,
            pageSize: pageSize,
            clientId: clientId,
            stocktakingDifferenceNumber: parseFloat(stocktakingDifferenceNumber),
            dateFrom: dateFrom,
            dateTo: dateTo,
            warehouseId: warehouseId
          }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Stocktaking/GetStocktakingDifferenceSourcePDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }
}