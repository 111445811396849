import { StocktakingModel } from "../stockTaking.model";

export class StocktakingSaveModel{
    activeStocktaking:StocktakingModel;
    activeStocktakingPreEdit:StocktakingModel;
    
    addEnabled:boolean;
    editEnabled:boolean;
    startedEditing:boolean;
    editingItems:boolean;
    headerSaved:boolean;
    newStocktakingActive:boolean;
}