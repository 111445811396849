import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { TaxModel } from "../models/tax.model";
import { DataService } from "./data.service";

@Injectable()
export class TaxRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }


    async getTaxes(clientId: number, name: string,  page: number, pageSize: number) {
        var params = "?Name=" + name + "&Page=" + page + "&PageSize=" + pageSize;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/Tax/FilterTaxes" + params).toPromise() as any;
    }

    async updateTax(obj: TaxModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Tax/UpdateTax", obj).toPromise() as any;
    }

    async createTax(obj: TaxModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Tax/CreateTax", obj).toPromise() as any;
    }

    async getTaxesByClientId(clientId:number){
        var params = "?";

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/Tax/GetTaxesByClientId" + params).toPromise() as any;
    }
}