import { StocktakingArticleModel } from "./stocktakingArticle.model";

export class StocktakingModel{
    id:string;
    clientId:number;
    stocktakingNumber:string;
    documentNumber:number;
    customDocumentNumber:number;
    warehouseId:number;
    warehouseName:string;
    note:string;
    lastId:number;
    articles:StocktakingArticleModel[];
    date:Date;

    filters:StocktakingFilter[];
    sources:StocktakingSource[];
    hideEntered:boolean;
    sortByGroup:boolean;
    searchInput:boolean;
}

export class StocktakingFilter{
    id:number;
    name:string;
    checked:boolean;
}
export class StocktakingSource{
    id:number;
    name:string;
    checked:boolean;
    date:Date;
}