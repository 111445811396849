import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StocktakingFilter, StocktakingSource, StocktakingModel } from 'src/app/models/documents/stockTaking.model';
import { GroupModel } from 'src/app/models/group.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleModel } from 'src/app/models/article.model';
import { StocktakingArticleModel } from 'src/app/models/documents/stocktakingArticle.model';
import { GroupRestService } from 'src/app/services/group.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/translate';
import { StocktakingRestService } from 'src/app/services/stocktaking.service';
import { StocktakingSaveModel } from 'src/app/models/documents/pageSaveModels/stocktakingSave.model';
import { minValidator } from 'src/app/validators/minValidator';
import toastr from 'toastr';
import { ArticleRestService } from 'src/app/services/article.service';
import { StocktakingLogModel } from 'src/app/models/documents/stocktakingLog.mode';
import { UpdateInvoiceDiscountDTO } from 'src/app/models/documents/updateInvoiceDiscountDTO';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;

@Component({
    selector: 'app-stocktaking',
    templateUrl: './stocktaking.component.html'
})
export class StocktakingComponent implements OnInit {
    public selectedRow: string = undefined;
    public stocktakings: StocktakingModel[];
    public groups: GroupModel[];
    public warehouses: WarehouseModel[];
    public retailPlaces: RetailPlaceModel[];
    public emptyData: boolean = false;
    public searchForm: UntypedFormGroup;
    public addForm: UntypedFormGroup;
    public isSubmitted: boolean;
    public selectedStocktaking: StocktakingModel;
    public selectedStocktakingWarehouse: WarehouseModel;
    public selectedArticle: StocktakingArticleModel;
    public addWarehouseId: number = 0;
    public filterWarehouseId: number;
    public headerForm: UntypedFormGroup;
    get hForm() { return this.headerForm.controls; }
    public headerFormEnabled: boolean;
    public onHeaderTab: boolean = true;

    public activeStocktaking: StocktakingModel;
    public totalAmount: number = 0;
    tableSetUp: boolean = false;
    public addTaxId: number = 0;
    public basePrice: number;
    public basePriceDisplay: number;
    public triggerFinal: boolean;
    public finalPrice: number;
    public postDiscountPrice: number;
    public invoiceDiscount: number;
    public addItemForm: UntypedFormGroup;
    public selectedCurrency: string;
    dateFrom: string;
    dateTo: string;
    get iForm() { return this.addItemForm.controls; }
    selectionModalTimer: any;
    enterCount: number = 0;
    creationInProgress: boolean = false;
    public previousWarehouseId: number;
    public sourcesForm: UntypedFormGroup;
    public sourceDate: string;
    public addSourceDate: Date = new Date();

    @ViewChild('closeAddInvoiceHeaderModal') closeAddInvoiceHeaderModal: ElementRef;
    @ViewChild('closeEditInvoiceHeaderModal') closeEditInvoiceHeaderModal: ElementRef;
    @ViewChild('closeEditInvoiceItemsModal') closeEditInvoiceItemsModal: ElementRef;
    @ViewChild('selectAddInvoiceWarehouseId') selectAddInvoiceWarehouseId: ElementRef;
    @ViewChild('selectEditInvoiceWarehouseId') selectEditInvoiceWarehouseId: ElementRef;
    @ViewChild('selectFilterWarehouseId') selectFilterWarehouseId: ElementRef;
    @ViewChild('headerTrigger') headerTrigger: ElementRef;
    @ViewChild('itemsTrigger') itemsTrigger: ElementRef;
    @ViewChild('listTrigger') listTrigger: ElementRef;
    @ViewChild('invoiceNumberOpen') invoiceNumberOpen: ElementRef;
    @ViewChild('invoiceNumberClose') invoiceNumberClose: ElementRef;
    @ViewChild('firstHeaderForm') firstHeaderFormElement: ElementRef;
    @ViewChild('invoiceNumberInput') invoiceNumberInput: ElementRef;
    @ViewChild('sourcesModalClose') sourcesModalClose: ElementRef;
    @ViewChild('filtersModalClose') filtersModalClose: ElementRef;
    @ViewChild('controlModalClose') controlModalClose: ElementRef;
    @ViewChild('timelineModalClose') timelineModalClose: ElementRef;
    @ViewChild('barcodeInput') barcodeInput: ElementRef;

    public saveState: StocktakingSaveModel;
    public showItems: boolean = true;
    public setUpComplete: boolean = false;
    public editEnabled: boolean = false;
    public startedEditing: boolean = false;
    public nextNumber: number;
    public addEnabled: boolean = false;
    public headerSaved: boolean = false;
    public editingItems: boolean = false;
    public newStocktakingActive: boolean = false;
    public editingItem: StocktakingArticleModel;
    public documentArticles: ArticleModel[];
    public pendingSourcesChanges: StocktakingSource[] = [];
    public pendingFilterChanges: StocktakingFilter[] = [];
    public fetchInProgress: boolean;
    public preventScrollLoadingitem: boolean = false;
    public itemsTabOpen: boolean;
    public showSpinner = false;
    public barcodeArticles: StocktakingArticleModel[] = [];
    public addDate: Date = new Date();

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;
    public resizeObserver: ResizeObserver;

    public articlePage: number = 1;
    public articlePageSize: number = 40;
    public totalArticleData: number;
    public maxArticlePage: number;
    public articlePages: number[] = [];
    public articleOffset: number = 5;
    public firstArticlePage: number = 1;
    public lastArticlePage: number;
    public counterArticlePage: number = 0;
    public firstArticleLoad: boolean = true;
    public showingSources: string[] = [];
    public focusLast: boolean = false;
    public controlUpdate: boolean;
    public enteredBarcode: boolean = false;
    public searchBarcode: string;
    public searchInputQuantity: any;
    public sourcesForTimelineDisplay: StocktakingSource[] = [];
    public logs: StocktakingLogModel[] = [{ articleId: 1, articleName: 'test', attemptedEntry: 10 }];
    public sourceAdded: boolean = false;

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        console.log(this.preventScrollLoadingitem);

        if (!this.itemsTabOpen || this.fetchInProgress || this.preventScrollLoadingitem || this.activeStocktaking.articles == undefined || this.activeStocktaking.articles.length == 0)
            return;

        let documentHeight = document.body.scrollHeight;
        let currentScroll = window.scrollY + window.innerHeight;
        let modifier = 20;
        if ((currentScroll + modifier) > documentHeight) {
            this.getArticles();
        }
    }

    constructor(
        private stocktakingRest: StocktakingRestService,
        private groupRestService: GroupRestService,
        private warehouseRestService: WarehouseRestService,
        private articleRestService: ArticleRestService,
        private datePipe: DatePipe,
        private _changeRef: ChangeDetectorRef,
        public data: DataService,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit(): void {
        KTMenu.createInstances();
        this.initializeSaveState();
        this.fetchStocktaking();
        this.fetchGroups();
        this.fetchWarehouses();
        this.createSearchForm();
        this.createHeaderForm();
        this.createSourceForm();
        this.viewDocuments();
    }

    ngAfterViewInit() {
        // $(this.headerTrigger.nativeElement).on('shown.bs.tab', ()=>{
        //     this.firstHeaderFormElement.nativeElement.focus();
        // });

        $("#kt_modal_invoice_number").on('shown.bs.modal', () => {
            this.invoiceNumberInput.nativeElement.focus();
        });

        $("#kt_modal_timeline").on('shown.bs.modal', () => {
            this.prepareSourcesForTimelineDisplay();
        });
    }

    ngAfterViewChecked() {
        if (!this.setUpComplete) {
            this.setUpFields();

            if (this.valuesMatch()) {
                this.setUpComplete = true;

                Object.keys(this.headerForm.controls).forEach(name => {
                    let control = this.headerForm.get(name);

                    control.valueChanges.subscribe(value => this.updateActiveStocktaking(name, value));
                })


                if (this.addEnabled || (this.startedEditing && !this.editingItems))
                    this.enableHeaderForm();
                else
                    this.disableHeaderForm();

                this.updateState();
                this._changeRef.detectChanges();
            }
        }
    }

    valuesMatch() {
        var check = true;

        Object.keys(this.headerForm.controls).forEach(name => {
            let control = this.headerForm.get(name);
            if (control.value != this.activeStocktaking[name]) {
                check = false;
            }
            else {
            }
        });

        return check;
    }

    async createSearchForm() {
        this.searchForm = this.formBuilder.group({
            filterWarehouseId: [null]
        })

        $("#invoice_filter_warehouseId").select2();
        $('#invoice_filter_warehouseId').on("change", function () {
            self.selectFilterWarehouseId.nativeElement.value = $(this).val();
            self.filterWarehouseId = $(this).val();
        });

        var start = new Date(new Date().getFullYear(), 0, 1);
        var end = new Date();
        var self = this;
        $("#kt_daterangepicker_filter").daterangepicker({
            startDate: start,
            endDate: end,
            autoApply: true,
            autoUpdateInput: true,
            locale: {
                format: 'DD/MM/YYYY',
                separator: '-'
            }
        });
        $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            self.fetchStocktaking();
        });
        $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
            picker.setStartDate(new Date());
            picker.setEndDate(new Date());
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            self.fetchStocktaking();
        });
        var im = new Inputmask("99/99/9999-99/99/9999");
        im.mask($("#kt_daterangepicker_filter"));
    }

    async resetFilter() {
        this.searchForm.reset();
        $("#invoice_filter_warehouseId").val(null).trigger("change");
    }

    filterByDate(event: KeyboardEvent) {
        if (event.key == "Enter" || event.key == "Tab") {
            var pickerElement = $("#kt_daterangepicker_filter");
            $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        }
    }

    async fetchGroups() {
        try {
            var response = await this.groupRestService.getGroups('', null, this.data.user.clientId, 1, 100000);
            this.groups = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchWarehouses() {
        try {
            this.warehouses = await this.warehouseRestService.getWarehousesForClient(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchStocktaking() {
        this.showSpinner = true;
        try {
            this.emptyData = false;
            if (this.dateFrom == undefined)
                this.dateFrom = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
            if (this.dateTo == undefined)
                this.dateTo = this.datePipe.transform((new Date()), 'yyyy-MM-dd');

            var response = await this.stocktakingRest.getStocktaking(this.filterWarehouseId, this.dateFrom, this.dateTo, this.data.user.clientId, this.page, this.perPage);
            this.stocktakings = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }

        }
        this.showSpinner = false;
    }

    async getNextNumber() {
        this.nextNumber = await this.stocktakingRest.getNextNumber(this.data.user.clientId);
        if (this.activeStocktaking.documentNumber == undefined) {
            this.activeStocktaking.documentNumber = this.nextNumber;
            this.activeStocktaking.stocktakingNumber = this.nextNumber + '/' + ((new Date()).getFullYear() - 2000);
            this.updateState();
        }
    }

    loadState() {
        return this.data.getDocumentState("stocktakingPage");
    }

    updateState() {
        this.saveState.activeStocktaking = this.activeStocktaking;
        this.data.saveDocumentState("stocktakingPage", this.saveState);
    }

    initializeSaveState() {
        this.saveState = this.loadState();
        if (this.saveState == undefined || this.saveState == null || !this.saveState.newStocktakingActive) {
            this.resetActiveStocktaking();
        } else if (this.saveState.newStocktakingActive) {
            this.activeStocktaking = this.saveState.activeStocktaking;
            this.addEnabled = this.saveState.addEnabled;
            this.headerSaved = this.saveState.headerSaved;
            this.editEnabled = this.saveState.editEnabled;
            this.startedEditing = this.saveState.startedEditing;
            this.editingItems = this.saveState.editingItems;
            this.newStocktakingActive = true;
        }
    }

    resetActiveStocktaking() {
        this.saveState = new StocktakingSaveModel();
        this.saveState.activeStocktaking = new StocktakingModel();
        this.saveState.activeStocktaking.clientId = this.data.user.clientId;
        this.saveState.activeStocktaking.sortByGroup = false;
        this.saveState.activeStocktaking.hideEntered = false;
        this.saveState.activeStocktaking.searchInput = false;
        this.saveState.activeStocktaking.articles = [];
        this.articlePage = 1;

        if (this.saveState.activeStocktaking.sources != undefined) {
            for (let index = 0; index < this.saveState.activeStocktaking.sources.length; index++) {
                this.saveState.activeStocktaking.sources[index].checked = false;
            }
        }
        this.activeStocktaking = this.saveState.activeStocktaking;
        this.saveState.activeStocktakingPreEdit = this.activeStocktaking;
        this.updateState();
    }

    setUpFields() {
        if (this.activeStocktaking.warehouseId == undefined && this.warehouses != undefined && !this.editEnabled) {
            $("#add_invoice_warehouseId").val(this.warehouses[0].id).trigger("change");
            this.activeStocktaking.warehouseId = this.warehouses[0].id;
        }
        else if (this.activeStocktaking.warehouseId != undefined) {
            if (this.addWarehouseId == undefined || this.addWarehouseId == null || this.addWarehouseId == 0 || isNaN(this.addWarehouseId))
                $("#add_invoice_warehouseId").val(this.activeStocktaking.warehouseId).trigger("change");
        }

        if (this.activeStocktaking.date != undefined) {
            $("#kt_stock_date_add_picker").data('daterangepicker').setStartDate(new Date(this.activeStocktaking.date));
            this.headerForm.controls.date.setValue(this.activeStocktaking.date);
        } else {
            this.addDate = new Date();
            this.headerForm.controls.date.setValue(this.addDate);
            $("#kt_stock_date_add_picker").data('daterangepicker').setStartDate(this.addDate);
            this.activeStocktaking.date = this.addDate;
        }
        this.headerForm.controls.note.setValue(this.activeStocktaking.note);

        this._changeRef.detectChanges();
    }

    updateActiveStocktaking(name: string, value: any) {
        this.activeStocktaking[name] = value;

        this.updateState();
    }

    async searchStocktaking() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchStocktaking();
    }

    setItemTabOpen(val: boolean) {
        this.itemsTabOpen = val;
    }

    viewDocuments() {
        this.setItemTabOpen(false);
        this.toggleDocNav(false);
        this.resetSave();
        this.resetForm();
        this.disableForm();
        this.headerSaved = false;
        this.startedEditing = false;
        this.editEnabled = false;
        this.addEnabled = false;
        this.editingItems = false;
    }

    async selectStocktaking(stocktaking: StocktakingModel, switchToHeaderTab: boolean = true) {
        if (switchToHeaderTab)
            this.headerTrigger.nativeElement.click();

        if (this.saveState.newStocktakingActive && this.activeStocktaking.id != stocktaking.id)
            this.saveState.newStocktakingActive = false;

        this.articlePage=1;
        this.preventScrollLoadingitem=false;
        this.activeStocktaking = stocktaking;
        this.activeStocktaking.articles=null;
        this.controlUpdate = this.activeStocktaking.searchInput;
        $('#add_invoice_warehouseId').val(this.activeStocktaking.warehouseId).trigger("change");
        $("#kt_stock_date_add_picker").data('daterangepicker').setStartDate(new Date(this.activeStocktaking.date));

        this.addEnabled = false;
        this.editEnabled = true;
        this.startedEditing = false;
        this.hForm.note.setValue(this.activeStocktaking.note);
        $("#kt_source_add_picker").data('daterangepicker').setStartDate(new Date());

        if (this.activeStocktaking.warehouseId != this.previousWarehouseId) {
            this._changeRef.detectChanges();
            this.previousWarehouseId = this.activeStocktaking.warehouseId;
        }

        if (this.activeStocktaking.searchInput) {
            this.getAllArticles();
        } else {
            this.focusLast = true;
            await this.getArticles();
            this.focusLast = false;
        }

        this.saveState.activeStocktakingPreEdit = this.makePreEditObject();
        this.selectedArticle = null;
        this.headerSaved = true;
        this.disableForm();
        // this.logs=[];
    }

    jumpToLast() {
        if (this.activeStocktaking.lastId == 0)
            return;
        if (this.activeStocktaking.articles.find(x => x.id == this.activeStocktaking.lastId) == undefined) {
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant("_Jump_not_included"), this.translate.instant("_Error"));
            return;
        }

        var row = $("[articleId=" + this.activeStocktaking.lastId + "]");
        var y = row[0].getBoundingClientRect().top - 300;
        window.scrollTo({ top: y, behavior: 'smooth' });
        row[0].focus();
    }

    jumpToBarcode() {
        window.scrollTo(0, 0);
        this.barcodeInput.nativeElement.focus();
    }

    async selectNext() {
        var index = this.stocktakings.findIndex(x => x.id == this.activeStocktaking.id);

        if (index == 0) {
            if (this.page == 1)
                return;

            await this.prevpage();
            await this.fetchStocktaking();

            if (this.stocktakings.length > 0) {
                this.selectStocktaking(this.stocktakings[this.stocktakings.length - 1]);
            }
        }
        else {
            this.selectStocktaking(this.stocktakings[index - 1]);
        }
    }

    async selectPrevious() {
        var index = this.stocktakings.findIndex(x => x.id == this.activeStocktaking.id);
        if (index == (this.stocktakings.length - 1)) {
            if (this.page == this.maxPage)
                return;

            await this.nextPage();
            await this.fetchStocktaking();

            if (this.stocktakings.length > 0) {
                this.selectStocktaking(this.stocktakings[0]);
            }
        }
        else {
            this.selectStocktaking(this.stocktakings[index + 1]);
        }
    }

    startEditing(header: boolean) {
        if (header) {
            this.headerSaved = false;
        }
        this.enableHeaderForm(false);
        this.sourceAdded = this.activeStocktaking.sources.find(x => x.checked == true) != undefined;

        this.startedEditing = true;
        if (this.editingItems)
            this.editingItems = false;
    }

    cancelEditing(header: boolean = true) {
        this.selectStocktaking(this.saveState.activeStocktakingPreEdit, false);
        this.disableForm();
        this.startedEditing = false;
        this.editEnabled = true;
        this.addEnabled = false;
        this.editingItems = false;
        if (this.activeStocktaking.id != undefined)
            this.headerSaved = true;
    }

    switchToArticles() {
        this.itemsTrigger.nativeElement.click();
    }

    exitDetails(redirectToHeader: boolean = false) {
        if (redirectToHeader)
            this.headerTrigger.nativeElement.click();

        this.resetSave();
        this.resetForm()
        this.headerSaved = false;
    }

    cancelAdding() {
        this.disableForm();
        this.resetSave();

        this.resetForm();
        this.updateState();
    }

    manualInputDate(id: string) {
        var pickerElement = $("#" + id);
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }

    selectOnFocus(id: string) {
        $("#" + id).select();
    }

    async createHeaderForm() {
        this.headerForm = this.formBuilder.group({
            warehouseId: [0, Validators.required],
            date: [null, Validators.required],
            note: [''],
        });

        var self = this;

        $("#add_invoice_warehouseId").select2();
        $('#add_invoice_warehouseId').on("change", function () {
            self.selectAddInvoiceWarehouseId.nativeElement.value = $(this).val();
            self.addWarehouseId = $(this).val();
        });

        var start = (new Date()).setDate((new Date()).getDate() - 29);
        var end = new Date();
        $("#kt_stock_date_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            autoUpdateInput: true,
            startDate: start,
            timePicker: true,
            timePicker24Hour: true,
            locale: {
                format: 'DD/MM/YYYY HH:mm',
            }
        });
        $("#kt_stock_date_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.addDate = picker.startDate.format('YYYY-MM-DD HH:mm');
            self.headerForm.controls.date.setValue(self.addDate);
        });
        var im = new Inputmask("99/99/9999");
        im.mask($("#kt_bill_date_add_picker"));


        if (this.editEnabled && !this.startedEditing)
            this.disableForm();
    }

    async createSourceForm() {
        var start = (new Date()).setDate((new Date()).getDate() - 29);
        var end = new Date();
        $("#kt_source_add_picker").daterangepicker({
            singleDatePicker: true,
            autoApply: true,
            autoUpdateInput: true,
            timePicker: true,
            startDate: start,
            endDate: end,
            locale: {
                format: 'DD/MM/YYYY HH:mm',
            }
        });
        var self = this;
        $("#kt_source_add_picker").on('apply.daterangepicker', function (ev, picker) {
            self.addSourceDate = picker.startDate._d;
            for (let index = 0; index < self.pendingSourcesChanges.length; index++) {
                const source = self.pendingSourcesChanges[index];
                source.date = self.addSourceDate;
            }
        });
        var im = new Inputmask("99/99/9999 99:99");
        im.mask($("#kt_source_add_picker"));
    }

    disableForm() {
        this.disableHeaderForm(false);
    }
    disableHeaderForm(emit: boolean = true) {
        if (emit == false) {
            this.headerForm.disable({ emitEvent: false });
        } else {
            this.headerForm.disable();
        }

        this.headerFormEnabled = false;
        $("#kt_stock_date_add_picker").prop("disabled", true);
    }
    enableForm() {
        this.enableHeaderForm();
    }
    enableHeaderForm(emit: boolean = true) {
        if (emit == false)
            this.headerForm.enable({ emitEvent: false });
        else
            this.headerForm.enable();
        this.headerFormEnabled = true;
        $("#kt_stock_date_add_picker").prop("disabled", false);
    }

    async resetForm() {
        $("#kt_stock_date_add_picker").data('daterangepicker').setStartDate(new Date());
        this.addDate = new Date();
        this.hForm.date.setValue(this.addDate);
        $('#add_invoice_warehouseId').val(this.warehouses[0].id).trigger("change");
        this.headerForm.controls.note.setValue(null);

        this.addEnabled = false;
        this.editEnabled = false;
        this.startedEditing = false;
        this.headerFormEnabled = false;
    }

    resetSave() {
        this.saveState = new StocktakingSaveModel();
        this.saveState.activeStocktaking = new StocktakingModel();
        this.saveState.activeStocktaking.clientId = this.data.user.clientId;
        this.saveState.activeStocktaking.warehouseId = this.warehouses[0].id;
        this.saveState.activeStocktaking.warehouseName = this.warehouses[0].name;
        this.saveState.activeStocktaking.articles = [];
        this.saveState.activeStocktaking.sources = [];
        this.saveState.activeStocktaking.filters = [];
        this.activeStocktaking = this.saveState.activeStocktaking;

        this.editEnabled = false;
        this.addEnabled = false;
        this.startedEditing = false;
        this.saveState.addEnabled = this.addEnabled;
    }

    async getArticles() {
        this.fetchInProgress = true;
        try {
            var lastShownSource = 0;
            if (this.activeStocktaking.sortByGroup) {
                if (this.activeStocktaking.articles != undefined) {
                    var shownSources = this.activeStocktaking.articles.filter(x => x.id == 0);
                    if (shownSources.length > 0) {
                        var sourceName = shownSources[shownSources.length - 1].name;
                        lastShownSource = this.groups.find(x => x.name == sourceName).id;
                    }
                }
            }

            var response = await this.stocktakingRest.getStocktakingArticles(this.activeStocktaking.id, lastShownSource, this.focusLast, this.articlePage, this.articlePageSize);
            
            if(this.activeStocktaking.articles==null || (response.data.length>0 && !this.lastArticleRemainsTheSame(response.data)))
                this.articlePage++;

            if (this.activeStocktaking.articles == undefined || this.activeStocktaking.articles.length == 0) {
                this.activeStocktaking.articles = response.data;
            }
            else {
                if (response.data.length==0 || this.lastArticleRemainsTheSame(response.data))
                    this.preventScrollLoadingitem = true;
                else
                    this.activeStocktaking.articles = this.activeStocktaking.articles.concat(response.data);
                
                // var previousLength=this.activeStocktaking.articles.length;
                // this.activeStocktaking.articles = this.activeStocktaking.articles.concat(response.data);
                // if(this.activeStocktaking.articles.length==previousLength)
                //     this.preventScrollLoadingitem=true;
            }
        }
        catch (err) {
            console.log(err);
            if (err.status == 401) {
                this.data.logout();
            }
        }
        this.fetchInProgress=false;
    }

    lastArticleRemainsTheSame(response: any) {
        return this.activeStocktaking.articles[this.activeStocktaking.articles.length - 1].id == response[response.length - 1].id;
    }

    async getAllArticles() {
        this.fetchInProgress = true;
        try {
            var response = await this.stocktakingRest.getStocktakingArticles(this.activeStocktaking.id, 0, false, this.articlePage, 999999);
            this.activeStocktaking.articles = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
        this.fetchInProgress=false;
    }

    async addStocktaking() {
        if (this.creationInProgress)
            return;
        this.creationInProgress = true;
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            this.creationInProgress = false;
            return;
        }

        this.initSourcesOnNewDocuments();
        this.activeStocktaking.lastId = 0;
        this.activeStocktaking.searchInput = this.controlUpdate;

        try {
            await this.stocktakingRest.createStocktaking(this.activeStocktaking);
            this.activeStocktaking.searchInput = false;
            this.articlePage=1;
            this.activeStocktaking.id = await this.getNewlyCreatedId();
            this.saveState.activeStocktaking.id = this.activeStocktaking.id;
            this.saveState.activeStocktakingPreEdit = this.makePreEditObject();
            this.headerSaved = true;
            this.editingItems = true;
            this.saveState.editingItems = true;
            this.saveState.headerSaved = true;
            this.updateState();
            this.setPostAddingLayout();

            if (this.activeStocktaking.warehouseId != this.previousWarehouseId) {
                this.previousWarehouseId = this.activeStocktaking.warehouseId;
            }
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_stocktaking_text'), this.translate.instant("_Success"));
            this.fetchStocktaking();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
        this.creationInProgress = false;
    }
    initSourcesOnNewDocuments() {
        this.activeStocktaking.sources = [];
        this.groups.forEach(g => {
            var source = new StocktakingSource();
            source.id = g.id;
            source.name = g.name;
            source.checked = false;
            this.activeStocktaking.sources.push(source);
        });
    }

    makePreEditObject() {
        var obj = new StocktakingModel();
        Object.keys(this.activeStocktaking).forEach(key => {
            if (key != 'articles') {
                let value = this.activeStocktaking[key];
                obj[key] = value;
            }
        });
        obj.articles = [];
        if (this.activeStocktaking.articles != undefined) {
            this.activeStocktaking.articles.forEach(x => {
                var itemCopy = new StocktakingArticleModel();
                Object.keys(x).forEach(key => {
                    let value = x[key];
                    itemCopy[key] = value;
                })
                obj.articles.push(itemCopy);
            });
        }

        return obj;
    }
    async getNewlyCreatedId() {
        var response = await this.stocktakingRest.getStocktaking(this.filterWarehouseId, this.datePipe.transform(this.activeStocktaking.date, 'yyyy-MM-dd'), this.datePipe.transform(this.activeStocktaking.date, 'yyyy-MM-dd'), this.data.user.clientId, 1, 1)
        var id = response.data[0].id;
        return id;
    }

    checkSource(id: number) {
        var existing = this.pendingSourcesChanges.find(x => x.id == id);
        if (existing != undefined) {
            this.pendingSourcesChanges.splice(this.pendingSourcesChanges.indexOf(existing), 1);
            return;
        }

        var change = new StocktakingSource();
        var source = this.activeStocktaking.sources.find(x => x.id == id);
        change.id = source.id;
        change.name = source.name;
        change.checked = !source.checked;
        change.date = this.addSourceDate;

        this.pendingSourcesChanges.push(change);
    }
    async addSources() {
        this.isSubmitted = false;
        if (this.pendingSourcesChanges.length == 0) {
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant('_No_new_sources_selected'), this.translate.instant("_Error"));
            return;
        }

        try {
            //add articles to the document
            await this.stocktakingRest.addSourcesToStocktaking(this.activeStocktaking.id, this.pendingSourcesChanges)
            this.pendingSourcesChanges.forEach(source => {
                var filter = new StocktakingFilter();
                filter.id = source.id;
                filter.name = source.name;
                filter.checked = true;
                this.activeStocktaking.filters.push(filter);
                this.activeStocktaking.sources.find(x => x.id == source.id).checked = source.checked;
            });
            this.saveState.activeStocktakingPreEdit = this.makePreEditObject();
            this.sourcesModalClose.nativeElement.click();
            this.pendingSourcesChanges = [];

            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_sources_text'), this.translate.instant("_Success"));
            this.fetchStocktaking();
            this.activeStocktaking.articles = undefined;
            await this.getArticles();
            this.preventScrollLoadingitem = false;
            this.updateState();
        }
        catch (err: any) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    async editHeader(instantReverse: boolean = false) {
        this.isSubmitted = false;
        if (this.hForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        try {
            var obj: any = {
                id: this.activeStocktaking.id,
                documentNumber: this.activeStocktaking.documentNumber,
                stocktakingNumber: this.activeStocktaking.stocktakingNumber,
                warehouseId: this.activeStocktaking.warehouseId,
                date: this.activeStocktaking.date,
                note: this.activeStocktaking.note
            };
            await this.stocktakingRest.editHeader(obj);
            this.saveState.activeStocktakingPreEdit = this.makePreEditObject();
            this.startedEditing = false;
            this.editingItems = false;
            this.saveState.startedEditing = false;
            this.saveState.editingItems = false;
            this.headerSaved = true;
            this.saveState.headerSaved = true;
            this.disableForm();
            this.updateState();

            if (this.activeStocktaking.warehouseId != this.previousWarehouseId) {
                this.previousWarehouseId = this.activeStocktaking.warehouseId;
            }

            $("#kt_source_add_picker").data('daterangepicker').setStartDate(this.addDate);


            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Edited_stocktaking_text'), this.translate.instant("_Success"));
            this.fetchStocktaking();
        }
        catch (err) {
            if (instantReverse)
                this.activeStocktaking = this.saveState.activeStocktakingPreEdit;

            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    async deleteStocktaking(id: string) {
        var self = this;
        var question_text = this.translate.instant("_Delete_stocktaking_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.showSpinner = true;
                await this.stocktakingRest.deleteStocktaking(id);
                if (this.activeStocktaking.id == id) {
                    this.resetActiveStocktaking();
                    this.disableForm();
                    this.startedEditing = false;
                    this.editEnabled = true;
                    this.addEnabled = false;
                    this.editingItems = false;
                    this.resetForm();
                }
                await this.fetchStocktaking();
                this.showSpinner = false;
                toastr.options = this.data.toastrOptions;
                toastr.success(this.translate.instant('_Deleted_stocktaking_text'), this.translate.instant("_Success"));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    validateAddForm() {
        var check = true;

        if (this.addWarehouseId == 0) {
            $("#add_invoice_warehouseId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_invoice_warehouseId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    proceedToAddItems() {
        this.isSubmitted = false;
        if (this.headerForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        $("#addInvoiceItemsTrigger").click();
    }

    backToHeader() {
        $("#headerModalTrigger").click();
    }

    negateEnter(event: KeyboardEvent) {
        if (event.key == "Enter")
            event.preventDefault();
    }

    editNumber() {
        if (!this.canEditNumber())
            return;

        this.invoiceNumberOpen.nativeElement.click();
        var input = $("#invoiceNumberInput");
        input.removeClass("is-invalid");

        if (this.activeStocktaking.documentNumber != null)
            input.val(this.activeStocktaking.documentNumber);
        else
            input.val(this.nextNumber);
    }
    canEditNumber() {
        if (this.editEnabled && !this.startedEditing)
            return false;

        return true;
    }
    saveNumber() {
        var input = $("#invoiceNumberInput");
        if (input.val() == '' || input.val().includes('.')) {
            input.addClass("is-invalid");
            return;
        } else {
            input.removeClass("is-invalid");
            this.activeStocktaking.documentNumber = input.val();
            this.activeStocktaking.customDocumentNumber = this.activeStocktaking.documentNumber;
            this.activeStocktaking.stocktakingNumber = this.activeStocktaking.documentNumber + "/" + ((new Date()).getFullYear() % 100);
        }

        this.updateState();
        this.invoiceNumberClose.nativeElement.click();
    }

    switchToHeaderAndEnableAdding() {
        this.headerTrigger.nativeElement.click();
        this.enableAdding();
    }


    switchToHeader() {
        this.headerTrigger.nativeElement.click();
    }

    enableAdding() {
        this.getNextNumber();
        this.resetSave();
        this.resetForm();
        this.addEnabled = true;
        this.headerSaved = false;
        this.saveState.addEnabled = true;
        this.saveState.newStocktakingActive = true;
        this.updateState();
        this.enableHeaderForm();
        // this.firstHeaderFormElement.nativeElement.focus();
    }
    setPostAddingLayout() {
        this.addEnabled = false;
        this.saveState.addEnabled = false;
        this.editEnabled = true;
        this.saveState.editEnabled = true;
        // this.startedEditing=true;
        // this.saveState.startedEditing=true;
        this.updateState();
        this.disableHeaderForm(false);
        this.itemsTrigger.nativeElement.click();
    }

    validateItemForm() {
        var check = true;

        if (this.selectedArticle == undefined) {
            check = false;
            $("#itemCodeInput").addClass('is-invalid');
        }
        else {
            $("#itemCodeInput").removeClass('is-invalid');
        }

        return check;
    }

    closeSourcesModal() {
        this.pendingSourcesChanges.forEach(s => {
            $("#" + s.id).prop('checked', false);
        });
        this.pendingSourcesChanges = [];
        this.sourcesModalClose.nativeElement.click();
    }

    checkFilter(id: number) {
        var existing = this.pendingFilterChanges.find(x => x.id == id);
        if (existing != undefined) {
            this.pendingFilterChanges.splice(this.pendingFilterChanges.indexOf(existing), 1);
            return;
        }

        var change = new StocktakingFilter();
        var filter = this.activeStocktaking.filters.find(x => x.id == id);
        change.id = filter.id;
        change.name = filter.name;
        change.checked = !filter.checked;

        this.pendingFilterChanges.push(change);
    }

    async updateFilters() {
        this.isSubmitted = false;
        this.showSpinner = true;
        try {
            await this.stocktakingRest.updateFiltersOnStocktakingDocument(this.activeStocktaking.id, this.pendingFilterChanges, this.activeStocktaking.hideEntered, this.activeStocktaking.sortByGroup);
            this.pendingFilterChanges.forEach(update => {
                this.activeStocktaking.filters.find(x => x.id == update.id).checked = update.checked;
            });
            if (this.saveState.activeStocktakingPreEdit.sortByGroup != this.activeStocktaking.sortByGroup) {
                this.activeStocktaking.articles = undefined;
            }
            this.saveState.activeStocktakingPreEdit = this.makePreEditObject();
            this.filtersModalClose.nativeElement.click();
            this.pendingFilterChanges = [];
            this.fetchStocktaking();
            this.activeStocktaking.articles = undefined;
            this.focusLast = true;
            await this.getArticles();
            this.focusLast = false;
            this.updateState();
        }
        catch (err: any) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
        this.showSpinner = false;
    }

    toggleDocNav(toggle: boolean) {
        this.onHeaderTab = toggle;
    }

    toggleUIControl() {
        this.activeStocktaking.searchInput = !this.activeStocktaking.searchInput;
    }

    toggleSortByGroup() {
        this.activeStocktaking.sortByGroup = !this.activeStocktaking.sortByGroup;
    }

    toggleHideEntered() {
        this.activeStocktaking.hideEntered = !this.activeStocktaking.hideEntered;
    }

    closeFiltersModal() {
        this.pendingFilterChanges.forEach(f => {
            $("#" + f.name).prop('checked', !f.checked);
        });
        this.pendingFilterChanges = [];
        this.activeStocktaking.sortByGroup = this.saveState.activeStocktakingPreEdit.sortByGroup;
        this.activeStocktaking.hideEntered = this.saveState.activeStocktakingPreEdit.hideEntered;

        this.filtersModalClose.nativeElement.click();
    }

    async updateArticleState(articleId: number, realState: string, barcodeInput: boolean = false) {
        this.showSpinner = false;
        try {
            var update: number = 0;
            if (realState == undefined || realState == null || realState == '')
                update = parseFloat(this.activeStocktaking.articles.find(x => x.id == articleId).warehouseState.toFixed(6));
            else
                update = parseFloat(parseFloat(realState).toFixed(6));
            this.activeStocktaking.articles.find(x => x.id == articleId).realState = update;
            await this.stocktakingRest.updateArticleState(this.activeStocktaking.id, articleId, update)
            this.activeStocktaking.lastId = articleId;
            this.updateState();
            var updatedName = this.activeStocktaking.articles.find(x => x.id == articleId).name;

            if (barcodeInput)
                this.jumpToBarcode();
        }
        catch (err: any) {
            var message = this.translate.instant('_Update_rs_failed');
            var updatedName = this.activeStocktaking.articles.find(x => x.id == articleId).name;
            message = message.concat(updatedName);
            toastr.options = this.data.toastrOptions;
            toastr.error(message, this.translate.instant("_Error"));

            var existing = this.logs.find(x => x.articleId == articleId);
            if (existing != undefined) {
                existing.attemptedEntry = parseFloat(realState);
            } else {
                var log = new StocktakingLogModel();
                log.articleId = articleId;
                log.articleName = updatedName;
                log.attemptedEntry = parseFloat(realState);
                this.logs.push(log);
            }
        }
        this.showSpinner = true;
    }

    removeLog(articleId: number) {
        var elementToDelete = this.logs.find(x => x.articleId == articleId);
        var index = this.logs.indexOf(elementToDelete);
        this.logs.splice(index, 1);
    }

    showList() {
        this.listTrigger.nativeElement.click();
    }

    async updateControl() {
        if (this.activeStocktaking.searchInput == this.controlUpdate)
            return;
        this.showSpinner = true;
        try {
            await this.stocktakingRest.updateControl(this.activeStocktaking.id, this.controlUpdate);
            this.fetchStocktaking();
            this.activeStocktaking.articles = [];
            this.articlePage = 1;

            if (this.controlUpdate)
                this.getAllArticles();
            else
                this.getArticles();

            this.activeStocktaking.searchInput = this.controlUpdate;
            this.saveState.activeStocktakingPreEdit = this.makePreEditObject();
            this.closeControlModal();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
        this.showSpinner = false;
    }

    closeControlModal() {
        this.controlUpdate = this.activeStocktaking.searchInput;
        this.controlModalClose.nativeElement.click();
    }

    async enterBarcode() {
        if (this.searchBarcode != "" && this.searchBarcode != null && this.searchBarcode != undefined) {
            try {
                var article = this.activeStocktaking.articles.filter(x => x.barcodes != null).find(x => x.barcodes.includes('"' + this.searchBarcode + '"'));
                if (article == undefined) {
                    article = await this.stocktakingRest.getStocktakingArticleByBarcode(this.activeStocktaking.id, this.searchBarcode, this.activeStocktaking.clientId);
                }
                else {
                    if (!this.barcodeArticles.includes(article))
                        this.barcodeArticles.push(article);
                    this.activeStocktaking.lastId = article.id;
                    this._changeRef.detectChanges();
                    this.jumpToLast();
                }
            }
            catch (err: any) {
                toastr.options = this.data.toastrOptions;
                toastr.error(this.translate.instant(err.error['Message']), this.translate.instant("_Error"));
            }
        }
    }

    prepareSourcesForTimelineDisplay() {
        var checkedSources = this.activeStocktaking.sources.filter(x => x.checked);
        if (this.sourcesForTimelineDisplay.length != checkedSources.length) {
            this.sourcesForTimelineDisplay = checkedSources;
            this._changeRef.detectChanges();
        }
    }

    closeModalTimeline() {
        this.timelineModalClose.nativeElement.click();
    }

    async createStocktakingDifference() {
        if (this.creationInProgress) {
            return;

        }
        this.creationInProgress = true;
        this.isSubmitted = false;
        if (this.activeStocktaking.articles.length == 0)
            this.isSubmitted = true;
        if (this.activeStocktaking.articles.find(x => x.realState != undefined && x.realState != null) == undefined) {
            this.isSubmitted = true;
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant("_No_RS_entered"), this.translate.instant("_Error"));
        }
        if (this.isSubmitted == true) {
            this.creationInProgress = false;

            return;
        }

        var question_text = this.translate.instant("_Confirm_stocktaking_diff_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    this.showSpinner = true;

                    var diffNumber = await this.stocktakingRest.getNextDifferenceNumber(this.data.user.clientId);
                    await this.stocktakingRest.createStocktakingDifference(this.activeStocktaking.id, diffNumber, this.allStatesEntered());
                    toastr.options = this.data.toastrOptions;
                    toastr.success(this.translate.instant('_Created_stocktaking_difference'), this.translate.instant("_Success"));
                }
                catch (err) {
                    toastr.options = this.data.toastrOptions;
                    toastr.error(err.error['Message'], this.translate.instant("_Error"));
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
        this.showSpinner = false;
        this.creationInProgress = false;
    }

    allStatesEntered() {
        var nullElement = this.activeStocktaking.articles.find(x => x.realState == undefined || x.realState == null)
        if (nullElement != undefined)
            return false;
        else return true;
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchStocktaking();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchStocktaking();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchStocktaking();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchStocktaking();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchStocktaking();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}