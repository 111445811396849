import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ArticleModel } from 'src/app/models/article.model';
import { PaymentTypeModel } from 'src/app/models/paymentType.model';
import { DashboardRestService } from 'src/app/services/dashboard.service';
import { DataService } from 'src/app/services/data.service';
import { PaymentTypeRestService } from 'src/app/services/payment-type.service';
import { environment } from 'src/environments/environment';
declare var $: any;
declare var KTUtil: any;
declare var ApexCharts: any;

@Component({
  selector: 'erp-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  public activeButton: string = 'ALL';
  public dateFrom: string = "";
  public dateTo: string = "";
  public dateFromRetail: string = "";
  public dateToRetail: string = "";
  public monthlySales: any;
  public weeklySales: any;
  public globalSales: any;
  public globalSalesToday: boolean = true;
  public globalSalesWeek: boolean = false;
  public globalSalesMonth: boolean = false;
  public globalSalesYear: boolean = false;
  public globalSalesCustom: boolean = false;
  public retailToday: boolean = true;
  public retailWeek: boolean = false;
  public retailMonth: boolean = false;
  public retailYear: boolean = false;
  public retailCustom: boolean = false;
  public monthlySalesChart: any;
  public weeklySalesChart: any;
  public globalSalesChart: any;
  public dailySalesDoughnutChart: any;
  public retailOperaterSalesBarChart: any;
  public retailPlaceSalesDoughnutChart: any;
  public hourlySalesChart:any;
  public pointOfSalePieChart: any;
  public totalCurrentWeeklySales: number = 0;
  public totalLastWeeklySales: number = 0;
  public weeklySalesChange: number = 0;
  public dailySalesByPayment: any;
  public retailDailySales: number = 0;
  public retailyDailySalesData: any[] = [];
  public retailyDailySalesLabels: any[] = [];
  public retailOperaterSales: any;
  public retailOperaterSalesData: any[] = [];
  public retailOperaterSalesLabels: any[] = [];
  public retailPlaceSales: any;
  public allRetailPlaceSales: any;
  public retailPlaceSalesPercentageData: any[] = [];
  public retailPlaceSalesRealValueData: any[] = [];
  public retailPlaceSalesLabels: any[] = [];
  public paymentTypes: PaymentTypeModel[];
  public selectedPaymentTypeId: number = null;
  public pointOfSalesSales: any;
  public allPointOfSalesSales: any;
  public pointOfSalesSalesPercentageData: any[] = [];
  public pointOfSalesRealValueData: any[] = [];
  public pointOfSalesSalesLabels: any[] = [];
  public bestSellingItems:any[]=[];
  public imagesUrl:string = environment.imagesUrl;
  public clientId:number;
  public hourlySales:any;

  constructor(
    private _dashservice: DashboardRestService,
    private _data: DataService,
    private datePipe: DatePipe,
    private _paymentTypeservice: PaymentTypeRestService
  ) {
  }

  ngOnInit() {
    this.clientId=this._data.user.clientId;
    var self = this;
    this.selectRetailTodayRange();
    this.selectGlobalSalesTodayRange();
    $("#kt_daterangepicker_globalSales").daterangepicker({
      locale: {
        format: 'DD.MM.YYYY'
      }
    }, function (start, end, label) {
      self.setCustomDateRange(start, end);
      self.globalSalesToday = false;
      self.globalSalesWeek = false;
      self.globalSalesMonth = false;
      self.globalSalesYear = false;
      self.globalSalesCustom = true;
      self.fetchGlobalSalesSummary();
    });
    $("#kt_daterangepicker_retail").daterangepicker({
      locale: {
        format: 'DD.MM.YYYY'
      }
    }, function (start, end, label) {
      self.dateFromRetail = self.datePipe.transform(start, 'yyyy-MM-dd');
      self.dateToRetail = self.datePipe.transform(end, 'yyyy-MM-dd');
      self.retailToday = false;
      self.retailWeek = false;
      self.retailMonth = false;
      self.retailYear = false;
      self.retailCustom = true;
      self.fetchSalesByOperatorReport();
      self.fetchMostSoldArticles();
      self.fetchSalesByRetailPlaceReport();
      self.fetchSalesByPointOfSaleReport();
    });
    this.fetchPaymentTypes();
    this.fetchWeeklySalesComparisonReport();
    this.fetchMonthlySalesComparisonReport();
    this.fetchDailyDalesByPaymentTypeReport();
    this.fetchMostSoldArticles();
    this.fetchSalesByRetailPlaceReport();
    this.fetchSalesByPointOfSaleReport();
    this.fetchDailySalesByHour();
  }

  ngAfterViewInit() {
    $("#kt_content_container").addClass('dashboardContainer');
  }

  selectRetail() {
    this.activeButton = 'RETAIL';
    this.createMonthlyLineChart();
    this.createWeeklyBarChart();
  }

  selectWholesale() {
    this.activeButton = 'WHOLESALE';
    this.createMonthlyLineChart();
    this.createWeeklyBarChart();
  }

  selectAll() {
    this.activeButton = 'ALL';
    this.createMonthlyLineChart();
    this.createWeeklyBarChart();
  }

  async fetchGlobalSalesSummary() {
    this.globalSales = await this._dashservice.getGlobalSalesSummary(this._data.user.clientId, this.activeButton, this.dateFrom, this.dateTo, 1, 100000);
    this.createGlobalSalesDoughnutChart();
  }

  async fetchWeeklySalesComparisonReport() {
    this.weeklySales = await this._dashservice.getWeeklySalesComparisonReport(this._data.user.clientId, 1, 100000);
    this.createWeeklyBarChart();
  }

  async fetchMonthlySalesComparisonReport() {
    this.monthlySales = await this._dashservice.getMonthlySalesComparisonReport(this._data.user.clientId, 1, 100000);
    this.createMonthlyLineChart();
  }

  async fetchDailyDalesByPaymentTypeReport() {
    this.dailySalesByPayment = await this._dashservice.getDailySalesByPaymentTypeReport(this._data.user.clientId, 1, 100000);

    this.retailDailySales = 0;
    this.retailyDailySalesData = [];
    this.retailyDailySalesLabels = [];

    for (var i = 0; i < this.dailySalesByPayment.length; i++) {
      if (i == 0)
        this.dailySalesByPayment[0].color = "bg-danger";
      if (i == 1)
        this.dailySalesByPayment[1].color = "bg-primary";
      if (i == 2)
        this.dailySalesByPayment[2].color = "bg-info";
      if (i == 3)
        this.dailySalesByPayment[3].color = "bg-secondary";
      this.retailDailySales += this.dailySalesByPayment[i].retailBillTotalAmount;
      this.retailyDailySalesData.push(parseFloat(this.dailySalesByPayment[i].retailBillTotalAmount).toFixed(2))
      this.retailyDailySalesLabels.push(this.dailySalesByPayment[i].paymentTypeName)
    }

    this.createDailySalesByPaymentDoughnutChart();
  }

  async fetchDailySalesByHour() {
    this.hourlySales = await this._dashservice.getDailySalesByHourSummaryReport(this._data.user.clientId, 1, 100000);
    this.createHourlyLineChart();
  }

  async fetchSalesByOperatorReport() {
    this.retailOperaterSales = await this._dashservice.getSalesByOperater(this._data.user.clientId, this.dateFromRetail, this.dateToRetail, 1, 100000);

    this.retailOperaterSalesData = [];
    this.retailOperaterSalesLabels = [];
    for (var i = 0; i < this.retailOperaterSales.length; i++) {
      this.retailOperaterSalesData.push(parseFloat(this.retailOperaterSales[i].retailBillTotalAmount).toFixed(2));
      this.retailOperaterSalesLabels.push(this.retailOperaterSales[i].clientUserAccountName.toUpperCase());
    }

    this.createOperaterSalesBarChart();
  }

  async fetchMostSoldArticles() {
    this.bestSellingItems = await this._dashservice.getMostSoldArticlesReport(this._data.user.clientId, this.dateFromRetail, this.dateToRetail, 1, 100000);
  }

  async fetchSalesByRetailPlaceReport() {
    var response = await this._dashservice.getSalesByRetailPlaceReport(this._data.user.clientId, this.dateFromRetail, this.dateToRetail, 1, 100000);
    this.allRetailPlaceSales = response;
    this.retailPlaceSales = response;
    let totalAmount = this.retailPlaceSales.reduce((sum, item) => sum + item.retailBillTotalAmount, 0);

    var colors = [
      '#ffd166', '#073b4c', '#ffcf25', '#b15dff', '#6ad49c', '#5aa7ff', '#2a9d8f',
      '#f35d82', '#8856ed', '#ef476f', '#118ab2', '#6ad3d4', '#5c5d6f', '#f28b3b',
      '#e76f51', '#7ed321', '#ff7eb9'
    ]

    this.retailPlaceSalesPercentageData = [];
    this.retailPlaceSalesRealValueData = [];
    this.retailPlaceSalesLabels = [];
    if (this.selectedPaymentTypeId == null) {
      const summedData = this.retailPlaceSales.reduce((accumulator, current) => {
        if (accumulator[current.retailPlaceId]) {
          accumulator[current.retailPlaceId].retailBillTotalAmount += current.retailBillTotalAmount;
        } else {
          accumulator[current.retailPlaceId] = { ...current }
        }
        return accumulator;
      }, {});

      const resultArray = Object.keys(summedData).map(id => ({
        retailPlaceId: Number(id),
        retailBillTotalAmount: summedData[id].retailBillTotalAmount,
        paymentTypeId: summedData[id].paymentTypeId,
        paymentTypeName: summedData[id].paymentTypeName,
        retailPlaceName: summedData[id].retailPlaceName,
      }));

      this.retailPlaceSales = resultArray;
      for (var i = 0; i < this.retailPlaceSales.length; i++) {
        this.retailPlaceSales[i].color = colors[i];
        var percentage = parseFloat((this.retailPlaceSales[i].retailBillTotalAmount / totalAmount * 100).toFixed(2));
        this.retailPlaceSalesPercentageData.push(percentage);
        this.retailPlaceSalesRealValueData.push(this.retailPlaceSales[i].retailBillTotalAmount);
        this.retailPlaceSalesLabels.push("");
      }
    }
    else {
      const summedData = this.retailPlaceSales.reduce((accumulator, current) => {
        if (current.paymentTypeId === this.selectedPaymentTypeId) {
          if (accumulator[current.retailPlaceId]) {
            accumulator[current.retailPlaceId].retailBillTotalAmount += current.retailBillTotalAmount;
          } else {
            accumulator[current.retailPlaceId] = { ...current }
          }
        }
        return accumulator;
      }, {});

      const resultArray = Object.keys(summedData).map(id => ({
        retailPlaceId: Number(id),
        retailBillTotalAmount: summedData[id].retailBillTotalAmount,
        paymentTypeId: summedData[id].paymentTypeId,
        paymentTypeName: summedData[id].paymentTypeName,
        retailPlaceName: summedData[id].retailPlaceName,
      }));

      this.retailPlaceSales = resultArray;
      for (var i = 0; i < this.retailPlaceSales.length; i++) {
        this.retailPlaceSales[i].color = colors[i];
        var percentage = parseFloat((this.retailPlaceSales[i].retailBillTotalAmount / totalAmount * 100).toFixed(2));
        this.retailPlaceSalesPercentageData.push(percentage);
        this.retailPlaceSalesRealValueData.push(this.retailPlaceSales[i].retailBillTotalAmount);
        this.retailPlaceSalesLabels.push("");
      }
    }

    this.createRetailPlaceSalesDoughnutChart();
  }

  async fetchSalesByPointOfSaleReport() {
    var response = await this._dashservice.getSalesByPointOfSaleReport(this._data.user.clientId, this.dateFromRetail, this.dateToRetail, 1, 10000);
    this.allPointOfSalesSales = response;
    this.pointOfSalesSales = response;

    var colors = [
      '#5aa7ff', '#f35d82', '#6ad49c', '#ffcf25', '#8856ed', '#6ad3d4', '#5c5d6f',
      '#f28b3b', '#b15dff', '#7ed321', '#ff7eb9', '#2a9d8f', '#ef476f', '#ffd166', '#118ab2', '#073b4c', '#e76f51'
    ]

    let totalAmount = this.pointOfSalesSales.reduce((sum, item) => sum + item.retailBillTotalAmount, 0);
    this.pointOfSalesRealValueData = [];
    this.pointOfSalesSalesPercentageData = [];
    this.pointOfSalesSalesLabels = [];


    if (this.selectedPaymentTypeId == null) {
      const summedData = this.pointOfSalesSales.reduce((accumulator, current) => {
        if (accumulator[current.pointOfSaleId]) {
          accumulator[current.pointOfSaleId].retailBillTotalAmount += current.retailBillTotalAmount;
        } else {
          accumulator[current.pointOfSaleId] = { ...current }
        }
        return accumulator;
      }, {});

      const resultArray = Object.keys(summedData).map(id => ({
        pointOfSaleId: Number(id),
        retailBillTotalAmount: summedData[id].retailBillTotalAmount,
        paymentTypeId: summedData[id].paymentTypeId,
        paymentTypeName: summedData[id].paymentTypeName,
        pointOfSaleIdName: summedData[id].pointOfSaleIdName,
      }));

      this.pointOfSalesSales = resultArray;

      for (var i = 0; i < this.pointOfSalesSales.length; i++) {
        this.pointOfSalesSales[i].color = colors[i];
        var percentage = parseFloat((this.pointOfSalesSales[i].retailBillTotalAmount / totalAmount * 100).toFixed(2));
        this.pointOfSalesSalesPercentageData.push(percentage);
        this.pointOfSalesRealValueData.push(this.pointOfSalesSales[i].retailBillTotalAmount);
        this.pointOfSalesSalesLabels.push("");
      }
    }
    else {
      const summedData = this.pointOfSalesSales.reduce((accumulator, current) => {
        if (current.paymentTypeId === this.selectedPaymentTypeId) {
          if (accumulator[current.pointOfSaleId]) {
            accumulator[current.pointOfSaleId].retailBillTotalAmount += current.retailBillTotalAmount;
          } else {
            accumulator[current.pointOfSaleId] = { ...current };
          }
        }
        return accumulator;
      }, {});

      const resultArray = Object.keys(summedData).map(id => ({
        pointOfSaleId: Number(id),
        retailBillTotalAmount: summedData[id].retailBillTotalAmount,
        paymentTypeId: summedData[id].paymentTypeId,
        paymentTypeName: summedData[id].paymentTypeName,
        pointOfSaleIdName: summedData[id].pointOfSaleIdName,
      }));

      this.pointOfSalesSales = resultArray;
      for (var i = 0; i < this.pointOfSalesSales.length; i++) {
        this.pointOfSalesSales[i].color = colors[i];
        var percentage = parseFloat((this.pointOfSalesSales[i].retailBillTotalAmount / totalAmount * 100).toFixed(2));
        this.pointOfSalesSalesPercentageData.push(percentage);
        this.pointOfSalesRealValueData.push(this.pointOfSalesSales[i].retailBillTotalAmount);
        this.pointOfSalesSalesLabels.push("");
      }
    }

    this.createPointOfSalesPieChart();
  }

  async fetchPaymentTypes() {
    var response = await this._paymentTypeservice.getPaymentTypes(this._data.user.clientId, '', 1, 100);
    this.paymentTypes = response.data;
  }

  filterSalesByPayment(paymentTypeId: number) {
    this.selectedPaymentTypeId = paymentTypeId;
    this.retailPlaceSales = [];
    this.retailPlaceSalesPercentageData = [];
    this.retailPlaceSalesRealValueData = [];
    this.retailPlaceSalesLabels = [];
    for (var i = 0; i < this.allRetailPlaceSales.length; i++) {
      if (this.allRetailPlaceSales[i].paymentTypeId == paymentTypeId) {
        this.retailPlaceSales.push(this.allRetailPlaceSales[i]);
      }
    }

    var colors = [
      '#ffd166', '#073b4c', '#ffcf25', '#b15dff', '#6ad49c', '#5aa7ff', '#2a9d8f',
      '#f35d82', '#8856ed', '#ef476f', '#118ab2', '#6ad3d4', '#5c5d6f', '#f28b3b',
      '#e76f51', '#7ed321', '#ff7eb9'
    ];
    var totalAmount = this.retailPlaceSales.reduce((sum, item) => sum + item.retailBillTotalAmount, 0);

    for (var i = 0; i < this.retailPlaceSales.length; i++) {
      this.retailPlaceSales[i].color = colors[i];
      var percentage = parseFloat((this.retailPlaceSales[i].retailBillTotalAmount / totalAmount * 100).toFixed(2));
      this.retailPlaceSalesPercentageData.push(percentage);
      this.retailPlaceSalesRealValueData.push(this.retailPlaceSales[i].retailBillTotalAmount);
      this.retailPlaceSalesLabels.push("");
    }

    this.createRetailPlaceSalesDoughnutChart();

    //point of sales

    this.pointOfSalesSales = [];
    this.pointOfSalesSalesPercentageData = [];
    this.pointOfSalesRealValueData = [];
    this.pointOfSalesSalesLabels = [];
    for (var i = 0; i < this.allPointOfSalesSales.length; i++) {
      if (this.allPointOfSalesSales[i].paymentTypeId == paymentTypeId) {
        this.pointOfSalesSales.push(this.allPointOfSalesSales[i]);
      }
    }

    var colors = [
      '#5aa7ff', '#f35d82', '#6ad49c', '#ffcf25', '#8856ed', '#6ad3d4', '#5c5d6f',
      '#f28b3b', '#b15dff', '#7ed321', '#ff7eb9', '#2a9d8f', '#ef476f', '#ffd166', '#118ab2', '#073b4c', '#e76f51'
    ];
    var totalAmount = this.pointOfSalesSales.reduce((sum, item) => sum + item.retailBillTotalAmount, 0);

    for (var i = 0; i < this.pointOfSalesSales.length; i++) {
      this.pointOfSalesSales[i].color = colors[i];
      var percentage = parseFloat((this.pointOfSalesSales[i].retailBillTotalAmount / totalAmount * 100).toFixed(2));
      this.pointOfSalesSalesPercentageData.push(percentage);
      this.pointOfSalesRealValueData.push(this.pointOfSalesSales[i].retailBillTotalAmount);
      this.pointOfSalesSalesLabels.push("");
    }

    this.createPointOfSalesPieChart();
  }

  filterSalesByAllPayments() {
    this.selectedPaymentTypeId = null;
    var totalAmount = this.retailPlaceSales.reduce((sum, item) => sum + item.retailBillTotalAmount, 0);

    var colors = [
      '#ffd166', '#073b4c', '#ffcf25', '#b15dff', '#6ad49c', '#5aa7ff', '#2a9d8f',
      '#f35d82', '#8856ed', '#ef476f', '#118ab2', '#6ad3d4', '#5c5d6f', '#f28b3b',
      '#e76f51', '#7ed321', '#ff7eb9'
    ];

    this.retailPlaceSalesPercentageData = [];
    this.retailPlaceSalesRealValueData = [];
    this.retailPlaceSalesLabels = [];
    this.retailPlaceSales = this.allRetailPlaceSales;

    var summedData = this.retailPlaceSales.reduce((accumulator, current) => {
      if (accumulator[current.retailPlaceId]) {
        accumulator[current.retailPlaceId].retailBillTotalAmount += current.retailBillTotalAmount;
      } else {
        accumulator[current.retailPlaceId] = { ...current }
      }
      return accumulator;
    }, {});

    var resultArray = Object.keys(summedData).map(id => ({
      retailPlaceId: Number(id),
      retailBillTotalAmount: summedData[id].retailBillTotalAmount,
      paymentTypeId: summedData[id].paymentTypeId,
      paymentTypeName: summedData[id].paymentTypeName,
      retailPlaceName: summedData[id].retailPlaceName,
    }));

    this.retailPlaceSales = resultArray;
    for (var i = 0; i < this.retailPlaceSales.length; i++) {
      this.retailPlaceSales[i].color = colors[i];
      var percentage = parseFloat((this.retailPlaceSales[i].retailBillTotalAmount / totalAmount * 100).toFixed(2));
      this.retailPlaceSalesPercentageData.push(percentage);
      this.retailPlaceSalesRealValueData.push(this.retailPlaceSales[i].retailBillTotalAmount);
      this.retailPlaceSalesLabels.push("");
    }

    this.createRetailPlaceSalesDoughnutChart();

    //point of sales

    var totalAmount = this.pointOfSalesSales.reduce((sum, item) => sum + item.retailBillTotalAmount, 0);

    var colors = [
      '#5aa7ff', '#f35d82', '#6ad49c', '#ffcf25', '#8856ed', '#6ad3d4', '#5c5d6f',
      '#f28b3b', '#b15dff', '#7ed321', '#ff7eb9', '#2a9d8f', '#ef476f', '#ffd166', '#118ab2', '#073b4c', '#e76f51'
    ];

    this.pointOfSalesSalesPercentageData = [];
    this.pointOfSalesRealValueData = [];
    this.pointOfSalesSalesLabels = [];
    this.pointOfSalesSales = this.allPointOfSalesSales;

    var summedData = this.pointOfSalesSales.reduce((accumulator, current) => {
      if (accumulator[current.pointOfSaleId]) {
        accumulator[current.pointOfSaleId].retailBillTotalAmount += current.retailBillTotalAmount;
      } else {
        accumulator[current.pointOfSaleId] = { ...current }
      }
      return accumulator;
    }, {});

    var resultArray1 = Object.keys(summedData).map(id => ({
      pointOfSaleId: Number(id),
      retailBillTotalAmount: summedData[id].retailBillTotalAmount,
      paymentTypeId: summedData[id].paymentTypeId,
      paymentTypeName: summedData[id].paymentTypeName,
      pointOfSaleIdName: summedData[id].pointOfSaleIdName,
    }));

    this.pointOfSalesSales = resultArray;
    for (var i = 0; i < this.pointOfSalesSales.length; i++) {
      this.pointOfSalesSales[i].color = colors[i];
      var percentage = parseFloat((this.pointOfSalesSales[i].retailBillTotalAmount / totalAmount * 100).toFixed(2));
      this.pointOfSalesSalesPercentageData.push(percentage);
      this.pointOfSalesRealValueData.push(this.pointOfSalesSales[i].retailBillTotalAmount);
      this.pointOfSalesSalesLabels.push("");
    }

    this.createPointOfSalesPieChart();
  }

  selectGlobalSalesTodayRange() {
    this.globalSalesToday = true;
    this.globalSalesWeek = false;
    this.globalSalesMonth = false;
    this.globalSalesYear = false;
    this.globalSalesCustom = false;
    this.setTodayRange();
    this.fetchGlobalSalesSummary();
  }

  selectGlobalSalesWeekRange() {
    this.globalSalesToday = false;
    this.globalSalesWeek = true;
    this.globalSalesMonth = false;
    this.globalSalesYear = false;
    this.globalSalesCustom = false;
    this.setWeekRange();
    this.fetchGlobalSalesSummary();
  }

  selectGlobalSalesMonthRange() {
    this.globalSalesToday = false;
    this.globalSalesWeek = false;
    this.globalSalesMonth = true;
    this.globalSalesYear = false;
    this.globalSalesCustom = false;
    this.setMonthRange();
    this.fetchGlobalSalesSummary();
  }

  selectGlobalSalesYearRange() {
    this.globalSalesToday = false;
    this.globalSalesWeek = false;
    this.globalSalesMonth = false;
    this.globalSalesYear = true;
    this.globalSalesCustom = false;
    this.setYearRange();
    this.fetchGlobalSalesSummary();
  }

  setTodayRange() {
    const now = new Date();

    const startOfDay = new Date(now);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(now);
    endOfDay.setDate(endOfDay.getDate() + 1);

    this.dateFrom = this.datePipe.transform(startOfDay, 'yyyy-MM-dd');
    this.dateTo = this.datePipe.transform(endOfDay, 'yyyy-MM-dd');
  }

  setWeekRange() {
    const now = new Date();

    const startOfDay = new Date(now);
    startOfDay.setDate(startOfDay.getDate() - 7);

    const endOfDay = new Date(now);
    endOfDay.setDate(endOfDay.getDate());

    this.dateFrom = this.datePipe.transform(startOfDay, 'yyyy-MM-dd');
    this.dateTo = this.datePipe.transform(endOfDay, 'yyyy-MM-dd');
  }

  setMonthRange() {
    const now = new Date();

    const startOfDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfDay = new Date(now);

    this.dateFrom = this.datePipe.transform(startOfDay, 'yyyy-MM-dd');
    this.dateTo = this.datePipe.transform(endOfDay, 'yyyy-MM-dd');
  }

  setYearRange() {
    const now = new Date();

    const startOfDay = new Date(now.getFullYear(), 0, 1);
    const endOfDay = new Date(now);

    this.dateFrom = this.datePipe.transform(startOfDay, 'yyyy-MM-dd');
    this.dateTo = this.datePipe.transform(endOfDay, 'yyyy-MM-dd');
  }

  setCustomDateRange(start, end) {
    this.dateFrom = this.datePipe.transform(start, 'yyyy-MM-dd');
    this.dateTo = this.datePipe.transform(end, 'yyyy-MM-dd');
  }

  createGlobalSalesDoughnutChart() {
    var e = document.getElementById("kt_chart_global_sales");
    var t = KTUtil.getCssVariableValue("--bs-gray-600");
    var a = KTUtil.getCssVariableValue("--bs-gray-200");
    var o = KTUtil.getCssVariableValue("--bs-primary");
    var s = KTUtil.getCssVariableValue("--bs-danger");
    var r = getComputedStyle(document.documentElement).getPropertyValue('--bs-info');
    var z = getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary'); // Additional color

    if (this.globalSalesChart) {
      this.globalSalesChart.destroy();
    }
    var options = {
      series: [this.globalSales.totalPurchaseValue, this.globalSales.totalSales, this.globalSales.taxAmount, this.globalSales.differenceInPrice],
      chart: {
        type: "donut",
        height: 180,
        width: '100%',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%'
          }
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (value, { seriesIndex }) {
            const colors = [s, o, z, r];
            return `
              <div>
                  <div>${value.toFixed(2)} KM</div>
              </div>
            `;
          }
        }
      },
      colors: [s, o, z, r], // Dodaj više boja za sve podatke
      grid: {
        borderColor: a,
        strokeDashArray: 4,
      },
      markers: {
        strokeColors: [s, o, z, r],
        strokeWidth: 6
      },
      labels: ['Nabavna vrijednost', 'Promet', 'Porez', 'Marža'], // Dodaj etikete za kategorije
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'right'
          }
        }
      }]
    };

    this.globalSalesChart = new ApexCharts(e, options);
    this.globalSalesChart.render();
  }

  createMonthlyLineChart() {
    var e = document.getElementById("kt_chart_mjesecni_promet");
    var t = KTUtil.getCssVariableValue("--bs-gray-600");
    var a = KTUtil.getCssVariableValue("--bs-gray-200");
    var o = KTUtil.getCssVariableValue("--bs-primary");
    var s = KTUtil.getCssVariableValue("--bs-light-primary");
    var r = getComputedStyle(document.documentElement).getPropertyValue('--bs-danger');
    var z = getComputedStyle(document.documentElement).getPropertyValue('--bs-light-danger'); // Additional color

    var currentMonthData = [];
    var lastMonthData = [];
    var dates = [];
    if (this.activeButton == "RETAIL") {
      for (var i = 0; i < this.monthlySales.currentMonthSales.length; i++) {
        currentMonthData.push(this.monthlySales.currentMonthSales[i].retailTotalAmount);
        lastMonthData.push(this.monthlySales.lastMonthSales[i].retailTotalAmount);

        var date = this.datePipe.transform(this.monthlySales.currentMonthSales[i].date, "dd");
        dates.push(date)
      }
    }
    else if (this.activeButton == "WHOLESALE") {
      for (var i = 0; i < this.monthlySales.currentMonthSales.length; i++) {
        currentMonthData.push(this.monthlySales.currentMonthSales[i].wholesaleTotalAmount);
        lastMonthData.push(this.monthlySales.lastMonthSales[i].wholesaleTotalAmount);

        var date = this.datePipe.transform(this.monthlySales.currentMonthSales[i].date, "dd");
        dates.push(date)
      }
    }
    else {
      for (var i = 0; i < this.monthlySales.currentMonthSales.length; i++) {
        currentMonthData.push(this.monthlySales.currentMonthSales[i].allTotalAmount);
        lastMonthData.push(this.monthlySales.lastMonthSales[i].allTotalAmount);

        var date = this.datePipe.transform(this.monthlySales.currentMonthSales[i].date, "dd");
        dates.push(date)
      }
    }
    if (e) {
      if (this.monthlySalesChart) {
        this.monthlySalesChart.destroy();
      }
      var options = {
        series: [{
          name: "Prošli mjesec",
          data: lastMonthData,
          color: o,
        },
        {
          name: "Tekući mjesec",
          data: currentMonthData,
          color: r
        }],
        chart: {
          fontFamily: "inherit",
          type: "area",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              zoomin: true, // Disables zoom in button (optional)
              zoomout: true, // Disables zoom out button (optional)
              pan: false,
              menu:false,
              download: false, // Isključuje dugme za preuzimanje
              selection: false, 
            },
            autoSelected: 'zoom',
          },
          zoom: {
            enabled: true,
            type: 'x', // Horizontal zoom only (you can use 'xy' for both axis)
            autoScaleYaxis: true,
          },
          events: {
            mouseWheelZoom: true // Enables zooming with mouse wheel
          }
        },
        plotOptions: {},
        legend: {
          show: true
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: "solid",
          opacity: 0.05
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [o, r]
        },
        xaxis: {
          categories: dates,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              colors: t,
              fontSize: "12px"
            }
          },
          crosshairs: {
            position: "front",
            stroke: {
              colors: [o, r],
              width: 1,
              dashArray: 3
            }
          },
          tooltip: {
            enabled: false,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: "12px"
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: t,
              fontSize: "12px"
            }
          }
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none",
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (value, { seriesIndex }) {
              const colors = [o, r];
              return `
              <div>
                  <div style="color: ${colors[seriesIndex]};">${value.toFixed(2)} KM</div>
              </div>
            `
            }
          }
        },
        colors: [s, z],
        grid: {
          borderColor: a,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        markers: {
          strokeColors: [o, r],
          strokeWidth: 3
        }
      };

      this.monthlySalesChart = new ApexCharts(e, options);
      this.monthlySalesChart.render();
    }
  }

  createHourlyLineChart() {
    var e = document.getElementById("kt_chart_promet_po_satu");
    var t = KTUtil.getCssVariableValue("--bs-gray-600");
    var a = KTUtil.getCssVariableValue("--bs-gray-200");
    var o = KTUtil.getCssVariableValue("--bs-primary");
    var s = KTUtil.getCssVariableValue("--bs-light-primary");
    var r = getComputedStyle(document.documentElement).getPropertyValue('--bs-danger');
    var z = getComputedStyle(document.documentElement).getPropertyValue('--bs-light-danger'); // Additional color

    var data = [];
    var hours = [];

    for (var i = 0; i < this.hourlySales.length; i++) {
      data.push(this.hourlySales[i].retailBillTotalAmount);
      var date = this.hourlySales[i].hour+":00h";
      hours.push(date)
    }

    console.log(hours)
  if (e) {
      if (this.monthlySalesChart) {
        this.monthlySalesChart.destroy();
      }
      var options = {
        series: [{
          name:"Dnevni promet",
          data: data,
          color: o,
        }],
        chart: {
          fontFamily: "inherit",
          type: "area",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              zoomin: true, // Disables zoom in button (optional)
              zoomout: true, // Disables zoom out button (optional)
              pan: false,
              menu:false,
              download: false, // Isključuje dugme za preuzimanje
              selection: false, 
            },
            autoSelected: 'zoom',
          },
          zoom: {
            enabled: true,
            type: 'x', // Horizontal zoom only (you can use 'xy' for both axis)
            autoScaleYaxis: true,
          },
          events: {
            mouseWheelZoom: true // Enables zooming with mouse wheel
          }
        },
        plotOptions: {},
        legend: {
          show: true
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: "solid",
          opacity: 0.05
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [o, r]
        },
        xaxis: {
          categories: hours,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              colors: t,
              fontSize: "12px"
            }
          },
          crosshairs: {
            position: "front",
            stroke: {
              colors: [o, r],
              width: 1,
              dashArray: 3
            }
          },
          tooltip: {
            enabled: false,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: "12px"
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: t,
              fontSize: "12px"
            }
          }
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none",
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (value, { seriesIndex }) {
              const colors = [o, r];
              return `
              <div>
                  <div style="color: ${colors[seriesIndex]};">${value.toFixed(2)} KM</div>
              </div>
            `
            }
          }
        },
        colors: [s, z],
        grid: {
          borderColor: a,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        markers: {
          strokeColors: [o, r],
          strokeWidth: 3
        }
      };

      this.hourlySalesChart = new ApexCharts(e, options);
      this.hourlySalesChart.render();
    } 
  }

  createWeeklyBarChart() {
    var element = document.getElementById('kt_chart_tjedni_promet');

    if (!element) {
      return;
    }

    var currentWeekData = [];
    var lastWeekData = [];
    var dates = [];
    this.totalCurrentWeeklySales = 0;
    this.totalLastWeeklySales = 0;
    if (this.activeButton == "RETAIL") {
      for (var i = 0; i < this.weeklySales.currentWeekSales.length; i++) {
        currentWeekData.push(this.weeklySales.currentWeekSales[i].retailTotalAmount);
        lastWeekData.push(this.weeklySales.lastWeekSales[i].retailTotalAmount);

        var date = this.datePipe.transform(this.weeklySales.currentWeekSales[i].date, "EEE", 'hr-HR').toUpperCase();
        dates.push(date)

        this.totalCurrentWeeklySales += this.weeklySales.currentWeekSales[i].retailTotalAmount;
        this.totalLastWeeklySales += this.weeklySales.lastWeekSales[i].retailTotalAmount;
      }
    }
    else if (this.activeButton == "WHOLESALE") {
      for (var i = 0; i < this.weeklySales.currentWeekSales.length; i++) {
        currentWeekData.push(this.weeklySales.currentWeekSales[i].wholesaleTotalAmount);
        lastWeekData.push(this.weeklySales.lastWeekSales[i].wholesaleTotalAmount);

        var date = this.datePipe.transform(this.weeklySales.currentWeekSales[i].date, "EEE", 'hr-HR').toUpperCase();
        dates.push(date)

        this.totalCurrentWeeklySales += this.weeklySales.currentWeekSales[i].wholesaleTotalAmount;
        this.totalLastWeeklySales += this.weeklySales.lastWeekSales[i].wholesaleTotalAmount;
      }
    }
    else {
      for (var i = 0; i < this.weeklySales.currentWeekSales.length; i++) {
        currentWeekData.push(this.weeklySales.currentWeekSales[i].allTotalAmount);
        lastWeekData.push(this.weeklySales.lastWeekSales[i].allTotalAmount);

        var date = this.datePipe.transform(this.weeklySales.currentWeekSales[i].date, "EEE", 'hr-HR').toUpperCase()
        dates.push(date)

        this.totalCurrentWeeklySales += this.weeklySales.currentWeekSales[i].allTotalAmount;
        this.totalLastWeeklySales += this.weeklySales.lastWeekSales[i].allTotalAmount;
      }
    }

    let change = ((this.totalCurrentWeeklySales - this.totalLastWeeklySales) / this.totalLastWeeklySales) * 100;

    if (change > 100)
      change = 100;
    this.weeklySalesChange = parseFloat(change.toFixed(2));

    if (this.weeklySalesChart) {
      this.weeklySalesChart.destroy();
    }
    var options = {
      series: [{
        name: 'Prošli tjedan',
        data: lastWeekData
      }, {
        name: 'Tekući tjedan',
        data: currentWeekData
      }],
      chart: {
        type: 'bar',
        height: 650,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '50%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: dates,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(2) + ' KM';
          }
        }
      },
      legend: {
        show: true
      },
      grid: {
        borderColor: '#e7e7e7',
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    };

    this.weeklySalesChart = new ApexCharts(element, options);
    this.weeklySalesChart.render();
  }

  createDailySalesByPaymentDoughnutChart() {
    var e = document.getElementById("kt_chart_daily_sales_payment");
    var t = KTUtil.getCssVariableValue("--bs-gray-600");
    var a = KTUtil.getCssVariableValue("--bs-gray-200");
    var o = KTUtil.getCssVariableValue("--bs-primary");
    var s = KTUtil.getCssVariableValue("--bs-danger");
    var r = getComputedStyle(document.documentElement).getPropertyValue('--bs-info');
    var z = getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary'); // Additional color

    if (this.dailySalesDoughnutChart) {
      this.dailySalesDoughnutChart.destroy();
    }
    var options = {
      series: this.retailyDailySalesData,
      chart: {
        type: "donut",
        height: 180,
        width: '100%',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%'
          }
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (value, { seriesIndex }) {
            const colors = [s, o, r, z];
            return `
              <div>
                  <div>${value.toFixed(2)} KM</div>
              </div>
            `;
          }
        }
      },
      colors: [s, o, r, z], // Dodaj više boja za sve podatke
      grid: {
        borderColor: a,
        strokeDashArray: 4,
      },
      markers: {
        strokeColors: [s, o, r, z],
        strokeWidth: 6
      },
      labels: this.retailyDailySalesLabels, // Dodaj etikete za kategorije
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'right'
          }
        }
      }]
    };

    this.dailySalesDoughnutChart = new ApexCharts(e, options);
    this.dailySalesDoughnutChart.render();
  }

  createOperaterSalesBarChart() {
    var element = document.getElementById('kt_chart_operater_promet');

    if (!element) {
      return;
    }

    if (this.retailOperaterSalesBarChart) {
      this.retailOperaterSalesBarChart.destroy();
    }
    var options = {
      series: [{
        name: '',
        data: this.retailOperaterSalesData
      }],
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '15%',
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: this.retailOperaterSalesLabels,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          }
        }
      },
      fill: {
        opacity: 1,
        colors: [
          '#5aa7ff', '#f35d82', '#6ad49c', '#ffcf25', '#8856ed', '#6ad3d4', '#5c5d6f',
          '#f28b3b', '#b15dff', '#7ed321', '#ff7eb9', '#2a9d8f', '#ef476f', '#ffd166', '#118ab2', '#073b4c', '#e76f51'
        ]
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(2) + ' KM';
          }
        }
      },
      legend: {
        show: false
      },
      grid: {
        borderColor: '#e7e7e7',
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    };

    this.retailOperaterSalesBarChart = new ApexCharts(element, options);
    this.retailOperaterSalesBarChart.render();
  }

  createPointOfSalesPieChart() {
    var a = KTUtil.getCssVariableValue("--bs-gray-200");
    var element = document.getElementById('kt_chart_pointOfSale_promet');

    if (!element) {
      return;
    }

    if (this.pointOfSalePieChart) {
      this.pointOfSalePieChart.destroy();
    }

    var self = this;
    var options = {
      series: this.pointOfSalesSalesPercentageData,
      chart: {
        type: "pie",
        height: 250,
        width: '100%',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50%'
          }
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          // Prikazuje vrednost kao tekst u segmentu
          return `${val.toFixed(2)} %`;
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        labels: {
          show: false // Sakrij oznake na x osi
        }
      },
      yaxis: {
        labels: {
          show: false // Sakrij oznake na y osi
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        x: {
          show: false // Sakrij x osu oznake ako je potrebno
        },
        y: {
          formatter: function (value, { seriesIndex }) {
            return `
                  <div>
                      <div>Promet: ${self.retailPlaceSalesRealValueData[seriesIndex].toFixed(2)} KM</div> 
                  </div>
                `;
          }
        },
        z: {
          show: false // Ako postoji z oznaka
        },
        title: {
          show: false // Ako postoji naslov
        }
      },
      colors: [
        '#5aa7ff', '#f35d82', '#6ad49c', '#ffcf25', '#8856ed', '#6ad3d4', '#5c5d6f',
        '#f28b3b', '#b15dff', '#7ed321', '#ff7eb9', '#2a9d8f', '#ef476f', '#ffd166', '#118ab2', '#073b4c', '#e76f51'
      ],
      grid: {
        borderColor: a,
        strokeDashArray: 4,
      },
      markers: {
        colors: [
          '#5aa7ff', '#f35d82', '#6ad49c', '#ffcf25', '#8856ed', '#6ad3d4', '#5c5d6f',
          '#f28b3b', '#b15dff', '#7ed321', '#ff7eb9', '#2a9d8f', '#ef476f', '#ffd166', '#118ab2', '#073b4c', '#e76f51'
        ],
        strokeWidth: 6
      },
      labels: this.pointOfSalesSalesLabels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'right'
          }
        }
      }]
    };

    this.pointOfSalePieChart = new ApexCharts(element, options);
    this.pointOfSalePieChart.render();
  }

  createRetailPlaceSalesDoughnutChart() {
    var a = KTUtil.getCssVariableValue("--bs-gray-200");
    var element = document.getElementById('kt_chart_retailPlace_promet');

    if (!element) {
      return;
    }

    if (this.retailPlaceSalesDoughnutChart) {
      this.retailPlaceSalesDoughnutChart.destroy();
    }

    var self = this;
    var options = {
      series: this.retailPlaceSalesPercentageData,
      chart: {
        type: "donut",
        height: 250,
        width: '100%',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50%'
          }
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          // Prikazuje vrednost kao tekst u segmentu
          return `${val.toFixed(2)} %`;
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        labels: {
          show: false // Sakrij oznake na x osi
        }
      },
      yaxis: {
        labels: {
          show: false // Sakrij oznake na y osi
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        x: {
          show: false // Sakrij x osu oznake ako je potrebno
        },
        y: {
          formatter: function (value, { seriesIndex }) {
            return `
                  <div>
                      <div>Promet: ${self.retailPlaceSalesRealValueData[seriesIndex].toFixed(2)} KM</div> 
                  </div>
                `;
          }
        },
        z: {
          show: false // Ako postoji z oznaka
        },
        title: {
          show: false // Ako postoji naslov
        }
      },
      colors: [
        '#ffd166', '#073b4c', '#ffcf25', '#b15dff', '#6ad49c', '#5aa7ff', '#2a9d8f',
        '#f35d82', '#8856ed', '#ef476f', '#118ab2', '#6ad3d4', '#5c5d6f', '#f28b3b',
        '#e76f51', '#7ed321', '#ff7eb9'
      ],
      grid: {
        borderColor: a,
        strokeDashArray: 4,
      },
      markers: {
        colors: [
          '#ffd166', '#073b4c', '#ffcf25', '#b15dff', '#6ad49c', '#5aa7ff', '#2a9d8f',
          '#f35d82', '#8856ed', '#ef476f', '#118ab2', '#6ad3d4', '#5c5d6f', '#f28b3b',
          '#e76f51', '#7ed321', '#ff7eb9'
        ],
        strokeWidth: 6
      },
      labels: this.retailPlaceSalesLabels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'right'
          }
        }
      }]
    };

    this.retailPlaceSalesDoughnutChart = new ApexCharts(element, options);
    this.retailPlaceSalesDoughnutChart.render();
  }

  selectRetailTodayRange() {
    this.retailToday = true;
    this.retailWeek = false;
    this.retailMonth = false;
    this.retailYear = false;
    this.retailCustom = false;
    const now = new Date();

    const startOfDay = new Date(now);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(now);
    endOfDay.setDate(endOfDay.getDate() + 1);

    this.dateFromRetail = this.datePipe.transform(startOfDay, 'yyyy-MM-dd');
    this.dateToRetail = this.datePipe.transform(endOfDay, 'yyyy-MM-dd');

    this.fetchSalesByOperatorReport();
    this.fetchMostSoldArticles();
    this.fetchSalesByRetailPlaceReport();
    this.fetchSalesByPointOfSaleReport();
  }

  selectRetailWeekRange() {
    this.retailToday = false;
    this.retailWeek = true;
    this.retailMonth = false;
    this.retailYear = false;
    this.retailCustom = false;
    const now = new Date();

    const startOfDay = new Date(now);
    startOfDay.setDate(startOfDay.getDate() - 7);

    const endOfDay = new Date(now);
    endOfDay.setDate(endOfDay.getDate());

    this.dateFromRetail = this.datePipe.transform(startOfDay, 'yyyy-MM-dd');
    this.dateToRetail = this.datePipe.transform(endOfDay, 'yyyy-MM-dd');
    this.fetchSalesByOperatorReport();
    this.fetchMostSoldArticles();
    this.fetchSalesByRetailPlaceReport();
    this.fetchSalesByPointOfSaleReport();
  }

  selectRetailMonthRange() {
    this.retailToday = false;
    this.retailWeek = false;
    this.retailMonth = true;
    this.retailYear = false;
    this.retailCustom = false;
    const now = new Date();

    const startOfDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfDay = new Date(now);

    this.dateFromRetail = this.datePipe.transform(startOfDay, 'yyyy-MM-dd');
    this.dateToRetail = this.datePipe.transform(endOfDay, 'yyyy-MM-dd');

    this.fetchSalesByOperatorReport();
    this.fetchMostSoldArticles();
    this.fetchSalesByRetailPlaceReport();
    this.fetchSalesByPointOfSaleReport();
  }

  selectRetailYearRange() {
    this.retailToday = false;
    this.retailWeek = false;
    this.retailMonth = false;
    this.retailYear = true;
    this.retailCustom = false;
    const now = new Date();

    const startOfDay = new Date(now.getFullYear(), 0, 1);
    const endOfDay = new Date(now);

    this.dateFromRetail = this.datePipe.transform(startOfDay, 'yyyy-MM-dd');
    this.dateToRetail = this.datePipe.transform(endOfDay, 'yyyy-MM-dd');

    this.fetchSalesByOperatorReport();
    this.fetchMostSoldArticles();
    this.fetchSalesByRetailPlaceReport();
    this.fetchSalesByPointOfSaleReport();
  }
}
