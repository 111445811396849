import { CustomerSuppliersModel } from "../../customerSupplier.model";
import { IncomingInvoiceModel } from "../incomingInvoice.model";

export class IncomingInvoiceSaveModel{
    activeInvoice:IncomingInvoiceModel;
    activeInvoicePreEdit:IncomingInvoiceModel;
    
    supplierCode:number;
    addEnabled:boolean;
    editEnabled:boolean;
    startedEditing:boolean;
    editingItems:boolean;
    changedArrivalDate:boolean;
    headerSaved:boolean;
    newInvoiceActive:boolean;
}