import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeUserPasswordModel } from 'src/app/models/changeUserPassword.model';
import { ChangeUserPINModel } from 'src/app/models/changeUserPIN.model';
import { ClientModel } from 'src/app/models/client.model';
import { ClientUserRoles } from 'src/app/models/clientUserRoles.model';
import { UserAccountModel } from 'src/app/models/userAccount.model';
import { ClientRestService } from 'src/app/services/client.service';
import { DataService } from 'src/app/services/data.service';
import { UserAccountRestService } from 'src/app/services/userAccount.service';
import { TranslateService } from 'src/app/translate';
import toastr from 'toastr';

declare var $: any;
declare var Swal:any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {

  public formGroup: UntypedFormGroup;
  public resetformGroup: UntypedFormGroup;
  public resetformGroupPin: UntypedFormGroup;
  public isSubmitted:boolean=false;
  public isSubmittedPass:boolean=false;
  public isSubmittedPin:boolean=false;
  public changePassword:boolean=false;
  public changePin:boolean=false;

  get f() { return this.formGroup.controls; }
  get f2() { return this.resetformGroup.controls; }
  get f3() { return this.resetformGroupPin.controls; }

  constructor(
    public data: DataService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private userAccountRest: UserAccountRestService) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    })

    this.setUserValues();
  }

  createResetPasswordForm(){
    this.resetformGroup = this.formBuilder.group({
      currentpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });
  }

  createResetPinForm(){
    if(this.data.user.pin!=undefined){
      console.log(this.data.user.pin);
      this.resetformGroupPin = this.formBuilder.group({
        currentPin: [null, Validators.required],
        newPin: ['',Validators.required],
        confirmPin: ['',Validators.required]
      });
    }
    else {
      this.resetformGroupPin = this.formBuilder.group({
        currentPin: [''],
        newPin: ['',Validators.required],
        confirmPin: ['',Validators.required]
      });
    }
  }

  setUserValues() {
    this.formGroup.controls['firstName'].setValue(this.data.user.firstName);
    this.formGroup.controls['lastName'].setValue(this.data.user.lastName);
  }

  discardChanges(){
    var self = this;
    var question_text = this.translate.instant("_Discard_changes_question_text");
    var yes_text = this.translate.instant("_Yes_discard_text");
    var no_text = this.translate.instant("_No_return_text");
    Swal.fire({
        html: question_text,
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: yes_text,
        cancelButtonText: no_text,
        customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: 'btn btn-secondary'
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            this.isSubmitted = false;
            self.setUserValues();
        } else if (result.dismiss === Swal.DismissReason.cancel) {  
        }
    });
  }

  async updateClient(){
    this.isSubmitted = false;
    if (this.formGroup.invalid) {
      this.isSubmitted = true;
    }

    if(this.isSubmitted)
      return;
    
    var obj = this.createEditObject();

    try {
      await this.userAccountRest.updateUserAccount(obj);
      toastr.options = this.data.toastrOptions;
      toastr.success(this.translate.instant('_Updated_profile_text'), this.translate.instant("_Success"));
      this.updateLoggedUser(obj);
    }
    catch (err) {
      toastr.options = this.data.toastrOptions;
      toastr.error(err.message, this.translate.instant("_Error"));
    }
  }

  createEditObject() {
    var obj = new UserAccountModel();
    obj.id = this.data.user.id;
    obj.username = this.data.user.username;
    obj.firstName = this.formGroup.controls.firstName.value;
    obj.lastName = this.formGroup.controls.lastName.value;
    obj.clientId = this.data.user.clientId;
    
    return obj;
  }

  updateLoggedUser(user: UserAccountModel){
    this.data.user.firstName=user.firstName;
    this.data.user.lastName=user.lastName;

    sessionStorage.setItem("logged_erp_client", JSON.stringify(this.data.user));
  }

  cancelPasswordReset(){
    var self = this;
    var question_text = this.translate.instant("_Discard_changes_question_text");
    var yes_text = this.translate.instant("_Yes_discard_text");
    var no_text = this.translate.instant("_No_return_text");
    Swal.fire({
        html: question_text,
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: yes_text,
        cancelButtonText: no_text,
        customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: 'btn btn-secondary'
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            this.resetformGroup.reset();
            this.changePassword=false;
            this.isSubmittedPass=false;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
    });
  }

  cancelPinReset(){
    var self = this;
    var question_text = this.translate.instant("_Discard_changes_question_text");
    var yes_text = this.translate.instant("_Yes_discard_text");
    var no_text = this.translate.instant("_No_return_text");
    Swal.fire({
        html: question_text,
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: yes_text,
        cancelButtonText: no_text,
        customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: 'btn btn-secondary'
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            this.resetformGroupPin.reset();
            this.changePin=false;
            this.isSubmittedPin=false;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
    });
  }

  toggleResetPinForm(show:boolean){
    this.changePin=show;

    if(show && this.resetformGroupPin==undefined){
      this.createResetPinForm();
    }
  }

  toggleResetPasswordForm(show:boolean){
    this.changePassword=show;

    if(show && this.resetformGroup==undefined){
      this.createResetPasswordForm();
    }
  }

  validatePasswordForm(){
    var check:boolean=true;
    if(this.f2.newpassword.value!=this.f2.confirmpassword.value){
      $("#confirmpassword").addClass("is-invalid");
      check = false;
    }
    return check;
  }
  async resetPassword(){
    this.isSubmittedPass = false;
    if (this.resetformGroup.invalid) {
      this.isSubmittedPass = true;
    }
    if (!this.validatePasswordForm()) {
        this.isSubmittedPass = true;
    }
    if (this.isSubmittedPass == true) {
        return;
    }

    var obj = new ChangeUserPasswordModel();
    obj.id=this.data.user.id;
    obj.newPassword=this.f2.newpassword.value; 
    obj.currentPassword=this.f2.currentpassword.value;

    try{
      await this.userAccountRest.changePassword(obj);
      this.resetformGroup.reset();
      this.toggleResetPasswordForm(false);
      toastr.options = this.data.toastrOptions;
      toastr.success(this.translate.instant('_Password_changed_text'), this.translate.instant("_Success"));
    }
    catch (err) {
        toastr.options = this.data.toastrOptions;
        toastr.error(err.error['Message'], this.translate.instant("_Error"));
    }
  }

  validatePinForm(){
    var check:boolean=true;
    if(this.f3.newPin.value!=this.f3.confirmPin.value){
      $("#confirmPin").addClass("is-invalid");
      check = false;
    }
    return check;
  }
  async resetPin(){
    this.isSubmittedPin = false;
    if (this.resetformGroupPin.invalid) {
      this.isSubmittedPin = true;
    }
    if (!this.validatePinForm()) {
        this.isSubmittedPin = true;
    }
    if (this.isSubmittedPin == true) {
        return;
    }

    var obj = new ChangeUserPINModel();
    obj.id=this.data.user.id;
    obj.newPIN=this.f3.newPin.value; 
    if(this.f3.currentPin.value=='' && this.data.user.pin==undefined)
      obj.currentPIN=null;
    else obj.currentPIN=this.f3.currentPin.value;

    try{
      await this.userAccountRest.changePin(obj);
      this.data.user.pin=this.f3.newPin;
      this.createResetPinForm();
      this.toggleResetPinForm(false);
      toastr.options = this.data.toastrOptions;
      toastr.success(this.translate.instant('_PIN_changed_text'), this.translate.instant("_Success"));
    }
    catch (err) {
        toastr.options = this.data.toastrOptions;
        toastr.error(err.error['Message'], this.translate.instant("_Error"));
    }
  }
}