import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

declare var $:any;
@Component({
  selector: 'erp-page-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {

  public currentYear:number;
  @ViewChild('footer') footer:ElementRef;

  constructor(){}

  ngOnInit(){
    this.currentYear=new Date().getFullYear();
  }
}