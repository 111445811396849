import { InvoiceCustomerPaymentModel } from "./invoice-customer-payment.model";
import { SoldArticleModel } from "./soldArticle.model";

export class DispatchNoteModel{
    id:string;
    clientId:number;
    dispatchNoteNumber:string;
    documentNumber:number;
    customDocumentNumber:number;
    customerId:number;
    customerName:string;
    customerAddress:string;
    customerIDNumber:string;
    customerPDVNumber:string;
    createdAt:Date;
    billDate:Date;
    arrivalDate:Date;
    paymentTypeId:number;
    paymentTypeName:string;
    currencyId:number;
    currencyCode:string;
    warehouseId:number;
    taxId:number;
    fis:number;
    note:string;
    invoiceDiscount:number;
    totalAmount:number;
    totalBase:number;
    totalDiscount:number;
    totalTax:number;
    amountWithoutTax:number;
    articles:SoldArticleModel[];
    paidOff:boolean;
    payments:InvoiceCustomerPaymentModel[];
    affectWarehouseState:boolean = true;
    noPrices:boolean = false;
}