import { ThisReceiver } from '@angular/compiler';
import { Component } from '@angular/core';
import { ClientModel } from './models/client.model';
import { DataService } from './services/data.service';
import { TranslateService } from './translate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private translate: TranslateService,
    public data: DataService) { }

  ngOnInit() {
    this.translate.use('bs');
    this.data.checkIfUserIsLogged();
  }
}