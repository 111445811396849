import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { ChangeUserPasswordModel } from "../models/changeUserPassword.model";
import { ChangeUserPINModel } from "../models/changeUserPIN.model";
import { IdModel } from "../models/id.model";
import { UserAccountModel } from "../models/userAccount.model";
import { DataService } from "./data.service";

@Injectable()
export class UserAccountRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }


    async getUserClientRoles() {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ClientUserAccount/FilterRoles", {}, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async getUserAccounts(username: string, isActive: boolean, clientUserRoleId: number, clientId: number, retailPlaceId: number, page: number, pageSize: number) {
        var params = "?Username=" + username + "&Page=" + page + "&PageSize=" + pageSize;
        if (clientUserRoleId != null && clientUserRoleId != undefined && clientUserRoleId != 0 && !isNaN(clientUserRoleId))
            params += "&ClientUserRoleId=" + clientUserRoleId;
        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;
        if (retailPlaceId != null && retailPlaceId != undefined && retailPlaceId != 0 && !isNaN(retailPlaceId))
            params += "&RetailPlaceId=" + retailPlaceId;
        if (isActive == true || isActive == false || isActive == "true" || isActive == "false")
            params += "&IsActive=" + isActive;
        var config = environment.apiUrl;
        return await this.http.get(config + "api/ClientUserAccount/Filter" + params,
            {
                headers: {
                    Authorization: 'Bearer ' + this.data.token,
                }
            }).toPromise() as any;
    }

    async updateUserAccount(obj: UserAccountModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ClientUserAccount/Update", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async createUserAccount(obj: UserAccountModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ClientUserAccount/Create", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async toggleVisibility(obj: IdModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ClientUserAccount/ToggleActivity", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async changePassword(obj: ChangeUserPasswordModel){
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ClientUserAccount/ChangeUserPassword", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }

    async changePin(obj: ChangeUserPINModel){
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ClientUserAccount/ChangeUserPIN", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
            }
        }).toPromise() as any;
    }
}