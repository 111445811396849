<div class="post d-flex flex-column-fluid" id="kt_post">
    <div class="container-xxl px-0">
        <div class="card">
            <div class="card-header border-0 pt-6">
                <form [formGroup]="searchForm">
                    <div class="card-title">
                       <h2>{{'_Money_withdrawal' |translate}}</h2>
                    </div>
                    <div class="card-toolbar">
                        <div class="d-flex justify-content-start mt-3">
                                <div class="d-flex align-items-center position-relative" style="margin-right:5px">
                                    <input class="form-control form-control-solid w-250px" [ngClass]="{'is-invalid': isSubmitted && f.amount.errors }" placeholder="Iznos" formControlName="amount" name="amount" required/>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <button type="reset" class="btn btn-light-primary btn-active-light-primary me-2"
                                        data-kt-menu-dismiss="true" data-kt-group-type-table-filter="reset"
                                        (click)="resetFilter()">{{'_Reset' | translate}}</button>
                                    <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true"
                                        data-kt-group-type-table-filter="filter" (click)="fetchReport()">{{'_Apply'|translate}}</button>
                                </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body pt-0">
                <!-- <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_group-types_table"
                    *ngIf="!emptyData">
                    <thead>
                        <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th>{{'_Group_name' | translate}}</th>
                            <th>{{'_Group_type_name' | translate}}</th>
                            <th>{{'_Tax' | translate}}</th>
                            <th class="text-end min-w-70px">{{'_Actions' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr *ngFor="let a of groups">
                            <td>{{a.name}}</td>
                            <td>{{a.groupTypeName}}</td>
                            <td>{{a.tax | number:'1.2-2'}}</td>
                            <td class="text-end">
                                <a (click)="selectGroup(a)" class="btn btn-icon btn-primary fs-8"
                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_edit_group">                                    
                                    <i class="fa fa-pencil-alt fs-4" aria-hidden="true"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row" *ngIf="!emptyData">
                    <div
                        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="dataTables_length" id="kt_customers_table_length"><label><select
                                    name="kt_customers_table_length" aria-controls="kt_customers_table"
                                    class="form-select form-select-sm form-select-solid" [(ngModel)]="perPage"
                                    (change)="perPageHandler()">
                                    <option [ngValue]="5">5</option>
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                </select></label></div>
                    </div>
                    <div
                        class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div class="dataTables_paginate paging_simple_numbers" id="kt_customers_table_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous disabled"
                                    id="kt_customers_table_previous"><a (click)="prevpage()"
                                        aria-controls="kt_customers_table" data-dt-idx="0" tabindex="0"
                                        class="page-link" style="cursor:pointer"><i class="previous"></i></a></li>

                                <div *ngFor="let p of pages">
                                    <li class="paginate_button page-item active" *ngIf="page==p">
                                        <a aria-controls="kt_customers_table" data-dt-idx="2" tabindex="0"
                                            class="page-link" (click)="goToPage(p)" style="cursor:pointer">{{p}}</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="page!=p">
                                        <a aria-controls="kt_customers_table" data-dt-idx="2" tabindex="0"
                                            class="page-link" (click)="goToPage(p)" style="cursor:pointer">{{p}}</a>
                                    </li>
                                </div>
                                <li class="paginate_button page-item disabled" id="kt_customers_table_morePages"
                                    *ngIf="maxPage>offset"><a (click)="morePages()" aria-controls="kt_customers_table"
                                        data-dt-idx="0" tabindex="0" class="page-link" style="cursor:pointer">...</a>
                                </li>
                                <li class="paginate_button page-item next" id="kt_customers_table_next"><a
                                        (click)="nextPage()" aria-controls="kt_customers_table" data-dt-idx="5"
                                        tabindex="0" class="page-link" style="cursor:pointer"><i class="next"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="emptyData">
                    <div class="text-center">
                        <h1 class="fw-bolder mb-5">{{'_No_results' | translate}}</h1>
                        <div class="separator separator-dashed border-danger opacity-25 mb-5"></div>
                        <div class="mb-9 text-dark">
                            {{'_No_results_text' | translate}}
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<ng-http-loader [spinner]="'sk-wave'"></ng-http-loader>