export class StocktakingArticleModel{
    id:number;
    code:string;
    name:string;
    measure:string;
    warehouseState:number;
    realState:any;
    nc:number;
    vpc:number;
    mpc:number;
    nv:number;
    vpv:number;
    mpv:number;
    barcodes:string;
    groupId:number;
}