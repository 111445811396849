import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { WarehouseTransferModel } from "../models/documents/warehouseTransfer.model";
import { DataService } from "./data.service";
import { DeleteDocumentDTO } from "../models/documents/deleteDocumentDTO";

@Injectable()
export class WarehouseTransferRestService{
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async getWarehouseTransfers(warehouseFromId:number, warehouseToId:number, dateFrom:string, dateTo:string, clientId:number, page:number, pageSize:number){
        var params = "?DateFrom=" + dateFrom + "&DateTo=" + dateTo + "&ClientId=" + clientId + "&Page=" + page + "&PageSize=" + pageSize;
        
        if(warehouseFromId!=undefined)
            params+="&WarehouseFromId="+warehouseFromId;
        if(warehouseToId!=undefined)
            params+="&warehouseToId="+warehouseToId;
        
        var config = environment.apiUrl;
        return await this.http.get(config + "api/WarehouseTransfer/FilterWarehouseTransfers" + params).toPromise() as any;
    }

    async createWarehouseTransfer(obj: WarehouseTransferModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/WarehouseTransfer/CreateWarehouseTransfer", obj).toPromise() as any;
    }

    async editWarehouseTransfer(obj: WarehouseTransferModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/WarehouseTransfer/UpdateWarehouseTransfer", obj).toPromise() as any;
    }

    async getNextNumber(clientId:number){
        var params = "?ClientId=" + clientId + "&TypeCode=" + "WHTRANS";
        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentGeneral/GetNextNumber" + params).toPromise() as any;
    }

    async deleteWarehouseTransfer(deleteId: string) {
        var dto = new DeleteDocumentDTO();
        dto.id=deleteId;
        var config = environment.apiUrl;
        return await this.http.post(config + "api/WarehouseTransfer/DeleteWarehouseTransfer", dto).toPromise() as any;
    }

    async getWarehouseTransfersPDF(clientId:number, id:any){
        var obj={
            clientId:clientId,
            id:id
           }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/WarehouseTransfer/GetWarehouseTransfersListPDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }
}