import { MovingArticleModel } from "./movingArticle.model";

export class WarehouseTransferModel{
    id:string;
    documentNumber:number;
    customDocumentNumber:number;
    warehouseTransferNumber:string;
    date:Date;
    note:string;
    warehouseFromId:number;
    warehouseFromName:string;
    warehouseToId:number;
    warehouseToName:string;
    clientId:number;
    currencyId:number;
    currencyCode:string;
    articles:MovingArticleModel[];
    totalAmount:number;
    totalDiscount:number;
}