import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { PaymentTypeModel } from "../models/paymentType.model";
import { DataService } from "./data.service";

@Injectable()
export class PaymentTypeRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }


    async getPaymentTypes(clientId: number, name: string,  page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize;

        if(name!=undefined && name!=null && name!='')
            params+="&Name="+name;
        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/PaymentType/Filter" + params).toPromise() as any;
    }

    async updatePaymentType(obj: PaymentTypeModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/PaymentType/Update", obj).toPromise() as any;
    }

    async createPaymentType(obj: PaymentTypeModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/PaymentType/Create", obj).toPromise() as any;
    }

    async getPaymentTypesByClientId(clientId:number){
        var params = "?";

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/PaymentType/FilterPaymentTypeByClientId" + params).toPromise() as any;
    }
}