import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DataService } from "./data.service";
import { WarehouseStateQueryDTO } from "../models/warehouseStateQueryDTO";

@Injectable()
export class WarehouseStateRestService{
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async getWarehouseState(fromDate:string, toDate:string, warehouseIds:number[], name:string, groupTypeIds:number[], groupIds:number[], fromWarehouse:boolean, hideZeroes:boolean, clientId:number, page:number, pageSize:number){
        var config = environment.apiUrl;
        var queryDTO=new WarehouseStateQueryDTO();
        queryDTO.fromDate=fromDate;
        queryDTO.toDate=toDate;
        queryDTO.articleName=name;
        queryDTO.groupIds=groupIds;
        queryDTO.groupTypeIds=groupTypeIds;
        queryDTO.warehouseIds=warehouseIds;
        queryDTO.clientId=clientId;
        queryDTO.page=page;
        queryDTO.pageSize=pageSize;
        return await this.http.post(config + "api/WarehouseState/GetWarehouseState", queryDTO).toPromise() as any;
    }
}