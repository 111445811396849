import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleRestService } from 'src/app/services/article.service';
import { CustomerSupplierRestService } from 'src/app/services/customerSupplier.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { OutgoingInvoiceRestService } from 'src/app/services/outgoing-invoice.service';
import { PaymentTypeRestService } from 'src/app/services/payment-type.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { GroupModel } from 'src/app/models/group.model';
import { TranslateService } from 'src/app/translate';
import { ArticleModel } from 'src/app/models/article.model';
import { TaxModel } from 'src/app/models/tax.model';
import { PaymentTypeModel } from 'src/app/models/paymentType.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { CustomerSuppliersModel } from 'src/app/models/customerSupplier.model';
import toastr from 'toastr';
import { OutgoingInvoiceModel } from 'src/app/models/documents/outgoingInvoice.model';
import { SoldArticleModel } from 'src/app/models/documents/soldArticle.model';
import { minValidator } from 'src/app/validators/minValidator';
import { CurrencyModel } from 'src/app/models/currency.model';
import { CurrencyRestService } from 'src/app/services/currency.service';
import { InvoiceCustomerPaymentModel } from 'src/app/models/documents/invoice-customer-payment.model';
import { UpdateInvoicePaymentDTO } from 'src/app/models/updateInvoicePaymentDTO';
import { UpdateInvoiceDiscountDTO } from 'src/app/models/documents/updateInvoiceDiscountDTO';
import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import { ReportRestService } from 'src/app/services/reports.service';
import { ItemLookupComponent } from '../../documents/item-lookup/item-lookup.component';
import { PointOfSaleRestService } from 'src/app/services/pointOfSale.service';
import { PointOfSaleModel } from 'src/app/models/pointOfSale.model';
import { UserAccountRestService } from 'src/app/services/userAccount.service';
import { GroupTypeRestService } from 'src/app/services/groupType.service';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;

@Component({
  selector: 'app-sales-article',
  templateUrl: './sales-article.component.html',
})
export class SalesArticleComponent {
  public sales:any[];
  public paymentTypeSummary:any;
  public groups:GroupModel[];
  public groupTypes:GroupModel[];
  public currencies:CurrencyModel[];
  public paymentTypes:PaymentTypeModel[];
  public pointsOfSale:PointOfSaleModel[];
  public operators:any[];
  public groupIds: number[] = [];
  public groupTypeIds: number[] = [];
  public operatorIds:number[]=[];
  public paymentTypeIds:number[]=[];
  public posIds:number[]=[];
  public emptyData: boolean = false;
  public searchForm: UntypedFormGroup;
  dateFrom:string;
  dateTo:string;
  public isSubmitted:boolean;
  tableSetUp:boolean=false;
  public selectedCurrency = 'BAM';
  public selectedArticle:ArticleModel;
  public filterPaymentTypeId:number;
  public filterPosId:number;
  public filterOperatorId:number;
  @ViewChild("selectFilterPaymentTypeId")selectFilterPaymentTypeId:ElementRef;
  @ViewChild("selectFilterPosId")selectFilterPosId:ElementRef;
  @ViewChild("selectFilterOperatorId")selectFilterOperatorId:ElementRef;
  @ViewChild("selectFilterGroupId")selectFilterGroupId:ElementRef;
  @ViewChild("selectFilterGroupTypeId")selectFilterGroupTypeId:ElementRef;
  @ViewChild(ItemLookupComponent) itemLookupComponent:ItemLookupComponent;

  //pagination
  public page: number = 1;
  public perPage: number;
  public totalData: number;
  public maxPage: number;
  public pages: number[] = [];
  public offset: number = 5;
  public firstPage: number = 1;
  public lastPage: number;
  public counter: number = 0;
  public firstLoad: boolean = true;
  public resizeObserver:ResizeObserver;
  selectionModalTimer:any;
  enterCount:number=0;

  constructor(
    private reportRest: ReportRestService,
    private groupRestService: GroupRestService,
    private groupTypeRestService:GroupTypeRestService,
    private pointOfSaleRestService: PointOfSaleRestService,
    private paymentTypeRestService: PaymentTypeRestService,
    private currencyRestService:CurrencyRestService,
    private userAccountRestService:UserAccountRestService,
    private datePipe: DatePipe,
    private _changeRef: ChangeDetectorRef,
    public data: DataService,
    private formBuilder: UntypedFormBuilder,
    private translate:TranslateService,
  ) {
    this.page=1;
    this.perPage = 50;
  }

  ngOnInit(): void {
    KTMenu.createInstances();
    this.fetchSales();
    this.fetchGroups();
    this.fetchGroupTypes();
    this.fetchPaymentTypes();
    this.fetchPOS();
    this.fetchOperators();
    this.createSearchForm();
  }

  async createSearchForm(){
    this.searchForm = this.formBuilder.group({
      posId:[null],
      paymentTypeId:[null],
      operatorId:[null],
      
    })

    $("#sales_article_filter_paymentTypeId").select2();
    var self = this;
    $('#sales_article_filter_paymentTypeId').on("change", function () {
        var values = $(this).val();
        self.paymentTypeIds = [];
        values.forEach(x => {
            if (x != undefined && x != null && x != '') {
                var val = parseInt(x);
                if(val!=null && !isNaN(val))
                    self.paymentTypeIds.push(val);
            }
        });
    });

    $("#sales_article_filter_posId").select2();
    var self = this;
    $('#sales_article_filter_posId').on("change", function () {
        var values = $(this).val();
        self.posIds = [];
        values.forEach(x => {
            if (x != undefined && x != null && x != '') {
                var val = parseInt(x);
                if(val!=null && !isNaN(val))
                    self.posIds.push(val);
            }
        });
    });

    $("#sales_article_filter_operatorId").select2();
    var self = this;
    $('#sales_article_filter_operatorId').on("change", function () {
        var values = $(this).val();
        self.operatorIds = [];
        values.forEach(x => {
            if (x != undefined && x != null && x != '') {
                var val = parseInt(x);
                if(val!=null && !isNaN(val))
                    self.operatorIds.push(val);
            }
        });
    });

    $("#filter_groupTypeId").select2();
    var self = this;
    $('#filter_groupTypeId').on("change", function () {
        var values = $(this).val();
        self.groupTypeIds = [];
        values.forEach(x => {
            if (x != undefined && x != null && x != '') {
                var val = parseInt(x);
                if(val!=null && !isNaN(val))
                    self.groupTypeIds.push(val);
            }
        });
    });

    $("#filter_groupId").select2();
    var self = this;
    $('#filter_groupId').on("change", function () {
        var values = $(this).val();
        self.groupIds = [];
        values.forEach(x => {
            if (x != undefined && x != null && x != '') {
                var val = parseInt(x);
                if(val!=null && !isNaN(val))
                    self.groupIds.push(val);
            }
        });
    });

    var start = new Date();
    var end = new Date();
    var self = this;
    $("#kt_daterangepicker_salesArticle_filter").daterangepicker({
        startDate: start,
        endDate: end,
        autoApply:true,
        autoUpdateInput:true,
        timePicker:true,
        timePicker24Hour:true,
        locale: {
            format: 'DD/MM/YYYY',
            separator:'-'
        }
    });
    $("#kt_daterangepicker_salesArticle_filter").on('apply.daterangepicker', function(ev, picker){
        self.dateFrom = picker.startDate.format('YYYY-MM-DD HH:mm');
        self.dateTo = picker.endDate.format('YYYY-MM-DD HH:mm');
        $("#searchByCustomerInput").focus();
        self.fetchSales();
    });
    $("#kt_daterangepicker_salesArticle_filter").on('cancel.daterangepicker', function(ev, picker){
        picker.setStartDate(new Date());
        picker.setEndDate(new Date());
        self.dateFrom = picker.startDate.format('YYYY-MM-DD HH:mm');
        self.dateTo = picker.endDate.format('YYYY-MM-DD HH:mm');
        self.fetchSales();
    });
    var im = new Inputmask("99/99/9999 - 99/99/9999");
    im.mask($("#kt_daterangepicker_salesArticle_filter"));
  }

  async resetFilter(){
    this.searchForm.reset();
    $("#sales_article_filter_paymentTypeId").val(null).trigger('change');
    $("#sales_article_filter_posId").val(null).trigger('change');
    $("#sales_article_filter_operatorId").val(null).trigger('change');
    $('#filter_groupId').val(null).trigger("change");
    $('#filter_groupTypeId').val(null).trigger("change");

    var pickerElement = $("#kt_daterangepicker_salesArticle_filter");
    var today = new Date();
    $(pickerElement).data('daterangepicker').setStartDate(new Date());
    $(pickerElement).data('daterangepicker').setEndDate(new Date());
    $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
  }

  filterByDate(event:KeyboardEvent){
    if(event.key=="Enter" || event.key=="Tab"){
       var pickerElement = $("#kt_daterangepicker_salesArticle_filter");
       $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }
  }

  async fetchGroups(){
    try{
    var response = await this.groupRestService.getGroups('', null, this.data.user.clientId, 1, 100000);
    this.groups = response.data;
    }
    catch(err){
        if (err.status == 401) {
            this.data.logout();
        }
        else{
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant(err.error['Message']), this.translate.instant("_Error"));
        }
      }
  }

  async fetchGroupTypes(){
    try{
    var response = await this.groupTypeRestService.getGroupTypes(null, this.data.user.clientId, 1, 10000);
    this.groupTypes = response.data;
    }
    catch(err){
        if (err.status == 401) {
            this.data.logout();
        }
        else{
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant(err.error['Message']), this.translate.instant("_Error"));
        }
      }
  }


  async fetchCurrencies(){
    try{
    this.currencies = await this.currencyRestService.getCurrencies();
    }
    catch(err){
        if (err.status == 401) {
            this.data.logout();
        }
        else{
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant(err.error['Message']), this.translate.instant("_Error"));
        }
      }
  }

  async fetchPaymentTypes(){
    try{
    var response = await this.paymentTypeRestService.getPaymentTypes(this.data.user.clientId, '', 1, 9999);
    this.paymentTypes=response.data;
    }
    catch(err){
        if (err.status == 401) {
            this.data.logout();
        }
        else{
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant(err.error['Message']), this.translate.instant("_Error"));
        }
      }
  }

  async fetchPOS(){
    try{
    var response = await this.pointOfSaleRestService.getPointsOfSale(this.data.user.clientId, 1,10000);
    this.pointsOfSale=response.data;
    }
    catch(err){
        if (err.status == 401) {
            this.data.logout();
        }
        else{
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant(err.error['Message']), this.translate.instant("_Error"));
        }
      }
  }

  async fetchOperators(){
    try{
    var response = await this.userAccountRestService.getUserAccounts('', true, null, this.data.user.clientId, null, 1, 10000);
    this.operators=response.data;
    }
    catch(err){
        if (err.status == 401) {
            this.data.logout();
        }
        else{
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant(err.error['Message']), this.translate.instant("_Error"));
        }
      }
  }

  async fetchSales(){
    try {
        this.emptyData=false;
        if(this.dateFrom == undefined)
            this.dateFrom=this.datePipe.transform((new Date()), 'yyyy-MM-dd HH:mm');
        if(this.dateTo == undefined)
            this.dateTo=this.datePipe.transform((new Date()), 'yyyy-MM-dd HH:mm');

        var response = await this.reportRest.getSalesByArticle(this.data.user.clientId, this.selectedArticle!=undefined?this.selectedArticle.id:null, this.posIds, this.paymentTypeIds, this.operatorIds, this.groupIds, this.groupTypeIds, this.dateFrom, this.dateTo, this.page, this.perPage);
        this.sales = response;
        if(this.sales.length==0)
            this.emptyData=true;
        // this.totalData = response.articleSalesReportItem.totalCount;
        // if (this.totalData == 0)
        //     this.emptyData = true;
        // else
        //     this.emptyData = false

        // if (this.firstLoad) {
        //     this.maxPage = response.articleSalesReportItem.totalPages;
        //     this.maxPage = Math.floor(this.maxPage);
        //     this.firstPage = 1;
        //     if (this.maxPage > 5)
        //         this.lastPage = 5;
        //     else
        //         this.lastPage = this.maxPage;
        //     this.enablePrevious();

        //     this.firstLoad = false;
        // }
        // this.initPages();
      }
      catch(err){
        if (err.status == 401) {
            this.data.logout();
        }
        else{
            toastr.options = this.data.toastrOptions;
            toastr.error(this.translate.instant(err.error['Message']), this.translate.instant("_Error"));
        }
      }
  }

  manualInputDate(id:string){
    var pickerElement = $("#"+id);
    $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
  }


  async selectArticle(a:ArticleModel){
    $("#itemCodeInput").val(a.code);
    this.selectedArticle=a;
    this.fetchSales();
  }
  selectArticleByCode(event:KeyboardEvent, c:string){
    if(event.key!='Enter')
        return;
        
    event.preventDefault();
    this.selectedArticle = this.itemLookupComponent.articles.find(x=>x.code==c);
    this.fetchSales();
  }
  enterOnRow(event:KeyboardEvent){
    if(event.key=='Enter'){
      event.preventDefault();
      var element = event.target as HTMLElement;
      element.click();
    }
  }

  openItemsOnEmpty($event:KeyboardEvent=null){
    var fieldValue = $("#itemCodeInput").val();

    if($event!=null){   
        if($event.key=="Enter"){
            this.enterCount++;
            clearTimeout(this.selectionModalTimer);
            this.selectionModalTimer = setTimeout(()=>{
                this.enterCount=0;
            }, 500);

            if(this.enterCount!=2)
                return;

            this.itemLookupComponent.openItemsModal();
        }
    }else{
        this.itemLookupComponent.openItemsModal(); 
    }
  }

  async search(name:string){
    await this.fetchSales();
  }

  //pagination
  initPages() {
    this.pages = [];
    for (var i = this.firstPage; i <= this.lastPage; i++) {
        this.pages.push(i);
    }
  }

  nextPage() {
      if ($("#kt_customers_table_next").hasClass("disabled"))
          return;

      if (this.page == 1 && this.firstPage == 1) {
          this.page++;
      }
      else {
          this.page++;
          if (this.counter == 1) {
              if (this.maxPage > (this.lastPage + this.offset)) {
                  this.firstPage++;
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }
              else if (this.maxPage > (this.firstPage + this.offset)) {
                  this.firstPage++;
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }
              else if (this.maxPage) {
                  this.firstPage = this.maxPage - this.offset;
                  this.lastPage = this.maxPage;
              }
              this.counter = 0;
          }
          else {
              if (this.maxPage > (this.lastPage + this.offset)) {
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else if (this.maxPage > (this.firstPage + this.offset)) {
                  this.lastPage = this.firstPage + this.offset;
                  this.initPages();
              }

              else {
                  this.firstPage = this.maxPage - this.offset;
                  this.lastPage = this.maxPage;
              }
          }
          this.counter++;
      }
      this.fetchSales();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  prevpage() {
      if ($("#kt_customers_table_previous").hasClass("disabled"))
          return;

      this.page--;
      if (this.counter == 1) {
          if (this.maxPage > this.lastPage + this.offset) {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          else {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          this.counter = 0;
      }
      else {
          if (this.maxPage > this.lastPage + this.offset) {
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
          else {
              if (this.page == 2)
                  this.firstPage = 1;
              else if (this.page != 1 && (this.firstPage - 1) > 1)
                  this.firstPage--;
              else
                  this.firstPage = 1;
              this.lastPage = this.firstPage + this.offset;
              this.initPages();
          }
      }
      this.counter++;
      this.fetchSales();
      this.enablePrevious();
      this._changeRef.detectChanges();

  }

  goToPage(p: number) {
      this.page = p;
      this.fetchSales();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  morePages() {
      if ($("#kt_customers_table_morePages").hasClass("disabled"))
          return;
      if (this.maxPage > this.lastPage + this.offset) {
          this.firstPage = this.lastPage;
          this.lastPage = this.firstPage + this.offset;
      }
      else {
          this.firstPage = this.maxPage - this.offset;
          this.lastPage = this.maxPage;
      }
      this.page = this.firstPage;
      this.fetchSales();
      this.initPages();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  perPageHandler() {
      this.firstLoad = true;
      this.page = 1;
      this.fetchSales();
      this.enablePrevious();
      this._changeRef.detectChanges();
  }

  enablePrevious() {
      if (this.page > 1) {
          $(document).ready(function () {
              $("#kt_customers_table_previous").removeClass("disabled");
          })
      }
      else if (this.page == 1) {
          $(document).ready(function () {
              $("#kt_customers_table_previous").addClass("disabled");
          })
      }

      if (this.page > 1 && this.page != this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_next").removeClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
          })
      }

      if (this.maxPage == 0) {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
              $("#morePages").addClass("disabled");
              $("#kt_customers_table_previous").addClass("disabled");
          })
      }

      if (this.page == this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_next").addClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_next").removeClass("disabled");
          })
      }

      if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
          $(document).ready(function () {
              $("#kt_customers_table_morePages").addClass("disabled");
          })
      }

      else {
          $(document).ready(function () {
              $("#kt_customers_table_morePages").removeClass("disabled");
          })
      }
  }
}