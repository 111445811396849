export class IncomingArticleModel{
    id:number;
    code:string;
    name:string;
    quantity:number;
    nc:number;
    totalNC:number;
    vpc:number;
    totalVPC:number;
    mpc:number;
    totalMPC:number;
    margin:number;
    marginPercentage:number;
    marginAmount:number;
    entryPrice:number;
    totalEntryPrice:number;

    discount1Percentage:number;
    discount1Amount:number;
    postDiscount1Price:number;
    totalDiscount1Amount:number;
    totalPostDiscount1Price:number;
    discount2Percentage:number;
    discount2Amount:number;
    postDiscountPrice:number;
    totalDiscount2Amount:number;
    totalPostDiscountPrice:number;
    combinedDiscountPercentage:number;
    combinedDiscountAmount:number;
    totalCombinedDiscountAmount:number;

    measureShort:string;
    taxId:number;
    taxName:string;
    taxPercentage:number;
    taxAmount:number
    totalTaxAmount:number;

    rememberLast:boolean;
    affectNC:boolean;
    affectVPC:boolean;
    affectMPC:boolean;
    
    pricesUpToDate:boolean;
    changedLastAverage:boolean;
    changeOrderIndex:number;
    addedOrderIndex:number;

    isNormative:boolean;
    g:number;
    gt:number;

    uniqueItemId:string;
}