export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
    '_Login_text': 'Sign in to start',
    '_Username': 'Username',
    '_Password':'Password',
    '_Login':'Continue',
    '_Error':'Error',
    '_General_data':'General',
    '_Countries':'Countries',
    '_Home':'Home',
    '_Add_country':'Add country',
    '_Country_name':'Country name',
    '_Actions':'Actions',
    '_Edit':'Edit',
    '_No_results':'No results found',
    '_No_results_text':'We cannot seem to find any items that match your search. Try adjusting your search or filter to find what you are looking for.',
    '_Submit':'Submit',
    '_Discard':'Discard',
    '_Added_country_text':'New country added successfully!',
    '_Edit_country':'Edit country',
    '_Updated_country_text':'Selected country updated successfully!',
    '_Towns':'Cities',
    '_Added_town_text':'New city added successfully!',
    '_Updated_town_text':'Selected city updated successfully!',
    '_Add_town':'Add town',
    '_City_name':'City name',
    '_Country':'Country',
    '_Select_country':'Select country',
    '_Group_types':'Group types',
    '_Add_group_type':'Add group type',
    '_Group_type_name':'Group type name',
    '_Client_name':'Client name',
    '_Filter_options':'Filter options',
    '_Client':'Client',
    '_Articles':'Articles',
    '_Groups':'Groups',
    '_Dashboard':'Dashboard',
    '_User_management':'User management',
    '_Admins':'Administrators',
    '_Clients':'Clients',
    '_Users':'Users',
    '_Customers_suppliers':'Customers and suppliers',
    '_Reset':'Reset',
    '_Apply':'Apply',
    '_Success':'Success',
    '_Edit_town':'Edit town',
    '_Added_group_type_text':'New group type added successfully!',
    '_Edit_group_type':'Edit group type',
    '_Updated_group_type_text':'Selected group type updated successfully!',
    '_Company_name':'Company',
    '_Address':'Address',
    '_Town':'City',
    '_Phone_number':'Phone',
    '_Email':'Email',
    '_Pdv_number':'PDV number',
    '_Price':'Price',
    '_Licence_expiration_date':'Licence expiration',
    '_Is_active':'Status',
    '_Add_client':'Add client',
    '_Active':'Active',
    '_Inactive':'Inactive',
    '_Point_of_sale':'POS',
    '_Retail_place':'Retail of place',
    '_Details':'Details',
    '_Search_clients':'Search clients',
    '_Date_from':'Date from',
    '_Date_to':'Date to',
    '_Client_deactivation_question_text':'You are about to deactivate this client, their users will not be able to login any more. Are you sure?',
    '_Yes_text':'Yes',
    '_No_text':'No',
    '_Client_deactivated_text':'You have successfully deactivated the selected client. His accounts and access have been suspended!',
    '_Deactivation_client_cancelled_text':'You have cancelled the deactivation of the selected client. Action not performed!',
    '_Warning':'Warning',
    '_Client_activation_question_text':'You are about to activate this client. Are you sure?',
    '_Client_activated_text':'You have successfully activated the selected client. His accounts and access have been activated!',
    '_Activation_cancelled_text':'You have cancelled the activation of the selected client. Action not performed!',
    '_Licence_information':'Licence information',
    '_Bank_information':'Bank information',
    '_Number1':'Number 1',
    '_Number2':'Number 2',
    '_First_name':'First name',
    '_Last_name':'Last name',
    '_User_Role':'User role',
    '_Status':'Status',
    '_Add_admin':'Add administrator',
    '_Group_name':'Group name',
    '_Add_group':'Add group',
    '_Group_type':'Group type',
    '_Added_group_text':'New group added successfully!',
    '_Search_groups':'Search groups',
    '_Search_admins':'Search admins',
    '_Admin_role':'Admin role',
    '_Admin_deactivation_question_text':'You are about to deactivate this administrator, His accounts and access will be suspended! Are you sure?',
    '_Admin_deactivated_text':'You have successfully deactivated the selected administrator. His accounts and access have been suspended!',
    '_Deactivation_admin_cancelled_text':'You have cancelled the deactivation of the selected administrator. Action not performed!',
    '_Admin_activation_question_text':'You are about to activate this administrator. Are you sure?',
    '_Admin_activated_text':'You have successfully activated the selected administrator. His accounts and access have been activated!',
    '_Activation_admin_cancelled_text':'You have cancelled the activation of the selected administrator. Action not performed!',
    '_Middle_name':'Middle name',
    '_Repeat_password':'Repeat password',
    '_Added_admin_text':'New administrator added successfully!',
    '_Updated_admin_text':'Selected administrator updated successfully!',
    '_Edit_admin':'Edit administrator',
    '_Added_client_text':'New client added successfully!',
    '_Edit_client':'Edit client',
    '_Updated_client_text':'Selected client updated successfully!',
    '_Select_all':'Select all',
    '_User_role':'User role',
    '_Add_user':'Add user',
    '_User_deactivation_question_text':'You are about to deactivate this user account, His accounts and access will be suspended! Are you sure?',
    '_User_deactivated_text':'You have successfully deactivated the selected user account. His accounts and access have been suspended!',
    '_Deactivation_user_cancelled_text':'You have cancelled the deactivation of the selected user account. Action not performed!',
    '_User_activation_question_text':'You are about to activate this user account. Are you sure?',
    '_User_activated_text':'You have successfully activated the selected user account. His accounts and access have been activated!',
    '_Activation_user_cancelled_text':'You have cancelled the activation of the selected user account. Action not performed!',
    '_Edit_user':'Edit user account',
    '_Search_users':'Search users',
    '_Retail_name':'Retail place name',
    '_Search_retail_places':'Search retail places',
    '_Add_retail_place':'Add retail place',
    '_Client_management':'Client management',
    '_Added_retailPlace_text':'New retail place added successfully!',
    '_Edit_retail_place':'Edit retail place',
    '_Updated_retailPlace_text':'Selected retail place updated successfully!',
    '_Updated_user_text':'Selected user account updated successfully!',
    '_Added_user_text':'New user account added sucessfully!',
    '_Search_towns':'Search towns',
    '_Search_countries':'Search countries',
    '_Add_point_of_sale':'Add point of sale',
    '_Point_of_sale_name':'Point of sale name',
    '_Is_mobile':'Is mobile',
    '_Added_pointOfSale_text':'New point of sale added successfully!',
    '_Quick_links':'Quick links',
    '_Manage_clients':'Client management',
    '_Manage':'Manage',
    '_English':'English',
    '_Bosnia_and_Herzegovine':'Bosnian',
    '_Cancel_question_text':'Are you sure you would like to cancel?',
    '_Yes_cancel_text':'Yes, cancel',
    '_No_return_text':'No, return',
    '_Sign_out':'Sign out',
    'Language':'Language',
    'My profile':'My profile',
    '_Edit_point_of_sale':'Edit point of sale',
    '_Updated_pointOfSale_text':'Selected point of sale updated successfully!',
    '_Search_point_of_sale':'Search point of sales',
    '_Account_settings':'Account settings',
    '_Profile_details':'Profile details',
    '_First_Last_name':'Full name',
    '_Sign_in_method':'Sign in method',
    '_Discard_changes_question_text':'Are you sure you want to discard changes?',
    '_Yes_discard_text':'Yes, discard',
    '_Updated_profile_text':'User account updated successfully!',
    '_Client_data_management':'Client data management',
    '_Repeat_PIN':'Repeat PIN',
    '_Edit_group':'Edit group',
    '_Tax':'Tax',
    '_Retail_place_name':'Retail place name',
    '_Is_normative':'Standardize',
    '_View_incoming_invoice':'View incoming inovoice',
    '_VP_total':'VP Total',
    '_View_stocktaking_difference':'View stocktaking difference',
    '_View_stocktaking':'View stocktaking',
    '_View_production_documents':'View production documents',
    '_No_data':'No data to export in PDF',
    '_View_PDF':'View PDF',
    '_Download':'Download document',
    '_View_PDF_without_prices':'View PDF (without prices)',
    '_View_Dispatch_Note_From_Outgoing_PDF':'View dispatch note from outgoing invoice',
    '_Print':'Print',
    '_Supplier_not_selected':'Supplier not selected',
     '_Most_sold_articles':'Best-selling items',
    '_Most_sold_articles_description':'Best-selling items in the selected period',
    '_Techical_support':'Techical support',
    '_Back_to_general_info':'Back to general info',
    '_Reset_normative_data':'Reset normative data',
    
}
