import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginModel } from 'src/app/models/login.model';
import { ClientRestService } from 'src/app/services/client.service';
import { DataService } from 'src/app/services/data.service';
import toastr from 'toastr';
import { TranslateService } from '../../translate';
import { FiscalRestService } from 'src/app/services/fiscal.service';

@Component({
  selector: 'erp-login',
  templateUrl: './login.component.html',
})

export class LoginComponent {

  public loginForm: UntypedFormGroup;
  public isSubmitted: boolean = false;

  get f() { return this.loginForm.controls; }

  constructor(
    private clientService: ClientRestService,
    private fiscalRest:FiscalRestService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private data: DataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  async login() {
    this.isSubmitted = false;

    if (this.loginForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    var obj = this.createObject();

    try {
      var response = await this.clientService.login(obj) as any;
      if (response.id != null || response.id != undefined) {
        this.setLoggedUser(response);
      }
      var config = await this.fiscalRest.getConfigForClient(response.clientId);
      if(config!=undefined){
        this.data.setFiscalType(config.fiscalTypeCode);
      }
      this.router.navigate(["home"]);
    }
    catch (err) {
      this.showError(err);
    }
  }

  setLoggedUser(user) {
    sessionStorage.setItem("logged_erp_client", JSON.stringify(user));
    this.data.checkIfUserIsLogged();
  }

  showError(err) {
    toastr.options = this.data.toastrOptions;
    toastr.error(err.error['Message'], this.translate.instant("_Error"));
  }

  createObject() {
    var obj = new LoginModel();
    obj.username = this.loginForm.controls.username.value;
    obj.password = this.loginForm.controls.password.value;

    return obj;
  }
}