<div class="toolbar" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1"  style="font-size:22px!important;">{{'_Fiscal_reports' | translate}}</h1>
            <span class="h-20px border-gray-600 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-gray-600">
                    <a [routerLink]="['/home']" class="text-gray-600 text-hover-primary" style="font-size:16px!important">{{'_Home' | translate}}</a>
                </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-600 w-5px h-2px" style="font-size:16px!important"></span>
                </li>
                <li class="breadcrumb-item text-gray-600" style="font-size:16px!important">{{'_Fiscal_reports' | translate}}</li>
            </ul>
        </div>
    </div>
</div>


<div class="post d-flex flex-column" id="kt_post" style="width:100%">
    <div id="kt_content_container" style="margin-top:25px;margin-bottom:25px;">
        <div class="row">
            <div class="col-lg-12 mb-5">
                <app-print-fiscal-report></app-print-fiscal-report>
            </div>
            <div class="col-lg-12 mb-5">
                <app-money-deposit></app-money-deposit>
            </div>
            <div class="col-lg-12 mb-5">
                <app-money-withdrawal></app-money-withdrawal>
            </div>
            <div class="col-lg-12 mb-5">
                <app-print-duplicate></app-print-duplicate>
            </div>
        </div>
    </div>
</div>
