import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { GroupModel } from "../models/group.model";
import { DataService } from "./data.service";

@Injectable()
export class GroupRestService {

    constructor(
        private http: HttpClient,
        private data: DataService) { }


    async getGroups(name: string, grouptypeId: number, clientId: number, page: number, pageSize: number) {
        var params = "?Page=" + page + "&PageSize=" + pageSize;

        if(name!=null && name!='' && name!=undefined)
            params+="&Name="+name;

        if (grouptypeId != null && grouptypeId != undefined && grouptypeId != 0 && !isNaN(grouptypeId))
            params += "&GroupTypeId=" + grouptypeId;

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "&ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/Group/Filter" + params).toPromise() as any;
    }

    async updateGroup(obj: GroupModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Group/Update", obj).toPromise() as any;
    }

    async createGroup(obj: GroupModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/Group/Create", obj).toPromise() as any;
    }

    async getTaxesByClientId(clientId:number){
        var params = "?";

        if (clientId != null && clientId != undefined && clientId != 0 && !isNaN(clientId))
            params += "ClientId=" + clientId;

        var config = environment.apiUrl;
        return await this.http.get(config + "api/Tax/GetTaxesByClientId" + params).toPromise() as any;
    }
}