import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeUserPasswordModel } from 'src/app/models/changeUserPassword.model';
import { ChangeUserPINModel } from 'src/app/models/changeUserPIN.model';
import { ClientModel } from 'src/app/models/client.model';
import { ClientUserRoles } from 'src/app/models/clientUserRoles.model';
import { FiscalizationConfigModel } from 'src/app/models/fiscalizationConfig.model';
import { FiscalTypeModel } from 'src/app/models/fiscalType.model';
import { GeneralUserSettingsModel } from 'src/app/models/generalUserSettings.model';
import { UserAccountModel } from 'src/app/models/userAccount.model';
import { ClientRestService } from 'src/app/services/client.service';
import { DataService } from 'src/app/services/data.service';
import { FiscalRestService } from 'src/app/services/fiscal.service';
import { UserAccountRestService } from 'src/app/services/userAccount.service';
import { TranslateService } from 'src/app/translate';
import toastr from 'toastr';

declare var $: any;
declare var Swal:any;
@Component({
  selector: 'app-fiscalization-settings',
  templateUrl: './fiscalization-settings.component.html',
})
export class FiscalizationSettingsComponent {

  public resizeObserver:ResizeObserver;
  public formGroup: FormGroup;
  public resetformGroup: UntypedFormGroup;
  public resetformGroupPin: UntypedFormGroup;
  private generalSettings: GeneralUserSettingsModel;
  private fiscalConfig: FiscalizationConfigModel;
  public isSubmitted:boolean=false;
  public isSubmittedPass:boolean=false;
  public isSubmittedPin:boolean=false;
  public changePassword:boolean=false;
  public changePin:boolean=false;
  public fiscalTypes:FiscalTypeModel[]; 
  public fiscalTypeId:number|null;
  public fiscalTypeCode:string|null;
  @ViewChild('fiscalTypeSelect') fiscalTypeSelect:ElementRef;

  get f() { return this.formGroup.controls; }
  get f2() { return this.resetformGroup.controls; }
  get f3() { return this.resetformGroupPin.controls; }

  constructor(
    public data: DataService,
    private formBuilder: UntypedFormBuilder,
    private _changeRef: ChangeDetectorRef,
    private translate: TranslateService,
    private userAccountRest: UserAccountRestService,
    private fiscalRest: FiscalRestService,
    private clientRest: ClientRestService
  ) { }

  ngOnInit() {
    this.fetchFiscalTypes();
    this.createForm();
  }

  async fetchFiscalTypes(){
    this.fiscalTypes=await this.fiscalRest.getFiscalTypes();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      skipAfterMPC:[true],
      commandPath: [''],
      answerPath: [''],
      fiscalTypeId: [null, Validators.required],
      fiscalDevice: [0, Validators.required],
      operatorName: ['', Validators.required],
      operatorCode: ['', Validators.required],
    })

    var self=this;
    $("#fiscalTypeSelect").select2();
      $('#fiscalTypeSelect').on("change", function () {
          self.fiscalTypeSelect.nativeElement.value = $(this).val();
          self.fiscalTypeId = $(this).val();
          self.fiscalTypeCode = self.fiscalTypes.find(x=>x.id==self.fiscalTypeId).code;
          self.formGroup.controls.fiscalTypeId.setValue($(this).val());
          if(self.fiscalTypeCode=='TRI')
            self.setDummyValues();
          else 
            self.resetDummyValues();
      });

    this.setSettingsValues();
    this.setConfigValues();
  }
  setDummyValues(){
    if(this.f.operatorName.value==undefined || this.f.operatorName.value==null || this.f.operatorName.value=='')
      this.f.operatorName.setValue('-');
    if(this.f.operatorCode.value==undefined || this.f.operatorCode.value==null ||this.f.operatorCode.value=='')
      this.f.operatorCode.setValue('-');
    if(this.f.fiscalDevice.value==undefined ||this.f.fiscalDevice.value==null || this.f.fiscalDevice.value=='')
      this.f.fiscalDevice.setValue(0);
  }
  resetDummyValues(){
    if(this.f.operatorName.value=='-')
      this.f.operatorName.setValue(undefined);
    if(this.f.operatorCode.value=='-')
      this.f.operatorCode.setValue(undefined);
  }

  async setSettingsValues(){
    this.generalSettings = await this.clientRest.getUserSettings(this.data.user.id);
    if(this.generalSettings!=undefined)  
      this.formGroup.controls.skipAfterMPC.setValue(this.generalSettings.skipAfterMPC);
  }

  async setConfigValues() {
    this.fiscalConfig = await this.fiscalRest.getConfigForClient(this.data.user.clientId);
    if(this.fiscalConfig!=undefined){
      this.formGroup.controls.commandPath.setValue(this.fiscalConfig.commandPath);
      this.formGroup.controls.answerPath.setValue(this.fiscalConfig.answerFilePath);
      this.formGroup.controls.fiscalDevice.setValue(this.fiscalConfig.fiscalDeviceId);
      this.formGroup.controls.operatorName.setValue(this.fiscalConfig.operatorName);
      this.formGroup.controls.operatorCode.setValue(this.fiscalConfig.operatorCode);
  
      $("#fiscalTypeSelect").val(this.fiscalConfig.fiscalTypeId).trigger('change');
    }
  }

  discardChangesGeneralSettings(){
    var self = this;
    var question_text = this.translate.instant("_Discard_changes_question_text");
    var yes_text = this.translate.instant("_Yes_discard_text");
    var no_text = this.translate.instant("_No_return_text");
    Swal.fire({
        html: question_text,
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: yes_text,
        cancelButtonText: no_text,
        customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: 'btn btn-secondary'
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            this.isSubmitted = false;
            self.setSettingsValues()
        } else if (result.dismiss === Swal.DismissReason.cancel) {  
        }
    });
  }

  async updateGeneralSettings(){
    this.isSubmitted = false;
    if (this.formGroup.invalid) {
      this.isSubmitted = true;
    }
    if (!this.validateForm()) {
      this.isSubmitted = true;
    }
    if(this.isSubmitted)
      return;
    
    var obj = {
      Id:this.generalSettings.id,
      ClientUserAccountId:this.data.user.id,
      SkipAfterMPC:this.formGroup.controls.skipAfterMPC.value
    };

    try {
      await this.clientRest.updateUserSettings(obj);
      this.data.setFiscalType(this.fiscalTypes.find(x=>x.id==this.fiscalTypeId).code);
      toastr.options = this.data.toastrOptions;
      toastr.success(this.translate.instant('_Updated_user_settings_text'), this.translate.instant("_Success"));
    }
    catch (err) {
      toastr.options = this.data.toastrOptions;
      toastr.error(err.message, this.translate.instant("_Error"));
    }
  }

  discardChanges(){
    var self = this;
    var question_text = this.translate.instant("_Discard_changes_question_text");
    var yes_text = this.translate.instant("_Yes_discard_text");
    var no_text = this.translate.instant("_No_return_text");
    Swal.fire({
        html: question_text,
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: yes_text,
        cancelButtonText: no_text,
        customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: 'btn btn-secondary'
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            this.isSubmitted = false;
            self.setConfigValues()
        } else if (result.dismiss === Swal.DismissReason.cancel) {  
        }
    });
  }

  async updateConfiguration(){
    this.isSubmitted = false;
    if (this.formGroup.invalid) {
      this.isSubmitted = true;
    }
    if (!this.validateForm()) {
      this.isSubmitted = true;
    }
    if(this.isSubmitted)
      return;
    
    var obj = this.createEditObject();

    try {
      await this.fiscalRest.setConfig(obj);
      this.data.setFiscalType(this.fiscalTypes.find(x=>x.id==this.fiscalTypeId).code);
      toastr.options = this.data.toastrOptions;
      toastr.success(this.translate.instant('_Updated_fiscal_config_text'), this.translate.instant("_Success"));
    }
    catch (err) {
      toastr.options = this.data.toastrOptions;
      toastr.error(err.message, this.translate.instant("_Error"));
    }
  }

  validateForm(){
    var check = true;

    if (this.fiscalTypeId==0 || this.fiscalTypeId==undefined ||this.fiscalTypeId==null) {
        $("#fiscalTypeSelect").nextAll('span').first().addClass("is-invalid");
        check = false;
    }
    else {
        $("#fiscalTypeSelect").nextAll('span').first().removeClass("is-invalid");
    }
    return check;
  }

  createEditObject() {
    var obj = new FiscalizationConfigModel();
    obj.clientId = this.data.user.clientId;
    obj.commandPath = this.f.commandPath.value;
    obj.answerFilePath = this.f.commandPath.value;
    obj.fiscalTypeId = this.f.fiscalTypeId.value;

    var type = this.fiscalTypes.find(x=>x.id==obj.fiscalTypeId);
    obj.fiscalTypeCode = type.code;
    obj.fiscalTypeName = type.name;

    obj.fiscalDeviceId = this.f.fiscalDevice.value;
    obj.operatorName = this.f.operatorName.value;
    obj.operatorCode = this.f.operatorCode.value;
    obj.clientId = this.data.user.clientId;

    return obj;
  }
}