import { Component } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

declare var KTScroll: any;
declare var KTToggle: any;
declare var KTDrawer: any;
@Component({
  selector: 'erp-page-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  constructor(public data:DataService){
  }

  ngOnInit() {
    KTScroll.createInstances();
    KTToggle.createInstances();
    // KTDrawer.createInstances();
  }
}

