import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ProductionDocumentModel } from "../models/documents/productionDocument.model";
import { ProductionArticleModel } from "../models/documents/productionArticle.model";
import { DataService } from "./data.service";
import { DeleteDocumentDTO } from "../models/documents/deleteDocumentDTO";

@Injectable()
export class ProductionDocumentRestService{
    constructor(
        private http: HttpClient,
        private data: DataService) { }

    async getProductionDocuments(warehouseId:number, dateFrom:string, dateTo:string, clientId:number, page:number, pageSize:number){
        var params = "?FromDate=" + dateFrom + "&ToDate=" + dateTo + "&ClientId=" + clientId + "&Page=" + page + "&PageSize=" + pageSize;
        
        if(warehouseId!=undefined)
            params+="&WarehouseId="+warehouseId;
        
        var config = environment.apiUrl;
        return await this.http.get(config + "api/ProductionDocument/FilterProductionDocuments" + params).toPromise() as any;
    }

    async createProductionDocument(obj: ProductionDocumentModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ProductionDocument/CreateProductionDocument", obj).toPromise() as any;
    }

    async editProductionDocument(obj: ProductionDocumentModel) {
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ProductionDocument/UpdateProductionDocument", obj).toPromise() as any;
    }

    async getNextNumber(clientId:number){
        var params = "?ClientId=" + clientId + "&TypeCode=" + "PROD";
        var config = environment.apiUrl;
        return await this.http.get(config + "api/DocumentGeneral/GetNextNumber" + params).toPromise() as any;
    }

    async deleteProductionDocument(deleteId: string) {
        var dto = new DeleteDocumentDTO();
        dto.id=deleteId;
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ProductionDocument/DeleteProductionDocument", dto).toPromise() as any;
    }

    async getProductionDocumentsPDF(clientId:number, id:any){
        var obj={
            clientId:clientId,
            id:id
           }
        var config = environment.apiUrl;
        return await this.http.post(config + "api/ProductionDocument/GetProductionDocumentPDF", obj, {
            headers: {
                Authorization: 'Bearer ' + this.data.token,
                'Accept': 'application/pdf' 
            },
            responseType: 'blob'
        }).toPromise() as any;
    }
}